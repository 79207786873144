.checkbox_container {
    display: flex;
    justify-content: center;
    position: relative;
}
.checkbox {
    text-align: center;
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.checkbox + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
}
.checkbox + label::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 2px solid #1e90ff;
    border-radius: 0.25em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}
.checkbox:checked + label::before {
    background-image: url("./Icon_Checkbox_Checked.svg");
    background-size: 40px 30px;
}
