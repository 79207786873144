.files {
    margin-top: 5px;
    width: 100%;
    padding: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
    align-items: center;
    border-radius: 5px;
    background-color: var(--primary-200);
    display: flex;
    justify-content: space-between;
}
.fileName {
    font-weight: 500;
}
.fileSelect {
    width: 100%;
}
.titleContainer {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 8px;
}
.label {
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-200);
}
