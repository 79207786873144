.wrap {
    border-radius: 12px;
    box-shadow: 0 4px 24px 0 rgba(48, 49, 51, 0.16);
    background-color: var(--whitebase);
    padding: 16px 20px 16px 20px;
}

.header {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    border-bottom: 1px solid var(--black-100);
}

.headerTitle {
    font-size: 18px;
    font-weight: bold;
    color: var(--black-200);
    margin-bottom: 0;
    margin-left: 8px;
}

.item {
    display: flex;
    align-items: center;
    padding: 10px 17px 10px 17px;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.04);
    margin-top: 4px;
}

.itemTitle {
    flex: 1;
    font-weight: 500;
    color: var(--black-base);
    font-size: 14px;
    margin-bottom: 0;
}

.iconWrap {
    margin-left: 2px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.body {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    margin-top: 10px;
}

.icon {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
}

.note {
    font-size: 14px;
    font-weight: 500;
    color: var(--error-500);
    margin-top: 8px;
    margin-bottom: 0;
}

.tickCircleIcon {
    background-color: #fff;
    border: 3px solid var(--success-400-base);
    width: 24px;
    height: 24px;
    background-color: #fff;
    border-radius: 50%;
    margin: 7px 0;
    position: relative;
}

.tickIcon {
    width: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.warningIcon {
    width: 18px;
    height: 18px;
    margin-bottom: -2px;
}
