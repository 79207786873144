.wrap {
    padding: 8px 12px;
    border-radius: 10px;
    background-color: #e9f4ff;
    margin-top: 16px;
}

.loadingWrapper {
    position: relative;
    height: 50px;
}

.progressWrap {
    margin-bottom: 8px;
}

.progressTitle {
    font-size: 12px;
    font-weight: 600;
    color: #666;
    margin-bottom: 2px;
    text-transform: uppercase;
}

.progressItemWrap {
    margin-bottom: 18px;
}

.progressBarWrap {
    position: relative;
    height: 25px;
    border-radius: 8px;
    background-color: var(--primary-200);
}

.progressBarValue {
    position: absolute;
    border-radius: 8px;
    background-color: var(--primary-400-base);
    height: 25px;
    display: flex;
    align-items: center;
}

.progressBarValueCount {
    border-radius: 8px;
    background-color: var(--primary-400-base);
    height: 25px;
    display: flex;
    align-items: center;
}

.progressBarNumber {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    margin-left: 8px;
    margin-bottom: 0;
}

.totalProgressWrap {
    margin-bottom: 9px;
}

.totalProgressTitle {
    font-size: 12px;
    font-weight: 600;
    color: #666;
    margin-bottom: 2px;
    text-transform: uppercase;
}

.totalProgressBarWrap {
    position: relative;
    height: 25px;
    border-radius: 8px;
}

.attemptedHourWrap {
    position: absolute;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: -6px;
}

.attemptedHourPointer {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #ff8a38;
}

.attemptedHourLine {
    height: 34px;
    width: 2px;
}

.firstItem,
.secondItem,
.thirdItem,
.fourthItem {
    position: absolute;
    border-radius: 8px;
    height: 25px;
    display: flex;
    align-items: center;
}

.firstItem {
    z-index: 3;
}

.secondItem {
    z-index: 2;
}

.thirdItem {
    z-index: 1;
}

.hourItemWrap {
    display: flex;
    margin-bottom: 2px;
}

.hourItem {
    display: flex;
    align-items: center;
    flex: 1;
}

.hourItemLeft {
    margin-right: 30px;
}

.hourTitleWrap {
    flex: 1;
    display: flex;
    align-items: center;
}

.hourTitle {
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 500;
    color: var(--black-300);
}

.hourDescription {
    margin-bottom: 0;
    text-align: right;
    font-size: 18px;
    font-weight: bold;
    color: var(--black-base);
}

.hourStatus {
    width: 13px;
    min-width: 13px;
    height: 13px;
    border-radius: 4px;
    margin-bottom: 0;
    margin-right: 8px;
}

.divider {
    height: 1px;
    background: #fff;
    margin-bottom: 16px;
}

.scoreInfoWrap {
    display: flex;
    gap: 8px;
}

.scoreInfo,
.scoreInfoItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 8px;
    border-radius: 8px;
    background-color: #fff;
}

.scoreInfoItem {
    flex: 1;
}

.scoreTitle {
    font-size: 13px;
    font-weight: bold;
    color: #666;
    text-transform: uppercase;
}

.score {
    font-size: 16px;
    font-weight: bold;
    color: #212121;
}
