.wrap {
    border-radius: 12px;
    box-shadow: 0 4px 24px 0 rgba(48, 49, 51, 0.16);
    padding: 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.headerWrap {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
}

.title {
    font-size: 21px;
    font-weight: 800;
    color: #939393;
    margin-bottom: 0;
    margin-left: 11px;
}

.divider {
    background-color: #5dc389;
    height: 1px;
    width: 50%;
    margin-bottom: 16px;
}

.infoWrap {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.infoItem {
    display: flex;
    align-items: center;
    margin-right: 40px;
}

.infoItem__color {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 8px;
}

.loaColor {
    background-color: #5540fb;
}

.returnColor {
    background-color: #bbb3fd;
}

.infoItem__title {
    font-size: 14px;
    font-weight: 500;
    color: #212121;
    margin-bottom: 0;
}

.chartWrap {
    flex: 1;
    display: flex;
    align-items: flex-end;
}
