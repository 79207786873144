.wrap {
    padding: 0 10px;
}
.titleSignContainer {
    display: flex;
    padding-top: 20px;
    flex-direction: column;
    align-items: center;
}
.titleSign {
    margin-top: 20px;
    font-family: Poppins;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-200);
}
.applyToLessons {
    font-family: Poppins;
    font-size: 16px;
    margin-top: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-200);
}
.tableLesson {
    height: 256px;
    max-height: 256px;
}

.selectLabel {
    font-family: Poppins;
    font-size: 10px;
    margin-bottom: 5px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-300);
}

.filterLesson {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    margin-top: 8px;
}

.selectItem {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.subTitleSign {
    margin-bottom: 20px;
    margin-top: 8px;
    font-family: Poppins;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #000;
}
.subLabel {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-200);
}
.instructorBox {
    display: flex;
    padding: 12px 18px 12px 18px;
    flex-direction: row;
    border-radius: 20px;
    margin-top: 8px;
    border: solid 1px var(--black-100);
    gap: 16px;
}

.studentName {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}

.studentAvatar {
    width: 40px;
    height: 40px;
    border-radius: 20px;
}

.listStudent {
    height: 224px;
    display: flex;
    margin-top: 8px;
    flex-direction: column;
    overflow: auto;
}

.studentItem {
    display: flex;
    flex-direction: row;
    padding: 6px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    gap: 12px;
}

.studentDetail {
    display: flex;
    align-items: center;
    gap: 12px;
}

.selectStudentIcon {
    display: none;
}

.studentItem:hover {
    background-color: var(--primary-100);
}

.studentItem:hover .selectStudentIcon {
    display: block;
}

.totalStudent {
    font-family: Poppins;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-200);
}

.instructorInfo {
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: 8px;
}

.searchBar {
    width: 100%;
    margin-top: 16px;
}
.student {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    align-items: center;
    gap: 8px;
}
.line {
    border-top: 1px solid var(--black-100);
    margin-top: 12px;
}

.separateLine {
    background-color: var(--black-100);
    height: 1px;
    margin-top: 39px;
    margin-bottom: 39px;
    width: 100%;
}

.eventContainer {
    padding: 16px;
    border-radius: 12px;
    background-color: #f5f5f5;
    width: 100%;
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.files {
    margin-top: 5px;
    width: 100%;
    padding: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
    align-items: center;
    border-radius: 5px;
    background-color: var(--primary-200);
    display: flex;
    justify-content: space-between;
}
.fileName {
    color: rgba(0, 0, 0, 0.4);
    font-weight: 500;
}
.fileSelect {
    width: 100%;
}
.labelCheckLesson {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-200);
}
.checkContainer {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 8px;
}
.eventAction {
    display: flex;
    flex-direction: row;
    gap: 14px;
}
.scanQrButton {
    border-radius: 8px;
    background-color: var(--black-100);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 8px 0px 8px;
}
.label {
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-200);
}
.labelUserId {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-200);
}

.valueUserId {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: var(--primary-400-base);
}

.instructorName {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-base);
}

.instructorDetail {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.nameBox {
    display: flex;
    flex: 1;
    margin-top: 10px;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
}

.avatar {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 12px;
}

.title {
    font-size: 28px;
    font-weight: bold;
    line-height: 1.41;
    color: #666666;
    padding-bottom: 14px;
    border-bottom: 1px solid #62b1ff;
    margin-bottom: 30px;
}

.date {
    display: flex;
    align-self: flex-start;
    margin-bottom: 24px;
    padding: 6px 12px 6px 12px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    border: solid 1px var(--black-100);
    background-color: var(--whitebase);
}

.textDate {
    font-family: Poppins;
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    margin-left: 5px;
    color: var(--black-300);
}

.calendarIcon {
    width: 10px;
    height: 10px;
}

.labelWithSpace {
    font-family: Poppins;
    font-size: 16px;
    margin-top: 32px;
    font-weight: 500;
    margin-bottom: 0px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-300);
}

.buttonSubmit {
    display: flex;
    margin-top: 40px;
    flex-direction: row;
    justify-content: space-between;
}
.titleCheckbox {
    margin-left: 10px;
    white-space: nowrap;
    font-weight: 600;
    font-size: 14px;
}
.checkbox {
    margin-top: 32px;
    cursor: pointer;
    text-transform: uppercase;
    padding: 8px 8px 8px 8px;
    height: 32px;
    border-radius: 6px;
    border: solid 1px var(--primary-400-base);
    display: flex;
    align-items: center;
    align-self: flex-start;
    flex-direction: row;
}
.header {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-direction: row;
    width: 100%;
    align-self: flex-start;
}
.majorDisable {
    opacity: 0.6;
    pointer-events: none;
}
.hintMajor {
    color: rgba(0, 0, 0, 0.4);
    margin-bottom: 0px;
    margin-top: 10px;
}

.event {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.eventTime {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-300);
}

.eventIcon {
    width: 30px;
    height: 30px;
}

.eventSeparator {
    width: 13px;
    height: 1px;
    background-color: var(--black-200);
    margin-left: 10px;
    margin-right: 10px;
}

.eventsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.nameBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.checkInBox {
    border-radius: 8px;
    border: solid 1px var(--black-100);
    background-color: var(--whitebase);
    padding: 4px;
    display: flex;
    gap: 2px;
    align-items: center;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
}

.signature__img {
    width: 15px;
    height: 8px;
    object-fit: fill;
}
.dateInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dateInfo__text {
    font-size: 8px;
    font-weight: 600;
    color: #666666;
}
.statusWrap {
    display: flex;
    margin-left: 4px;
    align-items: center;
}

.signatureWrap {
    padding: 7px 5px;
    display: flex;
    align-items: center;
    margin-right: 8px;
}

.signature__statusIcon {
    width: 18px;
    height: 18px;
    margin-right: 10px;
}

.signature__approval {
    background-color: #e8faef;
}
.titleTable {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;
    margin-bottom: 14px;
}

.applyToLessons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}
.notesTitle {
    margin-top: 20px;
}

.loadingView {
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
}
