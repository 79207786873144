.body {
    overflow: auto;
    max-height: 65vh;
    padding: 0 10px;
}

.title {
    font-size: 37px;
    font-weight: bold;
    color: #212121;
    margin-bottom: 0px;
    padding: 0 10px;
}

.divider {
    background-color: #62b1ff;
    margin: 10px 0 20px 0;
}

.actionBtnWrap {
    display: flex;
    justify-content: flex-end;
    padding: 30px 0;
}

.spinAlign {
    margin: auto;
    display: block;
}
.cancelBtn {
    margin-right: 20px;
}

.filterBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.totalBlock {
    border-radius: 4px;
    background-color: var(--primary-100);
}

.totalValue {
    font-size: 32px;
    font-weight: 800;
    line-height: 1.63;
    text-align: left;
    color: var(--primary-400-base);
}
