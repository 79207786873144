.wrap {
    margin: 20px 0;
}

.filterBody {
    border-radius: 12px;
    background-color: var(--primary-100);
    padding: 0 20px 20px;
}

.iconWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: -20px;
}

.closeIconWrap {
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.closeIcon {
    width: 20px;
    height: 20px;
}

.filterBtnWrap {
    display: flex;
    align-items: flex-end;
}

.filterBtnWrap__apply {
    margin-top: 20px;
    align-items: flex-start;
}

.line {
    flex: 1;
    height: 2px;
    background-color: var(--primary-400-base);
    margin-right: 25px;
}

.filterBtn {
    width: 140px;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.filterBtnClear {
    margin-right: 10px;
}
