.wrap {
    padding: 20px 44px;
}

.headerTitle {
    font-size: 37px;
    font-weight: bold;
    border-bottom: 1px solid var(--primary-400-base);
}

.avatar {
    width: 100%;
}

.advisorName {
    font-size: 32px;
    font-weight: bold;
    color: var(--black-base);
    margin-bottom: 0;
}

.infoWrap {
    background-color: #f5f5f5;
    border-radius: 12px;
    padding: 16px;
}

.studentPhoneEmail {
    display: flex;
    align-items: center;
    gap: 12px;
}

.studentPhoneWrap {
    display: flex;
    flex: 4;
    align-items: center;
}

.studentEmailWrap {
    display: flex;
    flex: 5;
    align-items: center;
}

.studentPhoneIconWrap {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.studentPhoneIcon {
    width: 24px;
    height: 24px;
}

.studentPhone__value {
    font-size: 16px;
    font-weight: 500;
    color: var(--primary-400-base);
    margin-bottom: 3px;
    word-break: break-all;
}

.studentAddress {
    display: flex;
    align-items: center;
    margin-top: 6px;
}

.messageBtnWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 40px;
}
