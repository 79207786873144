.headerButton {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-left: 24px;
    border: solid 1px var(--primary-400-base) !important;
    background-color: #fbfbfb !important;
    color: var(--black-base) !important;
    font-weight: 600 !important;
}

.selectedStaffButton {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    border: 1px solid var(--primary-400-base);
    background: #fbfbfb;
    border-radius: 8px;
    padding: 4px;
    padding-left: 16px;
    margin-left: 16px;
    font-weight: 500;
    color: var(--black-300);
}

.selectedStaffButtonInModule {
    display: flex !important;
    flex-direction: column !important;
}

.selectedStaffFullName {
    font-weight: 500;
    color: var(--black-base);
    display: flex-inline;
    gap: 5px;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    object-fit: contain;
}
