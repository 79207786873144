.formTitle {
    font-size: 24px;
    font-weight: bold;
    color: var(--black-300);
    text-transform: uppercase;
    line-height: 1.14;
    padding-bottom: 16px;
    margin-bottom: 16px;
}

.buttonWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    gap: 20px;
}
