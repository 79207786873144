.wrap {
    padding: 0 40px;
}

.title {
    font-size: 37px;
    font-weight: bold;
    line-height: 1.41;
    color: #666666;
    padding-bottom: 14px;
    border-bottom: 1px solid #62b1ff;
    margin-bottom: 30px;
}

.body {
    margin-bottom: 30px;
    overflow: auto;
    max-height: 60vh;
}

.body__title {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
    color: #212121;
    margin-bottom: 16px;
}

.taskInfo {
    border-radius: 12px;
    background-color: #e9f4ff;
    padding: 9px 16px;
    margin-bottom: 24px;
    display: inline-flex;
    align-items: center;
}

.taskName {
    font-size: 24px;
    font-weight: 800;
    color: #212121;
    margin-bottom: 0;
    margin-right: 16px;
}

.taskCode {
    font-size: 24px;
    font-weight: 800;
    color: #1e90ff;
    margin-bottom: 0;
}

.for {
    font-size: 24px;
    font-weight: bold;
    color: #212121;
}

.action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.cancelBtn {
    margin-right: 20px;
}

.reopenBody {
    padding: 0 2px;
    margin-top: 20px;
}
