.createBtnWrap {
    display: flex;
    justify-content: flex-end;
}

.plusIcon {
    height: 24px;
    width: 24px;
    margin-right: 6px;
}

.submitBtn {
    margin-left: 20px;
}
