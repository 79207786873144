.status {
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
    width: 80px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.status__read {
    background-color: #e5e5e5;
    color: var(--black-200);
}

.status__unread {
    background-color: var(--primary-400-base);
    color: var(--whitebase);
}

.fullnameWrap {
    display: flex;
    align-items: center;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 40px;
}

.fullname {
    margin: 0 0 0 10px;
}

.status__attending {
    width: 100px;
    background-color: var(--success-400-base);
    color: var(--whitebase);
}
