.tableHeaderTitleWrap {
    display: flex;
    align-items: center;
}

.tableHeaderTitle {
    margin-bottom: 0;
    margin-left: 10px;
}

.dateIcon {
    width: 24px;
    height: 24px;
}

.timeIcon {
    width: 20px;
    height: 20px;
}
.checkboxContainer {
    display: flex;
    flex-direction: column;
}
.isTransfer {
    font-size: 14px;
    color: blue;
    margin-left: 10px;
}
.timeCheck {
    font-size: 10px;
    font-weight: 600;
    color: var(--black-300);
}
.tickCircleIcon {
    background-color: #fff;
    border: 3px solid var(--success-400-base);
    width: 32px;
    height: 32px;
    background-color: #fff;
    border-radius: 50%;
    margin: 7px 0;
    position: relative;
    margin: 0 auto;
}
.tickCircleIconVerified {
    border: 3px solid var(--success-400-base);
    width: 32px;
    height: 32px;
    background-color: #18a957;
    border-radius: 50%;
    margin: 7px 0;
    position: relative;
    margin: 0 auto;
}

.tickIcon {
    width: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.rowItemIcon {
    width: 32px;
    height: 32px;
}

.attendanceTickCircleIcon {
    background-color: #fff;
    width: 24px;
    height: 24px;
    background-color: #fff;
    border-radius: 50%;
    margin: 7px 0;
    position: relative;
    margin-right: 20px;
}
.verifybtn {
    border-width: 0 !important;
    padding: 0 !important;
    color: black !important;
}
.actionButtonWrap {
    display: flex;
    align-self: stretch;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 24px;
    gap: 24px;
}
.actionDetailWrapPopup {
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: flex-end;
    flex: 1;
}
.popupTitle {
    font-size: 25px;
    font-weight: bold;
    color: var(--black-base);
}
.buttonPopupConfirm {
    margin-left: 10px;
}
.actionWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.attendanceTickCircleIcon:first-child {
    margin-right: 40px;
}

.attendanceTickCircleIcon:last-child {
    margin-left: 20px;
}

.attendanceTickIcon {
    width: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.attendancesWrap {
    display: flex;
}

.attendanceCompleted {
    background: var(--primary-400-base);
}

.attendanceInprogress {
    border: 2px solid var(--primary-400-base);
}

.attendanceTodo {
    border: 2px solid #e5e5e5;
}

.attendanceNotDone {
    background: #e5e5e5;
}
.smallTitle {
    font-size: 20px;
    color: var(--black-base);
}
.breakWrap {
    display: flex;
    gap: 12px;
    flex: 1;
}

.breakItem {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.breakItem__icon {
    width: 26px;
    height: 26px;
}
.attendanceOutIcon {
    width: 28px;
    height: 28px;
}
.attendanceIcon {
    height: 24px;
    width: 24px;
}
.checkInContainer {
    display: flex;
    align-items: center;
    position: relative;
    gap: 64px;
}

.checkInWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.checkInIconWrap {
    width: 28px;
    height: 28px;
    display: flex;
    background-color: white;
    border-radius: 14px;
    justify-content: center;
    align-items: center;
}

.checkInIcon {
    width: 24px;
    height: 24px;
}

.checkInTime {
    font-size: 12px;
    font-weight: 600;
    color: var(--black-300);
    margin-top: 3px;
    text-align: center;
    min-width: 60px;
}
.progressLine {
    height: 1px;
    background-color: var(--black-200);
    position: absolute;
    left: 0px;
    right: 0px;
    top: 13px;
    z-index: -1;
}

.attendanceContainer {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: center;
    align-items: center;
    position: relative;
}

.actionWarning path {
    fill: var(--warning-400-base);
}
.attCheckVerified svg {
    width: 34px;
    height: 34px;
}

.attCheck path {
    fill: var(--primary-400-base);
}
.attCheck svg {
    width: 34px;
    height: 34px;
}
.attClose path {
    stroke: var(--error-400-base);
}
.iconType {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-color: var(--black-100);
    margin-left: 10px;
}
