.baseButton {
    border-radius: 4px !important;
    padding: 7px 17px !important;
    height: auto !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    max-height: 40px;
}

.primary {
    color: #fff !important;
    background-color: #ccc !important;
    border: 2px solid transparent !important;
}

.secondary {
    border: 2px solid var(--primary-400-base) !important;
    color: var(--primary-400-base) !important;
    background-color: transparent !important;
}

.primary.isActive {
    background-color: #ff349b !important;
}

.primary:hover {
    background-color: #ccc !important;
    color: #fff;
    border-color: inherit;
}

.primary.isActive:hover {
    background-color: #ff7ec0 !important;
}

.baseAddCircleBtn {
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-color: #ff349b !important;
    height: 50px !important;
    width: 50px !important;
}

.baseAddCircleBtn:hover,
.baseAddCircleBtn:active {
    background-color: #ff7ec0 !important;
}

.iconPlus {
    height: 30px;
}

.uppercase {
    text-transform: uppercase !important;
}

.bold {
    font-weight: bold !important;
}

.disabled {
    pointer-events: none;
    color: #fff !important;
    background-color: #ccc !important;
    border-color: #ccc !important;
}

@media (max-width: 768px) {
    .baseButton {
        padding: 4px !important;
        font-size: 8px !important;
    }
}
