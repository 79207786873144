.root {
    width: 624px;
    background: var(--primary-100);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 33px 0 35px 0;
    border-radius: 12px;
    color: var(--black-base);
}

.title {
    text-align: center;
    font-size: 21px;
    line-height: 28px;
    font-weight: bold;
    margin-bottom: 16px;
    color: inherit;
}

.description {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    white-space: pre-line;
    color: inherit;
}
