.rsvpWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
}

.rsvpItem {
    padding: 6px 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    border-radius: 4px;
    background-color: #e5e5e5;
    color: #939393;
    text-transform: uppercase;
    cursor: pointer;
}

.rsvpItem:last-child {
    margin-right: 0;
}

.rsvpYesActive {
    background-color: var(--success-400-base);
    color: #fff;
}

.rsvpMaybeActive {
    background-color: var(--warning-400-base);
    color: #fff;
}

.rsvpNoActive {
    background-color: var(--error-400-base);
    color: #fff;
}
