.itemWrap {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #939393;
    border-bottom: 1px solid #939393;
    padding: 15px 0;
}

.itemHeaderWrap {
    display: flex;
    align-items: center;
    width: 100%;
}

.item__code {
    font-size: 20px;
    color: #1e90ff;
    margin-bottom: 0;
    margin-right: 16px;
}

.item__title {
    font-size: 24px;
    font-weight: bold;
    color: #212121;
    margin-bottom: 0;
    margin-right: 16px;
}

.item__time {
    font-size: 14px;
    font-weight: 500;
    color: #939393;
    margin-bottom: 0;
    margin-right: 16px;
}

.item__timeHighlight {
    font-weight: 600;
}

.rightWrap {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: stretch;
    height: 100%;
}

.balanceWrap {
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    border-radius: 4px;
    background-color: #e9f4ff;
    padding: 5px 12px;
    min-width: 100px;
}

.balanceWrap__inline {
    flex-direction: row;
    align-items: center;
}

.balance__title {
    font-size: 14px;
    font-weight: 500;
    color: #939393;
    margin-bottom: 0;
}

.balance__cost {
    font-size: 24px;
    font-weight: bold;
    color: #1e90ff;
    margin-bottom: 0;
    line-height: 1;
    margin-top: 4px;
}

.pastDue {
    color: var(--error-400-base) !important;
}

.due {
    color: var(--warning-400-base) !important;
}

.iconWrap {
    width: 32px;
    cursor: pointer;
}

.icon {
    width: 20px;
}
