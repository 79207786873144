.title {
    text-transform: uppercase;
    color: var(--black-200);
    font-size: 20px;
    font-weight: bold;
}

.statusWrap {
    padding: 16px 0;
}

.saveBtn {
    margin-left: auto;
}

.doneBtn {
    display: flex;
}
