.sigPad {
    margin: 0 0 20px;
    border: 1px solid #ccc;
    width: 100%;
    height: 300px;
}

.action {
    display: flex;
    align-items: center;
    margin: 0 0 20px;
}

.btnWrap {
    display: flex;
    justify-content: flex-end;
    flex: 1;
}

.clearBtn {
    margin-right: 20px;
}

@media (max-width: 768px) {
    .sigPad {
        height: 150px;
        max-width: 100%;
    }
}
