.root {
    display: flex;
    min-width: 1000px;
}

.flexColumn {
    flex-direction: column;
}

.selectPerson {
    margin-bottom: 30px;
}

.eventPreviewHeaderEditIcon {
    width: 25px;
    height: 25px;
}

.eventPreviewHeaderBookIcon {
    width: 20px;
    height: 20px;
}
