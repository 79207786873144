.container {
    display: flex;
    flex-direction: column;
    min-height: fit-content;
    gap: 16px;
    margin-bottom: 20px;
}

.header {
    font-size: 21px;
    font-weight: bold;
    line-height: 1.14;
    color: var(--black-200);
    text-transform: uppercase;
}

.section {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.itemsWrap {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
}

.item {
    min-width: 200px;
    min-height: 80px;
    padding: 12px;
    border-radius: 12px;
    border: solid 2px #f5f5f5;
    background-color: var(--whitebase);
    display: flex;
    flex-direction: column;
    gap: 12px;
    cursor: pointer;
}

.item:hover {
    background-color: var(--black-50);
}

.itemHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.headerLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.value {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
    color: var(--black-300);
}

.title {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    color: var(--black-300);
    text-transform: uppercase;
}

.iconWrap {
    padding: 12px;
    border-radius: 12px;
    background-color: #f5f5f5;
    display: flex;
}

.icon {
    fill: var(--black-200);
    height: 24px;
    width: 24px;
}

.linkItem {
    min-width: 320px;
}

.linkIconWrap {
    background-color: var(--primary-100);
}
