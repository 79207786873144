.wrap {
    margin-bottom: 60px;
}

.headerTitle {
    display: inline-block;
    margin-bottom: 0;
    padding: 8px 12px;
    border-radius: 8px;
    background-color: #f5f5f5;
    font-size: 21px;
    font-weight: bold;
    color: var(--black-300);
}

.actionWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
}

.checkbox {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    border-radius: 12px;
    border: solid 1px #e5e5e5;
    background-color: var(--whitebase);

    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: var(--black-base);
    text-transform: uppercase;
}

.checkbox > * + * {
    margin-right: 0;
    margin-left: 12px;
}

.checkbox label::before {
    border-color: #e5e5e5;
}

.checkbox.active {
    border: solid 1px var(--primary-400-base);
}

.checkbox.active label::before {
    border-color: var(--primary-400-base);
}

.checkmarkIcon {
    width: 24px;
    height: 24px;
}

.row.warning {
    background-color: #ffeff3;
}

.row.gray {
    background-color: #f5f5f5;
}

.row.total {
    background-color: #f7fbff;
}

.row.gray td,
.row.gray th {
    color: var(--black-300);
}

.row.total td,
.row.total th {
    font-weight: 500 !important;
}

.completedDateWrap {
    display: flex;
    align-items: center;
}

.completedDateInfo {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
}

.completedDateInfo__item {
    font-size: 13px;
    font-weight: 500;
    color: var(--black-300);
    margin-bottom: 3px;
}

.statsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 24px;
    margin-top: 16px;
    margin-bottom: 32px;
}

.statsBlock {
    flex: 1;
    padding: 16px;
    border-radius: 12px;
    box-shadow: 1px 2px 16px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px var(--black-100);
    background-color: var(--whitebase);
}

.statsTitle {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: #000;
    margin-bottom: 16px;
}

.statsSubtitle {
    font-size: 14px;
    color: var(--black-300);
    margin-top: -16px;
    margin-bottom: 16px;
}

.statsValue {
    font-size: 32px;
    font-weight: bold;
    line-height: 1.63;
    color: #000;
    margin: 0;
    position: relative;
}

.statsValue.due {
    line-height: 1.2;
}

.statsDate {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--black-200);
    margin: 0;
}

.statsLabel {
    position: relative;
    top: -4px;
    margin-right: 8px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
}

.statsLabel.due {
    color: var(--warning-400-base);
}

.statsLabel.pastDue {
    color: var(--error-400-base);
}

.notes {
    max-width: 350px;
}

.descText {
    font-size: 12px;
    color: var(--black-200);
}
