.wrap {
    margin-top: 10px;
}

.chooseTypeWrap {
    display: inline-block;
    min-width: 75%;
    margin-bottom: 16px;
}

.radioBtnWrap {
    display: flex !important;
    border: solid 1px #1e90ff;
    border-radius: 8px;
    width: 100%;
}

.radioBtn__item {
    border-right: 1px solid #1e90ff;
    padding: 12px !important;
    font-size: 16px !important;
    font-weight: bold;
    line-height: 1.33 !important;
    color: #939393 !important;
    margin: 0 !important;
    flex: 1;
}

.radioBtn__item:last-child {
    border-right: 1px solid transparent;
}

.radioBtn__active {
    color: #212121 !important;
}

.primaryDescWrap {
    margin-bottom: 24px;
}

.actionBtnWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
}

.cancelBtn {
    margin-right: 20px;
}
