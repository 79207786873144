.wrap {
    border-radius: 12px;
    box-shadow: 0 4px 24px 0 rgba(48, 49, 51, 0.16);
    background-color: var(--whitebase);
    padding: 16px 0 40px 40px;
    height: 100%;
}

.header {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    border-bottom: 1px solid var(--primary-300);
}

.headerTitle {
    font-size: 18px;
    font-weight: bold;
    color: var(--black-200);
    margin-bottom: 0;
    margin-left: 8px;
}

.body {
    overflow-y: auto;
    max-height: 450px;
    padding-right: 40px;
}

.item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--black-200);
    padding: 20px 0 10px 0;
}

.itemTitle {
    flex: 1;
    font-weight: 500;
    color: var(--black-base);
    margin-bottom: 0;
}

.itemDesc {
    display: flex;
    align-items: center;
}

.dueText {
    font-size: 14px;
    color: var(--primary-300);
    font-weight: 500;
    margin-bottom: 0;
    margin-right: 9px;
}

.redDueText {
    color: var(--error-300);
}

.datetime {
    font-weight: bold;
    color: var(--primary-400-base);
    margin-bottom: 0;
    font-size: 16px;
}

.redDateTime {
    color: var(--error-400-base);
}
