.headerInfoWrap {
    display: flex;
    margin-top: 10px;
    margin-bottom: 40px;
}

.headerInfoLeft {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-radius: 12px;
    margin-right: 20px;
    background-color: #f5f5f5;
    padding: 0px 20px;
}

.headerInfoLeftItem {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 3px 0px;
}

.headerInfoLeft__title {
    font-size: 14px;
    margin-bottom: 0;
    flex: 1;
    color: var(--black-300);
    font-weight: 500;
}

.headerInfoLeft__day {
    flex: 1;
    font-size: 20px;
    font-weight: bold;
    color: var(--primary-400-base);
    margin-bottom: 0;
    margin-right: 4px;
    text-align: right;
}

.headerInfoLeft__dateValueWrap {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
}

.headerInfoLeft__dateValue {
    font-size: 15px;
    font-weight: bold;
    color: var(--black-300);
    margin-bottom: 0;
    margin-right: 10px;
}

.headerInfoLeft__dateIconWrap {
    width: 30px;
    height: 30px;
    min-width: 30px;
    background: #1e90ff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.headerInfoLeft__dateIcon {
    height: 18px;
}

.headerInfoRightItem {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--success-400-base);
    padding: 10px 0px;
    margin-right: 10px;
    border-radius: 12px;
    max-height: 90px;
}

.headerInfoRightItemTarget {
    background-color: var(--secondary-500);
    margin-right: 0;
}

.headerInfoRightItem__title {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0px;
}

.headerInfoRightItemValueWrap {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.headerInfoRightItem__value {
    font-size: 35px;
    font-weight: bold;
    color: var(--whitebase);
    margin-bottom: 0;
}

.headerInfoRightItem__icon {
    margin-right: 10px;
    width: 32px;
    height: 32px;
}
