.statusWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 110px;
    padding: 2px 8px;
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
}

.statusText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.statusWrap:global(.pending) {
    background-color: transparent;
    color: transparent;
}
.statusWrap:global(.requested) {
    background-color: var(--black-200);
}
.statusWrap:global(.approved) {
    background-color: var(--success-400-base);
}
.statusWrap:global(.rejected) {
    background-color: var(--error-400-base);
}
.statusWrap:global(.disbursed) {
    background-color: var(--primary-400-base);
}
