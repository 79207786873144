.container {
    height: 310px;
    display: flex;
    flex-direction: row;
    gap: 24px;
    padding: 16px;
    border-radius: 12px;
    box-shadow: 1px 2px 16px 0 rgba(0, 0, 0, 0.04);
    background-image: linear-gradient(146deg, #ececec 30%, #bebebe 90%);
    align-items: center;
    justify-content: space-around;
    flex: 1;
}

.labelContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 264px;
}

.label {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--black-300);
}

.value {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.33;
    color: #000;
}

.labelWrap {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
}

.icon {
    width: 12px;
    height: 12px;
    border-radius: 24px;
    background-color: var(--success-400-base);
}

.iconDistant {
    background-color: var(--success-500);
}

.iconScheduled {
    background-color: var(--error-300);
}

.iconAttempt {
    background-color: var(--warning-400-base);
}

.iconRemaining {
    background-color: var(--black-200);
}

.iconProgress {
    background-color: var(--primary-400-base);
}

.divider {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
}

.labelDetailContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 16px 0;
}

.iconTransferHour {
    background-color: var(--primary-700);
}
