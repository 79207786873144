.root {
    padding: 0 40px 32px 40px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1;
    min-width: 0;
}

.header {
    margin-top: 50px;
    margin-bottom: 24px;
}

.screenWrap {
    display: flex;
    gap: 5px;
}

.reducePaddingRight {
    padding-right: 8px;
    max-height: 100vh;
    overflow-y: auto;
}

@media (max-width: 768px) {
    .root {
        padding: 0 12px 12px;
    }

    .header {
        margin-top: 46px;
    }
}
