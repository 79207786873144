.sapCheckointsContainer {
    overflow: hidden;
    border-radius: 12px;
    box-shadow: 0 4px 24px 0 rgba(48, 49, 51, 0.16);
    background-color: var(--whitebase);
}

.sapCheckpointIcon {
    width: 24px;
    height: 24px;
}

.sapCheckpointItem {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    color: #000;
    padding: 16px 24px;
}

.sapCheckpointItem:nth-child(odd) {
    background-color: #fbfbfb;
}

.sapCheckpointItem > div:nth-child(n + 2) {
    text-align: right;
}

.title {
    font-size: 21px;
    font-weight: 800;
    color: var(--black-200);
    margin-bottom: 0;
    padding-left: 11px;
}

.headerWrap {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
    text-transform: uppercase;
}
