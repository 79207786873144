.wrapper {
    margin-bottom: 40px;
}

.buttonWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
}

.cancelBtn {
    margin-right: 20px;
    text-transform: uppercase;
}

.admCategory {
    font-size: 16px;
    font-weight: bold;
    color: #212121;
}

.admCategoryId {
    font-weight: 500;
    color: #666;
}

.titleWrap {
    display: flex;
    align-items: center;
}

.lockIcon {
    display: block;
    float: left;
    margin-right: 5px;
}

.colorInfo {
    width: 24px;
    height: 24px;
    border-radius: 4px;
}
