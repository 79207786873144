.actionWrap {
    display: flex;
    justify-content: flex-end;
}

.summaryWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.itemWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.statusWrap {
    padding: 2px 6px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    text-align: center;
    color: var(--whitebase);
}

.status-pending {
    background-color: var(--black-100);
}

.status-processing {
    background-color: var(--warning-400-base);
}

.status-completed {
    background-color: var(--primary-400-base);
}
