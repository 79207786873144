.wrap {
    padding: 8px;
    border-radius: 12px;
    border: solid 1px var(--black-100);
}

.moduleInfo {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.moduleInfo__title {
    color: var(--primary-400-base);
    font-size: 14px;
    font-weight: 500;
    margin-right: 8px;
    text-transform: uppercase;
}

.moduleBody {
    display: flex;
    align-items: center;
}

.moduleBody__value {
    font-size: 18px;
    font-weight: bold;
    color: #000;
    padding-right: 6px;
}

.iconWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
}

.arrowDownIcon {
    width: 16px;
    height: 16px;
}

.menuItem {
    padding: 8px !important;
}

.menuItem__text {
    font-size: 16px;
    font-weight: 500;
}

.menuItem__active {
    background-color: #e9f4ff;
}
