.navigationWrap {
    display: flex;
    padding: 10px;
    height: 40px;
    border-bottom: solid 1px var(--black-100);
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.navigationIconWrap {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.navigationIconWrap__disabled {
    cursor: not-allowed;
}

.navigationIcon {
    width: 24px;
    height: 24px;
    fill: var(--black-100);
}

.navigationIcon__active {
    fill: var(--black-300);
}

.navigationSeparator {
    width: 1px;
    height: 24px;
    transform: rotate(-180deg);
    background-color: var(--black-100);
}
