.container {
    max-width: 320px;
    width: 320px;
    border-left: solid 1px var(--black-100);
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    flex-shrink: 0;
}

.section {
    padding: 0 12px;
}

.heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 8px;
    height: 42px;
    max-height: 42px;
    min-height: 42px;
}

.title {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.33;
    color: var(--black-200);
    text-transform: uppercase;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: var(--black-100);
    min-height: 1px;
}

.iconWrap {
    display: flex;
    cursor: pointer;
    align-items: center;
}

.icon {
    width: 20px;
    height: 20px;
}

.main {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.sectionWrap {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.infoHeading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
}

.info {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.subTitle {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.33;
    color: var(--primary-400-base);
}

.messageContainer {
    padding: 6px 12px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    cursor: pointer;
}

.messageContainer:hover {
    background-color: #f5f5f5;
}

.actionHeading {
    cursor: pointer;
}

.pinned:hover .removeWrap {
    display: flex;
}

.removeWrap:focus {
    display: flex;
    box-shadow: 0 0 0 2px var(--secondary-400-base), 0 3px 5px 0 rgba(48, 49, 51, 0.1), 0 0 1px 0 rgba(48, 49, 51, 0.05);
    background: var(--primary-100);
}

.titleWrap {
    display: flex;
    align-items: center;
    column-gap: 8px;
    overflow: hidden;
}

.titleHeading {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.33;
    color: #000;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.searchWrap {
    padding: 0 12px;
}

.messages {
    display: flex;
    flex-direction: column;
}

.loadingData {
    align-items: center;
}

.infoContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    min-width: 24px;
    min-height: 24px;
}

.fullName {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    color: #000;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.content {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--black-300);
    word-wrap: break-word;
    white-space: pre-wrap;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.notFound {
    display: flex;
    justify-content: center;
    padding: 12px;
}

.notFoundMessage {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--black-300);
    font-style: italic;
}

.emailAttachment {
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--black-300);
    font-style: italic;
}

.loadMoreText {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--black-300);
    font-style: italic;
    margin-left: 6px;
}
