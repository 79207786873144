.wrap {
    padding: 0 40px 30px;
}

.title {
    font-size: 37px;
    font-weight: 800;
    color: #666666;
    color: #666666;
    border-bottom: 1px solid #939393;
}

.buttonWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
}
