.wrap {
    height: 100%;
    background: #ccc;
    position: relative;
    overflow: auto;
    padding-top: 12px;
}

.headerContainer {
    position: sticky;
    top: 0;
    z-index: 2;
    background: #ccc;
}

.headerWrap {
    display: flex;
    background-color: #f5f5f5;
    border-radius: 12px;
    padding: 12px;
    margin: 0 50px 16px;
}

.headerWrapFromEmail {
    max-width: 1100px;
    margin: 0 auto 6px !important;
}

.webFormHeader {
    width: 1100px;
    min-width: 1100px;
    margin: 0 auto 6px !important;
}

.backBtn {
    margin-right: 20px;
}

.backBtnWrap {
    cursor: pointer;
}

.backIcon {
    width: 32px;
    height: 32px;
}

.headerTitleWrap {
    margin-right: 24px;
}

.headerTitle {
    font-size: 20px;
    font-weight: 800;
    color: #212121;
    line-height: 1.41;
    margin-bottom: 4px;
}

.headerTaskType {
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
    padding: 4px 8px;
    border-radius: 8px;
    background-color: #939393;
    margin-bottom: 0;
}

.taskCode {
    font-size: 20px;
    line-height: 40px;
    font-weight: 800;
    color: #1e90ff;
    margin-bottom: 0;
}

.reasonWrap {
    display: flex;
    gap: 24px;
    padding: 8px 24px;
    margin: 0 50px 16px;
    background-color: #ffffff;
    border-radius: 12px;
    border: solid 2px #1e90ff;
}

.reasonInput {
    flex: 1;
}

.headerAction {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
}

.body {
    margin: 0 60px;
}

.pageWrap {
    position: relative;
    margin-bottom: 30px;
}

.pageImg {
    margin-bottom: 10px;
    width: 100%;
}

.pageImg:last-child {
    margin-bottom: 0;
}

.lastPage {
    margin-bottom: 0;
}
