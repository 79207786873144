.formBody {
    margin-bottom: 24px;
}

.form__title {
    font-size: 16px;
    color: #666666;
    margin-bottom: 4px;
}

.iconCompleted {
    cursor: pointer;
    display: inline-block;
    margin-right: 20px;
}

.checkboxWrapper {
    display: flex;
    align-items: center;
    margin: 16px 0px;
}

.actionBtnWrap {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
}

.spinAlign {
    margin: auto;
    display: block;
}

.divider {
    /*background-color: #62b1ff;*/
    margin-top: 24px;
    margin-bottom: 16px !important;
}

.uploadTitle {
    margin-bottom: 6px;
    font-size: 16px;
    color: var(--black-300);
    text-transform: uppercase;
}

.uploadIconWrap {
    margin-top: 8px;
}

.uploadIcon {
    cursor: pointer;
}

.sectionTitle {
    font-size: 20px;
    font-weight: bold;
    color: var(--black-200);
    margin-bottom: 12px;
    text-transform: uppercase;
}

.colorContainer {
    display: grid;
    grid-template-columns: 24px 1fr;
    align-items: center;
}

.colorCode {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    display: inline-block;
}

.colorLabel {
    padding-left: 8px;
    font-size: 16px;
    display: inline-block;
}
