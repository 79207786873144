.termItem {
    background: #a5d3ff;
    width: 420px;
    height: 100%;
    min-height: 500px;
    border-radius: 8px;
    display: inline-block;
    margin-right: 20px;
}

.termItem__register {
    background-color: var(--warning-100);
}

.termItem:last-child {
    margin-right: 0;
}

.termBody {
    display: flex;
    flex-direction: column;
}

.termItemFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
}

.termHeader {
    background-color: var(--black-200);
    min-height: 100px;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 8px 12px;
    border-radius: 6px;
    margin: 4px 4px 0 4px;
}

.termHeader__register {
    cursor: pointer;
}

.termHeader__activeTerm {
    background: var(--primary-400-base);
}

.termHeader__activeRegistration {
    background: var(--warning-400-base);
}

.termTitle {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    max-width: 220px;
    white-space: pre-wrap;
    cursor: text;
    min-height: 70px;
}

.termTitle__register {
    color: var(--whitebase);
}

.termPercent {
    font-size: 16px;
    font-weight: bold;
    color: var(--black-base);
}

.registerOpen {
    font-size: 16px;
    font-weight: 600;
    color: var(--warning-100);
}

.termHeaderRight {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 6px;
}

.hourInfo {
}

.hourInfoItem {
    display: flex;
    height: 32px;
    align-items: center;
    border-radius: 4px;
    padding: 3px 16px;
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 600;
    background-color: var(--whitebase);
    color: var(--black-base);
}

.hourInfoItemTitle {
    margin-right: 12px;
}

.hourInfoItemValue {
    font-size: 18px;
}

.termItemBodyWrap {
    max-height: 600px;
    min-height: 600px;
    height: 600px;
    overflow: auto;
}

.iconBtn {
    border: none !important;
    background: transparent !important;
}

.moreIcon {
    height: 16px;
}

.searchWrap {
    margin-bottom: 6px;
}

.menuActionBody {
    max-height: 300px;
    overflow: auto;
}

.menuActionItem {
    padding: 6px 8px;
    font-size: 14px;
    border-radius: 4px;
    max-width: 300px;
    word-break: break-word;
    cursor: pointer;
    margin-bottom: 2px;
}

.menuActionItem:hover {
    background: rgb(235, 235, 235);
}

.menuActionIcon {
    width: 24px;
    height: 24px;
}

.menuActionTitle {
    margin-left: 11px;
}

.chooseTitleWrap {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.chooseTitle__icon {
    display: flex;
    margin-left: 6px;
    height: 60px;
}

.termNotSelectedLabel {
    color: #c9c9c9;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
}

.termSelectedLabel {
    color: #8ec7ff;
    font-size: 12px;
    font-weight: 500;
}
