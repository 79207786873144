.actionWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.addButton {
    height: 40px !important;
    justify-content: center;
    font-size: 16px;
}

.rightBtnWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.exportBtn {
    margin-right: 48px;
}

.columnBtn {
    margin-right: 24px;
    border: 0 !important;
}

.expandBtn {
    border: 0 !important;
}

.colPickerWrap {
    position: relative;
}

.divider {
    height: 2px;
    background-color: var(--primary-400-base);
    margin: 32px 0;
}
