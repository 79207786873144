.headerWrap {
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 5px;
}

.title {
    font-size: 32px;
    font-weight: 800;
    line-height: 0.75;
    color: var(--black-200);
    margin-bottom: 0;
    flex: 1;
}

.expandIconWrap {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.status {
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    width: 90px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.status__new {
    border: solid 1px var(--success-400-base);
    color: var(--success-400-base);
    background-color: #fff;
}

.status__overdue {
    background-color: var(--error-400-base);
    color: var(--whitebase);
}

.status__started {
    background-color: var(--warning-400-base);
    color: var(--whitebase);
}

.createBtnWrap {
    display: flex;
    justify-content: flex-end;
}

.plusIcon {
    height: 24px;
    width: 24px;
}

.statusInfoWrap {
    margin: 20px 0;
    display: flex;
    align-items: center;
}

.statusColItem {
    flex: 1;
    margin: 0 6px;
}

.assignedTo {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.enrollmentIds {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
}

.enrollmentIdItem {
    border-radius: 2px;
    background: var(--primary-600);
    color: var(--whitebase);
    padding: 2px 4px;
}
