.wrap {
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.title {
    font-size: 24px;
    font-weight: bold;
    color: #939393;
    margin-bottom: 0px;
}

.addBtn {
    height: 48px !important;
    padding: 0 40px !important;
}

.signerWrap {
    margin-bottom: 30px;
    border-radius: 8px;
    border: 1px solid transparent;
}

.error {
    border: 1px solid var(--error-400-base) !important;
}

.itemWrap {
    border-radius: 12px;
    background-color: #f5f5f5;
    padding: 20px;
}

.tableRowDragging {
    background: #a5d3ff !important;
}
