.PanelDatePicker .DayPicker {
    flex-direction: row;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.PanelDatePicker .DayPicker-Months {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}

.PanelDatePicker .DayPicker-Month {
    display: table;
    margin: 0;
    padding: 0;
    margin-top: auto;
    border-spacing: 0;
    border-collapse: collapse;
    user-select: none;
}
