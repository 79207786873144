.wrap {
    margin-top: 30px;
}

.title {
    font-size: 16px;
    font-weight: bold;
    color: #666666;
    line-height: 1.14;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    margin-bottom: 16px;
}

.exportBtnWrap {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 24px;
}

.status {
    min-width: 80px;
    display: inline-block;
    padding: 2px 8px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    color: var(--whitebase);
    text-align: center;
    word-break: keep-all;
    white-space: nowrap;
}

.status:global(.pending) {
    background-color: var(--black-200);
}
.status:global(.in_progress) {
    background-color: var(--warning-400-base);
}
.status:global(.completed) {
    background-color: var(--success-400-base);
}

.pastDue {
    color: var(--error-400-base) !important;
}

.due {
    color: var(--warning-400-base) !important;
}
