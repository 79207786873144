host:video {
    height: 0px;
    width: 0px;
}

host:canvas {
    height: 0px;
    width: 0px;
    overflow: auto;
}

.screenCapture {
    align-self: flex-start;
}

.recordWrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    padding: 8px;
    border-radius: 12px;
    background-color: var(--primary-100);
    margin-bottom: 4px;
    cursor: pointer;
}

.recordWrap.recording {
    background-color: #ffeff3;
}

.recordIcon {
    width: 20px;
    height: 20px;
}
