.status {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 112px;
    height: 32px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    color: var(--whitebase);
    text-transform: capitalize;
}

.attendingStatus {
    background-color: var(--success-400-base);
}

.admissionStatus {
    background-color: var(--secondary-400-base);
}

.loaStatus {
    background-color: var(--warning-400-base);
}
.droppedText {
    font-size: 12px;
    font-weight: bold;
    margin-left: 10px;
    margin-bottom: 0px;
}
.addStudentBox {
    display: flex;
    flex: 1;
    align-items: flex-end;
    flex-direction: column;
}
.attendanceFailed {
    font-weight: 500;
    color: var(--error-400-base);
}
.name {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}

.iconDrop {
    width: 18px;
    height: 18px;
}

.icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-color: var(--black-100);
    margin-left: 10px;
}

.nameStudent {
    display: inline-block;
}
