.title {
    font-size: 24px;
    font-weight: bold;
    color: var(--primary-400-base);
}

.formItem {
    margin-bottom: 16px;
}

.actionWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
}
