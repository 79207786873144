.itemBodyWrap {
    margin-bottom: 30px;
}

.itemBodyMargin {
    margin-right: 20px;
}

.bodyTitle {
    margin-bottom: 6px;
    font-size: 16px;
    color: var(--black-300);
}

.footerInfo {
    margin-top: 60px;
    font-size: 16px;
    font-weight: 500;
    color: var(--black-200);
}

.footerInfo__bold {
    font-weight: 600;
}
