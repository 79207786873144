.wrap {
    border-radius: 12px;
    border: solid 1px var(--primary-400-base);
    padding: 12px 16px;
    margin-bottom: 40px;
}

.leftHeader {
    display: flex;
    align-items: center;
    padding-bottom: 6px;
    border-bottom: 1px solid var(--primary-400-base);
    height: 50px;
    margin-bottom: 10px;
}

.termName {
    font-size: 20px;
    font-weight: bold;
    color: var(--black-base);
    margin-right: 12px;
}

.termIdInfo {
    padding: 4px 8px;
    border-radius: 4px;
    background-color: var(--primary-100);
}

.termIdInfo__title {
    font-size: 12px;
    font-weight: 400;
    color: var(--black-300);
    padding-right: 48px;
}

.termIdInfo__value {
    font-size: 12px;
    font-weight: bold;
    color: var(--primary-400-base);
}

.rightHeader {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 6px;
    border-bottom: 1px solid var(--primary-400-base);
    height: 50px;
    margin-bottom: 10px;
}

.rightHeaderIconWrap {
    width: 32px;
    height: 32px;
    background-color: var(--secondary-400-base);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rightHeaderIcon {
    width: 16px;
    height: 16px;
}

.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    border-bottom: 1px solid var(--black-100);
}

.item__title {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    color: var(--primary-400-base);
}

.item__value {
    font-size: 14px;
    font-weight: bold;
    color: #000;
}

.progressWrap {
    padding: 8px;
    border-radius: 10px;
    background-color: #f5f5f5;
}
