.cardWrap {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    box-shadow: 0 4px 24px 0 rgba(48, 49, 51, 0.16);
    background-color: var(--whitebase);
    padding: 10px 12px;
    height: 100%;
}

.titleWrap {
    display: flex;
    min-height: 4rem;
}

.cardTitle {
    font-size: 18px;
    font-weight: bold;
    color: var(--black-300);
    margin-bottom: 0;
    padding-left: 10px;
}

.cardValue {
    font-size: 64px;
    font-weight: 600;
    margin: auto;
    text-align: center;
    margin: auto;
    color: var(--primary-400-base);
}

.marginBottom {
    margin-bottom: 30px;
}

.includeArchiveWrapper {
    border: 2px solid var(--primary-400-base);
    padding: 5px 12px;
    border-radius: 6px;
    font-weight: bold;
}
