.wrap {
    margin-top: 30px;
    border-radius: 12px;
    background-color: #f5f5f5;
    padding: 16px 20px;
    margin-bottom: 40px;
}

.formItemWrap {
}

.radioBtnWrap {
    display: flex !important;
    border: solid 1px #1e90ff;
    border-radius: 8px;
    width: 100%;
}

.radioBtn__item {
    border-right: 1px solid #1e90ff;
    padding: 12px !important;
    font-size: 16px !important;
    font-weight: bold;
    line-height: 1.33 !important;
    color: #939393 !important;
    margin: 0 !important;
    flex: 1;
}

.radioBtn__item:last-child {
    border-right: 1px solid transparent;
}

.radioBtn__active {
    color: #212121 !important;
}

.primaryInfo {
    display: inline-flex;
    align-items: center;
    border-radius: 12px;
    background-color: #ffffff;
    padding: 12px 16px;
}

.primaryInfo__iconWrap {
    height: 24px;
    width: 24px;
    margin-right: 16px;
}

.primaryInfo__icon {
    height: 24px;
    width: 24px;
}

.primaryInfo__text {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
    color: #666666;
    margin-bottom: 0;
}

.actionBtnWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
}

.cancelBtn {
    margin-right: 20px;
}
