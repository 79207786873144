.nameWrap {
    display: flex;
    align-items: center;
}

.avatar {
    width: 40px;
    height: 40px;
    min-width: 40px;
    border-radius: 50%;
    margin-right: 15px;
}

.name,
.code {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 500;
    color: #212121;
    margin-right: 20px;
}

.code {
    margin-right: 0;
}

.actionBtnWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
}

.cancelBtn {
    margin-right: 20px;
}

.checkboxWrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

.inputWrap {
    position: relative;
}

.errorText {
    position: absolute;
    top: 53px;
    left: 0;
    color: var(--error-400-base);
}

.error {
    border: 1px solid var(--error-400-base) !important;
}
