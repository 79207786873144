.popupWrapper {
    display: flex;
    flex-direction: column;
}

.modalTitle {
    color: var(--black-300);
    font-size: 37px;
    font-weight: bold;
    margin-bottom: 0px;
}

.sectionTitle {
    padding: 8px 12px;
    border-radius: 8px;
    background-color: #f5f5f5;
    text-transform: uppercase;
    margin: 40px 0 16px 0;

    font-size: 21px;
    font-weight: bold;
    line-height: 1.14;
    color: var(--black-300);
}

.profileContainer {
    display: inline-block;
    padding: 8px;
    border-radius: 12px;
    border: solid 1px var(--black-100);
}

.profileInfo {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 4px 16px 4px 4px;
    border-radius: 12px;
    background-color: #f5f5f5;
    border: none;
}

.infoValue {
    flex-shrink: 0;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    color: #000;
}

.infoAvatar img {
    width: 32px;
    height: 32px;
    border-radius: 999px;
}

.amountValue {
    padding: 8px 12px;
    border-radius: 8px;
    background-color: var(--primary-100);

    font-size: 21px;
    font-weight: bold;
    line-height: 1.14;
    color: var(--primary-400-base);
}

.buttons {
    display: flex;
    justify-content: flex-end;
}
.button {
    margin-left: 24px;
}
