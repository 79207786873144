.calendarWidget {
    position: absolute;
    z-index: 100;
}

.hideCalendar {
    display: none;
}

.root {
    width: 100%;
    position: relative;
}

.input {
    width: 100%;
    height: 48px;
    border: none;
    background-color: transparent;
    outline: none;
    padding: 8px 16px;
    font-family: Poppins, sans-serif;
    font-size: 18px;
    line-height: 1.75;
    letter-spacing: normal;
    color: var(--primary-400-base);
    margin: 0;
    padding-right: 80px;
    border-radius: 6px;
    box-sizing: border-box;
    box-shadow: 0 3px 5px 0 rgba(48, 49, 51, 0.1), 0 0 1px 0 rgba(48, 49, 51, 0.05);
}

.iconWrapper {
    width: 48px;
    height: 48px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: var(--primary-400-base);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    fill: white;
    right: 0;
    top: 0;
}

.icon {
    fill: var(--whitebase);
    width: 24px;
    height: 24px;
}

.icon:hover {
    cursor: pointer;
}

.input:disabled {
    background-color: var(--grey-100);
}

.clearButton {
    position: absolute;
    top: calc(50% - 8px);
    right: 58px;
}

.input:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base), 0 3px 5px 0 rgba(48, 49, 51, 0.1), 0 0 1px 0 rgba(48, 49, 51, 0.05);
}

.input::placeholder {
    font-family: Poppins, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--black-200);
    padding-left: 0;
}
