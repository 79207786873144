.wrap {
    border-radius: 12px;
    border: solid 1px #62b1ff;
    height: 100%;
}

.title {
    flex: 1;
    font-size: 21px;
    font-weight: 800;
    color: #939393;
    margin-bottom: 0;
    padding: 12px 20px;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: #62b1ff;
}

.item,
.headerItem {
    display: flex;
    align-items: center;
    padding: 12px 20px;
}

.item:nth-child(odd) {
    background: #f7fbff;
}

.headerItem__title {
    font-size: 16px !important;
    font-weight: bold !important;
    color: #939393 !important;
}

.item__title {
    flex: 2;
    font-size: 18px;
    font-weight: 600;
    color: #212121;
    margin-bottom: 0;
}

.item__students {
    flex: 1;
    font-size: 21px;
    font-weight: bold;
    color: #125699;
    margin-bottom: 0;
}

.item__cost {
    flex: 1;
    font-size: 21px;
    font-weight: bold;
    color: #125699;
    margin-bottom: 0;
}
