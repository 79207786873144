.title {
    font-size: 32px;
    font-weight: 800;
    color: var(--black-200);
    margin-top: 40px;
    margin-bottom: 16px;
}

.cardWrap {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    box-shadow: 0 4px 24px 0 rgba(48, 49, 51, 0.16);
    background-color: var(--whitebase);
    padding: 10px 12px;
    height: 100%;
}

.cardTitle {
    font-size: 21px;
    font-weight: bold;
    color: var(--black-base);
    margin-bottom: 0;
}

.cardValue {
    font-size: 28px;
    font-weight: 800;
    color: var(--black-200);
}

.cardBodyWrap {
    display: flex;
}

.cardBodyItem {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-left: 10px;
}

.cardBodyItemLeft {
    padding-left: 0;
    padding-right: 10px;
    border-right: 1px solid #e5e5e5;
}

.cardBodyItem__title {
    font-size: 14px;
    font-weight: 800;
    color: var(--black-200);
}

.cardBodyItemInfo {
    display: flex;
    align-items: center;
}

.cardBodyItemInfo__total {
    font-size: 20px;
    font-weight: 800;
    color: var(--black-200);
    margin-bottom: 0;
    margin-right: 6px;
}

.cardBodyItemInfo__desc {
    font-size: 12px;
    font-weight: bold;
    color: var(--black-200);
    margin-bottom: 0;
}

.cardBodyItemInfo__amount {
    font-size: 20px;
    font-weight: 800;
    color: var(--black-200);
}
