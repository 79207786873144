.body {
    overflow-y: auto;
    max-height: 450px;
    min-height: 200px;
    padding-right: 15px;
    margin-top: 10px;
    position: relative;
}

.emptyWrap {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.emptyTitle {
    font-size: 21px;
    color: #666;
    font-weight: 500;
    margin: 0;
    margin-right: 3px;
}

.item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--black-200);
    padding: 20px 0 10px 0;
    cursor: pointer;
}

.itemTitle {
    flex: 1;
    font-weight: 500;
    color: var(--black-base);
    margin-bottom: 0;
}

.itemDesc {
    width: 110px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.dueText {
    font-size: 14px;
    color: var(--primary-300);
    font-weight: 500;
    margin-bottom: 0;
    margin-right: 9px;
}

.redDueText {
    color: var(--error-300);
}

.datetime {
    font-weight: bold;
    color: var(--primary-400-base);
    margin-bottom: 0;
    font-size: 16px;
}

.redDateTime {
    color: var(--error-400-base);
}

.taskStatus {
    margin: 0 6px;
}

@media (max-width: 768px) {
    .item {
        padding: 6px 0;
    }

    .itemTitle {
        font-size: 12px;
    }

    .datetime {
        font-size: 11px;
    }
}
