.filterItem {
    margin-bottom: 24px;
}

.divider {
    background: #fff;
    height: 3px;
    margin: 6px 0 24px 0 !important;
}

.statusInfoWrap {
    display: flex;
    align-items: center;
    overflow-x: auto;
    padding: 16px;
}

.statusColItem {
    flex: 1;
    margin: 0 6px;
}

.statusColItem:first-child {
    margin-left: 0;
}

.statusColItem:last-child {
    margin-right: 0;
}

.search {
    background: #fff;
}

.filterWrap {
    margin-bottom: 30px;
}

.dropdownWrapper {
    min-width: 150px;
}
