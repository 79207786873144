.wrap {
    display: flex;
    align-items: center;
}

.filterWrap {
    display: flex;
    flex: 1;
    align-items: center;
}

.dropdown {
    width: 100%;
}

.infoIconWrap {
    margin-left: 16px;
    display: flex;
    align-items: center;
}

.infoIcon {
    width: 28px;
    height: 28px;
}

.exportBtnWrap {
    flex: 2;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;
}

.exportBtn {
    height: 40px;
    font-size: 18px;
    font-weight: bold;
    color: var(--primary-400-base);
    border-radius: 4px;
    border: solid 2px var(--primary-400-base);
    padding: 4px 20px;
    margin-bottom: 0;
    cursor: pointer;
}
