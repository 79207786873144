.iconWrap {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 24px;
}

.error {
    background: #ffe5eb;
}

.success {
    background: #d6ebdf;
}

.warning {
    background: #f9eacc;
}

.info {
    background: #cde6ff;
}

.icon {
    width: 32px;
    height: 32px;
}

.infoWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.closeWrap {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
}

.closeIcon {
    width: 20px;
    height: 20px;
}

.title {
    font-size: 21px;
    font-weight: bold;
    color: var(--black-base);
    margin-bottom: 3px;
}

.description {
    font-size: 16px;
    font-weight: 500;
    color: var(--black-300);
}
