.cardWrap {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    box-shadow: 0 4px 24px 0 rgba(48, 49, 51, 0.16);
    background-color: var(--whitebase);
    padding: 10px 12px;
    height: 100%;
}

.headerWrap {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
    text-transform: uppercase;
}

.title {
    font-size: 21px;
    font-weight: 800;
    color: var(--black-200);
    margin-bottom: 0;
    padding-left: 11px;
}

.divider {
    background-color: var(--primary-300);
    height: 1px;
    width: 50%;
    margin-bottom: 16px;
}

.headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.linkedDetails {
    color: var(--primary-300);
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}
