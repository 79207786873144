.tagSlider {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
}
.tagContainer {
    display: flex;
    width: 100%;
    transition: transform 0.5s ease;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
}

.tagContainer::-webkit-scrollbar {
    display: none;
}

.tag {
    padding: 10px 20px;
    border: 1px solid var(--black-200);
    border-radius: 15px;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    min-width: fit-content;
    font-size: 20;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
}

.closeIcon {
    font-size: 12px;
    font-weight: 600;
}

.tag.selected {
    background-color: var(--primary-100);
    color: black;
    border: 1px solid var(--primary-100);
}
.controls {
    background-color: var(--whitebase);
    border: none;
    cursor: pointer;
    padding: 12px;
    border-radius: 5px;
    font-size: 30px;
    z-index: 9;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}
