.wrap {
    border-radius: 12px;
    display: flex;
    flex: 1;
    flex-direction: column;
    box-shadow: 0 4px 24px 0 rgba(48, 49, 51, 0.16);
    background-color: var(--whitebase);
    width: 100%;
    padding: 16px 20px 16px 20px;
    margin-bottom: 10px;
}

.header {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    border-bottom: 1px solid var(--black-100);
}

.headerTitle {
    font-size: 18px;
    font-weight: bold;
    color: var(--black-200);
    margin-bottom: 0;
    margin-left: 8px;
}

.body {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 220px;
    overflow-y: scroll;
    margin-top: 2px;
}

.item {
    display: flex;
    align-items: center;
    padding: 12px 20px 0px 0;
}

.rowItem {
    width: 100%;
    margin-bottom: 12px;
}

.itemTitle {
    flex: 1;
    font-weight: 600;
    color: var(--black-base);
    margin-bottom: 0;
}

.courseName {
    font-weight: 600;
    color: var(--black-base);
    font-size: 16px;
    margin-bottom: 12px;
}

.courseScore {
    font-weight: 600;
    color: var(--black-base);
    font-size: 16px;
    margin-bottom: 12px;
}

.dueDate {
    font-weight: 500;
    color: var(--black-base);
    font-size: 14px;
    text-align: right;
    margin-bottom: 12px;
    margin-right: 20px;
}

.progressValue {
    font-weight: 600;
    color: var(--black-200);
    font-size: 16px;
    margin-bottom: 12px;
}

.progressBarWrap {
    width: 100%;
    height: 6px;
    border-radius: 8px;
    background-color: var(--primary-100);
    position: relative;
}

.progressBarValue {
    position: absolute;
    height: 6px;
    height: 6px;
    background-color: var(--primary-400-base);
}

.progressBarColor1 {
    background-color: var(--primary-100);
}

.progressBarValueColor1 {
    background-color: var(--primary-400-base);
}

.progressBarColor2 {
    background-color: var(--error-100);
}

.progressBarValueColor2 {
    background-color: var(--error-500);
}

.progressBarColor3 {
    background-color: var(--success-200);
}

.progressBarValueColor3 {
    background-color: var(--success-500);
}

.progressBarColor4 {
    background-color: var(--warning-300);
}

.progressBarValueColor4 {
    background-color: var(--warning-400-base);
}

.progressBarColor5 {
    background-color: #bbb3fd;
}

.progressBarValueColor5 {
    background-color: #5540fb;
}
