.wrap {
    padding: 24px;
}

.title {
    font-size: 21px;
}

.body {
    margin-bottom: 24px;
}

.formItem {
    margin-bottom: 12px;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
}
