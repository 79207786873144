.arrowLink {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    background-color: transparent;
    position: relative;
    border: none;
    border-radius: 4px;
    text-decoration: none;
}

.backIcon {
    width: 48px;
    height: 48px;
}
