.container {
    display: flex;
    align-items: center;
    gap: 16px;
}

.divider {
    width: 2px;
    height: 100%;
    background-color: var(--black-100);
}

.publicWrap {
    display: flex;
    align-items: center;
    gap: 6px;
    min-width: fit-content;
}

.publicLabel {
    font-size: 18px;
    line-height: 1.78;
    color: var(--black-base);
    min-width: fit-content;
}

.inputFilterName {
    min-width: 240px;
}
