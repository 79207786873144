.wrapper {
    min-width: 450px !important;
}

.title {
    font-size: 21px;
    font-weight: 600;
}

.radioBtnWrap {
    display: flex;
    flex-direction: column;
}

.radioBtn__item {
    margin-bottom: 10px;
}

.action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
}

.cancelBtn {
    margin-right: 20px;
}
