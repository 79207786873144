.wrap {
    flex: 1;
}

.finAidInfo {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    border-radius: 10px;
    background-color: #f5f5f5;
}

.infoSection {
    padding: 0 8px 4px 8px;
    border-radius: 6px;
    background-color: var(--whitebase);
}

.infoLabel {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: var(--black-200);
    text-transform: uppercase;
}

.infoValue {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    color: #000;
}

/**/

.panelWrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 16px;
}

.headingWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.headingTitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: var(--black-300);
    text-transform: uppercase;
}

.headingIcon {
    width: 24px;
    height: 24px;
}

.collapseButton {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    width: 32px;
    height: 32px;
    transform: rotate(180deg);
    cursor: pointer;
}

.collapsed {
    transform: rotate(0deg);
    transform-origin: center;
}

.contentWrap {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}

.contentTitle {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--black-200);
    text-transform: uppercase;
    white-space: nowrap;
}

.contentValue {
    padding: 4px 8px;
    border-radius: 8px;
    background-color: #f5f5f5;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: var(--primary-400-base);
    min-height: 26px;
    min-width: 32px;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    text-align: center;
}
