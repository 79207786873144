.divider {
    background: #62b1ff;
    margin: 40px 0 !important;
}

.studentLeftInfo {
    border-radius: 10px;
    background-color: #f5f5f5;
    padding: 8px 10px;
    flex: 1;
    height: fit-content;
}

.studentRightInfo {
    flex: 1;
}

.studentRightInfo__margin {
    margin-bottom: 8px;
}

.courseInfo {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
}
