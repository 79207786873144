.container {
    display: flex;
    flex-direction: column;
}

.titleAddGrade {
    margin-top: 40px;
    margin-bottom: 16px;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-200);
}

.subTitleAddGrade {
    margin: 0 66px 7.2px 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-300);
}
.subTitle {
    margin-bottom: 7.2px;
}

.cancelBtn {
    margin-right: 20px;
    text-transform: uppercase;
}
.actionSubmit {
    margin-top: 111px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.datePicker {
    border-radius: 6px !important;
    box-shadow: 0 2px 12px 0 rgba(48, 49, 51, 0.1);
    background-color: var(--whitebase) !important;
    border-color: transparent !important;
    padding-right: 0px !important;
}
.titleView {
    display: flex;
    flex-direction: column;
    width: 350px;
}
.hr {
    margin-top: 38px;
    margin-bottom: 40px;
    left: 0;
    border: none;
    width: 100%;
    height: 2px;
    background-color: var(--primary-300);
}
.error {
    border: 1px solid var(--error-400-base) !important;
}
.titleTextField {
    font-family: Raleway;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-300);
}
