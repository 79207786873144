.wrap {
    padding: 0 40px;
}

.title {
    font-size: 37px;
    font-weight: bold;
    color: #212121;
    margin-bottom: 30px;
}

.formItemWrap {
    margin-bottom: 20px;
}

.formItemFlex {
    display: flex;
    align-items: center;
}

.formLabel {
    margin: 0 10px 0 0;
}

.action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
}

.tableWrapper {
    overflow: auto;
    max-height: 500px;
    margin-bottom: 20px;
}

.radioBtnWrap {
    display: flex !important;
    border: solid 1px #1e90ff;
    border-radius: 8px;
    width: 430px;
    margin-bottom: 20px;
}

.radioBtn__item {
    border-right: 1px solid #1e90ff;
    padding: 12px !important;
    font-size: 16px !important;
    font-weight: bold;
    line-height: 1.33 !important;
    color: #939393 !important;
    margin: 0 !important;
    flex: 1;
}

.radioBtn__item:last-child {
    border-right: 1px solid transparent;
}

.checklistTitle {
    font-size: 24px;
    font-weight: bold;
    color: var(--black-300);
    margin: 0;
}

.checklistTitle__desc {
    font-size: 16px;
    font-weight: 500;
    color: var(--black-300);
    padding-left: 16px;
}

.archiveOldChecklistContainer {
    font-weight: bold;
    border-bottom: 1px solid var(--primary-400-base);
    padding: 10px 0px;
}

.archiveOldChecklistsLabel {
    margin-right: 10px;
    font-size: 16px;
}
