.wrap {
    margin-bottom: 40px;
}

.itemWrap {
    display: flex;
    flex-direction: column;
    padding: 12px 0 25px 12px;
    border-radius: 12px;
    box-shadow: 0 4px 24px 0 rgba(48, 49, 51, 0.16);
    background-color: var(--whitebase);
    height: 100%;
}

.itemHeader {
    display: flex;
    border-bottom: 1px solid var(--primary-300);
}
.editGpa {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.editAttendance {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: center;
    align-items: center;
}
.headerTitle {
    font-size: 18px;
    font-weight: bold;
    color: var(--black-200);
    margin-bottom: 5px;
    margin-left: 8px;
}

.body {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 12px;
    padding-right: 12px;
}

.bodyDesc {
    text-align: center;
    margin-top: 10px;
}

.gpaScore {
    font-size: 40px;
    font-weight: 600;
    color: var(--primary-400-base);
    margin: 0;
}

.gpaBody {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
}

.openBracket,
.closeBracket {
    font-size: 60px;
    color: var(--black-200);
    font-weight: 300;
    position: absolute;
}

.openBracket {
    left: -24px;
    top: -18px;
}

.closeBracket {
    right: -24px;
    top: -18px;
}

.gpaText {
    font-size: 16px;
    font-weight: bold;
    color: var(--black-200);
    margin: 0;
}

.gpaScoreBottom {
    font-size: 20px;
    color: var(--black-base);
    font-weight: 600;
    margin: 0;
}

.ldaTitle {
    font-size: 18px;
    font-weight: bold;
    color: var(--black-base);
}

.ldaBody {
    align-items: flex-start;
    justify-content: flex-start;
}

.ldaItem {
    display: flex;
    align-items: center;
    flex: 1;
    align-self: stretch;
    justify-content: center;
    margin-bottom: 10px;
}

.ldaIcon {
    width: 25px;
    height: 25px;
}

.editSap {
    margin-left: 10px;
    cursor: pointer;
}
.sapView {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.buttonEditLda {
    flex-direction: row;
    align-self: stretch;
    justify-content: flex-end;
    display: flex;
    flex: 1;
    cursor: pointer;
    margin-right: 10px;
}

.iconEditLda {
    width: 30px;
    height: 30px;
}

.ldaText {
    font-size: 22px;
    font-weight: 600;
    color: var(--black-base);
    margin-bottom: 0;
    margin-left: 10px;
}

.sapStatus {
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 600;
    color: var(--black-base);
    margin-bottom: 0;
    margin-left: 8px;
}

.ldaTextBold {
    font-weight: 600;
}

.ldaScoreWrap {
    display: flex;
    align-items: center;
}

.ldaScoreDesc {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: var(--black-300);
    margin-bottom: 0;
    margin-left: 10px;
}

.sapStatusTitle {
    font-size: 14px;
    margin-bottom: 0;
    font-weight: bold;
    color: var(--black-200);
}

.circleIcon {
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
    background-color: var(--success-300);
    border-radius: 6.5px;
    margin: 0 5px;
}

.missedText {
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
    margin-left: 8px;
    color: var(--error-400-base);
}
.missedTitle {
    font-size: 18px;
    margin-bottom: 0;
    font-weight: bold;
    color: var(--error-400-base);
}
