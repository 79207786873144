.cardWrap {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    box-shadow: 0 4px 24px 0 rgba(48, 49, 51, 0.16);
    background-color: var(--whitebase);
    padding: 10px 12px;
    height: 100%;
    min-height: 140px;
}

.titleWrap {
    display: flex;
    align-items: center;
    height: 50px;
    font-weight: bold;
    font-size: 16px;
    color: var(--black-base);
}

.cardValue {
    font-size: 24px;
    font-weight: 800;
    margin: 0;
    text-align: center;
    color: var(--black-200);
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cardTitle {
    margin-bottom: 0;
    text-transform: uppercase;
}

.cardSubTitle {
    color: var(--black-200);
    margin-bottom: 0;
    text-transform: uppercase;
}

.iconWrapper {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    height: 40px;
    width: 40px;
    min-width: 40px;
}

.icon {
    width: 24px;
    height: 24px;
}
