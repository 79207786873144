.divider {
    height: 2px;
    background-color: var(--primary-400-base);
    margin: 40px 0;
}
.addItemButton {
    margin-top: 24px;
}
.titleGradingScale {
    font-size: 37px;
    margin-bottom: 8px;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    color: var(--black-200);
}
.checkBoxGroup {
    display: flex;
    margin-bottom: 5px;
    align-items: center;
    margin-top: 40px;
    padding: 8px 16px 8px 8px;
    border-radius: 12px;
    border: solid 2px var(--primary-400-base);
    background-color: var(--whitebase);
}
.textCheckBox {
    margin-left: 16px;
}
.rowCheckBox {
    display: flex;
    flex-direction: row;
}
.checkBoxGroupGradeFour {
    display: flex;
    margin-left: 40px;
    margin-bottom: 5px;
    align-items: center;
    margin-top: 40px;
    padding: 8px 16px 8px 8px;
    border-radius: 12px;
    border: solid 2px var(--primary-400-base);
    background-color: var(--whitebase);
}
.minimumPassingGrade {
    display: flex;
    flex-direction: column;
    margin-left: 35px;
    padding: 8px 16px 8px 8px;
    background-color: var(--whitebase);
}
.textMinimumPassingGrade {
    margin-bottom: 10px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-300);
}

.popupGradingElement {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.titlePopup {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-base);
}
.contentPopup {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-300);
}
.containerGradingElement {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 0.4;
    padding: 32px 53px 24px 54px;
    border-radius: 12px;
    background-color: var(--primary-100);
}
.buttonAddGradingElements {
    margin-top: 24px;
}
.buttonAddGrading {
    border-radius: 4px;
    border: solid 2px var(--primary-400-base);
    background-color: transparent;
}
.titleWrap {
    display: flex;
    align-items: center;
}

.title {
    display: block;
    float: left;
}
.subHeader {
    top: 39px !important;
}
