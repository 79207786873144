@import "~antd/dist/antd.css";
@import "antd-custom.css";
@import "utilities.css";
@import "calendar.css";
@import "date-picker.css";
@import "variables.css";

body,
html {
    margin: 0;
    font-family: "Poppins", sans-serif;
    line-height: 19px;
    font-size: 16px;
    color: var(--black-base);
    font-weight: normal;
}
* {
    box-sizing: border-box;
}

img {
    max-width: 100%;
    height: auto;
}

a {
    outline: none;
}

input {
    font-family: "Poppins", sans-serif;
}

/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 101; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */

    justify-content: center;
    align-items: center;
}

.status-in_progress {
    background-color: #ff8a38;
    width: 150px;
    padding: 0px;
}

.status-pending_approval {
    background-color: #1e90ff;
    width: 150px;
    padding: 0px;
}

.status-completed {
    background: #18a957;
    width: 150px;
    padding: 0px;
}

.status-available {
    background: #18a957;
}

.status-unavailable {
    background: #df1642;
}

.status-active {
    background: #18a957;
}

.status-inactive {
    background: #df1642;
}

.type-room {
    background: #ffea4b;
}

.type-equipment {
    background: #8879fc;
}

.type-lab {
    background: #e95c7b;
}

.type-instrument {
    background: #3b2db0;
}

.custom-scroll::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb {
    background: #cdcdcd87;
    border-radius: 10px;
}

.custom-scroll .ps__rail-y {
    z-index: 2;
}

.rdw-option-wrapper,
.rdw-dropdown-wrapper {
    background: transparent !important;
}

.rdw-option-active {
    background: #e5e5e5 !important;
}

/* react-select */

.klassapp-dropdown.readOnly .klassapp-dropdown__multi-value__remove {
    display: none !important;
}

.hidden-scroll .ps__rail-y {
    visibility: hidden;
}

/* Fix table has left scroll when in dialog */
.base-popup .klassapp-table.ps--active-y > .ps__rail-y {
    left: unset !important;
}
