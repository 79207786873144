.wrap {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: 60px;
    margin-top: -20px;
    margin-bottom: 20px;
}

.item {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 12px;
    border: solid 1px var(--black-100);
    cursor: pointer;
}

.item__title {
    color: var(--primary-400-base);
    font-size: 14px;
    font-weight: 500;
    margin-right: 8px;
}

.item__value {
    font-size: 18px;
    font-weight: bold;
    color: #000;
    padding-right: 6px;
}

.without_back_button {
    margin-left: 0;
}
