.filterContainer {
    padding: 0px 10px;
}

.listingTypeContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.listingIcon {
    margin: 5px;
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
}

.listingIconSelected {
    background-color: #e9f4ff;
}

.actionWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 30px;
}

.addButton {
    height: 40px !important;
    justify-content: center;
    font-size: 16px;
}

.filterRightContainer {
    display: flex;
    justify-content: flex-end;
}
