.actionWrap {
    display: flex;
    justify-content: center;
}

.addAction {
    width: 100%;
    justify-content: center;
}

.calendarWrap {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
