.primaryDescriptionWrap {
    display: inline-flex;
    align-items: center;
    border-radius: 12px;
    background-color: #ffffff;
    padding: 12px 16px;
}

.primaryDescription__iconWrap {
    height: 24px;
    width: 24px;
    margin-right: 16px;
}

.primaryDescription__icon {
    height: 24px;
    width: 24px;
}

.primaryDescription__text {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
    color: #666666;
    margin-bottom: 0;
}
