.wrap {
    margin-top: 24px;
    padding: 18px 0 23px;
    border-radius: 12px;
    box-shadow: 0 4px 24px 0 rgba(48, 49, 51, 0.16);
    border: solid 0.5px var(--primary-300);
    background-color: var(--whitebase);
    margin-bottom: 40px;
    display: flex;
}

.termStepItem {
    display: flex;
    flex-direction: column;
    flex: 1;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}

.termItemTitle {
    font-size: 13px;
    font-weight: 500;
    color: var(--black-base);
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    text-align: center;
    flex: 1;
    margin-bottom: 0;
}

.termItemStatusWrap {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.termItemLine {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
    height: 4px;
    background-color: #e5e5e5;
}

.termItemCompletedLine {
    background-color: var(--success-400-base);
}

.termItemStatus,
.termItemStatusCompleted {
    width: 44px;
    height: 44px;
    background-color: #e5e5e5;
    border-radius: 50%;
    margin: 7px 0;
    position: relative;
}

.termItemStatusCompleted {
    background-color: #fff;
    border: 7px solid var(--success-400-base);
}

.termItemStatusInCompleted {
    width: 16px;
    height: 16px;
    background-color: var(--whitebase);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.termItemStatusCompletedIcon {
    width: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.currentTermItemWrap {
    position: absolute;
    /* left: calc(50% + 30px); */
    display: flex;
    align-items: center;
    z-index: 1;
}

.afterCurrentTermItemWrap {
    position: absolute;
    left: calc(50% + 30px);
    display: flex;
    align-items: center;
    z-index: 1;
}

.currentTermItemStatus {
    width: 36px;
    height: 36px;
    background-color: var(--primary-400-base);
    border-radius: 50%;
    margin: 7px 0;
    position: relative;
}

.currentTermItemStatusWhite {
    width: 16px;
    height: 16px;
    background-color: var(--whitebase);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.currentTermItemDate {
    font-size: 12px;
    font-weight: bold;
    background-color: var(--primary-400-base);
    color: var(--whitebase);
    border-radius: 8px;
    height: 22px;
    padding: 5px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    position: absolute;
    left: 30px;
}

.termItemGpa {
    font-size: 16px;
    font-weight: bold;
    color: var(--success-300);
    height: 20px;
    margin-bottom: 0;
}

.tempGpa {
    font-size: 16px;
    font-weight: bold;
    height: 20px;
    opacity: 0;
    margin-bottom: 0;
}

.termPopupWrap {
}

.termPopupTitle {
    font-size: 18px;
    font-weight: bold;
    color: var(--black-200);
    margin-bottom: 20px;
}

.termPopupItem {
    display: flex;
    margin-bottom: 30px;
}

.termPopupItem__icon {
    width: 28px;
    height: 28px;
    min-width: 28px;
    min-height: 28px;
}

.termPopupItem__text {
    font-size: 16px;
    font-weight: 600;
    color: var(--black-base);
    margin-left: 12px;
    padding-right: 40px;
    flex: 1;
    margin-bottom: 0;
}

.termPopupItem__score {
    font-size: 16px;
    font-weight: bold;
    color: var(--black-base);
    margin-bottom: 0;
}

.detailBtnWrap {
    margin: 10px 40px;
}

.detailBtn {
    padding: 5px 10px;
    border: 1px solid var(--primary-400-base) !important;
    font-size: 14px !important;
    font-weight: bold !important;
    color: var(--primary-400-base) !important;
    text-align: center;
}

.termPopupProgressItem {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.termPopupProgressWrap {
    display: flex;
}

.termPopupProgress__text {
    font-size: 16px;
    font-weight: 500;
    color: var(--black-300);
    flex: 1;
    margin-bottom: 5px;
}

.termPopupProgress__progressValue {
    font-size: 16px;
    font-weight: bold;
    color: var(--primary-400-base);
    margin-bottom: 5px;
}

.progressBarWrap {
    width: 100%;
    height: 6px;
    border-radius: 8px;
    background-color: var(--primary-100);
    position: relative;
}

.progressBarValue {
    position: absolute;
    height: 6px;
    height: 6px;
    background-color: var(--primary-400-base);
}
