.wrap {
    border-radius: 12px;
    border: solid 1px #62b1ff;
    height: 100%;
}

.headerWrap {
    display: flex;
    align-items: center;
    padding: 12px 20px;
}

.title {
    flex: 1;
    font-size: 21px;
    font-weight: 800;
    color: #939393;
    margin-bottom: 0;
}

.date {
    font-size: 18px;
    font-weight: bold;
    color: #1e90ff;
    border-radius: 6px;
    background-color: #e9f4ff;
    padding: 4px 8px;
    margin-bottom: 0;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: #62b1ff;
}

.item,
.totalItem {
    display: flex;
    align-items: center;
    padding: 12px 20px;
}

.item:nth-child(odd) {
    background: #f7fbff;
}

.item__title {
    flex: 1;
    font-size: 18px;
    font-weight: 600;
    color: #212121;
    margin-bottom: 0;
    text-transform: uppercase;
}

.item__cost {
    font-size: 21px;
    font-weight: bold;
    color: #125699;
    margin-bottom: 0;
}
