.cardItem {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
    border-radius: 12px;
    box-shadow: 0 2px 12px 0 rgba(48, 49, 51, 0.1);
    border: solid 0 var(--black-base);
    background-color: var(--whitebase);
    padding: 12px;
    margin-right: 12px;
}

.cardItem:last-child {
    margin-right: 0;
}

.headerWrap {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.status {
    width: 16px;
    height: 16px;
    background-color: var(--primary-300);
    border-radius: 50%;
    margin-right: 12px;
    min-width: 16px;
}

.headerTitle {
    font-size: 21px;
    font-weight: bold;
    color: var(--black-300);
    margin-bottom: 0;
}

.value {
    font-size: 48px;
    font-weight: bold;
    color: var(--primary-300);
    line-height: 1.17;
    text-align: center;
    margin-bottom: 0;
}

.percent {
    font-size: 32px;
    font-weight: 500;
    text-align: center;
    line-height: 1;
    color: var(--primary-300);
    margin-bottom: 20px;
}

.title {
    font-size: 14px;
    font-weight: bold;
    color: var(--black-200);
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 0;
}

.targetNumber {
    border-radius: 8px;
    background-color: #e5e5e5;
    width: 119px;
    height: 40px;
    font-size: 27px;
    font-weight: bold;
    color: var(--black-200);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
