.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 12px;
    box-shadow: 1px 2px 16px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px var(--black-100);
    background-color: var(--whitebase);
    gap: 10px;
}

.cardIcon {
    width: 40px;
    height: 40px;
}

.title {
    font-size: 21px;
    font-weight: bold;
    line-height: 1.14;
    color: var(--black-200);
}

.value {
    font-size: 30px;
    font-weight: bold;
    color: #000;
    margin-top: 4px;
}

.distant {
    font-size: 24px;
    font-weight: 500;
    color: var(--black-base);
    margin-top: 24px;
    opacity: 0.8;
}

.description {
    font-size: 24px;
    font-weight: 500;
    color: var(--black-base);
    margin-top: 4px;
    opacity: 0.5;
}

.subContainer {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.contractTitle {
    font-size: 21px;
    font-weight: bold;
    line-height: 1.14;
    color: var(--black-200);
    margin-top: 24px;
}
