.activitiesCourseText {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0px;
}

.activitiesCourseItem {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.absentStats {
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-top: -10;
}
.attClose path {
    stroke: var(--error-400-base);
}
.attCheckVerified svg {
    width: 34px;
    height: 34px;
}

.attCheck svg {
    width: 34px;
    height: 34px;
}

.attCheck path {
    fill: var(--primary-400-base);
}
.actionWarning {
    display: flex;
}

.actionWarning path {
    fill: var(--warning-400-base);
}
