.wrapper {
    margin-bottom: 40px;
}

.buttonWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
}

.cancelBtn {
    margin-right: 20px;
    text-transform: uppercase;
}

.titleWrap {
    display: flex;
    align-items: center;
}

.lockIcon {
    display: block;
    float: left;
    margin-right: 5px;
}

.title {
    display: block;
    float: left;
}

.visibleCheckboxWrap {
    display: flex;
    align-items: center;
    justify-content: center;
}
