/* DayPicker styles */

.DayPicker {
    display: inline-block;
    font-size: 1rem;
}

.DayPicker-wrapper {
    position: relative;
    outline: none;
    flex-direction: row;
    padding-bottom: 1em;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.DayPicker-Months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.DayPicker-Month {
    display: table;
    margin: 0 1em;
    border-spacing: 0;
    border-collapse: collapse;
    font-family: Poppins, sans-serif;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.DayPicker-NavBar {
    width: 100%;
}

.DayPicker-NavButton {
    position: absolute;
    top: 1em;
    right: 1.5em;
    left: auto;
    display: inline-block;
    margin-top: 2px;
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
    outline: none;
    border-radius: 4px;
}

.DayPicker-NavButton:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base);
}

.DayPicker-NavButton:hover {
    opacity: 0.8;
}

.DayPicker-NavButton--prev {
    right: auto;
    left: 16px;
    background-image: url(./icons/line.svg);
}

.DayPicker-NavButton--next {
    right: 16px;
    left: auto;
    background-image: url(./icons/line.svg);
    transform: rotate(180deg);
}

.DayPicker-NavButton--interactionDisabled {
    display: none;
}

.DayPicker-Caption {
    display: table-caption;
    margin-bottom: 16px;
    padding: 0 0.5em;
}

.DayPicker-Caption > div {
    font-family: Poppins, sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-base);
}

.DayPicker-Weekdays {
    display: table-header-group;
}

.DayPicker-WeekdaysRow {
    display: table-row;
}

.DayPicker-Weekday {
    display: table-cell;
    width: 40px;
    height: 33px;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-200);
}

.DayPicker-Weekday abbr[title] {
    border-bottom: none;
    text-decoration: none;
}

.DayPicker-Body {
    display: table-row-group;
}

.DayPicker-Week {
    display: table-row;
}

.DayPicker-Day {
    display: table-cell;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    outline: none;
}

/* .DayPicker-Day:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base);
} */

.DayPicker-WeekNumber {
    display: table-cell;
    padding: 0.5em;
    min-width: 1em;
    border-right: 1px solid #eaecec;
    color: #8b9898;
    vertical-align: middle;
    text-align: right;
    font-size: 0.75em;
    cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
    cursor: default;
}

.DayPicker-Footer {
    padding-top: 0.5em;
}

.DayPicker-TodayButton {
    border: none;
    background-color: transparent;
    background-image: none;
    box-shadow: none;
    color: #4a90e2;
    font-size: 0.875em;
    cursor: pointer;
}

/* Default modifiers */

.DayPicker-Day--today {
    background-color: #1a73e8 !important;
    color: #fff !important;
}

.DayPicker-Day--outside {
    color: #8b9898;
    cursor: default;
}

.DayPicker-Day--disabled {
    color: #dce0e0;
    cursor: default;
    /* background-color: #eff1f1; */
}

/* Example modifiers */

.DayPicker-Day--sunday {
    background-color: #f7f8f8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
    color: #dce0e0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;
    background-color: var(--primary-400-base);
    color: #f0f8ff;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: var(--primary-400-base);
}

.DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: #f1f3f4;
}

/* DayPickerInput */

.DayPickerInput {
    display: inline-block;
}

.DayPickerInput-OverlayWrapper {
    position: relative;
}

.DayPickerInput-Overlay {
    position: absolute;
    left: 0;
    z-index: 1;
    background: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}
