.wrap {
    display: flex;
    align-items: center;
    padding: 5px 0px 5px 20px;
    min-height: 46px;
}

.totalTitle {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: var(--black-300);
    margin: 0 24px 0 0;
}

.totalCount {
    font-size: 18px;
    font-weight: bold;
    color: var(--black-300);
    padding-right: 5px;
}

.title {
    font-size: 13px;
    font-weight: bold;
    color: var(--black-200);
    margin-right: 10px;
    margin-bottom: 0;
}

.dropdown {
    min-width: 80px;
    margin: 0 20px 0 0;
}

.rowPerPage {
    font-size: 13px;
    font-weight: 800;
    color: var(--black-200);
    margin-right: 20px;
    border: 1px solid var(--black-200);
    border-radius: 4px;
    padding: 3px 10px;
}

.paginationInfo {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.paginationBorder {
    border: 1px solid;
    padding: 2px 9px;
    border-radius: 4px;
}

.paginationInfo__page {
    font-size: 15px;
    font-weight: 800;
    color: var(--black-300);
    margin-bottom: 0;
}

.paginationInfo__text {
    font-size: 13px;
    font-weight: 500;
    color: var(--black-300);
    margin-bottom: 0;
    margin: 0 5px;
}

.paginationAction {
    display: flex;
}

.paginationActionItem {
    margin-right: 20px;
    cursor: pointer;
}

.actionItemDisabled {
    cursor: not-allowed;
}

.rotate {
    transform: rotate(180deg);
}

.panelWrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4px 8px;
    border-radius: 8px;
    background-color: #f5f5f5;
}

.panelInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.panelTotalTitle {
    display: flex;
    align-items: center;
    font-weight: 500;
    color: var(--black-300);
    gap: 5px;
}

.panelTotalCount {
    font-size: 14px;
}

.panelTotalDesc {
    font-size: 12px;
}

.panelDivider {
    width: 1px;
    height: 22px;
    flex-grow: 0;
    transform: rotate(-180deg);
    background-color: var(--black-100);
}

.panelRowPerPage {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2px 10px;
    border-radius: 4px;
    border: solid 1px var(--black-100);
    background-color: var(--black-100);
}

.panelPaginationInfo {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: var(--black-300);
}

.panelPaginationAction {
    display: flex;
    align-items: center;
}

.panelPaginationActionItem {
    margin-left: 10px;
    cursor: pointer;
    width: 16px;
    height: 16px;
}

.panelSvg {
    width: 16px;
    height: 16px;
}
