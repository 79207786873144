.subjectWrapper {
    display: flex;
    flex-direction: column;
}

.subjectWrapper label {
    font-size: 14px;
}

.subjectWrapper input,
.subjectWrapperDropdown {
    border-radius: 11px;
    margin-bottom: 7px;
    max-width: 428px;
}

.checkboxWrapper {
    display: flex;
    max-width: 428px;
    align-items: flex-start;
    justify-content: space-between;
}

.smsWrapper {
    padding: 5px 10px;
    border-radius: 6px;
    border: solid 1px var(--primary-400-base);
    background-color: var(--whitebase);
    display: flex;
    gap: 10px;
    align-items: center;
    width: fit-content;
    margin-bottom: 10px;
}

.smsText {
    float: left;
    margin-right: 10px;
}

.mediaCheckbox {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    text-align: left;
    color: #212121;
}
