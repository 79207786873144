.root {
    display: flex;
    font-size: 12px;
}

.eventPreviewHeaderEditIcon {
    width: 25px;
    height: 25px;
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.statsInfo {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 40px;
    gap: 4px;
    margin-bottom: 40px;
}

.scheduled {
    padding: 2px 4px 2px 10px;
    border-radius: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
    background-color: var(--black-200);
}

.attended {
    padding: 2px 4px 2px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
    border-radius: 24px;
    background-color: var(--black-100);
}

.missed {
    padding: 2px 4px 2px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
    border-radius: 24px;
    background-color: var(--black-100);
}

.remaining {
    padding: 2px 4px 2px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
    border-radius: 24px;
    background-color: var(--black-100);
}

.scheduledStats {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.attendedStats {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: var(--primary-400-base);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.missedStats {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: var(--error-400-base);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.remainingStats {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: var(--black-300);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.scheduledText {
    font-size: 10px;
    font-weight: 500;
    color: var(--black-200);
}

.attendedText {
    font-size: 10px;
    font-weight: 500;
    color: white;
}

.missedText {
    font-size: 10px;
    font-weight: 500;
    color: white;
}

.remainingText {
    font-size: 10px;
    font-weight: 500;
    color: white;
}

.scheduledLabel {
    font-size: 14px;
    font-weight: 500;
    color: white;
}

.attendedLabel {
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-400-base);
}

.missedLabel {
    font-size: 14px;
    font-weight: 500;
    color: var(--error-400-base);
}
.remainingLabel {
    font-size: 14px;
    font-weight: 500;
    color: var(--black-300);
}
