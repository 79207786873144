.monthlyItem {
    display: flex;
    margin-bottom: 16px;
}

.monthlyItem:last-child {
    margin-bottom: 0;
}

.monthlyItem__title {
    margin: 0 10px;
    width: 30px;
}

.monthlyItem__body {
    flex: 1;
}

.dropdownItem {
    margin-bottom: 20px;
}

.test {
    border-top: 1px solid red;
}
