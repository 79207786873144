.actionWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.statusWrap {
    display: flex;
    align-items: center;
}

.status {
    display: inline-block;
    padding: 0 8px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    color: var(--whitebase);
}

.status:global(.certified) {
    background-color: var(--success-500);
}

.status:global(.accepted) {
    background-color: var(--success-400-base);
}

.status:global(.packaged) {
    background-color: var(--primary-400-base);
}

.status:global(.estimated) {
    background-color: var(--black-200);
}

.statusInfo {
    display: flex;
    flex-direction: column;
}

.statusInfo__text {
    font-size: 14px;
    font-weight: 500;
    color: var(--black-300);
    margin-bottom: 4px;
}
