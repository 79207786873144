.wrap {
    box-shadow: 0 4px 24px 0 rgba(48, 49, 51, 0.16);
    padding: 24px;
    margin-bottom: 40px;
    border-radius: 12px;
}

.headerWrap {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.title {
    font-size: 21px;
    font-weight: 800;
    color: #939393;
    margin-bottom: 0;
    padding-bottom: 8px;
    border-bottom: 1px solid #5dc389;
}

.barInfoWrap {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
}

.barInfoItem {
    display: flex;
    margin-right: 20px;
}

.barInfoItem__title {
    font-size: 14px;
    font-weight: 500;
    color: #212121;
    margin-left: 5px;
    margin-bottom: 0;
}

.lineInfoWrap {
    margin-bottom: 30px;
    display: flex;
}

.lineInfoItem {
    display: flex;
    align-items: center;
    margin-right: 24px;
}

.lineInfoItem__color {
    width: 20px;
    height: 2px;
    border-radius: 4px;
    margin-right: 8px;
}

.lineInfoItem__title {
    font-size: 14px;
    font-weight: 500;
    color: #212121;
    margin-bottom: 0;
}

.actualColor {
    background-color: #1e90ff;
}

.revenueColor {
    background-color: #b3246d;
}
