.root {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
}

.button {
    background-color: transparent;
    padding: 0 7px;
    outline: none;
    text-decoration: none;
    border: none;
    border-bottom: solid 3px var(--black-100);
    min-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.active {
    border-bottom: solid 2px var(--primary-400-base);
}

.text {
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--black-base);
}

.text:first-letter {
    text-transform: uppercase;
}

.buttonInner {
    padding: 3px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 5px;
    margin-bottom: 3px;
    margin-top: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button:focus .buttonInner {
    box-shadow: 0 0 0 2px var(--secondary-400-base);
}

.icon {
    fill: var(--primary-400-base);
    margin-right: 20px;
}
