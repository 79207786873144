.formItemWrap {
}

.error {
    border: 1px solid var(--error-400-base) !important;
}

.errorSelect {
    border: 1px solid var(--error-400-base) !important;
    border-radius: 6px !important;
}

.errorSelect > div {
    box-shadow: none !important;
}
.taskIdBadgeContainer {
    gap: 10px;
    display: none;
}
.taskIdBadgeContainerVisible {
    display: flex;
}
.taskIdBadge {
    padding: 3px 8px;
    border-radius: 4px;
    background-color: #e5e5e5;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-200);
    text-align: center;
    margin-left: -6px;
}

.taskReloadTaskCodeButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.taskReloadTaskCodeButton > svg {
    width: 24px;
    height: 24px;
    color: var(--primary-400-base);
    stroke-width: 2;
}
