.cancelBtn {
    margin-left: auto;
    margin-right: 20px;
    padding: 7px 17px !important;
    height: auto !important;
}

.saveBtn {
    margin-left: auto;
}

.doneBtn {
    display: flex;
    margin-top: 30px;
}

.itemBodyWrap {
    margin-bottom: 30px;
}

.itemBodyMargin {
    margin-right: 20px;
}

.itemBodyMarginCollege {
    margin-right: 20px;
    margin-bottom: 20px;
}

.bodyTitle {
    margin-bottom: 6px;
}

.imgWrapper {
    position: relative;
    width: 100%;
    height: 234px;
    border: solid 2px var(--black-100);
    border-radius: 8px;
    background-image: url("./line.svg");
    background-repeat: no-repeat;
    background-position: center;
    grid-row-start: 1;
    grid-row-end: 4;
    grid-column-start: 3;
    grid-column-end: 4;
    align-self: flex-end;
    overflow: hidden;
    margin-top: 20px;
}

.imgWrapper:hover {
    border-color: var(--primary-400-base);
    cursor: pointer;
}

.img {
    position: absolute;
    object-fit: contain;
    height: 100%;
    width: 100%;
}

.deleteImg {
    position: absolute;
    right: 0px;
    top: -1px;
    width: 33px;
    height: 38px;
    cursor: pointer;
    border-radius: 50%;
}

.deleteImg:before,
.deleteImg:after {
    content: "";
    position: absolute;
    top: 21px;
    left: 10px;
    width: 15px;
    height: 3px;
    background: var(--secondary-400-base);
}

.deleteImg:before {
    transform: rotate(45deg);
}

.deleteImg:after {
    transform: rotate(-45deg);
}

.uploadPhotoBtn {
    width: 100%;
    justify-content: center;
    margin-top: 10px;
    font-size: 14px !important;
    text-transform: uppercase;
}

.bodyWrapTitle {
    font-size: 24px;
    font-weight: 800;
    color: var(--black-200);
}

.notificationTitle {
    font-size: 18px;
    font-weight: 800;
    color: var(--black-300);
    margin-bottom: 5px;
}

.notificationWrap {
    border-radius: 4px;
    border: solid 1px #e5e5e5;
    padding: 10px;
    margin-bottom: 16px;
}

.notificationItem {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.notificationItem__title {
    margin-bottom: 0;
    margin-right: 20px;
    width: 140px;
}

.radioGroup {
    display: flex !important;
    flex: 1;
    justify-content: flex-end;
}

.radioGroupHighSchool {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px !important;
    padding: 15px;
    background-color: #f5f5f5;
    font-weight: 600;
}

.signatureWrap {
    margin-top: 40px;
}

.signatureTitle {
    font-size: 24px;
    font-weight: 800;
    color: #666666;
    margin-bottom: 10px;
}

.signatureBtn {
    width: 80%;
    margin-bottom: 16px;
    justify-content: center;
}

.deleteBtn {
    width: 50%;
    margin-bottom: 16px;
    justify-content: center;
}

.buttonIcon {
    display: flex;
    align-items: center;
}

.iconWarp {
    width: 46px;
    height: 46px;
    margin: 0px 0 12px 12px;
    padding: 11px;
    background-color: var(--primary-400-base);
    border-radius: 50%;
    cursor: pointer;
}

.userPreferences {
    font-size: 24px;
    font-weight: 800;
    color: var(--black-200);
    margin-bottom: 10px;
    margin-top: 20px;
}

.dateFormatWrap {
    margin-bottom: 16px;
}

.multiInputTitle__icon {
    width: 24px;
    height: 24px;
}

.multiInputTitle__icon path {
    fill: #1e90ff;
}

.multiInputValue {
    display: flex;
    align-items: center;
    width: 150px;
    min-width: 150px;
    margin-bottom: 0;
    padding-left: 20px;
}

.multiInputValue__text {
    margin-bottom: 0;
    margin-left: 10px;
    font-size: 16px;
    font-weight: 500;
}

.multiInputValue__empty {
    width: 24px;
    height: 24px;
    border: 1px solid #e6e6e6;
    border-radius: 50%;
    margin: 0;
    cursor: pointer;
    position: relative;
}

.multiInputValue__empty .multiInputValue__emptyIcon {
    display: none;
    width: 22px;
    height: 22px;
    position: absolute;
    top: 0;
    left: 0;
}

.multiInputValue__empty .multiInputValue__emptyIcon path {
    fill: #ccc;
}

.multiInputValue__empty:hover .multiInputValue__emptyIcon {
    display: block;
}

.tableHeaderActiveIcon path {
    fill: #1e90ff;
}

.buttonWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 5px;
}

.addItemText {
    cursor: pointer;
    position: relative;
}

.addCircleBtn {
    height: 32px !important;
    width: 32px !important;
    margin-left: 10px;
}

.addCircleBtn svg {
    height: 20px !important;
}

.okBtnWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.error {
    border: 1px solid var(--error-400-base) !important;
}
.errorSelect {
    border: 1px solid var(--error-400-base) !important;
    border-radius: 6px !important;
}
.errorSelect > div {
    box-shadow: none !important;
}
.errorTitle {
    border: 1px solid var(--error-400-base) !important;
    display: inline-block;
    padding: 0px 10px;
}
.errorText {
    color: red;
}
.fieldRequiredMark {
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: var(--error-400-base);
}
.divider {
    height: 2px;
    background-color: var(--primary-400-base);
}

.dividerCollegeDetail {
    height: 2px;
    background-color: var(--primary-400-base);
    margin-bottom: 0px;
}

.star {
    color: var(--error-400-base);
}

.columnRequired {
    padding-left: 4px;
    color: var(--error-400-base);
    font-size: 12px;
    text-transform: initial;
}

.hidden {
    display: none;
}

.typeTitleWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    margin-bottom: 16px;
}

.collapseButton {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    border-radius: 6px;
    transform: rotate(180deg);
    cursor: pointer;
}

.collapsed {
    transform: rotate(0deg);
    transform-origin: center;
}

.checkboxGroup {
    border-radius: 6px;
    border: solid 1px var(--primary-200);
    display: flex !important;
    justify-content: space-evenly;
}

.checkboxGroup :global(.ant-radio-wrapper) {
    padding: 10px 20px;
    margin-right: 0 !important;
    border-right: solid 1px var(--primary-200);
    font-weight: 600;
    width: 100%;
    justify-content: center;
    text-align: center;
}

.checkboxGroup :global(.ant-radio-wrapper):last-child {
    border-right: none;
}

.checkboxGroupMailingAddress {
    border-radius: 6px;
    border: solid 1px var(--primary-200);
}

.checkboxGroupMailingAddress :global(.ant-radio-wrapper) {
    padding: 10px;
    margin-right: 0 !important;
    border-right: solid 1px var(--primary-200);
    font-weight: 600;
}

.checkboxGroupMailingAddress :global(.ant-radio-wrapper):last-child {
    border-right: none;
}

.createInfoMargin {
    margin: 0px 0 !important;
}

.switchWrap {
    border-radius: 6px;
    border: solid 1px #1e90ff;
    padding: 12px 16px;
    display: inline-flex;
    align-items: center;
}

.switchItem {
    font-size: 18px;
    font-weight: 500;
    color: #939393;
    margin-left: 11px;
    margin-right: 10px;
}

@media (max-width: 1368px) {
    .notificationItem__title {
        margin-right: 5px;
    }
}

/*@media (max-width: 1440px) {
    .checkboxGroup :global(.ant-radio-wrapper) {
        padding: 10px calc(100% - 77%) !important;
    }
}*/

.personalWrap {
    margin-bottom: 40px;
    padding: 0 9%;
}

.userPreferencesWidth {
    width: 75%;
}

.titleWrap {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 0 5px;
}
