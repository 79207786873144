.title {
    font-size: 24px;
    font-weight: bold;
    color: var(--primary-400-base);
    margin-bottom: 24px;
}

.loading {
    position: relative;
}

.eventTypeWrap {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    padding: 16px;
    border-radius: 16px;
    background-color: var(--black-100);
    margin-bottom: 24px;
}

.radioGroup {
    border-radius: 12px;
    border: solid 2px var(--primary-400-base);
    background-color: var(--whitebase);
    padding: 0 16px !important;
    display: flex !important;
    margin-bottom: 16px !important;
}

.radioItem {
    font-size: 16px !important;
    font-weight: 600;
    color: #000 !important;
    text-transform: uppercase;
    border-right: 1px solid var(--primary-400-base);
    padding: 8px 0 !important;
    flex: 1;
}

.radioItem:last-child {
    border-right: none;
}

.submoduleDropdown {
    width: 100%;
}

.icon {
    width: 24px;
    height: 24px;
}

.timeWrap {
    display: flex;
    align-items: center;
    gap: 24px;
}

.action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
    margin-top: 24px;
}

.coursesDropdown {
    margin-bottom: 24px;
}
