.drawerWrap {
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow-x: hidden;
}

.panelJobWrap {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    width: 400px;
    height: 100%;
    border-right: solid 1px var(--black-100);
}

.panelJobContainer {
    height: 100%;
    padding: 16px;
    overflow-y: scroll;
}

.panelWrap {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.profileActionWrap {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 12px;
}

.profileActionIconWrap {
    width: 36px;
    height: 36px;
    padding: 8px;
    border-radius: 24px;
    background-color: var(--primary-100);
    cursor: pointer;
}

.profileActionIcon {
    width: 20px;
    height: 20px;
    fill: var(--primary-300);
}

.profileActionIconActiveWrap {
    background-color: var(--primary-400-base);
}

.profileActionIconActive {
    fill: var(--whitebase);
}

.jobDescription {
    margin-top: 16px;
    padding-top: 16px;
    border-top: solid 1px var(--black-100);
    border-bottom: solid 1px var(--black-100);
}

.descTitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: var(--black-200);
    text-transform: uppercase;
}

.descValue {
    margin: 16px 0 0;
    max-height: 300px;
    overflow-y: scroll;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: #000;
}

.expandedDescValue {
    max-height: unset;
    overflow: hidden;
    flex: 1;
}

.metadataWrap {
    display: flex;
    border-top: solid 1px var(--black-100);
    padding-top: 16px;
}

.metadata {
    display: flex;
    flex: 1;
    gap: 10px;
    padding: 7px 16px;
    border-radius: 4px;
    background-color: #f5f5f5;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--black-200);
    text-transform: uppercase;
}

.studentActionWrap {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 8px;
}

.studentAction {
    justify-content: center;
}

.actionBorder {
    width: 1px;
    height: 36px;
    flex-grow: 0;
    background-color: var(--black-100);
}

.reactionContainer {
    display: flex;
    gap: 2px;
    cursor: pointer;
}

.count {
    font-size: 12px;
}

.applicationStatusWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 8px 0;
    border-radius: 8px;
    background-color: #e8faef;
    flex: 1;
}

.applicationStatus {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    text-align: center;
    color: var(--success-400-base);
}

.applicationAppliedIcon {
    width: 20px;
    height: 20px;
}

.readinessText {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: var(--black-200);
    text-align: center;
}
