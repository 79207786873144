.datePicker {
    border-radius: 6px !important;
    box-shadow: 0 2px 12px 0 rgba(48, 49, 51, 0.1);
    border-color: transparent !important;
    padding-right: 0px !important;
}
.datePicker :global(.ant-picker-clear) {
    margin-right: 52px;
}

.calendarIconWrap {
    background-color: var(--primary-400-base);
    width: 44px;
    height: 44px;
    display: grid;
    place-content: center;
    border-radius: 0px 6px 6px 0px;
    position: relative;
    right: -2px;
}

.error {
    border: 1px solid var(--error-400-base) !important;
}

.readOnly {
    background: #ffffff !important;
}

.readOnly :global(.ant-picker-input > input) {
    color: var(--black-base) !important;
}

.disabled {
    background: #f2f2f2 !important;
}

.calendarIconWrap svg path {
    fill: white;
}
