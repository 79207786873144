.filterItem {
    display: flex;
    align-items: center;
    border-radius: 12px;
    flex: 1;
    min-height: 44px;
    padding: 8px 16px 8px 8px;
    border: solid 2px var(--primary-400-base);
    background-color: var(--whitebase);
}

.filterTitle {
    -webkit-text-stroke: 0 #000;
    font-family: Poppins;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    width: 160px;
    margin-right: 22px;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-200);
}
.actionButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.inputValue {
    flex-direction: row;
    align-items: center;
    display: flex;
    flex: 1;
}
.inputGrade {
    width: 75px;
}
.verifyButton {
    border-width: 0 !important;
    margin-left: 10px !important;
}
.iconEdit {
    width: 20px;
}
.cancelButton {
    margin-right: 10px;
}
.editButton {
    padding-left: 5px !important;
    padding-right: 0px !important;
    border-width: 0px !important;
    cursor: pointer;
}
.isVerified svg {
    width: 24px;
    margin-top: 10px;
    margin-left: 5px;
    height: 24px;
}
.notVerified svg {
    width: 24px;
    margin-top: 10px;
    margin-left: 5px;
    height: 24px;
}
.actionWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.studentGradingTemplateSelect {
    width: 100%;
    height: 30px;
    padding-top: 2px;
    padding-bottom: 2px;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.verifybtn {
    border-width: 0 !important;
    padding: 0 !important;
    color: black !important;
}
.dropDownPassFail {
    border: 1px solid gray;
    border-radius: 5px;
    color: red;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 5px;
}
.dropDownPass {
    color: green !important;
}
.dropDownIncomplete {
    color: orange !important;
}
.dropDownFail {
    color: red !important;
}
.filterRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.dataFilter {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}

.radioBtnWrap {
    display: flex !important;
    flex: 1;
    width: 100%;
}

.radioBtn__item {
    font-size: 14px !important;
    font-weight: bold;
    color: var(--black-300) !important;
    margin: 0 !important;
    flex: 2;
    text-transform: uppercase;
}

.radioBtn__item:last-child {
    flex: 1;
}

.radioBtn__active {
    color: #000;
}

.actionWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.actionAdd {
    margin-left: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.actionChangeMode {
    margin-right: 12px;
    display: flex;
}
.valueLabel {
    font-family: Poppins;
    font-size: 15px;
    margin-left: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: black;
}
.headerTableText {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-200);
}

.date {
    font-family: Raleway;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-200);
}

.dot {
    width: 12px;
    height: 12px;
    border-radius: 6px;
}
.textGrade {
    flex-grow: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    margin-left: 5px;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
.passFailText {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
.iconSummary {
    width: 20px;
    height: 20px;
}
.gradeItem {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.summaryDataContent {
    display: flex;
    height: 65px;
    padding-top: 5px;
    flex: 1;
    justify-content: space-between;
    flex-direction: column;
    margin-left: 18px;
}
.numberOfGradesFrom {
    display: flex;
    padding-top: 2px;
    justify-content: space-between;
    flex-direction: row;
}
.numberOfGrades {
    display: flex;
    position: relative;
    flex-direction: row;
}
.summaryDataContainer {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    margin-bottom: 20px;
    flex: 1;
    /* margin: 40px 34px 40px 81px; */
    padding: 20px 20px 20px 20px;
    border-radius: 12px;
    justify-content: space-evenly;
    box-shadow: 0 4px 24px 0 rgba(48, 49, 51, 0.08);
    background-color: var(--whitebase);
}
.gradeItems {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 20px;
    flex: 1;
    /* margin: 40px 34px 40px 81px; */
    padding: 20px 20px 20px 20px;
    border-radius: 12px;
    box-shadow: 0 4px 24px 0 rgba(48, 49, 51, 0.08);
    background-color: var(--whitebase);
}
.titleSummary {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
.subTitleSummary {
    font-family: Poppins;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-200);
}
.numberSummary {
    font-family: Poppins;
    font-size: 30px;
    margin-top: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-200);
}
.name {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}
.droppedText {
    font-size: 12px;
    font-weight: bold;
    margin-left: 10px;
    margin-bottom: 0px;
}
.iconDrop {
    width: 18px;
    height: 18px;
}
.buttonSave {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}
.icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-color: var(--black-100);
    margin-left: 10px;
}

.nameStudent {
    display: inline-block;
}
.studentGradingTemplate {
    width: 100%;
    text-align: center;
    padding-left: 5px;
    font-size: 0.7rem;
    padding-right: 5px;
}

.fixedName {
    font-size: 14px;
    font-weight: bold;
}
