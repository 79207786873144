.finPlanContainer {
    min-height: 300px;
}

.actionWrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.awardYearsContainer {
}

.awardYearContent {
    margin-top: 24px;
    margin-bottom: 40px;
}

.title {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
    color: var(--black-base);
    text-transform: uppercase;
    margin-bottom: 8px;
}

.infosContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
}

.infoContent {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 8px;
    border-radius: 12px;
    border: solid 1px var(--black-100);
    background-color: var(--whitebase);
}

.infoLabel {
    flex-shrink: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: var(--primary-400-base);
    text-transform: uppercase;
}

.infoValue {
    flex-shrink: 0;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    color: #000;
}

.actionBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}

.closeIcon {
    height: 20px;
    width: 20px;
}

.modalTitle {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 24px;
    color: var(--primary-400-base);
    text-transform: uppercase;
    line-height: 0.75;
    margin-left: 0;
}
