.wrapper {
    margin-bottom: 40px;
}

.buttonWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
}

.cancelBtn {
    margin-right: 20px;
    text-transform: uppercase;
}

.activityTarget {
    margin-top: 20px;
    margin-bottom: 20px;
}

.activityTarget__title {
    font-size: 24px;
    font-weight: 800;
    color: var(--black-200);
    margin-bottom: 10px;
}

.activityTargetForm {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.activityTargetSquare {
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
    background-color: var(--primary-400-base);
    border-radius: 4px;
}

.activityTarget__text {
    margin-bottom: 0;
    margin-left: 10px;
    color: var(--black-base);
    font-size: 18px;
    font-weight: bold;
    margin-right: 40px;
    width: 100px;
}

.titleWrap {
    display: flex;
    align-items: center;
}

.lockIcon {
    display: block;
    float: left;
    margin-right: 5px;
}

.title {
    display: block;
    float: left;
}

.inactiveTitle {
    font-style: italic;
}
