.filterLesson {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    margin-top: 8px;
}

.selectItem {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.tableLesson {
    height: 256px;
    max-height: 256px;
}

.selectLabel {
    font-family: Poppins;
    font-size: 10px;
    margin-bottom: 5px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-300);
}
