.backBtnWrap {
    cursor: pointer;
}

.backIcon {
    width: 48px;
    height: 48px;
}

.screenTitle {
    font-size: 25px;
}
