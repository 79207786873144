.pdfPage {
    padding: 32px;
    width: 297mm;
    padding-top: 0px;
    visibility: hidden;
    height: 0px;
}

.heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    align-items: flex-start;
    width: 100%;
}

.clientSection {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    line-height: 1.5;
    color: var(--black-300);
}

.clientName {
    font-size: 1.2rem;
    font-weight: bold;
}

.logo {
    height: 64px;
}

.clientInfo {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 0.75rem;
}

.studentSection {
    display: flex;
    font-size: 0.75rem;
    flex-direction: column;
    line-height: 1.5;
    color: var(--black-300);
}

.date {
    display: flex;
    gap: 4px;
    margin-top: 4px;
}

.titleWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pageTitle {
    font-size: 27px;
    font-weight: bold;
    line-height: 1.41;
    color: #000;
}

.pageSubTitle {
    font-size: 16px;
    font-weight: bold;
    color: var(--black-300);
}

.tableContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 16px;
}

.table {
    border-collapse: collapse;
    border: 1px solid var(--black-100);
}

.table td,
.table th {
    border: 1px solid var(--black-100);
    border-bottom: none;
    border-right: none;
    padding: 6px;
}

.table th:last-child,
.table td:last-child {
    /* border-bottom: 1px solid var(--black-100); */
    border-right: 1px solid var(--black-100);
}

.tableHeaderText {
    font-size: 10px;
    font-weight: bold;
    line-height: 1.5;
    word-break: break-word;
    text-transform: uppercase;
    color: var(--black-200);
}

.tableColumn {
    font-size: 10px;
    word-break: break-word;
    line-height: 1.33;
    color: var(--black-base);
}

.tableTitle {
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 18px;
    color: #000;
}
