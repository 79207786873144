.lessonInfoWrap {
    border-radius: 18px;
    border: solid 1px var(--black-100);
    padding: 14px 22px;
    margin-bottom: 30px;
}

.lessonHeader {
    display: flex;
    align-items: center;
    gap: 12px;
}

.lessonName {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    color: var(--black-base);
}

.lessonCode {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    color: var(--primary-400-base);
}

.line {
    height: 1px;
    width: 100%;
    background-color: var(--black-100);
    margin-top: 11px;
    margin-bottom: 11px;
}

.lessonInfo__title {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    color: var(--black-200);
}

.lessonInfo__value {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    color: #000;
}

.capitalize {
    text-transform: capitalize;
}
