.createdInfo {
    display: inline-block;
    border-radius: 4px;
    background-color: #f5f5f5;
    padding: 7px 16px;
    margin: 8px 0;
}

.createdInfo__text {
    font-size: 16px;
    font-weight: 500;
    color: #939393;
}

.createdInfo__text b {
    font-weight: 600;
}

@media (max-width: 768px) {
    .createdInfo {
        padding: 2px 4px;
        margin-top: 4px;
        margin-bottom: 4px;
        line-height: 8px;
    }

    .createdInfo__text {
        font-size: 6px;
        line-height: 8px;
    }
}
