.wrapper {
    margin-bottom: 40px;
}

.title {
    font-size: 18px;
    font-weight: bold;
}

.checkboxContainer {
    margin-top: 20px;
}

.action {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 40px;
}

.line {
    border: none;
    margin-top: 38px;
    margin-bottom: 40px;
    width: 100%;
    height: 2px;
    background-color: var(--black-100);
}
