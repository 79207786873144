.wrap {
    padding: 0 30px 20px;
}

.title {
    font-size: 37px;
    font-weight: 800;
    line-height: 1.41;
    color: #666666;
    padding-bottom: 14px;
    margin-bottom: 32px;
    border-bottom: 1px solid #62b1ff;
}

.iconWrap {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.iconItemWrap {
    width: 64px;
    height: 64px;
    border-radius: 16px;
    background-color: #a5d3ff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 28px;
    cursor: pointer;
}

.iconItemWrap__active {
    background-color: #1e90ff;
}

.icon {
    width: 36px;
    height: 36px;
}

.desc {
    font-size: 21px;
    font-weight: 500;
    line-height: 1.52;
    color: #666666;
    margin-bottom: 16px;
}

.signatureImgWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    border: solid 2px #e5e5e5;
    margin-bottom: 8px;
    position: relative;
    padding: 20px;
}

.signatureImg {
    width: auto;
    height: 100%;
}

.editBtnWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 40px;
}

.editBtn {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    color: #1e90ff;
    text-decoration: underline;
    cursor: pointer;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
}

@media (max-width: 768px) {
    .wrap {
        padding: 0;
    }

    .title {
        font-size: 16px;
        padding-bottom: 5px;
        margin-bottom: 10px;
    }

    .desc {
        font-size: 12px;
        margin-bottom: 15px;
    }

    .iconWrap {
        margin-bottom: 10px;
    }

    .iconItemWrap {
        width: 32px;
        height: 32px;
        margin-right: 10px;
        border-radius: 8px;
    }

    .icon {
        width: 16px;
        height: 16px;
    }
}
