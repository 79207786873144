.userId {
    color: #3e3636;
    font-weight: bold;
    margin-right: 10px;
}

.userType {
    color: #666666;
    font-size: 11px;
    text-transform: capitalize;
}
