.checkInContainer {
    display: flex;
    align-items: center;
    gap: 64px;
}

.checkInWrap {
    display: flex;
    flex-direction: column;
}

.checkInIconWrap {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
}

.checkInIcon {
    width: 24px;
    height: 24px;
}
.attCheckVerified svg {
    width: 34px;
    height: 34px;
}

.checkInTime {
    font-size: 12px;
    font-weight: 600;
    color: var(--black-300);
    margin-top: 3px;
}
.verifybtn {
    border-width: 0 !important;
    padding: 0 !important;
    color: black !important;
}
.actionButtonWrap {
    display: flex;
    align-self: stretch;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 24px;
    gap: 24px;
}
.actionDetailWrapPopup {
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: flex-end;
    flex: 1;
}
.popupTitle {
    font-size: 25px;
    font-weight: bold;
    color: var(--black-base);
}
.buttonPopupConfirm {
    margin-left: 10px;
}
.actionWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.breakWrap {
    display: flex;
    gap: 12px;
}

.breakItem {
    display: flex;
    flex-direction: column;
}

.breakItem__icon {
    width: 28px;
}
.actionWarning {
    display: flex;
}

.actionWarning path {
    fill: var(--warning-400-base);
}

.attCheck svg {
    width: 34px;
    height: 34px;
}

.attCheck path {
    fill: var(--primary-400-base);
}

.attClose path {
    stroke: var(--error-400-base);
}
.smallTitle {
    font-size: 20px;
    color: var(--black-base);
}
.name {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}
.icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-color: var(--black-100);
    margin-left: 10px;
}
.name {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}
.nameStudent {
    width: 150px;
    display: inline-block;
}
.titleMakeupPopup {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    margin-bottom: 8px;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
.detailMakeupPopup {
    padding: 4px 8px;
    border-radius: 8px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.contentMakeupPopup {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-300);
}
.rowContentMakeup {
    display: flex;
    gap: 5px;
    flex-direction: row;
}
