.root {
    display: inline-flex;
    margin-bottom: 6px;
    color: var(--black-600);
}

.rootError {
    color: var(--error-400-base);
}

.asterisk {
    color: var(--error-400-base);
    font-size: 16px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
}
