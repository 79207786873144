.wrap {
    position: absolute;
    z-index: 1;
}

.actionItem {
    position: relative;
    display: flex;
    border: solid 1px #1e90ff;
    background-color: #e9f4ff;
    border-radius: 4px;
    padding: 1px;
}

.actionItemRequired {
    border: solid 1px #ff8a38;
    background-color: #fff1d7;
}

.actionItem_error {
    border: solid 2px var(--error-400-base) !important;
}

.flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.textWrap {
    display: flex;
    width: 100%;
    padding: 0 4px;
    position: relative;
}

.label {
    flex: 1;
    font-size: 8px;
    font-weight: bold;
    color: #666666;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    margin-right: 5px;
}

.icon {
    width: 6px;
    min-width: 6px;
    height: 6px;
}

.iconChecked {
    width: 80%;
    height: 80%;
}

.date {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.inputWrap {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.answerText {
    align-items: flex-start;
    white-space: pre-wrap;
}

.input {
    height: 100% !important;
    max-height: 44px;
    font-size: inherit !important;
    line-height: 16px !important;
    padding: 1px !important;
    border: none !important;
    border-radius: 4px !important;
    box-shadow: none !important;
}

.textarea {
    width: 100%;
    resize: none;
    max-height: unset;
}

.signedItem {
    height: auto;
    width: auto;
    max-width: 100%;
    max-height: 100%;
}

.studentDataValue {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hasCommentBorder {
    border-color: #ffe55b;
}

.hasComment {
    border-color: #ffe55b;
    background: #ffe55b;
}

.remarkCommentWrap {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.remarkComment {
    white-space: pre-line;
}

.remarkIndicator {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -10px;
    left: -10px;
    width: 24px;
    height: 24px;
    padding: 4px;
    border-radius: 8px;
    background-color: #ffe55b;
    cursor: pointer;
}

.remarkIndicatorIcon {
    width: 12px;
    height: 12px;
}

.checkedIcon {
    position: absolute;
    top: 0;
    right: 0;
    width: 6px;
    min-width: 6px;
    height: 6px;
}

.smallInput {
    font-size: 10px;
    line-height: 12px;
}

.superSmallInput {
    font-size: 7px;
    line-height: 9px;
}

@media (max-width: 768px) {
    .actionItem {
        padding: 1px;
        border: solid 0.5px #1e90ff;
    }

    .textWrap {
        padding: 1px;
    }

    .label {
        font-size: 3px;
        line-height: 5px;
        margin-right: 0;
    }

    .remarkIndicator {
        left: -2px;
        top: -2px;
        width: 8px;
        height: 8px;
        padding: 0px;
        border-radius: 2px;
    }

    .remarkIndicatorIcon {
        width: 6px;
        height: 6px;
    }

    .checkedIcon {
        width: 3px;
        min-width: 3px;
        height: 3px;
    }

    .input {
        max-height: 20px;
        line-height: 10px !important;
        padding: 1px !important;
    }

    .textarea {
        max-height: 100%;
    }
}

@media (max-width: 400px) {
    .actionItem {
        padding: 0;
    }

    .textWrap {
        padding: 0;
    }
}

.textAreaWrap {
    width: 100%;
    height: 100%;
}
