.name {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}
.icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-color: var(--black-100);
    margin-left: 10px;
}
.name {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}
.nameStudent {
    display: inline-block;
}
.titleMakeupPopup {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    margin-bottom: 8px;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
.detailMakeupPopup {
    padding: 4px 8px;
    border-radius: 8px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.contentMakeupPopup {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-300);
}
.rowContentMakeup {
    display: flex;
    gap: 5px;
    flex-direction: row;
}

.notAttended path {
    stroke: var(--error-400-base);
}

.notAttendedIcon {
    width: 24px;
    height: 20px;
}

.actionWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
