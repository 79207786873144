.review {
    font-size: 18px;
    font-weight: bold;
    color: #1e90ff;
    text-decoration: underline;
    cursor: pointer;
}

.statusWrap {
    display: flex;
    align-items: center;
}

.signatureWrap {
    padding: 7px 5px;
    display: flex;
    align-items: center;
    margin-right: 8px;
}

.signature__statusIcon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

.signature__approval {
    background-color: #e8faef;
}

.signature__reject {
    background-color: #ffe5eb;
}

.signature__icon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

.signature__img {
    width: 56px;
    height: 20px;
}

.dateInfo {
    display: flex;
    flex-direction: column;
}

.dateInfo__text {
    font-size: 14px;
    font-weight: 600;
    color: #666666;
    margin-bottom: 3px;
}

.upload {
    font-size: 18px;
    font-weight: bold;
    color: #1e90ff;
    text-decoration: underline;
    cursor: pointer;
}

.upload__disabled {
    font-size: 18px;
    color: #666666;
    font-weight: normal;
}

.fileName {
    display: inline-block;
    padding: 4px 10px;
    border-radius: 4px;
    background-color: #939393;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
}

.divider {
    height: 1px;
    background-color: #e5e5e5;
    margin: 30px 0 40px;
}

.action {
    display: flex;
    justify-content: space-between;
}

.actionBtn {
    height: 48px !important;
    border-radius: 8px !important;
    font-size: 18px !important;
    font-weight: bold !important;
    display: flex !important;
    padding: 10px 20px !important;
    align-items: center;
}

.rejectBtn {
    border: solid 1px #df1642 !important;
    color: #df1642 !important;
}

.action__icon {
    width: 32px;
    height: 32px;
    margin-left: 15px;
}

.approveBtn {
    color: #18a957 !important;
    border: solid 1px #18a957 !important;
}

.rowHighlighted {
    background-color: #cbcbcb !important;
}

.notRequiredCheckbox {
    display: flex;
    align-items: center;
    justify-content: center;
}

.naText {
    font-size: 16px;
}

.rightActionBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    gap: 16px;
}

.checklistName {
    cursor: text !important;
}

.archiveOldChecklistContainer {
    font-weight: bold;
    padding: 10px 0px;
}

.archiveOldChecklistsLabel {
    margin-right: 10px;
    font-size: 16px;
}

.tasksContainer {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.review_dependency {
    font-size: 16px;
    font-weight: bold;
    color: var(--black-200);
    cursor: disabled;
}
