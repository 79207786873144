.label {
    width: 100%;
    margin: 0;
    padding: 0;
}

.title {
    margin: 0;
    padding: 0;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    letter-spacing: normal;
    color: var(--black-300);
    line-height: 1.4;
    margin-bottom: 5px;
    margin-left: 2px;
    text-transform: capitalize;
}

.required {
    color: var(--secondary-400-base);
    font-weight: 700;
}

.errorWrapper {
    width: 100%;
    background-color: var(--secondary-400-base);
    padding: 6px 14px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 16px 24px 0 rgba(48, 49, 51, 0.1);
}

.errorText {
    margin: 0;
    padding: 0;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    line-height: 1.75;
    letter-spacing: normal;
    color: var(--black-base);
}
