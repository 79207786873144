.closeIcon {
    height: 20px;
    width: 20px;
}

.body {
    max-height: 65vh;
    overflow: auto;
    overflow-x: hidden;
    padding-right: 10px;
}

.eventPopupWrap {
    padding: 10px;
}

.eventPopupIcon {
    width: 28px;
}

.eventPopupHeader {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
}

.eventPopupTitle {
    font-size: 2rem;
    font-weight: 800;
    margin-bottom: 0;
    color: var(--primary-400-base);
    text-transform: uppercase;
    line-height: 0.75;
    margin-left: 0;
}

.eventTypeWrap {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.eventPopupLabel {
    display: flex !important;
    align-items: center;
    color: var(--black-300);
    font-size: 16px;
}

.eventPopupRecurringEvents {
    padding: 5px 0px;
}

.removeEventBtn {
    background: transparent !important;
    width: 16px !important;
    height: 16px !important;
    border: 0 !important;
    position: absolute !important;
    top: -4px;
    right: 0;
}
.removeEventBtnTitle {
    font-weight: bold;
    font-size: 16px;
    display: inline-block;
    margin-left: 8px;
}

.removeEventBtnTitleConflict {
    margin-top: 10px;
}

.removeEventBtnSubtitle {
    margin-left: 8px;
}

.removeIcon {
    width: 15px;
    height: 15px;
}

.actionBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    padding-right: 10px;
}

.rightActionBtn {
    display: flex;
    align-items: center;
}

.cancelBtn {
    margin-right: 24px;
}

.clearBtn {
    margin-right: 20px;
    padding: 7px 17px !important;
    height: auto !important;
    border: 1px solid #1e90ff !important;
    color: #1e90ff !important;
    font-weight: bold !important;
    background: transparent !important;
    border-radius: 4px !important;
}

.doneBtnWrap {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.shadow {
    border-radius: 6px !important;
    box-shadow: 0 2px 12px 0 rgba(48, 49, 51, 0.1) !important;
}

.error {
    border: 1px solid var(--error-400-base) !important;
}

.dropDown > div > div {
    border-radius: 6px;
    box-shadow: 0 2px 12px 0 rgba(48, 49, 51, 0.1);
    background-color: var(--whitebase);
    border-color: white;
}

.monthly {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--black-300);
}

.monthly input,
.monthly .input {
    width: 178px;
}

.monthly > div > label > div::before {
    border: solid 2px white;
}

.rowItem {
    margin-bottom: 24px;
}

.eventType {
    display: grid;
    grid-template-columns: 24px 1fr;
    align-items: center;
}

.eventType__color {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    display: inline-block;
}

.eventType__label {
    padding-left: 8px;
    font-size: 16px;
    display: inline-block;
}

.rsvpWrap {
    display: flex;
    align-items: center;
}

.rsvpItem {
    width: 96px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    border-radius: 4px;
    background-color: #e5e5e5;
    color: #939393;
    margin-right: 24px;
    text-transform: uppercase;
    cursor: pointer;
}

.rsvpYesActive {
    background-color: var(--success-400-base);
    color: #fff;
}

.rsvpMaybeActive {
    background-color: var(--warning-400-base);
    color: #fff;
}

.rsvpNoActive {
    background-color: var(--error-400-base);
    color: #fff;
}

.userId {
    color: #3e3636;
    font-weight: bold;
    margin-right: 10px;
}

.userType {
    color: #666666;
    font-size: 11px;
}

.monthlyWrap {
    border-top: 1px solid blue;
}

.bodyPanel {
    max-height: unset;
}

.actionBtnPanel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.saveBtn {
    flex: 1 1;
    justify-content: center;
}

.checkboxWrap {
    display: inline-block;
    margin-left: 8px;
}

.checkbox {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    border-radius: 4px;
    border: solid 1px var(--black-100);
    background-color: var(--whitebase);
    flex: 1;
    cursor: pointer;
}

.checked {
    border: solid 1px var(--primary-400-base);
}

.checkboxTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    color: #000;
    text-transform: uppercase;
}

.hostsWrap {
    display: flex;
    align-items: center;
    gap: 8px;
}

.hostsDropdown {
    flex: 1;
}

.reminderMethods {
    display: flex;
    flex-direction: column;
    gap: 4px;
}
