.progressWrapLeft {
    padding: 8px;
    margin-right: 20px;
    border-radius: 10px;
    background-color: #f5f5f5;
}
.progressWrapRight {
    margin-left: 20;
    padding: 8px;
    border-radius: 10px;
    background-color: #f5f5f5;
}
.progressView {
    border: 1px solid #e6e6e6;
    border-radius: 12px;
    margin-top: 40px;
    padding: 16px;
}
.container {
    padding-bottom: 60px;
    padding-top: 30px;
}

.termInfoWrap {
    position: relative;
    margin-top: 20px;
}

.termInfo {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    border-radius: 12px;
    box-shadow: 1px 2px 16px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px var(--black-100);
    height: 100%;
}

.termInfoItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.termInfoItem__label {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--black-300);
}

.termInfoItem__value {
    font-size: 16px;
    font-weight: 500;
    color: var(--primary-400-base);
    line-height: 1.5;
}
