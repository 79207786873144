.root {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.noHeader :global(.DayPicker-Caption),
:global(.DayPicker-NavBar) {
    display: none;
}

.indicatorWrap {
    display: flex;
    margin-bottom: 10px;
}

.indicatorIconBtn {
    border: 0 !important;
    padding: 3px 10px !important;
    margin-left: 10px;
    height: 30px !important;
}

.indicatorIcon {
    width: 10px;
}

.rotate {
    transform: rotate(180deg);
}

.indicatorDate {
    flex: 1;
    font-size: 24px;
    font-weight: 700;
    color: #939393;
}

.indicatorPanelWrap {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.indicatorPanelDate {
    flex: unset;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
}

.indicatorIconBtnPanel {
    margin-left: 0;
}
