.closeIcon {
    height: 20px;
    width: 20px;
}

.actionBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    padding-right: 10px;
}
