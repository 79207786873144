.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 73px;
}

.content {
    padding: 30px 70px 30px 70px;
    padding-bottom: 60px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.detail {
    font-family: Poppins;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    text-align: center;
    color: var(--black-300);
}

.title {
    font-family: Poppins;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    align-self: stretch;
    text-align: left;
    color: var(--black-200);
}

.line {
    align-self: stretch;
    height: 1px;
    margin-bottom: 30px;
    background-color: var(--black-100);
}

.actions {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-bottom: 20px;
}

.buttonAction {
    display: flex;
    flex-direction: row;
    gap: 8px;
    cursor: pointer;
    align-items: center;
    padding: 10px 17px;
    border-radius: 8px;
    border: solid 1px var(--black-100);
}

.active {
    border: 2px solid var(--primary-400-base);
}

.buttonText {
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-300);
}

.eventIcon {
    width: 30px;
    height: 30px;
}

.countDownText {
    font-size: 20px;
}

.pageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
}

.standaloneContent {
    background-color: unset;
    flex-direction: column-reverse;
}

.timeLabel {
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    color: var(--primary-400-base);
    line-height: 1.13;
}

.qrInfoWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}
