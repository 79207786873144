.courseItem {
    padding: 6px 8px;
    border: solid 1px var(--black-100);
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.courseAction {
    display: flex;
    align-items: center;
    flex: 1;
    gap: 4px;
}

.editCourseBtn,
.deleteCourseBtn {
    cursor: pointer;
}

.actionIcon {
    width: 16px;
    height: 16px;
}

.dotWrap {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.dotTitle {
    font-size: 16px;
    font-weight: 500;
    color: var(--black-200);
    width: 48px;
    min-width: 48px;
}

.dotDayWrap {
    display: flex;
    align-items: center;
    background: #fff;
}

.daysInfo {
    min-height: 48px;
}

.dotDayItem {
    font-size: 16px;
    font-weight: 500;
    color: var(--black-200);
    width: 78px;
    min-width: 78px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.timeContainer {
    max-height: 400px;
    overflow: auto;
}

.timeCellWrap {
    display: flex;
}

.timeCellItem {
    border: solid 1px var(--black-100);
    width: 78px;
    min-width: 78px;
    height: 32px;
    min-height: 32px;
    display: flex;
    padding-right: 12px;
}

.timeCellItem__event {
    position: relative;
    flex: 1;
    border-radius: 4px;
}

.dayOfTerm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 78px;
    min-width: 78px;
    font-size: 16px;
    font-weight: 500;
    color: var(--black-200);
    gap: 3px;
}

.courseNameWrap {
    display: flex;
    align-items: center;
    width: calc(100% - 36px);
}

.courseName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    font-size: 14px;
}

.courseDisabledIcon {
    width: 16px;
    height: 16px;
    margin-left: 8px;
}
