.courseSection {
    margin-top: 40px;
    padding-bottom: 32px;
    border-bottom: 1px solid #e5e5e5;
}

.courseSection:last-child {
    border-bottom: none;
}

.headerWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sectionInfo {
    display: flex;
    align-items: center;
}

.sectionTitle {
    font-size: 24px;
    font-weight: 800;
    color: var(--black-base);
    margin-right: 24px;
}

.creditWrap {
    display: flex;
    align-items: center;
    border-radius: 8px;
    background-color: #e5e5e5;
    padding: 4px 12px;
    margin-right: 15px;
}

.creditTitle,
.completedTitle {
    font-size: 18px;
    font-weight: 500;
    color: var(--black-300);
    margin-right: 6px;
}

.creditValue,
.completedValue {
    font-size: 24px;
    font-weight: 800;
    color: var(--black-base);
}

.unitValue {
    font-size: 18px;
    font-weight: 600;
    color: var(--black-base);
}

.completedWrap {
    display: flex;
    align-items: center;
    border-radius: 8px;
    background-color: var(--primary-100);
    padding: 4px 12px;
}

.loadingWrap {
    margin-top: 40px;
}

.addSectionBtn {
    margin-top: 30px;
}

.actionWrap {
    display: flex;
    align-items: center;
    gap: 12px;
}
.selectTerm {
    width: 500px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}
