.preloader {
    --withPreloader: 25px;
    --border-width: 4px;
    --position-x: 50%;
    --position-y: 50%;
    color: transparent !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 200;
}
.preloader svg {
    fill: transparent !important;
}
.preloader span {
    color: transparent !important;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(180deg);
    }
}
.preloader::before {
    border: solid var(--border-width) var(--primary-400-base);
    content: "";
    display: block;
    position: absolute;
    width: var(--withPreloader);
    height: var(--withPreloader);
    top: calc(var(--position-y) - var(--withPreloader) / 2);
    left: calc(var(--position-x) - var(--withPreloader) / 2);
    border-radius: 50%;
    border-top-color: var(--whitebase);
    border-bottom-color: var(--whitebase);
    box-shadow: 0 0 6px var(--primary-400-base);
    z-index: 1;
    animation-play-state: paused;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}
.preloader::after {
    content: "";
    display: block;
    position: absolute;
    width: calc(var(--withPreloader) - var(--border-width) * 2);
    height: calc(var(--withPreloader) - var(--border-width) * 2);
    top: calc(var(--position-y) - (var(--withPreloader) - var(--border-width) * 2) / 2);
    left: calc(var(--position-x) - (var(--withPreloader) - var(--border-width) * 2) / 2);
    border-radius: 50%;
    box-shadow: 0 0 6px var(--primary-400-base) inset;
    z-index: 3;
    animation: anim_rotation 1s linear infinite;
}
