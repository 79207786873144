.root {
    width: 100%;
    min-height: 300px;
    border-radius: 10px;
    box-shadow: 0 16px 24px 0 rgba(48, 49, 51, 0.1), 0 0 1px 0 rgba(48, 49, 51, 0.05);
    background-color: var(--whitebase);
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
