.wrap {
    border-radius: 12px;
    background-color: #f5f5f5;
    padding: 12px 32px;
    margin-bottom: 40px;
}

.title {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
    color: #939393;
    margin-bottom: 24px;
}

.search {
    background: #fff;
    margin-bottom: 24px;
}

.tabelCellStyle {
    background: #fbfbfb;
}

.seletedTaskWrap {
    display: flex;
    align-items: center;
}

.backBtn {
    margin-right: 16px;
}

.backBtnWrap {
    cursor: pointer;
}

.backIcon {
    width: 32px;
    height: 32px;
}

.selectedTaskTitle {
    font-size: 24px;
    font-weight: bold;
    color: #939393;
    margin-bottom: 0;
}
