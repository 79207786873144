.tabContainer {
    height: 60vh;
}

.unreadCount {
    min-width: 16px;
    box-sizing: content-box;
    border-radius: 50%;
    background-color: var(--secondary-400-base);
    padding: 2px 3px;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.41;
    text-align: center;
    color: var(--whitebase);
    margin-left: 4px;
}

.sidebarContainer {
    height: calc(100vh - 48px) !important;
}

.composeContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.reduceHeight {
    height: 45vh;
}

.noMessage {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--black-300);
}

.sidebarComposeContainer {
    padding: 0 8px;
}
