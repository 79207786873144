.formTitle {
    font-size: 24px;
    font-weight: bold;
    color: var(--black-300);
    text-transform: uppercase;
    line-height: 1.14;
    padding-bottom: 16px;
    border-bottom: solid 1px var(--black-100);
    margin-bottom: 16px;
}

.doneBtn {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 30px;
}

.tableContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 16px;
}

.headerTitle {
    font-size: 10px;
    font-weight: bold;
    line-height: 1.5;
    word-break: break-word;
    text-transform: uppercase;
    color: var(--black-200);
}

.rowHeader {
    align-items: center;
    min-height: 40px;
    background-color: var(--primary-100);
    display: grid;
    gap: 6px;
    padding: 0 6px;
}

.row {
    margin-top: 6px;
    display: grid;
    gap: 6px;
    padding: 0 6px;
}

.checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
}

.docName {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.removeIcon {
    width: 24px;
    height: 24px;
}

.action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.star {
    font-weight: bold;
    color: var(--error-400-base);
    padding-left: 4px;
    padding-right: 4px;
}

.removeWrap {
    display: flex;
    align-items: center;
    cursor: pointer;
}
