.formDetail {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 8px;
}

.title {
    font-size: 20px;
    font-weight: 700;
    color: var(--black-200);
}

.inputCheck {
    font-size: 16px;
    color: var(--black-base);
}

.inputWrap {
    padding: 8px 0 8px 24px;
}

.radioWrap {
    display: flex !important;
    flex-direction: column;
    gap: 8px;
}
