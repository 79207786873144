.container {
    display: flex;
    align-self: stretch;
    flex-direction: column;
    padding-bottom: 20px;
    text-transform: uppercase;
}

.tableLesson {
    height: 256px;
    max-height: 256px;
}

.selectTerm {
    flex-grow: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-300);
}
