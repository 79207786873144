.baseInput {
    height: 40px;
    border: solid 2px #e5e5e5;
    outline: none;
    padding: 8px 14px;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    line-height: 1.75;
    letter-spacing: normal;
    color: var(--black-base);
    margin: 0;
}

/* Chrome, Safari, Edge, Opera */
.baseInput.disabledArrow::-webkit-outer-spin-button,
.baseInput.disabledArrow::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
.baseInput.disabledArrow[type="number"] {
    -moz-appearance: textfield;
}

.borderless {
    border: none !important;
}

.baseInput > input {
    height: 100%;
}

:global(.ant-input-suffix) {
    cursor: pointer !important;
}
.baseInput.error {
    border: 1px solid red;
}

.input:disabled {
    background-color: var(--grey-100);
}

.input:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base);
}

.input::placeholder {
    width: 154px;
    height: 24px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--black-200);
}

.suffixIconWrap {
    display: flex;
    align-items: center;
}

.suffixIcon {
    width: 20px;
}

.fileInputWrap {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.fileNameWrap {
    height: 48px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    background: #e9f4ff;
    padding: 4px 8px;
    border: 0;
    margin-right: 16px;
    cursor: default;
}

.fileName {
    font-size: 18px;
    font-weight: 600;
    color: #1e90ff;
}

.removeIconBtn {
    height: 48px !important;
    width: 48px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border: none !important;
    background: #1e90ff !important;
    padding: 8px !important;
    border-radius: 12px !important;
}

.removeIcon {
    width: 20px;
    height: 20px;
}

.hidden {
    display: none;
}

@media (max-width: 768px) {
    .baseInput {
        font-size: 8px !important;
        line-height: 10px !important;
        padding: 1px !important;
        height: 20px !important;
    }

    .fileNameWrap {
        margin-right: 10px;
    }

    .fileName {
        font-size: 11px;
    }

    .removeIconBtn {
        height: 24px !important;
        width: 24px;
    }
}

@media (max-width: 400px) {
    .baseInput {
        font-size: 5px !important;
        line-height: normal !important;
    }
}

.hideText {
    font-family: text-security-disc;
    -webkit-text-security: disc;
}

.hideText > input {
    font-family: text-security-disc;
}

.hideText > input::placeholder {
    font-family: Poppins, sans-serif !important;
}
