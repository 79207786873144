.wrap {
    padding: 0 30px;
}

.title {
    font-size: 37px;
    font-weight: bold;
    color: #666666;
    padding-bottom: 14px;
    border-bottom: 2px solid #62b1ff;
    margin-bottom: 30px;
}

.description {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}

.content {
    font-size: 14px;
    font-weight: 500;
    color: #666666;
}

.actionBtnWrap {
    display: flex;
    justify-content: flex-end;
    margin-top: 60px;
}

.cancelBtn {
    margin-right: 40px;
}

.agreeBtn {
    text-transform: uppercase;
}
