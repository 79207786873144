.bodyContent {
    position: absolute;
    top: 0;
    border-radius: 10px;
    box-shadow: 2px 4px 32px 0 rgba(0, 0, 0, 0.24);
    background-color: #ffffff;
    padding: 16px;
    z-index: 999;
    overflow: auto;
    max-height: 450px;
    min-width: 220px;
}

.bodyContentHiddenScroll {
    max-height: unset;
    overflow: unset;
}

.bodyHeader {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #1e90ff;
    padding-bottom: 7px;
    margin-bottom: 16px;
}

.bodyHeaderTitle {
    flex: 1;
    font-size: 18px;
    font-weight: bold;
    color: #212121;
    margin-bottom: 0;
}

.headerActions {
    display: flex;
    align-items: center;
    gap: 6px;
}

.actionBtn {
    cursor: pointer;
}

.actionIcon {
    width: 24px;
    height: 24px;
}

.formItem {
    margin-bottom: 16px;
}

.actionWrap {
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.divider {
    background-color: #e5e5e5;
    height: 1px;
    margin-bottom: 16px;
}

.itemWrap {
    margin-bottom: 16px;
}

.itemHeader {
    display: flex;
    align-items: center;
}

.itemHeaderTitle {
    flex: 1;
}

.itemHeaderTitle__active {
    color: #1e90ff;
    font-weight: bold;
}

.itemHeaderIcon {
    cursor: pointer;
}
