.wrap {
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 12px 12px 16px;
    border-radius: 8px;
    background-color: #f5f5f5;
}

.progressWrap {
    display: flex;
}

.title {
    font-size: 21px;
    font-weight: bold;
    color: var(--black-200);
}

.progressWrap {
    /*margin-bottom: 30px;*/
    /*margin-left: 50px;*/
}

.progressBody {
    display: flex;
    position: relative;
    min-height: 150px;
    overflow-x: auto;
    overflow-y: hidden;
}

.progressBar {
    position: absolute;
    top: 56px;
    left: 1px;
    background: var(--primary-400-base);
    height: 8px;
    width: calc(100% - 10px);
}

.progressBarPerStage {
    background: var(--primary-400-base);
    height: 8px;
    top: 24px;
    position: relative;
    left: 10px;
}

.progressItem {
    display: flex;
    flex-direction: column;
    align-items: initial;
    flex: 1;
    z-index: 1;
}

.progressItem__all {
    align-items: flex-end;
}

.progressItem__center {
    align-items: center;
}

.progressItem__title {
    font-size: 16px;
    font-weight: bold;
    color: var(--black-base);
    height: 40px;
    text-align: center;
    display: flex;
    align-items: flex-end;
    margin-bottom: 0px;
}

.progressItem__status {
    position: relative;
    width: 40px;
    height: 40px;
    background-color: var(--primary-400-base);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.progressItem__statusAll {
    width: 86px;
    border-radius: 20px;
    background-color: var(--primary-400-base);
    font-size: 21px;
    font-weight: bold;
    color: var(--whitebase);
}

.progressItem__descWrap {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    color: var(--black-200);
    font-weight: bold;
    font-size: 14px;
    line-height: 1.5;
}

.progressItem__descStatus {
    margin-bottom: 0;
}

.progressItem__descTime {
    margin-bottom: 0;
}

.triangle {
    width: 0px;
    height: 0px;
    border-left: 70px solid transparent;
    border-right: 70px solid transparent;
    border-bottom: 70px solid #fff;
    position: absolute;
    top: 65px;
}

.tableWrap {
    padding: 12px;
    background: #fff;
    border-radius: 8px;
}

.tableHeader {
    margin-top: 0;
}

.activityHeaderWrap {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.activity__title {
    font-size: 32px;
    font-weight: 800;
    color: var(--black-200);
    margin-bottom: 0;
}

.addIconWrap {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.plusIcon {
    height: 24px;
    width: 24px;
}

.marginBottom {
    margin-bottom: 8px;
}
