.wrapper {
    display: flex;
}

.public_wrapper {
    display: block;
}

.bodyWrap {
    height: 100vh;
    overflow: auto;
    position: relative;
    flex: 1;
}

.bodySubmitTask {
    min-width: unset;
}

.v2Wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 100vh;
}

.header {
    overflow: hidden;
    overflow-y: auto;
}

.main {
    overflow: auto;
}

.logo {
    margin: 0;
}

.logoIcon {
    fill: var(--darkBlue-400);
    width: 110px;
    height: 21px;
}

.hidden {
    display: none;
}

.errorBoundary {
    padding: 48px 24px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.errorBoundaryAlert {
    width: 100%;
    max-width: 720px;
}

.errorBoundaryRefreshPage {
    text-decoration: underline;
    cursor: pointer;
}
