.addItemButton {
    margin-top: 24px;
}
.titleWrap {
    display: flex;
    padding: 11px 12px;
    align-items: center;
}

.title {
    display: block;
    float: left;
}

.checkBoxAffectGPA {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.checkbox + label::before {
    border: 2px solid #c7c7c7;
}
.checkbox:checked + label::before {
    background-image: url("../../../../../../../components/buttons/buttonCheckbox/Icon_Checkbox_Checked.svg");
    background-size: 40px 30px;
    border: 2px solid #1e90ff;
}

.weightingNotRequired {
    font-size: 16px;
    font-weight: 500;
}

.displayColor {
    width: 20px;
    height: 20px;
    border-radius: 6px;
    background-color: red;
}
.containerDropdownColor {
    display: flex;
    padding-top: 14px;
    padding-bottom: 14px;
    flex: 1;
    justify-content: space-between;
    flex-direction: row;
}
.icon {
    width: 18px;
    height: 18px;
}
.bottomRow {
    display: flex;
    flex-direction: row;
}
.paddingRow {
    padding: 11px 12px;
}
.checkBoxColumn {
    width: 30px;
}
.flexView {
    display: flex;
    flex: 1;
}
.colorColumn {
    min-width: 120px;
    width: 120px;
    max-width: 120px;
}
.percentColumn {
    min-width: 150px;
    width: 150px;
    max-width: 150px;
}
.paddingValueData {
    padding: 11px 22px;
}
