.wrap {
    margin-bottom: 32px;
    display: flex;
    align-items: center;
}

.taskDetail {
    font-size: 24px;
    font-weight: bold;
    color: #939393;
    margin-right: 16px;
    margin-bottom: 0;
}

.title {
    border-radius: 8px;
    background-color: #939393;
    padding: 4px 8px;
    font-size: 21px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 0;
    text-transform: uppercase;
}

.studentViewHeader {
    display: flex;
    margin-bottom: 30px;
}

.studentViewHeaderItem {
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    padding: 3px 16px;
    margin-right: 2px;
}

.studentViewHeaderItem__title {
    font-size: 14px;
    font-weight: 500;
    color: #939393;
    line-height: 1;
    margin-bottom: 2px;
}

.studentViewHeaderItem__value {
    font-size: 21px;
    font-weight: bold;
    line-height: 1.33;
    color: #666666;
    margin-bottom: 0;
}
