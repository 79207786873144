.baseBtn {
    color: var(--primary-400-base) !important;
    background-color: var(--whitebase);
    border-radius: 8px !important;
    padding: 12px 18px !important;
    height: 48px !important;
    display: flex !important;
    align-items: center;
    font-weight: bold !important;
    text-align: center;
}

.isActive {
    background-color: #f5f5f5 !important;
    border-color: inherit;
}

.baseBtn:hover {
    background-color: #ccc !important;
    color: #fff;
    border-color: inherit;
}

.baseBtn.isActive:hover {
    background-color: #e0eefc !important;
}
