.createBtnWrap {
    display: flex;
    justify-content: flex-end;
}

.createBtn {
    color: #fff !important;
    border-radius: 4px !important;
    padding: 7px 17px !important;
    height: auto !important;
    display: flex !important;
    align-items: center;
    font-weight: bold !important;
    background-color: #ff349b !important;
}

.createBtnText {
    margin-right: 10px;
}

.plusIcon {
    height: 24px;
    width: 24px;
}

.headerWrap {
    display: flex;
    margin-bottom: 40px;
}

.headerLeftWrap {
    display: flex;
}

.headerRightWrap {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
}

.backBtnIcon {
    height: 53px !important;
    width: 50px !important;
    border: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    padding: 0.5px 0px !important;
}

.headerTitle {
    font-size: 24px;
    color: #212121;
    font-weight: bold;
    margin-left: 20px;
    margin-bottom: 0;
}

.headerRightTitle {
    font-weight: 800;
    color: var(--black-base);
    font-size: 24px;
    margin-bottom: 10px;
}

.headerRightInfo {
    display: flex;
}

.headerRightInfo__title {
    color: var(--black-200);
    font-weight: 600;
    font-size: 18px;
    margin-right: 10px;
    margin-bottom: 0;
}

.headerRightInfo__code {
    color: var(--primary-400-base);
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 0;
}

.flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.itemBodyWrap {
    margin-bottom: 30px;
}

.itemBodyMargin {
    margin-right: 20px;
}

.bodyTitle {
    margin-bottom: 6px;
}

.textarea {
    height: 150px;
    margin: 0 !important;
}

.textarea:focus {
    margin: 0 !important;
}

.cancelBtn {
    margin-right: 20px;
    text-transform: uppercase;
}

.doneBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 40px;
    margin-bottom: 40px;
}

.required {
    font-weight: bold;
    color: var(--error-400-base);
}

.radioWrap {
    margin-bottom: 6px;
}
