.collapsedRightPanel {
    width: 0px;
    min-width: 0px;
}

.rightPanelIconWrap {
    width: 36px;
    height: 36px;
    padding: 8px;
    border-radius: 12px;
    background-color: var(--primary-100);
    cursor: pointer;
    position: absolute;
    top: 50px;
    right: 40px;
}

.rightPanelIcon {
    width: 17px;
    height: 17.9px;
}

.expandedRightPanel {
    width: 320px;
    min-width: 320px;
    background-color: #fbfbfb;
    transition: 0.3s;
    border-left: solid 1px var(--black-100);
}

.hidden {
    display: none;
}
