.orButton {
    right: 15px;
    width: 32px;
    bottom: 12px;
    height: 22px;
    padding: 1px 6px 1px 5px;
    border-radius: 4px;
    border: solid 1px var(--secondary-400-base);
    background-color: var(--secondary-100);
}
.orText {
    width: 21px;
    height: 20px;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary-400-base);
}
.orButtonContainer {
    display: flex;
    justify-content: center;
}

.orLabelContainerLeft {
    flex-grow: 0;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    flex-direction: row;
    padding: 4px 4px 4px 4px;
    border-radius: 4px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: var(--warning-200);
}
.orLabelContainer {
    flex-grow: 0;
    display: flex;
    align-items: center;
    margin-right: 5px;
    margin-bottom: 5px;
    flex-direction: row;
    padding: 4px 4px 4px 4px;
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    background-color: var(--warning-200);
}
.orLabel {
    flex-grow: 0;
    margin: 0px 0 0px 4px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-300);
}
