.actionItem {
    position: relative;
    display: flex;
    border: solid 1px #1e90ff;
    background-color: #e9f4ff;
    border-radius: 4px;
    padding: 1px;
}

.inputWrap {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.answerText {
    align-items: flex-start;
    white-space: pre-wrap;
}

.smallInput {
    font-size: 10px;
    line-height: 12px;
}

.superSmallInput {
    font-size: 7px;
    line-height: 9px;
}

.input {
    height: 100% !important;
    max-height: 44px;
    font-size: inherit !important;
    line-height: 16px !important;
    padding: 1px !important;
    border: none !important;
    border-radius: 4px !important;
    box-shadow: none !important;
}

.textarea {
    width: 100%;
    resize: none;
    max-height: unset;
}

.textAreaWrap {
    width: 100%;
    height: 100%;
}

@media (max-width: 768px) {
    .actionItem {
        padding: 1px;
        border: solid 0.5px #1e90ff;
    }

    .input {
        max-height: 20px;
        line-height: 10px !important;
        padding: 1px !important;
    }

    .textarea {
        max-height: 100%;
    }
}

@media (max-width: 400px) {
    .actionItem {
        padding: 0;
    }
}
