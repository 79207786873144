.root {
    position: relative;
}

.tableFirefoxScroll {
    overflow: auto;
    max-height: 600px;
}

.tableScroll {
    max-height: 600px;
}

.root .tableScrollHidden {
    overflow-x: unset !important;
    overflow-y: unset !important;
}

.table {
    width: 100%;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
}

.tableHead {
    background: #e9f4ff;
    position: sticky;
    top: 0;
    z-index: 1;
}
.tableSubHead {
    background: #f5f5f5;
    position: sticky;
    top: 0;
    z-index: 1;
}

.bodyTable {
    max-height: 500px;
    overflow: auto;
    display: table-row-group;
}

.flexEnd {
    display: flex;
    justify-content: flex-end;
}

.tableRowHeader {
    color: inherit;
    display: table-row;
    outline: 0;
    vertical-align: middle;
}

.tableCell {
    display: table-cell;
    padding: 3px 8px;
    font-size: 13px;
    text-align: left;
    font-weight: 400;
    line-height: 1.43;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    letter-spacing: 0.01071em;
    vertical-align: inherit;
    color: #212121;
    min-height: 40px;
    height: 40px;
    word-break: normal;
}

.tableCellHeader {
    font-size: 13px;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 1.5rem;
    position: relative;
    color: var(--black-200);
}

.tableCellSubHeader {
    height: 36px;
    min-height: 36px;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.5rem;
    position: relative;
    color: #000000;
    padding: 5px 12px;
}

.tableCellCenter {
    text-align: center;
}

.tableBody {
    display: table-row-group;
}

.tableRow {
    color: inherit;
    display: table-row;
    outline: 0;
    vertical-align: middle;
    cursor: pointer;
}

.withoutCursor {
    cursor: default;
}

.tableEmptyRow {
    color: inherit;
    display: table-row;
    outline: 0;
    vertical-align: middle;
}

.tableRowDragging {
    background: #a5d3ff !important;
}

.tableRow:nth-child(even) {
    background: #fbfbfb;
}

.tableRow:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.tableRowActive {
    background: #a5d3ff !important;
}

.tableRowInactive {
    background: var(--black-100) !important;
}

.expandIconCell {
    width: 32px !important;
    min-width: 32px;
    max-width: 32px;
    text-align: center;
    padding: 0 5px !important;
}

.arrowWrap {
    width: 24px;
    height: 24px;
    min-width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0;
    cursor: pointer;
}

.arrowIcon {
    width: 18px;
    height: 18px;
}

.iconBtn {
    border: none !important;
    background: transparent !important;
}

.menuActionWrap {
    padding: 17px !important;
}

.menuActionIcon {
    width: 24px;
    height: 24px;
}

.menuActionTitle {
    margin-left: 11px;
}

.cursor {
    cursor: pointer;
}

.emptyRowData {
    text-align: center;
    margin: 16px;
    max-width: 70vw;
}

.rowLoading {
    text-align: center;
    margin: 30px;
    max-width: 70vw;
}

.plusIconBtn {
    background: transparent !important;
    border: none !important;
}

.iconPlus {
    height: 24px;
}

.colsPicker {
    position: absolute;
    background: #fff;
    z-index: 99;
    width: 230px;
    right: 50px;
    border-radius: 10px;
    box-shadow: 2px 4px 32px 0 rgba(0, 0, 0, 0.16);
    overflow: hidden;
}

.closeColsPickerIcon {
    position: absolute !important;
    top: 2px;
    right: 8px;
    border: 0 !important;
    width: 20px !important;
    height: 20px !important;
}

.closeIcon {
    width: 19px;
    height: 19px;
}

.colsPickerTitle {
    font-size: 14px;
    display: flex;
    align-items: center;
    height: 40px;
    font-weight: 800;
    color: var(--black-base);
    background-color: #f5f5f5;
    padding: 0px 20px;
    margin: 0;
    text-transform: uppercase;
}

.colsPickerDesc {
    font-size: 16px;
    color: #212121;
    margin-bottom: 10px;
}

.colsPickerDescAvailable {
    font-size: 16px;
    color: #212121;
    margin-top: 13px;
    margin-bottom: 10px;
}

.colsPickerBody {
    padding: 12px 20px;
    max-height: 400px;
    overflow: auto;
}

.checkboxes {
    font-weight: 500;
    display: flex !important;
    flex-direction: column;
}

.OKBtnCheckbox {
    border: 1px solid #1e90ff !important;
    color: #1e90ff;
}

.menuActionItem {
    display: flex;
    align-items: center;
}

.sortIcon {
    width: 15px;
    height: 15px;
    vertical-align: middle;
    margin-left: 5px;
}

.warningBackground {
    background-color: var(--secondary-200) !important;
}

.textLeft {
    text-align: left;
}

.subCategory {
    background: lightgrey;
    padding: 10px;
    color: #565656;
    text-transform: uppercase;
}

.rightHTML {
    text-align: right;
}

.subText {
    font-size: 12px;
    color: var(--black-300);
}

.containerExpandIconWithColumn {
    display: flex;
    align-items: center;
}

.colsNormalWrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.colWrap {
    margin-bottom: 8px;
}

.colPickerItem {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.colTitle {
    padding-left: 8px;
    margin: 0;
}
