.checkInContainer {
    display: flex;
    align-items: center;
    position: relative;
    gap: 64px;
}

.checkInWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.checkInIconWrap {
    width: 28px;
    height: 28px;
    display: flex;
    background-color: white;
    z-index: 2;
    border-radius: 14px;
    justify-content: center;
    align-items: center;
}

.checkInIcon {
    width: 24px;
    height: 24px;
}

.checkInTime {
    font-size: 12px;
    font-weight: 600;
    color: var(--black-300);
    margin-top: 3px;
    text-align: center;
    min-width: 60px;
}
.buttonOut {
    cursor: pointer;
}
.inOutIcon {
    width: 32px;
    height: 32px;
}
.verifybtn {
    border-width: 0 !important;
    padding: 0 !important;
    color: black !important;
}
.actionButtonWrap {
    display: flex;
    align-self: stretch;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 24px;
    gap: 24px;
}
.actionDetailWrapPopup {
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: flex-end;
    flex: 1;
}
.popupTitle {
    font-size: 30px;
    font-weight: bold;
    color: var(--black-base);
}
.buttonPopupConfirm {
    margin-left: 10px;
}
.actionWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.breakWrap {
    display: flex;
    gap: 12px;
    flex: 1;
}

.breakItem {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.breakItem__icon {
    width: 26px;
    height: 26px;
}
.attendanceOutIcon {
    width: 28px;
    height: 28px;
}
.attendanceIcon {
    height: 24px;
    width: 24px;
}
.actionWarning {
    display: flex;
}

.actionWarning path {
    fill: var(--warning-400-base);
}

.attCheck svg {
    width: 34px;
    height: 34px;
}

.attCheckVerified svg {
    width: 34px;
    height: 34px;
}

.attCheck path {
    fill: var(--primary-400-base);
}

.attClose path {
    stroke: var(--error-400-base);
}
.searchBox {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.filterWrap {
    display: flex;
    flex: 1;
}
.buttonInOut {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    border-radius: 8px;
    background-color: var(--secondary-400-base);
}
.progressLine {
    height: 1px;
    background-color: var(--black-200);
    position: absolute;
    left: 0px;
    right: 0px;
    top: 13px;
    z-index: 1;
}

.attendanceContainer {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: center;
    align-items: center;
    position: relative;
}
.name {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}

.iconNotes {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    background-color: var(--black-100);
    margin-left: 10px;
}
.droppedText {
    font-size: 12px;
    font-weight: bold;
    margin-left: 10px;
    margin-bottom: 0px;
}
.nameStudent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.actionButton {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-color: var(--black-100);
    margin-left: 10px;
}
.addStudentButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-color: var(--black-100);
    margin-left: 10px;
}
.iconDrop {
    width: 18px;
    height: 18px;
}

.name {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}
.icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-color: var(--black-100);
    margin-left: 10px;
}
.name {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}
.titleMakeupPopup {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    margin-bottom: 8px;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
.detailMakeupPopup {
    padding: 4px 8px;
    border-radius: 8px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.contentMakeupPopup {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-300);
}
.rowContentMakeup {
    display: flex;
    gap: 5px;
    flex-direction: row;
}
