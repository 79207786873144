.closeIcon {
    height: 20px;
    width: 20px;
}
.eventPopupWrap {
    padding: 10px;
}

.eventPopupIcon {
    width: 28px;
}

.eventPopupHeader {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
}

.eventPopupTitle {
    font-size: 2rem;
    font-weight: 800;
    margin-bottom: 0;
    color: var(--primary-400-base);
    text-transform: uppercase;
    line-height: 0.75;
    margin-left: 0;
}

.rowItem {
    margin-bottom: 16px;
}

.eventPopupLabel {
    display: flex !important;
    align-items: center;
    color: var(--black-300);
    font-size: 16px;
}
.eventPopupIcon {
    width: 28px;
}

.actionBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}

.title {
    height: 24px;
    margin: 0 42px 38px 0;
    font-family: Poppins;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.96;
    letter-spacing: normal;
    color: var(--primary-300);
}

.body {
    margin-top: 38px;
}
.footer {
    margin-top: 40px;
}
.item {
    display: flex;
    margin-bottom: 16px;
    align-items: flex-end;
}
.item :global(textarea) {
    background-color: white;
}
.item.hidden {
    display: none;
}
.icon {
    display: flex;
    align-items: center;
    min-width: 28px;
}
.label {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.22;
    letter-spacing: normal;
    color: var(--black-300);
    margin-left: 20px;
    width: 100px;
}
.typeDropDown > div > div {
    z-index: 10;
}
.component {
    margin-left: 36px;
    flex-grow: 1;
}
.two_components {
    display: inline-block;
    width: calc(50% - 9px);
    position: relative;
}
.two_components:first-child {
    padding-left: 10px;
}
.two_components:last-child {
    margin-left: 18px;
}
.every {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--black-300);
}

.every input,
.every .input {
    width: 178px;
}
.executions {
    position: absolute;
    font-size: 12px;
    right: 0px;
}
.dayBoxContainer {
    display: flex;
    justify-content: space-between;
}
.dayBox {
    width: 39px;
    height: 39px;
    padding: 12px 10px 12px 9px;
    border-radius: 6px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: var(--primary-200);
}
.dayBoxSelected {
    background-color: var(--primary-400-base);
}
.buttons {
    display: flex;
    justify-content: flex-end;
}
.button:last-child {
    margin-left: 24px;
}
/* Date picker styles */
.datePicker > input {
    height: 44px;
    outline: none;
    box-shadow: azure;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    border-top-color: rgb(217, 217, 217);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(217, 217, 217);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(217, 217, 217);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(217, 217, 217);
    border-left-style: solid;
    border-left-width: 1px;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
}

.datePicker > input:hover {
    border: 1px solid var(--primary-300);
}

.datePicker_padding input {
    padding-left: 15px;
}
.error {
    border: red solid 1px;
}
