.buttonWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
}

.cancelBtn {
    margin-right: 20px;
}
