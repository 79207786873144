.wrap {
}

.headerTitle {
    margin-bottom: 0;
    padding: 8px 12px;
    border-radius: 8px;
    background-color: #f5f5f5;
    font-size: 21px;
    font-weight: bold;
    color: var(--black-300);
}

.actionWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.actionWrap > * {
    margin-left: 16px;
}

.assignChargeBtn {
    min-width: 80px;
    display: inline-block;
    padding: 2px 8px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    color: var(--whitebase);
    text-align: center;
    word-break: keep-all;
    background-color: #ff349b !important;
}

.status {
    min-width: 80px;
    display: inline-block;
    padding: 2px 8px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    color: var(--whitebase);
    text-align: center;
    word-break: keep-all;
    white-space: nowrap;
}

.status:global(.pending) {
    background-color: var(--black-200);
}
.status:global(.canceled) {
    background-color: var(--warning-400-base);
}
.status:global(.success) {
    background-color: var(--success-400-base);
}

.chargeList {
    margin-bottom: 4px;
}

.accountName {
    color: #666666;
    font-size: 13px;
    margin-bottom: 4px;
}

.chargeId {
    color: #3e3636;
    font-weight: bold;
    font-size: 13px;
    margin-right: 6px;
}

.notes {
    max-width: 350px;
}

.fundType {
    color: #aaaaaa;
    font-size: 12px;
    margin-top: 4px;
    line-height: 1.2;
}

.modalTitle {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 24px;
    color: var(--primary-400-base);
    text-transform: uppercase;
    line-height: 0.75;
    margin-left: 0;
}
