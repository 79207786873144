.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 50%;
    margin: 0 auto;
}

.root p {
    font-weight: 500;
    font-size: 18px;
}
