.label {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.22;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.85);
    margin-left: 20px;
    width: 100px;
}

.itemWrap {
    display: flex;
}

.two_components {
    display: inline-block;
    width: calc(50% - 9px);
    position: relative;
}

.two_components:last-child {
    margin-left: 18px;
}

.every {
    display: flex;
    align-items: center;
    color: var(--black-300);
    margin-bottom: 16px;
}

.everyLabel {
    margin-right: 20px;
}

.everyInput {
    flex: 1;
    display: flex;
    align-items: center;
}

.everyTitle {
    margin-left: 10px;
}

.endBody {
    flex: 1;
}

.endInput {
    flex: 1;
    display: flex;
    margin-bottom: 12px;
}

.executions {
    margin-left: 10px;
}

.dayBoxContainer {
    display: flex;
}

.dayBox {
    height: 44px;
    border-radius: 6px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: var(--primary-200);
    flex: 1;
    margin-right: 12px;
}

.dayBox:last-child {
    margin-right: 0;
}

.dayBoxSelected {
    background-color: var(--primary-400-base);
}

.error {
    border: red solid 1px;
}

.inputNumber {
    width: 100%;
    height: 40px;
    border: solid 2px #e5e5e5;
    outline: none;
    padding: 8px 14px;
    font-size: 16px;
    color: var(--black-base);
    margin: 0;
}
