.button {
    position: absolute;
    top: 55px;
    right: 2px;
    transform: translateX(50%);
    width: 32px;
    height: 32px;
    border: none;
    border-radius: 50%;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    background-color: var(--whitebase);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    outline: none;
    cursor: pointer;
    z-index: 100;
}

.button:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base);
}

.iconArrow {
    fill: var(--primary-200);
    width: 12px;
    height: 24px;
    margin-right: 5px;
}

.iconList {
    fill: var(--primary-200);
    width: 24px;
    height: 24px;
}

@media (max-width: 768px) {
    .button {
        width: 24px;
        height: 24px;
        margin-right: 2px;
        top: 40px;
    }

    .iconArrow {
        width: 6px;
        height: 12px;
    }

    .iconList {
        width: 12px;
        height: 12px;
    }
}
