.screen {
    padding: 0px;
}
.header {
    position: absolute;
    left: 40px;
    top: 0px;
    z-index: 1;
}
.container {
    padding: 120px 2% 100px 2%;
    background-color: var(--black-200);
    min-height: 100vh;
}

.tableContainerCourse {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.tableClockHoursTranscript {
    width: 100%;
}

.tableBlockClockHoursTranscript {
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    width: 100%;
    flex: 1;
}

.pdfPage {
    padding: 33px 40px 33px 40px;
    display: flex;
    page-break-before: always;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    background-color: white;
}
.signatureName {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.signatureBlock {
    display: flex;
    margin-bottom: 16px;
    flex-direction: column;
    flex: 1;
}
.signatureText {
    font-size: 14px;
}
.titleSign {
    margin-top: 20px;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-200);
}
.titleSignContainer {
    display: flex;
    padding-top: 20px;
    flex-direction: column;
    align-items: center;
}
.signatureImage {
    width: 300px;
    height: 40px;
}
.termName {
    display: flex;
    flex-direction: row;
    flex: 1;
    gap: 16px;
}
.line {
    width: 100%;
    height: 1px;
    background-color: black;
}
.bottomInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.signatureForm {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: space-between;
    align-items: flex-end;
}
.signatureBottom {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.signature {
    gap: 16px;
    display: flex;
    align-items: flex-end;
    flex-direction: row;
}
.rowTitle {
    display: flex;
    align-self: stretch;
    flex-direction: row;
}
.logo {
    width: 120px;
}
.titleView {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    flex: 1;
    margin-top: 21px;
    align-items: flex-end;
}
.signatureImg {
    width: 200px;
    margin-bottom: 20px;
}
.signature {
    display: flex;
    align-self: stretch;
    justify-content: flex-end;
}
.signatureContainer {
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.titleSignature {
    font-size: 16px;
    font-weight: 400;
}
.nameSignature {
    font-size: 16px;
    font-weight: 400;
}
.title {
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    text-align: right;
    color: var(--black-base);
}
.studentNameForm {
    display: flex;
    flex: 1;
    align-self: stretch;
    flex-direction: row;
    align-items: flex-end;
    flex-direction: row;
    justify-content: space-between;
}
.studentCode {
    font-size: 27px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    text-align: right;
    color: var(--black-300);
    margin-right: 14px;
}
.studentName {
    font-size: 27px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    text-align: right;
    color: #000;
}
.issuedDate {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: right;
    color: var(--black-300);
}
.studentInfo {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    padding: 8px 26px 5px 24px;
    border-radius: 16px;
    border: solid 2px var(--black-100);
    background-color: var(--whitebase);
    margin-bottom: 40px;
}
.degreeText {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    margin-bottom: 9px;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-300);
}
.studentInfoDetail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.blockDetail {
    display: flex;
    flex: 1;
    flex-direction: column;
}
.sectionDetail {
    display: flex;
    flex: 1;
    flex-direction: row;
    border-bottom: 1px solid var(--black-100);
    padding-bottom: 7px;
    justify-content: space-between;
    margin-bottom: 8px;
}
.sectionDetailWithoutBorder {
    display: flex;
    flex: 1;
    flex-direction: row;
    padding-bottom: 7px;
    justify-content: space-between;
    margin-bottom: 8px;
}
.titleInfo {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    text-transform: uppercase;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-200);
}
.textInfo {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: right;
    color: #000;
}
.tableTerm {
    display: flex;
    flex-direction: column;
    align-self: stretch;
}
.statusInfo {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2px 8px;
    background-color: var(--black-300);
}
.statusText {
    color: white;
    position: static;
    font-size: 14px;
    font-weight: bold;
    top: -2px;
    font-stretch: normal;
    font-style: normal;
}
.tableTitle {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    margin-bottom: 8px;
    text-transform: uppercase;
    text-align: left;
    color: var(--black-base);
}
.tableHeaderText {
    border-bottom: 1px solid var(--black-100);
    padding-bottom: 8px;
    margin-bottom: 6px;
    padding-right: 10px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    text-transform: uppercase;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-200);
}
.headerTransfer {
    display: flex;
    flex-direction: column;
}

.tableContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
}
@media print {
    .tableContainer {
        display: flex;
        flex: 1;
        flex-direction: column;
        break-after: always;
    }
}
.table {
    margin-bottom: 30px;
    width: 100%;
}

.tableColumn {
    padding-top: 6px;
    padding-right: 10px;
    padding-bottom: 6px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-base);
}

.termAvg {
    border-top: 1px solid var(--black-100);
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-300);
}

.tableTermTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.gpaBlock {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    margin-bottom: 8px;
    padding: 4px 8px;
    border-radius: 8px;
    border: solid 1px var(--black-100);
    background-color: #fbfbfb;
}

.cgpaBlock {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;
    gap: 16px;
    padding: 4px 8px;
    margin-left: 7px;
    border-radius: 8px;
    border: solid 1px var(--black-100);
    background-color: #fbfbfb;
}
.cgpaText {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;

    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-base);
}
.cgpaValue {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-base);
}
.buttonDownload {
    position: absolute !important;
    right: 40px;
    top: 40px;
    z-index: 2;
}

.button {
    background-color: #ff349b !important;
    color: #fff !important;
    border: 2px solid transparent !important;
}

.placeholderSignature {
    display: flex;
    flex-direction: row;
    align-self: stretch;
    justify-content: space-around;
}
.signatureLine {
    margin-top: 120px;
    width: 100%;
    height: 1;
    background-color: black;
}
.totalHours {
    display: flex;
    margin-right: 2%;
    align-items: stretch;
    justify-content: flex-end;
    font-weight: bold;
}
.parent {
    position: relative;
    min-height: 100vh;
    width: 100%;
}
.subjectBlock {
    display: flex;
    flex-direction: row;
    border: 1px solid black;
    margin-bottom: 16px;
    gap: 60px;
    align-items: center;
    padding: 8px;
}

.subjectTitle {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-base);
}

.subjectValue {
    margin-left: 10px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-base);
}
