.wrapper {
    margin-bottom: 40px;
}

.buttonWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
}

.addCircleBtn {
    height: 32px !important;
    width: 32px !important;
    margin-left: 10px;
}

.addCircleBtn svg {
    height: 20px !important;
}

.cancelBtn {
    margin-right: 20px;
    text-transform: uppercase;
}
