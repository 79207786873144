.fullnameWrap {
    display: flex;
    align-items: center;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    object-fit: cover;
}

.fullname {
    margin: 0 0 0 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.actionWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.program {
    min-width: 150px;
    max-width: 220px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.studentLeftInfo {
    border-radius: 10px;
    background-color: #f5f5f5;
    padding: 8px 10px;
    flex: 1;
    height: fit-content;
}

.studentRightInfo {
    flex: 1;
}

.studentRightInfo__margin {
    margin-bottom: 8px;
}

.dateInfo {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 8px;
}

.dateInfoItem {
    border-radius: 8px;
    background-color: var(--whitebase);
    padding: 4px;
    flex: 1;
}

.dateInfo__title {
    font-size: 13px;
    font-weight: 500;
    color: var(--primary-400-base);
    text-transform: uppercase;
    margin-bottom: 0;
}

.dateInfo__value {
    font-size: 16px;
    font-weight: bold;
    color: var(--black-base);
    margin-bottom: 0;
}
