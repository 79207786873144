.wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 50%;
    background-color: var(--tertiary-400-base);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    text-transform: uppercase;
    color: var(--whitebase);
}

.small {
    font-size: 10px;
}

@media (max-width: 768px) {
    .wrapper {
        font-size: 11px;
    }
}
