.container {
    display: flex;
    gap: 6px;
    align-items: center;
}

.icon {
    width: 20px;
    height: 20px;
}
