.container {
    padding: 8px;
    /* background-color: var(--whitebase); */
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.fullName {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    color: #000;
}

.main {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.content {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--black-300);
    word-wrap: break-word;
    white-space: pre-wrap;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.attached {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--black-300);
    font-style: italic;
}
