.wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
}

.small {
    font-size: 10px;
}

.avatar {
    object-fit: cover;
    height: 100%;
    width: 100%;
    max-width: 100%;
    border-radius: 50%;
}

@media (max-width: 768px) {
    .wrapper {
        font-size: 11px;
    }
}
