.body {
    overflow: auto;
    max-height: 65vh;
    padding: 0 20px;
}

.container {
    padding: 0 24px;
}

.title {
    font-size: 37px;
    font-weight: bold;
    color: #212121;
    margin-bottom: 16px;
}

.rowItem {
    margin-bottom: 24px;
}

.addActivityWrap {
    margin-top: 40px;
}

.addActivityHeader {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    position: relative;
}

.closeIconWrap {
    position: absolute;
    right: 0;
    cursor: pointer;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.closeIcon {
    height: 20px;
    width: 20px;
}

.addActivity__iconWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e9f4ff;
    width: 48px;
    height: 48px;
    border-radius: 50%;
}

.addActivity__title {
    margin-left: 16px;
    font-size: 32px;
    font-weight: 800;
    color: #939393;
    margin-bottom: 0;
}

.divider {
    background-color: #62b1ff;
}

.actionBtnWrap {
    display: flex;
    justify-content: flex-end;
    padding: 30px 30px 0px;
}

.spinAlign {
    margin: auto;
    display: block;
}
