.root {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 32px;
    width: 102px;
    border-radius: 4px;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: var(--primary-100);
    text-transform: capitalize;
    border: none;
    outline: none;
}

.active {
    background-color: var(--success-400-base);
}

.inactive {
    background-color: var(--error-400-base);
}
