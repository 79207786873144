.previewHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.previewTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.icon {
    width: 40px;
    height: 40px;
}

.title {
    font-size: 37px;
    font-weight: bold;
    line-height: 1.41;
    color: var(--black-base);
}

.actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.closeIcon {
    width: 24px;
    height: 24px;
}

.actionClose {
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
}
