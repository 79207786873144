.wrap {
    padding: 24px 20px;
    border-radius: 12px;
    background-color: #f5f5f5;
}

.title {
    font-size: 21px;
    font-weight: bold;
    color: var(--black-200);
    text-transform: uppercase;
    margin-bottom: 0;
}

.subTitle {
    font-size: 21px;
    font-weight: 600;
    color: var(--black-200);
    margin-bottom: 16px;
    margin-top: 16px;
}

.body {
    overflow-x: hidden;
    padding-bottom: 24px;
}

.divider {
    background-color: var(--black-100);
    height: 1px;
    margin: 24px 0;
}

.action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
    position: sticky;
    bottom: 0;
    background: white;
    padding: 24px 0;
    border-top: solid 1px var(--black-100);
}

.wrap .action {
    position: relative;
    background: #f5f5f5;
    padding: 24px 0 0 0;
    border-bottom: none;
}

/* Advisor */

.contentWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    min-width: 0;
}

.contentTitle {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--black-200);
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    max-width: 50%;
}

.contentValue {
    flex-shrink: 0;
    padding: 4px 8px;
    border-radius: 8px;
    background-color: #f5f5f5;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: var(--black-base);
    min-height: 26px;
    min-width: 32px;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    text-align: center;
    max-width: 55%;
}

.contentLink {
    font-size: 14px;
    font-weight: bold;
    color: var(--primary-400-base);
    cursor: pointer;
}

.contactWrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 16px;
    border-bottom: solid 1px var(--black-100);
}
