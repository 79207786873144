.status {
    padding: 4px 8px;
    border-radius: 8px;
    background-color: var(--black-100);
    color: var(--whitebase);
    font-size: 14px;
}

.cursor {
    cursor: pointer;
}

.status-applied {
    background-color: var(--black-300);
}

.status-in-review {
    background-color: var(--warning-400-base);
}

.status-rejected {
    background-color: var(--error-400-base);
}

.status-job-closed {
    background-color: var(--black-300);
}

.status-job-offer {
    background-color: var(--primary-400-base);
}

.status-interview {
    background-color: var(--primary-400-base);
}

.status-hired {
    background-color: var(--success-400-base);
}
