.wrap {
    overflow-y: auto;
    max-height: 450px;
    margin-top: 16px;
}

.item {
    display: flex;
    margin-bottom: 24px;
    cursor: pointer;
}

.imageWrap {
    display: flex;
    align-items: center;
}

.image {
    width: 164px;
    margin-right: 16px;
    height: 100%;
}

.info {
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 21px;
    font-weight: bold;
    color: #212121;
    line-height: 1.14;
    margin-bottom: 8px;
}

.description {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: #666;
    margin-bottom: 8px;
}

.date {
    font-size: 14px;
    font-weight: bold;
    color: #666;
    margin-bottom: 0;
}

.headerPopup {
    font-size: 37px;
    font-weight: 800;
    color: #666;
    padding-bottom: 14px;
    border-bottom: 1px solid #62b1ff;
    margin-bottom: 0;
}

.bodyPopup {
    margin-top: 10px;
}

.titlePopup {
    font-size: 28px;
    line-height: 1.41;
    font-weight: bold;
    color: #212121;
    margin-top: 0;
    margin-bottom: 16px;
}

.content {
    overflow-y: auto;
    max-height: 60vh;
}

.moreInfoWrap {
    margin-bottom: 16px;
}

.moreInfo {
    display: flex;
    align-items: center;
}

.moreInfoText {
    font-size: 14px;
    font-weight: bold;
    color: #666;
    margin-left: 8px;
}

.imagePopup {
    cursor: pointer;
    height: 200px;
    margin-bottom: 40px;
}

.descriptionPopup {
    font-size: 16px;
    font-weight: 500;
    line-height: 2;
    color: #666;
    margin-bottom: 60px;
}

.actionPopup {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.viewSource {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.viewSourceText {
    font-size: 16px;
    font-weight: 600;
    color: #212121;
    margin-right: 20px;
}

.viewSourceIcon {
    width: 32px;
    height: 32px;
    transform: rotate(180deg);
}

@media (max-width: 768px) {
    .wrap {
        margin-top: 8px;
    }
    .item {
        margin-bottom: 12px;
    }
    .image {
        width: 60px;
    }
    .title {
        font-size: 12px;
        margin-bottom: 5px;
    }
    .description {
        font-size: 11px;
        margin-bottom: 3px;
    }
    .date {
        font-size: 11px;
    }
}

.noFeedContainer {
    text-align: center;
    margin: 30px 10px;
    font-size: 25px;
}

.authorName {
    display: flex;
}
