.root {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--black-300);
}

.root.rootHoverable:hover {
    background-color: var(--black-100);
    cursor: pointer;
}
