.avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
}

.avatarWrap {
    overflow: hidden;
    border-radius: 50%;
    background-color: #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    text-transform: uppercase;
    color: var(--black-300);
    width: 24px;
    height: 24px;
}
