.color {
    width: 24px;
    height: 24px;
    border-radius: 2px;
}

.swatch {
    padding: 5px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    display: inline-block;
    cursor: pointer;
}

.popover {
    position: absolute;
    z-index: 2;
}

.tableColorPickerPopover {
    right: 0;
}

.cover {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
