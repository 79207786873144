.formBody {
    margin: 0;
    padding: 8px 0;
}

.formSection {
    padding-right: 24px;
    padding-left: 24px;
}

.formTitle {
    font-size: 21px;
    font-weight: bold;
    color: var(--black-200);
    text-transform: uppercase;
}

.graduatingInfo {
    padding: 8px 12px;
    border-radius: 8px;
    border: solid 1px var(--primary-400-base);
    background-color: var(--primary-100);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.graduatingInfo__title {
    font-size: 14px;
    font-weight: 500;
    color: var(--black-300);
    text-transform: uppercase;
}

.graduatingInfo__value {
    font-size: 16px;
    font-weight: bold;
    color: #000;
}

.line {
    background-color: var(--black-100);
    height: 1px;
    width: 100%;
    margin-top: 24px;
}

.formAction {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
}
