.rightBtnWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.exportBtn {
    margin-right: 48px;
}

.columnBtn {
    margin-right: 24px;
    border: 0 !important;
}

.expandBtn {
    border: 0 !important;
}

.colPickerWrap {
    position: relative;
}

.studentLeftInfo {
    border-radius: 10px;
    background-color: #f5f5f5;
    padding: 16px;
    flex: 1;
    height: fit-content;
}

.studentLeftInfoItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.studentLeftInfoItem:last-child {
    margin-bottom: 0;
}

.studentLeftInfoItem__title {
    font-size: 16px;
    font-weight: 600;
    color: var(--black-200);
    text-transform: uppercase;
}

.studentLeftInfoItem__value {
    font-size: 18px;
    font-weight: bold;
    color: #000;
}

.studentRightInfo {
    flex: 1;
    border-radius: 10px;
    padding: 8px 16px;
    height: fit-content;
}

.studentRightInfo.dued {
    border: solid 1px var(--secondary-400-base);
    background-color: var(--secondary-100);
}

.studentRightInfoHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.studentRightInfo__amount {
    font-size: 16px;
    font-weight: 600;
    color: var(--black-300);
    text-transform: uppercase;
}

.studentRightInfo__cost {
    font-size: 18px;
    font-weight: bold;
    color: #000;
}

.studentRightInfo__btn {
    width: 100%;
    justify-content: center;
}

.contentWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    margin-top: 8px;
}

.contentTitle {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--black-200);
    text-transform: uppercase;
    white-space: normal;
}

.contentValue {
    flex-shrink: 0;
    font-size: 14px;
    font-weight: 500;
    color: var(--black-base);
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
}

.inputNumber {
    width: 100px;
    border: solid 2px #e5e5e5;
    outline: none;
    padding: 2px 8px;
    margin: 2px;
    font-size: 16px;
    letter-spacing: normal;
    color: var(--black-base);
}

.cellMoney {
    display: block !important;
    width: 100%;
    text-align: right;
    font-weight: 600;
    font-size: 16px;
}

.item {
    flex: 1;
    /*border-left: solid 1px var(--black-100);*/
    color: var(--black-200);
    max-height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.itemTotal {
    font-size: 16px;
    font-weight: 800;
    color: #212121;
}

.status {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 102px;
    height: 32px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    margin: 0 auto;
    text-transform: capitalize;
}

.status__active {
    background-color: #18a957;
}

.status__complete {
    background-color: #1e90ff;
}

.status__withdrawn {
    background-color: #df1642;
}

.status__loa {
    background-color: #ff8a38;
}
