.progressReportBtn {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
    margin-bottom: 24px;
}

.rowInfo {
    margin-bottom: 24px;
}

.infoWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    box-shadow: 0 2px 12px 0 rgba(48, 49, 51, 0.1);
    border: solid 0 var(--black-base);
    padding: 8px 12px 24px 12px;
    height: 100%;
}

.infoLeftWrap {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex: 1;
    width: 100%;
}

.infoRightWrap {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex: 1;
    width: 100%;
}

.infoTitle {
    font-size: 16px;
    font-weight: bold;
    color: var(--black-300);
    flex: 1;
    margin-bottom: 8px;
    text-transform: uppercase;
}

.infoValue {
    font-size: 24px;
    font-weight: bold;
    color: var(--primary-400-base);
    margin-bottom: 0;
}

.center {
    text-align: center;
}

.studentLeftInfo {
    border-radius: 10px;
    background-color: #f5f5f5;
    padding: 8px 10px;
    flex: 1;
    height: fit-content;
}

.studentRightInfo {
    flex: 1;
}

.studentRightInfo__margin {
    margin-bottom: 8px;
}
