.wrap {
    margin-bottom: 40px;
    margin-top: 40px;
}

.cardItem {
    border-radius: 12px;
    box-shadow: 0 4px 24px 0 rgba(48, 49, 51, 0.16);
    background-color: #ffffff;
    padding: 8px 16px;
    min-height: 132px;
}

.cardItem__header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.cardItem__icon__container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-right: 20px;
}

.cardItem__icon__container:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    opacity: 0.1;
}

.cardItem__icon__container:global(.requested):before {
    background-color: #000;
}

.cardItem__icon__container:global(.approved):before {
    background-color: #50a375;
}

.cardItem__icon__container:global(.rejected):before {
    background-color: #cd3247;
}

.cardItem__icon__container:global(.lastPosted):before {
    background-color: #4291f7;
}

.cardItem__icon__container:global(.requested) .cardItem__icon path {
    fill: #000 !important;
}

.cardItem__icon__container:global(.approved) .cardItem__icon path {
    fill: #50a375 !important;
}

.cardItem__icon__container:global(.rejected) .cardItem__icon path {
    fill: #cd3247 !important;
}

.cardItem__icon__container:global(.lastPosted) .cardItem__icon path {
    fill: #4291f7 !important;
}

.cardItem__icon {
    width: 24px;
    height: 24px;
}

.cardItem__title {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    color: #000;
    text-transform: uppercase;
}

.cardItem__body {
    padding-left: 60px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.cardItem__value1 {
    min-width: 38px;
    margin-right: 16px;
    margin-bottom: 4px;
    padding: 2px 6px;
    border-radius: 4px;
    background-color: var(--black-200);
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
    text-align: center;
    color: var(--whitebase);
}

.cardItem__value1:global(.small) {
    font-size: 16px;
}

.cardItem__value2 {
    padding: 2px 6px;
    border-radius: 4px;
    background-color: var(--black-100);
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
    text-align: center;
    color: var(--black-200);
}
