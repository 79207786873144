.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header {
    font-size: 19px;
    font-weight: bold;
}

.subHeader {
    font-size: 16px;
    font-weight: 500;
}

.title {
    font-size: 16px;
    font-weight: bold;
}

.detail {
    display: flex;
    flex-direction: column;
    align-self: stretch;
}

.list {
    list-style-type: upper-roman;
    display: block;
}

.listTitle {
    font-size: 16px;
    font-weight: bold;
}

.content {
    margin-top: 10px;
}
.logo {
    width: 120px;
}
