.notificationWrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    padding: 8px;
    border-radius: 12px;
    background-color: var(--primary-100);
    margin-bottom: 4px;
    cursor: pointer;
}

.notificationWrap__opened {
    background-color: var(--primary-400-base);
}

.notificationIcon {
    width: 20px;
    height: 20px;
}

.notificationUnread {
    position: absolute;
    right: 10px;
    top: 6px;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: var(--secondary-400-base);
}
