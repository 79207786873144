.wrap {
    margin-bottom: 40px;
}

.cardItem {
    border-radius: 12px;
    box-shadow: 0 4px 24px 0 rgba(48, 49, 51, 0.16);
    background-color: #ffffff;
    padding: 16px 0;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.cardItem__title {
    font-size: 21px;
    font-weight: 800;
    color: #939393;
    padding-bottom: 8px;
    border-bottom: 1px solid #62b1ff;
    margin-left: 20px;
    margin-bottom: 0;
}

.cardItemBody {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.cardItemStatusWrap {
    display: flex;
    align-items: center;
}

.cardItem__number {
    font-size: 32px;
    font-weight: 700;
    color: #212121;
    margin-bottom: 0;
    line-height: 1.13;
    margin-left: 12px;
}

.cardItem__cost {
    font-size: 16px;
    font-weight: 500;
    color: #939393;
    margin-bottom: 0;
}
