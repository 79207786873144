.fullnameWrap {
    display: flex;
    align-items: center;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 40px;
}

.fullname {
    margin: 0 15px;
}

.tableSize {
    max-height: 500px;
    overflow: auto;
}

.primaryLabelWrap {
    display: flex;
    align-items: center;
}

.defaultLabel {
    border-radius: 5px;
    background-color: var(--primary-400-base);
    color: #fff;
    padding: 5px;
    text-align: center;
}

.tableWrapper {
    overflow: auto;
    max-height: 55vh;
    margin-top: 10px;
}

.usersTable {
    width: 100%;
    margin-top: 20px;
}

.usersTable td {
    padding: 5px 0px;
}

.titleColor {
    color: var(--black-200);
}

.spinContainer {
    padding: 30px;
    text-align: center;
}

.columnSize {
    min-width: 100px;
}

.saveBtnWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 10px 10px 10px 0;
}

.checkIconWrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

.tableRow {
    color: inherit;
    display: table-row;
    outline: 0;
    vertical-align: middle;
    cursor: pointer;
}

.tableRow:nth-child(even) {
    background: #fbfbfb;
}

.tableRow:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.textAlignCenter {
    text-align: center;
}
.textAlignLeft {
    text-align: left;
}
.defaultWrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.emptyRowData {
    text-align: center;
    margin: 30px;
}
