.titleWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title {
    margin: 0;
    margin-right: 28px;
    font-size: 27px;
    font-weight: bold;
    color: var(--black-200);
}
.pollTypeWrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.textSecondary {
    font-size: 13px;
    color: var(--black-200);
    margin-bottom: 0px;
}
.row {
    padding: 10px 0px 0px;
}
.item {
    margin-bottom: 15px;
}
.optionsContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 2px 0;
    border: 3px dashed transparent;
}
.optionItem {
    display: flex;
    align-items: center;
    gap: 5px;
    position: relative;
}
.addOption {
    border-width: 1px !important;
    height: 40px !important;
    display: flex !important;
    justify-content: center;
    gap: 10px;
}
.addIcon {
    width: 30px;
}
.iconWrapper {
    display: flex;
    align-items: center;
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.cursorMove {
    cursor: grab;
}
.actionButtons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 20px;
}
.dragging {
    border: 3px dashed var(--primary-300);
}
.draggingItem {
    box-shadow: 0 0 0 2px var(--primary-400-base), 0 3px 5px 0 rgba(48, 49, 51, 0.1), 0 0 1px 0 rgba(48, 49, 51, 0.05);
}
.error {
    border: 1px solid var(--error-400-base) !important;
}

.textError {
    font-size: 13px;
    color: var(--error-400-base);
    margin-bottom: 0px;
}
.errorOptionsList {
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 5px;
    background: var(--error-100);
}
.closePeriodContainer {
    display: flex;
    gap: 10px;
    overflow: auto;
    margin-bottom: 10px;
}
.periodItem {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    border-radius: 5px;
    color: white;
    background: var(--primary-200);
    cursor: pointer;
}
.periodItemActive {
    background: var(--primary-400-base);
}
