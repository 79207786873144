.wrap {
    padding: 0 40px;
}

.title {
    font-size: 37px;
    font-weight: 800;
    color: var(--black-300);
    margin-top: 14px;
    margin-bottom: 14px;
    border-bottom: 1px solid var(--primary-300);
}
.submitContainer {
    display: flex;
    margin-top: 40px;
    align-self: stretch;
    flex-direction: row;
    justify-content: flex-end;
}

.fieldTitle {
    margin-top: 15px;
}
.textError {
    font-size: 13px;
    color: var(--error-400-base);
    margin-bottom: 0px;
}
.errorOptionsList {
    margin-top: 20px;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 5px;
    background: var(--error-100);
}

.line {
    background-color: var(--black-100);
    width: 100%;
    margin-top: 40px;
    opacity: 0.3;
    margin-bottom: 40px;
}

.signature {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    margin-bottom: 0;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-300);
}

.addSignature {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    margin-bottom: 0;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-300);
}

.editButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 4px;
    border-radius: 8px;
    background-color: var(--secondary-400-base);
}

.signatureButton {
    display: flex;
    flex-direction: row;
    margin-top: 12px;
    gap: 12px;
    align-items: center;
    cursor: pointer;
}

.actionBtn {
    height: 48px !important;
    border-radius: 8px !important;
    font-size: 18px !important;
    font-weight: bold !important;
    display: flex !important;
    padding: 10px 20px !important;
    align-items: center;
}
.rejectBtn {
    border: solid 1px #df1642 !important;
    color: #df1642 !important;
}

.action__icon {
    width: 32px;
    height: 32px;
    margin-left: 15px;
}

.approveBtn {
    color: #18a957 !important;
    border: solid 1px #18a957 !important;
}
.action {
    display: flex;
    justify-content: space-between;
}

.signatureImage {
    height: 60px;
}
