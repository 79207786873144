.notesContainer {
    padding-top: 16px;
    padding-bottom: 16px;
}

.title {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    color: var(--black-300);
}

.noteItem {
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
    border-radius: 12px;
    background-color: #f5f5f5;
    margin: 8px 0;
    gap: 5px;
}

.noteContent {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: #000;
    white-space: pre-wrap;
}

.noteDate {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--black-200);
    white-space: nowrap;
}

.noteInput {
    margin: 8px 0px;
    border-radius: 8px;
    border: solid 1px #c7c7c7;
    background-color: var(--whitebase);
    padding: 8px;
}

.noteBtn {
    display: flex;
    justify-content: flex-end;
}

.noteStatus {
    background-color: #ffeff3;
}

.noteHeader {
    display: flex;
    justify-content: space-between;
    justify-items: flex-end;
    align-items: center;
}

.attachmentsWrap {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    cursor: pointer;
}

.documentWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 8px;
    max-width: 200px;
    border-radius: 8px;
    background-color: var(--primary-400-base);
}

.documentFileName {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: var(--whitebase);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fileIcon {
    flex: 1;
    min-width: 24px;
}

.respondOfferWrap {
    display: flex;
    gap: 5px;
}

.photoWrap {
    width: 32px;
    height: 32px;
    object-fit: cover;
}

.respondWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 8px 0;
    border-radius: 8px;
    background-color: #e8faef;
    flex: 1;
}

.respondStatus {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    text-align: center;
    color: var(--success-400-base);
}

.respondIcon {
    width: 20px;
    height: 20px;
}
