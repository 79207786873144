.moreEventWrap {
    display: flex;
    min-width: 150px;
    border-bottom: 1px solid var(--primary-400-base);
}

.moreEventHeaderLeft {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-right: 1px solid var(--primary-400-base);
}

.moreEventHeaderRight {
    width: 100%;
    padding-top: 44px;
}

.moreEventHeaderRightItem {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--black-300);
    margin-bottom: 3px;
    padding-left: 20px;
}

.moreEventHeaderRightItemActive {
    background-color: var(--primary-200);
    color: var(--black-base);
}

.dot {
    width: 16px;
    height: 16px;
    background-color: var(--primary-400-base);
    border-radius: 50%;
    margin-right: 8px;
}

.headerEventInfoTitle {
    font-size: 16px;
    font-weight: 500;
}

.headerEventInfoTitle__startTime {
    font-weight: 600;
    margin-right: 16px;
}

.moreEventDayOfWeek {
    font-size: 24px;
    color: var(--primary-400-base);
    text-transform: capitalize;
    margin-bottom: 0;
    text-align: center;
}

.moreEventDate {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: 24px;
    font-weight: bold;
    border-radius: 50%;
    color: var(--primary-400-base);
    margin-bottom: 0;
    text-align: center;
    cursor: pointer;
}

.moreEventDate:hover {
    background-color: #d8dadb;
}

.moreEventHeaderBtn {
    position: absolute;
    right: 0;
    border: none !important;
    top: -5px;
    width: 20px;
    height: 20px;
}

.moreEventItem {
    border-radius: 4px;
    cursor: pointer;
}

.moreEventItem:hover {
    background: #f1f3f4;
}

.tooltipCard {
    padding-top: 0;
    font-size: 11px;
}

.tooltipCard .ant-tooltip-inner {
    display: flex;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    min-height: 27px;
}
