.calendarContainer {
    position: relative;
}
.disabledCalendar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background: rgb(148 148 148/ 50%);
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.arrowsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}
.headerIcon {
    height: 24px;
    width: 24px;
    cursor: pointer;
    padding: 2px;
}
.arrowButton {
    display: flex;
    border: none;
    background-color: transparent;
}

.dateInfo {
    color: var(--black-200);
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 0;
    min-width: 200px;
    text-align: center;
}
.fullCalendarContainer > div {
    max-height: 634px;
    margin-bottom: 20px;
}

.conflictIconWrap {
    background-color: white;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0 0 10px;
}
.conflictIconWrap > svg {
    width: 15px;
    height: 15px;
}

.eventContent {
    width: 100%;
    height: 100%;
}

.removeEventBtn {
    background: transparent !important;
    width: 16px !important;
    height: 16px !important;
    border: 0 !important;
    position: absolute !important;
    top: -4px;
    right: 0;
}

.removeEventBtnTitle {
    font-weight: bold;
    font-size: 14px;
    display: inline-block;
    margin-left: 8px;
    margin-bottom: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.removeEventBtnTitleConflict {
    margin-top: 5px;
}

.removeEventBtnSubtitle {
    margin-left: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.removeIcon {
    width: 15px;
    height: 15px;
}

.scheduleItems {
    display: flex;
    gap: 25px;
    overflow-x: auto;
    padding-bottom: 25px;
    margin: 25px 0;
}

.scheduleItem {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    background-color: var(--primary-200);
    border: 2px solid rgb(255 255 255/0%);
    padding: 5px 18px;
    width: fit-content;
    color: var(--whitebase);
    font-size: 16px;
    font-weight: 600;
    position: relative;
    border-radius: 4px;
    cursor: pointer;
}
.scheduleItemActive {
    background-color: var(--primary-500);
}
.scheduleItemDisabled {
    background-color: var(--black-100);
    cursor: not-allowed;
}
.scheduleConflictWrap {
    display: flex;
    background: var(--error-400-base);
    color: var(--whitebase);
    border-radius: 12px;
    width: 450px;
    height: 132px;
    padding: 0px 30px;
    margin: 40px 0;
}
.scheduleConflictIconWrap {
    background-color: var(--whitebase);
    width: 56px;
    height: 56px;
    border-radius: 100%;
    display: grid;
    place-content: center;
    margin-right: 20px;
    margin-top: 20px;
}
.scheduleConflictTextWrap {
    margin: 30px 0;
}
.scheduleConflictTextWrap > h2 {
    color: white;
    font-weight: bold;
}
.scheduleConflictTextWrap > p {
    color: var(--error-100);
    font-size: 16px;
}

.eventPreviewHeaderEditIcon {
    width: 25px;
    height: 25px;
}

.calendarDayHeaderWrap {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 100%;
    height: 100%;
}

.calendarDayHeader__infoWrap {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.calendarDayHeader__today {
    background-color: var(--primary-400-base);
    border-radius: 50%;
    color: var(--whitebase);
    width: max-content;
    padding: 6px;
    margin: 0 auto;
}

.calendarDayHeader__info {
    font-size: 16px;
}

.calendarDayHeader__termDate {
    position: absolute;
    left: 0;
    bottom: -3px;
    display: flex;
    align-items: center;
    gap: 2px;
    font-size: 12px;
    font-weight: 500;
    color: var(--whitebase);
    width: 100%;
}

.calendarDayHeader__startDate {
    background-color: var(--primary-400-base);
}

.calendarDayHeader__endDate {
    background-color: var(--error-400-base);
}
