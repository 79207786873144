.step1 {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 16px;
    cursor: pointer;
    border-radius: 24px;
    border: dashed 1px var(--whitebase);
}

.step1:hover {
    border: dashed 1px var(--black-200);
}

.step1Desc {
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: center;
}

.iconWrap {
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 24px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
    background-color: var(--whitebase);
}

.uploadLable {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    color: #000;
}

.uploadDescLabel {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--black-200);
}
