.wrap {
    padding: 0 40px;
}

.title {
    font-size: 37px;
    font-weight: bold;
    color: #666666;
    line-height: 1.41;
    margin-bottom: 14px;
    border-bottom: 2px solid #62b1ff;
}

.body {
    padding: 40px 0;
}

.description {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
    color: #212121;
}

.item {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 24px 0 rgba(48, 49, 51, 0.16);
    background-color: #ffffff;
    align-items: center;
    height: 100%;
    cursor: pointer;
}

.iconWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    background-color: #e9f4ff;
    border-radius: 50%;
    margin-bottom: 20px;
}

.icon {
    width: 32px;
    height: 32px;
}

.item__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.33;
    color: #212121;
    text-align: center;
}
