.studentWrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 16px;
    border-top: solid 1px var(--black-100);
    border-bottom: solid 1px var(--black-100);
}

.contentWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}

.contentTitle {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--black-200);
    text-transform: uppercase;
    white-space: normal;
}

.contentTitle.dark {
    color: var(--black-300);
}

.contentValue {
    flex-shrink: 0;
    padding: 4px 8px;
    border-radius: 8px;
    background-color: var(--black-100);
    font-size: 14px;
    font-weight: 500;
    color: var(--black-base);
    min-width: 32px;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
}

.contentValue.active {
    color: var(--primary-400-base);
}

.contentValue.noBg {
    padding: 0 !important;
    background-color: transparent !important;
}

.contentValue.due {
    background-color: #ffe5eb;
    color: var(--error-400-base);
    font-weight: bold;
}

.infoBlock {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 8px;
    border-radius: 12px;
    border: solid 1px var(--black-100);
    background-color: #f5f5f5;
}

.infoBlock.due {
    border: solid 1px var(--error-400-base);
}
