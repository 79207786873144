.definedUser {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}

.definedUserTitle {
    font-size: 16px;
    font-weight: 600;
    color: #666666;
}

.definedUserBody {
    display: flex;
    align-items: center;
}

.definedUserItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-color: #e5e5e5;
    margin-right: 16px;
    padding: 4px 16px;
    width: 64px;
    height: 64px;
}

.definedUserItem:last-child {
    margin-right: 0;
}

.definedUserItem__icon {
    width: 32px;
    height: 32px;
    min-width: 32px;
}

.definedUserItem__text {
    font-size: 12px;
    font-weight: bold;
    color: #666666;
}
