.actionWrap {
    display: flex;
    justify-content: center;
}

.addAction {
    width: 100%;
    justify-content: center;
}

.markUnreadButton {
    color: var(--primary-400-base);
    font-weight: 600;
}

.activityIcon {
    width: 15px;
    height: 15px;
}

.indicator {
    position: absolute;
    right: -2px;
    top: -2px;
    width: 8px;
    height: 8px;
    background: red;
    border: solid 2px white;
    border-radius: 999px;
}
