.root {
    display: flex;
    width: 100%;
}

.flexColumn {
    flex-direction: column;
}

.leftSide {
    display: flex;
    flex-direction: column;
    min-width: 330px;
    margin-right: 30px;
}

.hideCalendar {
    display: none;
}

.yearContainer {
    grid-column: 1/3;
}

.conflictWrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: bold;
    color: white;
    padding: 0 3px;
}

.conflictIconWrap {
    background-color: white;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.conflictIconWrap > svg {
    width: 14px;
    height: 14px;
}

.removeEventBtn {
    background: transparent !important;
    width: 16px !important;
    height: 16px !important;
    border: 0 !important;
    position: absolute !important;
    top: -4px;
    right: 0;
}

.eventContent {
    font-size: 12px;
    display: block;
    margin-left: 8px;
    width: 100%;
    height: 100%;
    line-height: 1.3;
    padding-right: 3px;
    cursor: pointer;
}

.eventTitle {
    font-weight: 500;
    margin-bottom: 3px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 9px;
}

.eventTime {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 3px 0 0 0;
    padding-right: 9px;
}

.removeIcon {
    width: 15px;
    height: 15px;
}

.scheduleTagWrapper {
    display: flex;
    overflow-x: auto;
    margin-bottom: 15px;
    gap: 20px;
}
.scheduleTagItem {
    display: flex;
    background-color: var(--primary-200);
    justify-content: center;
    align-items: center;
    min-height: 50px;
    min-width: 180px;
    color: white;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    position: relative;
    border-radius: 4px;
    cursor: pointer;
}

.scheduleTagItemSelected {
    background-color: var(--primary-500);
}

.scheduleTagItemDisabled {
    cursor: not-allowed;
}

.selectPerson {
    margin-bottom: 30px;
}

.eventMonthView {
    display: grid;
    align-items: center;
    grid-template-columns: 7px auto;
    gap: 5px;
    color: var(--black-300);
    padding: 0px 3px;
}

.eventTitleMonthView {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    gap: 5px;
}

.eventTitleMonthView > strong {
    margin-right: 5px;
}

.dot {
    width: 7px;
    height: 7px;
    border-radius: 4px;
}

.bodyWrap {
    display: flex;
}

.rightSide {
    position: relative;
    flex: 1;
}

.viewOptionsWrap {
    margin-bottom: 30px;
}

.calendarIndicator {
    margin-bottom: 10px;
}

.highlightEventPreviewWrap {
    z-index: 9999 !important;
}

.highlightEventPreview {
    box-shadow: 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%), 0px 3px 5px -1px rgb(0 0 0 / 20%) !important;
}

.eventPreviewWrap {
    max-width: 300px;
    width: 300px;
}

.eventPreviewHeader {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 4px;
}

.eventPreviewHeaderBtn {
    border: none !important;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.eventPreviewHeaderEditIcon {
    width: 25px;
    height: 25px;
}

.eventPreviewHeaderIcon {
    width: 18px;
    height: 18px;
}

.eventPreviewContent {
    margin-bottom: 10px;
}

.eventPreviewContentRow {
    display: flex;
    cursor: pointer;
}

.eventPreviewContentLeft {
    width: 30px;
    height: 30px;
    min-width: 30px;
}

.eventPreviewType {
    width: 14px;
    height: 14px;
    border-radius: 4px;
    margin-top: 5px;
    background: #ccc;
}

.eventPreviewInfo {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
}

.eventPreviewTitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #3c4043;
    margin-bottom: 3px;
}

.eventPreviewDate {
    font-size: 13px;
    font-weight: 400;
    color: #3c4043;
}

.eventPreviewDate__dot {
    margin: 0 6px;
}

.moreEventWrap {
    min-width: 150px;
}

.moreEventHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.moreEventDayOfWeek {
    color: #70757a;
    font-size: 11px;
    text-transform: uppercase;
    margin-bottom: 0;
    text-align: center;
}

.moreEventDate {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-weight: 400;
    font-size: 20px;
    border-radius: 50%;
    color: #80868b;
    margin-bottom: 0;
    text-align: center;
    cursor: pointer;
}

.moreEventDate:hover {
    background-color: #f1f3f4;
}

.moreEventItem {
    border-radius: 4px;
    cursor: pointer;
}

.moreEventItem:hover {
    background: #f1f3f4;
}

@media (max-width: 768px) {
    .root {
        font-size: 6px;
    }
}
