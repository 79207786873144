:root {
    --size: 10px;
}

.bubblingGSmall {
    --size: 5px;
}

.bubblingG {
    display: grid;
    grid-template-columns: repeat(3, var(--size));
    /* column-gap: 8px; */
    column-gap: calc((var(--size) * 80) / 100);
}

.bubbling {
    width: var(--size);
    height: var(--size);
    background-color: var(--black-300);
    border-radius: 50%;
    animation: bubblingG 1.2s infinite alternate;
}

.bubbling1 {
    animation-delay: 0.3s;
}

.bubbling2 {
    animation-delay: 0.7s;
}

.bubbling3 {
    animation-delay: 1.1s;
}

@keyframes bubblingG {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
