.statusWrap {
    display: flex;
    align-items: center;
    gap: 8px;
}

.status {
    font-size: 16px;
    font-weight: bold;
    padding-left: 5px;
}

.notStarted {
    color: var(--error-400-base);
}

.loa {
    color: var(--primary-300);
}

.completed {
    color: var(--success-400-base);
}

.pendingReview {
    color: #ff8a38;
}

.circle {
    width: 16px;
    height: 16px;
    border: 1px solid var(--error-400-base);
    border-radius: 50%;
}

.checkedIcon {
    width: 20px;
    height: 20px;
}

.courseStatus__icon {
    height: 24px;
    width: 24px;
}
