.main {
    display: flex;
    flex-direction: column;
    margin: 16px 0px;
}

.loadingData {
    margin-top: 12px;
}

.emptyList {
    font-size: 12px;
    font-style: italic;
    /* text-align: center; */
}

.item {
    padding: 10px;
    border-radius: 12px;
    box-shadow: 0 1px 12px 0 rgba(48, 49, 51, 0.1);
    border: solid 1px var(--black-100);
    background-color: var(--whitebase);
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.headerWrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    overflow: hidden;
}

.code {
    font-size: 16px;
    line-height: 1.5;
    color: var(--primary-400-base);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
}

.name {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.33;
    color: var(--black-300);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.statusWrap {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
}

.statusInfo {
    display: flex;
    flex-direction: column;
}

.statusInfo__text {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--black-300);
}

.preview {
    padding: 8px;
    background-color: var(--black-300);
    min-height: 200px;
    margin: -16px;
    margin-top: 16px;
    position: relative;
}

.previewIcon {
    position: absolute;
    top: 12px;
    right: 16px;
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    padding: 6px;
    border-radius: 12px;
    border: solid 1px var(--primary-400-base);
    background-color: var(--whitebase);
    display: flex;
    justify-items: center;
    cursor: pointer;
}

.previewFile {
    display: flex;
    overflow: hidden;
    background-color: var(--black-50);
    margin: 16px;
    min-height: 200px;
    max-height: 200px;
    margin-bottom: -2px;
    border-radius: 12px 12px 0 0;
}

.detail {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.links {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
}

.linkIcon {
    width: 16px;
    height: 16px;
}

.linkIconWrap {
    display: flex;
    justify-content: center;
}

.department {
    display: flex;
    justify-content: center;
    padding: 4px 8px;
    border-radius: 8px;
    background-color: var(--black-200);
    font-size: 14px;
    font-weight: bold;
    line-height: 1.29;
    color: var(--whitebase);
}

.previewFile > div {
    width: 100%;
}

.previewFile > div > iframe {
    overflow: hidden;
    border: unset;
}

.pdfPreview > div {
    height: 85vh;
    overflow: hidden;
}

.imgPreview {
    height: 100%;
}
