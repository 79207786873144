.wrap {
    padding: 20px 0;
}

.menu {
    display: flex;
    align-items: center;
    padding: 6px 0 6px 26px;
    cursor: pointer;
    height: 48px;
}

.menu:hover {
    background-color: #1e90ff;
}

.menu:hover .menuTitle,
.menuActive .menuTitle {
    color: #fff;
}

.menu:hover .menuIcon svg path,
.menuActive .menuIcon svg path {
    fill: #fff;
}

.menuIcon {
    width: 24px;
    height: 100%;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menuIcon svg {
    width: 100%;
    max-height: 24px;
}

.menuTitle,
.mainMenu,
.parentTitle {
    font-size: 16px;
    font-weight: 500;
    color: var(--black-base);
    margin-bottom: 0;
    line-height: 1.5;
}

.menuActive {
    background-color: var(--secondary-400-base);
}

.mainMenuWrap {
    display: flex;
    align-content: center;
    margin: 0 26px;
    cursor: pointer;
}

.mainMenu {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: var(--black-300);
    text-transform: uppercase;
}

.backIcon {
    width: 24px;
    height: 24px;
    margin-right: 4px;
}

.divider {
    height: 1px;
    background-color: #62b1ff;
    margin: 30px 26px;
}

.parentTitle {
    padding-left: 26px;
    margin-bottom: 20px;
}

.subTitle {
    padding-left: 26px;
    margin-bottom: 0;
    padding-bottom: 5px;
    padding-top: 5px;
    background-color: #a5d3ff;
    text-transform: uppercase;
    color: var(--black-300);
    font-weight: 600;
}

.dividerMainMenu {
    height: 1px;
    background-color: #a5d3ff;
}

.logoWrap {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    margin: 36px 0;
    overflow: hidden;
    padding: 0 30px 5px;
    width: 100%;
}

.logoImg {
    height: 100px;
    object-fit: contain;
    cursor: pointer;
}

@media (max-width: 768px) {
    .wrap {
        padding: 10px 0;
    }

    .mainMenuWrap {
        margin: 0 12px;
    }

    .backIcon {
        width: 16px;
        height: 16px;
    }

    .menuIcon {
        width: 16px;
    }

    .menuIcon svg {
        max-height: 16px;
        height: 16px;
        width: 16px;
    }

    .divider {
        margin: 10px 12px;
    }

    .menu {
        height: 24px;
        padding: 3px 0 3px 12px;
    }

    .subTitle {
        font-size: 12px;
        padding-left: 12px;
    }

    .mainMenu {
        font-size: 12px;
    }

    .menuTitle,
    .parentTitle {
        font-size: 11px;
    }

    .logoWrap {
        margin: 15px 0;
        padding: 0 16px 5px;
    }

    .logoImg {
        height: 60px;
    }
}
