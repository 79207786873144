.container {
    min-height: 300px;
}

.actionWrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.title {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
    color: var(--black-base);
    text-transform: uppercase;
    margin-bottom: 8px;
}

.actionBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}

.closeIcon {
    height: 20px;
    width: 20px;
}
