.container {
    position: relative;
}

.input {
    width: 100%;
    height: 100%;
    border: solid 1px var(--black-100);
    background-color: #fff;
    outline: none;
    padding: 8px 24px 8px 14px;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    line-height: 1.75;
    letter-spacing: normal;
    color: var(--black-base);
    margin: 0;
    resize: none;
    font-weight: 500;
}

.input:disabled {
    background-color: var(--grey-100);
}

.input:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base);
}

.input::placeholder {
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #939393;
    padding: 0;
}

.error {
    border-color: red;
}

.characterCount {
    position: absolute;
    bottom: 6px;
    right: 12px;
    font-size: 13px;
    background-color: white;
    padding: 2px 4px;
    margin-bottom: 2px;
}
