.popupWrapper {
    display: flex;
    flex-direction: column;
}

.title {
    color: var(--black-300);
    font-size: 37px;
    font-weight: bold;
    margin-bottom: 0px;
}

.timeWrapper {
    display: inline-flex;
    background-color: var(--primary-100);
    color: var(--primary-400-base);
    font-size: 21px;
    font-weight: bold;
    border-radius: 12px;
    padding: 12px 18px;
    margin: 14px 0px;
}

.divisor {
    margin: 0px;
    border-color: var(--primary-300);
}
.emptyView {
    display: flex;
    padding-bottom: 20px;
    flex-direction: column;
    align-items: center;
}
.textEmpty {
    color: var(--black-200);
    font-size: 16px;
}
.studentWrapper {
    background-color: #f5f5f5;
    display: flex;
    margin: 24px 0px;
}

.imgWrapper {
    padding: 8px 24px 8px 8px;
}

.avatar {
    border-radius: 12px;
    width: 120px;
    height: 120px;
}

.studentName {
    font-size: 24px;
    font-weight: bold;
    color: var(--black-base);
}

.labelContainer {
    display: flex;
    justify-content: space-between;
}

.columnContainer {
    display: flex;
    flex-direction: column;
}

.idLabel {
    color: var(--black-200);
    font-size: 16px;
    font-weight: 600;
}

.idValue {
    color: var(--primary-400-base);
    font-size: 18px;
    font-weight: bold;
    padding-right: 40px;
    padding-left: 7px;
}

.flexContainer {
    display: flex;
}

.courseValue {
    color: var(--black-base);
    font-size: 18px;
    font-weight: bold;
    padding-left: 7px;
}

.infoWrapper {
    border-radius: 6px;
    background-color: var(--primary-100);
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.hourLabel {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: var(--black-base);
    padding: 8px 9px;
    background-color: var(--whitebase);
    border-radius: 8px;
    margin-right: 24px;
    display: inline-block;
    min-width: 100px;
}

.lessonWrapper {
    border-radius: 6px;
    background-color: var(--warning-100);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.attendedWrapper {
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    border: solid 1px #e5e5e5;
    margin: 24px 0;
    padding: 12px;
}

.attendedBody {
    display: flex;
    align-items: center;
}

.attendedBodyItem {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-right: 32px;
    cursor: pointer;
}

.inputWidth {
    display: flex;
    flex: 1;
}

.iconWrapper {
    border-radius: 50%;
    background-color: var(--success-100);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
}

.lessonIcon {
    display: flex;
    width: 45px;
    justify-content: center;
}

.iconWidth {
    width: 20px;
    height: 20px;
}

.infoLabel {
    color: #212121;
    display: inline-block;
    min-width: 150px;
    font-size: 16px;
    margin-right: 37px;
    font-weight: 500;
}

.attendedTitle {
    color: var(--black-base);
    font-size: 24px;
    font-weight: bold;
    margin-right: 60px;
    margin-bottom: 0;
}

.rightIcon {
    width: 32px;
    height: 32px;
    margin-right: 16px;
}

.optionLabel {
    font-size: 18px;
    font-weight: 500;
}

.attendanceDivider {
    border-color: #e5e5e5;
    width: 100%;
    margin: 12px 0;
}

.checkWrapper {
    border: solid var(--primary-400-base);
    border-radius: 6px;
    padding: 12px;
    margin-right: 25px;
}

.checkLabel {
    font-size: 18px;
    font-weight: bold;
}
.alignItems {
    align-items: center;
}

.saveContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 48px;
}

.approveAttendWrap {
    display: flex;
    align-items: center;
    border-radius: 12px;
    background-color: #f5f5f5;
    padding: 16px;
    gap: 40px;
    cursor: pointer;
}
