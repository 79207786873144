.root {
    position: absolute;
    z-index: 50;
    top: 50%;
    left: 45%;
    transform: translate(0%, -50%);
    width: 470px;
    min-height: 200px;
    max-height: 50%;
    border-radius: 10px;
    box-shadow: 2px 4px 32px 0 rgba(0, 0, 0, 0.16);
    background-color: var(--whitebase);
    display: none;
    grid-template-rows: auto;
}
.rootShow {
    display: grid;
}
:global(.emoji-mart-category-label) {
    background-color: var(--whitebase);
}
:global(.emoji-mart-category-label > span) {
    background-color: var(--whitebase);
}
:global(.emoji-mart-preview-emoji) {
    display: none !important;
}
