.wrap {
    padding: 0 40px;
}

.title {
    font-size: 28px;
    font-weight: bold;
    line-height: 1.41;
    color: #666666;
    padding-bottom: 14px;
    border-bottom: 1px solid #62b1ff;
    margin-bottom: 30px;
}

.subTitle {
    font-family: Poppins;
    font-size: 18px;
    font-weight: bold;
    color: var(--black-200);
    margin-bottom: 16px;
}

.tabButton {
    display: inline-flex;
    flex-direction: row;
    margin-bottom: 30px;
    align-self: flex-start;
    justify-content: space-between;
    gap: 8px;
    align-items: center;
}

.tab {
    flex-grow: 0;
    display: flex;
    cursor: pointer;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    border-radius: 24px;
    border: solid 1px var(--black-200);
    background-color: #f5f5f5;
}

.tabLabel {
    flex-grow: 0;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-200);
}

.tabLabelSelected {
    flex-grow: 0;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--whitebase);
}

.tabSelected {
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    border-radius: 24px;
    background-color: var(--primary-400-base);
}

.body {
    overflow: auto;
    max-height: 70vh;
    padding: 0 10px;
}

.listStudent {
    overflow-y: scroll;
    min-height: 30vh;
    max-height: 30vh;
}
.cancelButton {
    border-radius: 8px;
    border: solid 1px var(--black-100);
    background-color: var(--whitebase);
}
.search {
    border-radius: 8px;
    border: solid 1px var(--black-100);
    background-color: var(--whitebase);
}

.lessonTime {
    display: inline-block;
    font-size: 16px;
    border-radius: 12px;
    border-radius: 12px;
    font-weight: 600;
    border: solid 1px var(--black-100);
    color: var(--primary-400-base);
    padding: 8px 12px;
    margin-bottom: 16px;
}

.listStudents {
    margin: 24px 0 40px 0;
}

.listStudentHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.totalStudent {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 22px;
    font-weight: bold;
}

.totalStudent__count {
    color: var(--primary-400-base);
}

.totalStudent__title {
    color: var(--black-200);
    text-transform: uppercase;
}

.rightHeader {
    display: flex;
    align-items: center;
    gap: 6px;
}

.rightHeader__title {
    font-size: 16px;
    font-weight: 600;
    color: var(--black-200);
}

.studentsBody {
    margin-top: 10px;
}

.action {
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
}
