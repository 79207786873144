.wrapper {
    background-color: var(--white);
    position: relative;
    padding: 50px 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
}
.title {
    font-family: Poppins, sans-serif;
    font-size: 37px;
    margin: 0;
    margin-left: 29px;
    padding: 0;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    color: var(--black-base);
}

.text {
    font-family: Poppins, sans-serif;
    font-size: 25px;
    margin: 0;
    margin-left: 29px;
    padding: 0;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    color: var(--black-base);
}
