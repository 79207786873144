.title {
    font-size: 24px;
    font-weight: 800;
    color: var(--black-300);
    margin-bottom: 8px;
}

.bodyWrap {
    border-top: 1px solid var(--primary-300);
    padding: 30px 0 0 0;
    margin: 10px 0 30px 0;
}

.bodyItem {
    margin-bottom: 30px;
}

.bodyItemTitle {
    font-size: 16px;
    margin-bottom: 5px;
    color: var(--black-300);
    display: inline-block;
}

.userNameLabel {
    font-weight: bold;
    font-size: 24px;
}

.nameLabel {
    color: #1e90ff;
}
.roleLabel {
    margin-bottom: 30px;
    color: var(--black-300);
    font-size: 18px;
}
.emergencyWrap {
    margin-left: 30px;
}

.emergencyBodyWrap {
    display: flex;
    align-items: center;
}

.emergencyText {
    font-size: 18px;
    font-weight: 500;
    color: var(--black-base);
    margin: 0 10px 0 0;
}

.emergencyTextYes {
    font-size: 18px;
    font-weight: 500;
    color: var(--black-base);
    margin: 0 0 0 10px;
}

.actionWrap {
    display: flex;
    align-items: center;
    margin-top: 50px;
}

.addBtnWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
}
.addBtn {
    text-transform: uppercase;
}
.cancelBtn {
    border-radius: 4px !important;
    color: #1e90ff !important;
    font-size: 14px !important;
    font-weight: bold !important;
    text-transform: uppercase !important;
    padding: 7px 17px;
    border: 1px solid #1e90ff !important;
    background: transparent;
    height: 38px !important;
    margin-right: 20px;
    cursor: pointer;
}

.fullname {
    font-size: 27px;
    font-weight: bold;
    color: var(--black-base);
}

.editContactBody {
    width: 60%;
}

.rightInfoWrap {
    padding: 10px 15px;
    border-radius: 10px;
    background-color: var(--primary-100);
}

.rightInfo {
    display: flex;
}

.rightInfoItem {
    display: flex;
    flex: 1;
    margin-bottom: 20px;
}

.rightInfoItem__icon {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
}

.rightInfoItem__value {
    font-size: 16px;
    font-weight: bold;
    color: var(--primary-400-base);
    margin-bottom: 0;
    margin-left: 10px;
    word-break: break-all;
}

.removeBodyWrap {
    border-top: 1px solid var(--primary-300);
    padding: 30px 0 0 0;
    margin: 10px 0 30px 0;
}

.removeText {
    font-size: 24px;
    font-weight: bold;
    color: var(--black-300);
}

.removeText__name {
    color: var(--primary-400-base);
}
.userWrap {
    border-radius: 12px;
    border: solid 1px var(--primary-400-base);
    background-color: var(--whitebase);
    padding: 12px;
}

.infoItem {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 10px;
}

.infoItem__title {
    font-size: 14px;
    font-weight: bold;
    color: var(--primary-400-base);
    margin-bottom: 0;
    text-transform: uppercase;
}

.infoItem__value {
    font-size: 18px;
    font-weight: bold;
    color: var(--black-base);
}
.avatar {
    border-radius: 12px;
}

.info {
    margin-left: 21px;
    margin-right: 30px;
}

.info__name {
    font-size: 24px;
    font-weight: bold;
    color: var(--black-base);
    margin-bottom: 10px;
}
.otherInfoItem {
    display: flex;
}
.rightInfoWrap {
    padding: 10px 15px;
    border-radius: 10px;
    background-color: var(--primary-100);
}

.rightInfo {
    display: flex;
}

.rightInfoItem {
    display: flex;
    flex: 1;
    margin-bottom: 20px;
}

.rightInfoItem__icon {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
}

.rightInfoItem__value {
    font-size: 16px;
    font-weight: bold;
    color: var(--primary-400-base);
    margin-bottom: 0;
    margin-left: 10px;
    word-break: break-all;
}
.paddingContent {
    padding: 20px 40px 30px 40px !important;
}
.containerField {
    margin-bottom: 30px;
}
.profileLabel {
    color: #939393;
    margin-right: 8px;
}
.checkboxItem {
    align-items: center;
    margin-right: 16px;
    min-width: 190px;
    margin-bottom: 10px;
}
.error {
    border: 1px solid var(--error-400-base) !important;
}

.errorSelect {
    border: 1px solid var(--error-400-base) !important;
    border-radius: 6px !important;
}

.errorSelect > div {
    box-shadow: none !important;
}

.phoneWidth {
    width: 251px;
}
.checkboxItemRight {
    float: right;
}
.hide {
    display: none;
}
.profileButtonContainer {
    display: flex;
    flex-wrap: wrap;
}
