/* Custom ant design */
.ant-tabs.fullwidth .ant-tabs-nav,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
}

.ant-tabs-nav-operations {
    display: none !important;
}

.ant-tabs.fullwidth .ant-tabs-nav > div {
    display: flex;
}

.ant-tabs.fullwidth .ant-tabs-nav .ant-tabs-tab {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.ant-tabs.fullwidth .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
    font-weight: bold;
    color: var(--primary-400-base);
}

.klassTab > .ant-tabs-nav {
    background-color: #f5f5f5;
    border-radius: 24px;
}

.klassTab > .ant-tabs-nav > .ant-tabs-nav-wrap {
    overflow: auto;
}

.klassTab > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list {
    display: flex;
    gap: 4px;
    align-items: center;
    height: 40px;
}

.klassTab > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab {
    padding: 4px 10px;
    height: 32px;
}

.klassTab > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab-active {
    border-radius: 24px;
    background-color: var(--primary-400-base);
}

.klassTab > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab .tabTitle {
    text-transform: uppercase;
    font-weight: bold;
}

.klassTab > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab-active .tabTitle {
    color: var(--whitebase) !important;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 800;
}

.klassTab > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab-active .tabIcon path {
    fill: var(--whitebase) !important;
}

.klassTab > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-ink-bar {
    display: none;
}

.klassTab.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before {
    display: none;
}

.klassSubTab > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list {
    width: auto;
    gap: 8px;
}

.klassSubTab > .ant-tabs-nav::before {
    bottom: 50%;
    border-color: var(--primary-400-base);
}

.klassSubTab > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list {
    background: #fff;
    padding-right: 8px;
}

.klassSubTab > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab {
    flex: auto;
    height: 32px;
    padding: 4px 8px;
    font-size: 16px;
    font-weight: 500;
    color: var(--primary-300);
    border-radius: 24px;
    border: solid 2px transparent;
}

.klassSubTab > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab.ant-tabs-tab-active {
    border-color: var(--primary-400-base);
    color: var(--primary-400-base);
}

.klassSubTab > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-ink-bar {
    display: none;
}

.klassSubTab > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab .tabTitle {
    color: inherit;
}

.ant-modal {
    min-width: 600px;
}

.ant-modal-close {
    top: 20px;
    right: 20px;
}

.ant-modal-close-x {
    width: 25px;
    height: 25px;
    line-height: unset;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-pagination-options {
    display: none;
}

.ant-pagination-item,
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
    height: 24px;
    line-height: 24px;
    min-width: 24px;
    background-color: #e6e6e6;
    margin-right: 4px;
}

.ant-pagination-item-active {
    background-color: #1e90ff;
    color: #fff;
}

.ant-pagination-item-active a {
    color: #fff;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
}

.ant-picker {
    width: 100%;
    height: 40px;
}

.ant-input-search .ant-input {
    height: 48px;
}

.ant-input,
.ant-picker {
    height: 44px;
    font-size: 16px;
}

.ant-input.error,
.ant-input-affix-wrapper.error,
.ant-picker.error {
    border: 1px solid #ff6347;
}

.ant-input-search-button {
    background: #ff349b !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 48px;
}

.ant-input-search-button:hover,
.ant-input-search-button:active {
    background: #ff7ec0 !important;
}

.ant-input-search .anticon-search svg {
    width: 18px;
    height: 18px;
}

.ant-btn > .ant-btn-loading-icon {
    display: flex;
}

.ant-modal {
    top: 50px;
}

.base-popup .ant-modal-body {
    padding: 0;
}

.calendar-event-popup .ant-modal-content {
    border-radius: 6px;
}

.calendar-event-popup .ant-modal-body {
    background: #e9f4ff;
    border: 1px solid #1e90ff;
    border-radius: 6px;
}

.eventPopup .ant-modal-content {
    border-radius: 6px;
}
.eventPopup .ant-modal-body {
    background: #f5f5f5;
    border: 1px solid var(--primary-200);
    border-radius: 6px;
}

.ant-select-selector {
    min-height: 44px;
    align-items: center;
}

.chatNotificationSetting {
    width: 100%;
}

.chatNotificationSetting .ant-select-selector {
    padding: 0 !important;
    border: none !important;
}

.ant-select-multiple .ant-select-selection-item {
    background-color: #1e90ff;
    border-radius: 4px;
    color: #fff;
}

.ant-select-multiple .ant-select-selection-item-content {
    margin-right: 6px;
}

.ant-select-multiple .ant-select-selection-item-remove {
    display: flex;
    align-items: center;
}

.ant-select-multiple .ant-select-selection-item-remove svg {
    fill: #fff;
}
.ant-tabs.ant-tabs-top.fullwidth {
    overflow: visible;
}
.ant-upload.ant-upload-select {
    width: 100%;
}
.ant-upload-list {
    display: none;
}

.border-green .ant-checkbox {
    border: 1px solid #1e90ff;
    border-radius: 4px;
}

.circle-checkbox {
    position: relative;
    width: 100%;
}

.circle-checkbox .ant-checkbox-inner {
    border-radius: 50%;
    height: 12px;
    width: 12px;
    background: transparent;
    border-color: #fff !important;
    z-index: 2;
}

.circle-checkbox .ant-checkbox {
    position: absolute;
    right: 10px;
    top: calc(50% - 6px);
    z-index: 1;
}

.col-picker .ant-checkbox-group-item {
    margin-bottom: 15px;
    display: flex;
}

.calendar .ant-checkbox-group-item {
    padding: 12px 8px;
    border: 1px solid #e5e5e5;
    margin-right: 24px;
}

.ant-popover-inner {
    border-radius: 8px;
}

.event-content-preview .ant-popover-inner-content {
    padding: 0;
}

/* checkbox */

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: white !important;
    border-color: #1e90ff !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #1e90ff !important;
}

/* message */

.custom-message-wrap .ant-message-notice-content {
    border: solid 2px var(--black-300);
    background-color: #fbfbfb;
    border-radius: 12px;
    padding: 12px 16px;
    position: relative;
}

.custom-message-wrap.error .ant-message-notice-content {
    border: solid 2px var(--error-400-base);
}

.custom-message-wrap.success .ant-message-notice-content {
    border: solid 2px var(--success-400-base);
}

.custom-message-wrap.warning .ant-message-notice-content {
    border: solid 2px var(--warning-400-base);
}

.custom-message-wrap.info .ant-message-notice-content {
    border: solid 2px var(--primary-400-base);
}

.custom-message-wrap .ant-message-custom-content {
    display: flex;
    align-items: center;
}
.ant-input::placeholder,
.ant-picker-input > input::placeholder {
    color: var(--black-200);
    font-size: 16px;
}

.ant-dropdown-menu-title-content {
    display: flex;
}

.ant-dropdown-menu-submenu-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
}

@media (max-width: 768px) {
    .ant-modal {
        min-width: unset !important;
        width: 95vw !important;
    }
}
