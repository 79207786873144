.notesContainer {
    padding-top: 16px;
    padding-bottom: 16px;
}

.title {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    color: var(--black-300);
}

.noteItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 12px;
    margin: 16px 0;
    gap: 8px;
    padding: 8px;
    background-color: #f5f5f5;
}

.noteContent {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: #000;
    white-space: pre-wrap;
}

.noteDate {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--black-200);
    white-space: nowrap;
}

.noteInput {
    margin: 8px 0px;
    border-radius: 8px;
    border: solid 1px #c7c7c7;
    background-color: var(--whitebase);
    padding: 8px;
}

.noteBtn {
    display: flex;
    justify-content: flex-end;
}

.contentContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
}

.logo {
    width: 24px;
    height: 24px;
    min-width: 24px;
}
