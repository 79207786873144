.viewTypeIconContainer {
    padding: 10px;
    border: 1px solid var(--black-100);
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
}

.viewTypeIconContainerSelected {
    border: unset;
    background-color: var(--primary-100);
}

.viewTypeIcon {
    width: 20px;
    height: 20px;
}

.actionWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.actionWrap button {
    margin-left: 10px;
}
