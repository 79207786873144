.root {
    width: 100%;
    position: relative;
}

.rootDense {
}

.rootBordered {
}

.rootBordered .table td {
    border-collapse: collapse;
    border: solid 1px #c7c7c7;
}
.rootBordered .table th {
    border-collapse: collapse;
    border: solid 1px var(--primary-200);
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.head {
}

.body {
}

.row {
}

.rowClickable {
    cursor: pointer;
}

.row.rowHoverable:hover {
    background-color: var(--primary-200);
}

.cell {
    height: 60px;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: var(--black-base);
    text-decoration: none;
    padding: 0 20px;
}

.cellInput {
    padding: 0 10px;
    color: var(--black-300);
}

.cellInputPrice {
    font-weight: 600;
}

.cellInputContent {
    display: flex;
    align-items: center;
    border-radius: 6px;
    padding: 0 10px;
    transition: background-color 0.1s ease-in-out;
    cursor: pointer;
}

.cellInputContent:hover,
.cellInputContent:focus-within {
    background-color: rgba(0, 0, 0, 0.03);
}

.cellInputField {
    flex: 1;
    height: 36px;
    border: none;
    outline: none;
    background-color: transparent;
    color: inherit;
    font: inherit;
    min-width: 0;
}

.cellInputField:focus {
    background-color: #f8f9fa;
}

.cellInputPrice .cellInputField {
    padding-left: 0;
}

.cellInputCurrency {
    margin-right: 2px;
}

.cellSelect {
    padding: 0 10px;
}

.cellSelectField {
    width: 100%;
}

.cellSelectField :global(.ant-select-selection-item),
.cellSelectField :global(.ant-select-selection-placeholder) {
    line-height: 44px !important;
}

.cellContent {
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
}

.cellContentRight {
    justify-content: flex-end !important;
}

.cellContentCenter {
    justify-content: center !important;
}
.cellContentStretch {
    justify-content: space-between !important;
}

.cellContentIconStart {
    display: inline-flex;
    margin-right: 10px;
}

.cellContentIconEnd {
    display: inline-flex;
    margin-left: 10px;
}

.head .cell {
    white-space: nowrap;
    background-color: var(--primary-100);
    font-family: Poppins, sans-serif;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--black-base);
    height: 56px;
    text-align: start;
    padding: 0 20px;
}

.root:not(.rootDense) .cell:first-child {
    padding-left: 40px;
}

.root:not(.rootDense) .cell:last-child {
    padding-right: 40px;
}
