.flexRow {
    display: flex;
    gap: 10px;
    align-items: center;
}

.flexRowMinGap {
    display: flex;
    gap: 3px;
    align-items: center;
}

.status {
    gap: 10px;
    padding: 2px 8px;
    border-radius: 8px;
    color: var(--whitebase);
    font-size: 12px;
    margin-right: 0px;
}

.status-applied {
    background-color: var(--black-300);
}

.status-in-review {
    background-color: var(--warning-400-base);
}

.status-rejected {
    background-color: var(--error-400-base);
}

.status-job-closed {
    background-color: var(--black-300);
}

.status-job-offer {
    background-color: var(--primary-400-base);
}

.status-interview {
    background-color: var(--primary-400-base);
}

.status-hired {
    background-color: var(--success-400-base);
}

.icon {
    fill: var(--primary-400-base);
    width: 18px;
    height: 18px;
}

.count {
    font-size: 11px;
}

.commentContainer {
    display: block;
}

.commentTitle {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.29;
    color: var(--black-200);
}

.comment {
    margin: 8px 0 0;
    padding: 8px;
    border-radius: 8px;
    border: solid 1px var(--black-100);
    background-color: var(--whitebase);
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: var(--black-200);
    white-space: pre-wrap;
}
