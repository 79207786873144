.wrap {
    margin-bottom: 60px;
}

.headerWrap {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.headerTitle {
    font-size: 24px;
    font-weight: 800;
    color: #666666;
    margin-bottom: 0;
}

.headerActionWrap {
    flex: 1;
    justify-content: flex-end;
    display: flex;
}

.headerActionItem {
    margin-right: 40px;
}

.chargeWrap {
    display: flex;
    align-items: center;
}

.arrowWrap {
    width: 30px;
    height: 30px;
    min-width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
    cursor: pointer;
}

.arrowIcon {
    width: 24px;
    height: 24px;
}

.charge__code {
    font-size: 16px;
    font-weight: bold;
    color: #212121;
    margin-bottom: 0;
    margin-right: 16px;
}

.charge__name {
    font-size: 16px;
    font-weight: 500;
    color: #212121;
    margin-bottom: 0;
}

.cellChildTableStyle {
    background: #f5f5f5;
}

.headerChildTitle {
    font-size: 18px;
    font-weight: bold;
    color: #939393;
}

.status {
    width: 110px;
    height: 32px;
    padding: 5px 3px;
    border-radius: 4px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
}

.status__pastDue {
    background-color: #df1642;
}

.status__ordered {
    background-color: #939393;
}

.status__rejected {
    background-color: #df1642;
}

.status__upcoming {
    background-color: #1e90ff;
}

.orderStatus {
    width: 140px;
    height: 40px;
    border-radius: 4px;
    background-color: #ff349b;
    padding: 5px 3px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}
