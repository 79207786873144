.itemWrap {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #939393;
    border-bottom: 1px solid #939393;
    padding: 15px 0;
}

.itemHeaderWrap {
    display: flex;
    align-items: center;
    width: 100%;
}

.item__code {
    font-size: 20px;
    color: #1e90ff;
    margin-bottom: 0;
    margin-right: 16px;
}

.item__title {
    font-size: 24px;
    font-weight: bold;
    color: #212121;
    margin-bottom: 0;
    margin-right: 16px;
    min-width: 200px;
}

.item__description {
    font-size: 16px;
    font-weight: 500;
    color: #939393;
    margin-bottom: 0;
    margin-right: 16px;
}

.rightWrap {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
}

.disbursedDate {
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    border-radius: 4px;
    background-color: #e5e5e5;
    padding: 5px 12px;
    height: 58px;
}

.disbursedDate__title {
    font-size: 14px;
    font-weight: 500;
    color: #939393;
    margin-bottom: 0;
    margin-right: 10px;
}

.disbursedDate__value {
    font-size: 18px;
    font-weight: bold;
    color: #1e90ff;
    margin-bottom: 0;
}

.balanceWrap {
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    border-radius: 4px;
    background-color: #e9f4ff;
    padding: 5px 12px;
    height: 58px;
}

.balance__title {
    font-size: 14px;
    font-weight: 500;
    color: #939393;
    margin-bottom: 0;
    margin-right: 10px;
}

.balance__cost {
    font-size: 24px;
    font-weight: bold;
    color: #1e90ff;
    margin-bottom: 0;
}

.statusWrap {
    margin-right: 24px;
    display: flex;
    align-items: center;
}

.status__text {
    border-radius: 4px;
    background-color: #18a957;
    width: 102px;
    height: 32px;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
}

.iconWrap {
    width: 32px;
    cursor: pointer;
}

.icon {
    width: 20px;
}
