.title {
    margin: 40px 0px 16px 0px;
    font-family: Poppins;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-200);
}
.addButton {
    margin-left: auto;
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    outline: none;
    align-self: flex-end;
    margin-top: 12px;
    padding: 0;
}
.titleHeader {
    font-size: 12px;
}
.parentTitleHeader {
    color: var(--black-300);
    font-size: 12px;
}
.tableRowParent {
    border-bottom: 2px solid white;
}
.plusIcon {
    width: 30px;
    height: 30px;
    fill: var(--secondary-400-base);
}
.textAdd {
    font-family: Poppins;
    font-size: 14px;
    font-weight: bold;
    margin-right: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: right;
    color: var(--black-base);
}
.titleWrap {
    display: flex;
}
.tableRowParent {
    border-bottom: 2px solid white;
}

.titleInput {
    display: block;
    float: left;
}
.actionWrap {
    margin-top: 111px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.cancelBtn {
    margin-right: 20px;
    text-transform: uppercase;
}

.statusWrap {
    display: flex;
    align-items: center;
    gap: 8px;
}

.signatureWrap {
    padding: 7px 5px;
    display: flex;
    align-items: center;
    margin-right: 8px;
}

.signature__statusIcon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

.signature__approval {
    background-color: #e8faef;
}

.signature__reject {
    background-color: #ffe5eb;
}
.dropDownCourse {
    flex: 1;
}
.signature__icon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

.signature__img {
    width: 56px;
    height: 20px;
}
.actionBtn {
    height: 48px !important;
    border-radius: 8px !important;
    font-size: 18px !important;
    font-weight: bold !important;
    display: flex !important;
    padding: 10px 20px !important;
    align-items: center;
}
.titleWrapId {
    margin-left: -12px;
    padding-left: 10px;
    display: flex;
    position: relative;
    align-items: center;
    height: 100%;
}
.titleWrapId::before {
    content: "";
    position: absolute;
    width: 2px;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: grey;
}
.tableRow {
    padding: 0px 8px;
}
.rejectBtn {
    border: solid 1px #df1642 !important;
    color: #df1642 !important;
}
.divider {
    height: 1px;
    background-color: #e5e5e5;
    margin: 30px 0 40px;
}
.action {
    display: flex;
    justify-content: space-between;
}
.action__icon {
    width: 32px;
    height: 32px;
    margin-left: 15px;
}

.approveBtn {
    color: #18a957 !important;
    border: solid 1px #18a957 !important;
}

.review {
    font-size: 18px;
    font-weight: bold;
    color: #1e90ff;
    text-decoration: underline;
    cursor: pointer;
}

.dateInfo {
    display: flex;
    flex-direction: column;
}

.dateInfo__text {
    font-size: 12px;
    font-weight: 600;
    color: #666666;
    margin-bottom: 3px;
}
.disabledHtml {
    color: #666666;
}
.equivalentHeader {
    margin-left: -4px;
    padding-left: 10px;
    display: flex;
    color: var(--black-300);
    font-size: 12px;
}
.equivalentHeader::before {
    content: "";
    position: absolute;
    width: 2px;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: grey;
}
.idEquivalentHeader {
    font-size: 12px;
    padding-left: 10px;
}
.idEquivalentHeader::before {
    content: "";
    position: absolute;
    width: 2px;
    top: 0;
    left: -4px;
    bottom: 0;
    background-color: grey;
}
