.panelWrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 16px;
}

.headingWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.headingTitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: var(--black-300);
    text-transform: uppercase;
}

.headingIcon {
    width: 24px;
    height: 24px;
}

.collapseButton {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    width: 32px;
    height: 32px;
    transform: rotate(180deg);
    cursor: pointer;
}

.collapsed {
    transform: rotate(0deg);
    transform-origin: center;
}

.contentWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}

.contentTitle {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--black-200);
    text-transform: uppercase;
    white-space: nowrap;
}

.contentValue {
    padding: 4px 8px;
    border-radius: 8px;
    background-color: #f5f5f5;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: var(--black-base);
    min-height: 26px;
    min-width: 32px;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    text-align: center;
}

/* Enroll Section */

.container {
    padding: 10px 15px;
    border-radius: 10px;
    background-color: #f5f5f5;
    flex: 1;
}

.rightInfoWrap {
    display: flex;
}

.itemLabel {
    font-size: 14px;
    font-weight: bold;
    color: var(--primary-400-base);
    margin-bottom: 0;
}

.status {
    background-color: var(--primary-400-base);
    border: none;
    border-radius: 4px;
    padding: 4px 20px;
    color: var(--whitebase);
    font-weight: bold;
    cursor: pointer;
}

.valueLabel {
    font-size: 18px;
    font-weight: bold;
    color: var(--black-base);
    margin-bottom: 0;
}

.enrolledBtn {
    display: flex;
    align-items: center;
    gap: 6px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    border-radius: 4px;
    border: none;
    display: flex;
    justify-content: center;
    padding: 8px;
    width: 100%;
    cursor: pointer;
    flex: 1;
}

.enrolledColor {
    background-color: var(--success-200);
    color: var(--success-400-base);
}
.permanentOutColor {
    background-color: red;
    color: white;
}

.enrollColor {
    background-color: var(--secondary-400-base);
    color: var(--whitebase);
}

.btnWrapper {
    width: 90%;
    justify-content: center;
    margin-bottom: 16px;
}

.fullyEnrollWrap {
    display: flex;
    flex: 1;
    align-items: center;
    gap: 12px;
}

/* Academic Plan section */

.rowWrap {
    display: flex;
}

.paddingRow {
    padding-right: 30%;
}

.progressLabel {
    background-color: var(--black-200);
    font-weight: bold;
    padding: 6px 16px;
    border-radius: 5px;
    color: var(--whitebase);
    text-align: center;
    margin-bottom: 0;
}

.itemWrap {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}
