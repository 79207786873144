.selectTerm {
    flex-grow: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    text-transform: uppercase;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-300);
}
