.choiceChecklistWrap {
    border-radius: 6px;
    border: solid 1px var(--primary-400-base);
    display: flex;
    align-items: center;
    padding: 10px;
    height: 48px;
}

.choiceChecklist__title {
    margin-bottom: 0;
    margin-right: 10px;
    font-size: 18px;
    font-weight: bold;
    color: var(--black-200);
}

.choiceChecklist__item {
    font-size: 18px;
    font-weight: 600;
    color: var(--black-base);
}

.search {
    background: #fff;
}

.includeArchiveWrapper {
    border: 2px solid var(--primary-400-base);
    padding: 5px 12px;
    border-radius: 6px;
    font-weight: bold;
}

.mb30 {
    margin-bottom: 30px;
}

.actionWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.actionWrap button {
    margin-left: 10px;
}

.viewTypeIconContainer {
    padding: 10px;
    border: 1px solid var(--black-100);
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
}

.viewTypeIconContainerSelected {
    border: unset;
    background-color: var(--primary-100);
}

.viewTypeIcon {
    width: 20px;
    height: 20px;
}

.dropdownWrapper {
    width: 200px !important;
}
