.title {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: var(--black-300);
    text-transform: uppercase;
}

.col {
    display: flex;
    align-items: center;
    height: 100%;
}
