.title {
    font-size: 21px;
    font-weight: 600;
}

.radioBtnWrap {
    display: flex;
    gap: 12px;
    margin-top: 8px;
}

.radioBtn__item {
    margin-bottom: 10px;
}

.description {
    margin-top: 12px;
    margin-bottom: 4px;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
    margin-top: 24px;
}

.detailTitle {
    font-size: 18px;
    font-weight: 500;
    margin: 12px 0 0 0;
}
