.infoProgress {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 8px 5px 8px;
    border-radius: 12px;
    border: 1px solid;
    border-color: var(--black-100);
    border-width: 1px;
}

.titleProgress {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    text-align: left;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: var(--primary-400-base);
}

.row {
    display: flex;
    flex-direction: row;
}

.numberProgress {
    font-family: Poppins;
    font-size: 18px;
    margin-left: 8px;
    top: 2px;
    position: relative;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
.titleTable {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-300);
}

.credits {
    margin-right: 12px;
}

.status {
    margin-right: 12px;
}
.gpa {
    margin-right: 12px;
}

.statusProgress {
    display: flex;
    margin-left: 8px;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 2px 8px 2px 8px;
    border-radius: 10px;
    background-color: var(--success-400-base);
}
.titleStatus {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--whitebase);
}
.classAssistanceInfo {
    margin-top: 5px;
}
.classAssistanceLabel {
    font-size: 16px;
    font-weight: 600;
    color: var(--primary-400-base);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.classAssistance {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}
