.container {
    height: 100%;
    border-radius: 12px;
    box-shadow: 1px 2px 16px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px var(--black-100);
    background-color: var(--whitebase);
    position: relative;
}

.card {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 6px;
}

.comingSoon {
    position: absolute;
    width: 100%;
    top: 40%;
    left: 0;
    height: 64px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12), inset 0 -1px 3px 0 rgba(0, 0, 0, 0.12),
        inset 0 1px 2px 0 rgba(255, 255, 255, 0.25);
    border-style: solid;
    border-width: 1px;
    border-image-source: linear-gradient(to right, #ff7cbf, #c2337c);
    border-image-slice: 1;
    background-image: linear-gradient(to right, var(--warning-400-base), #e46e42 63%, #991f5d),
        linear-gradient(to right, #ff7cbf, #c2337c);
    transform: rotate(-5deg);
}

.comingSoonLabel {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.33;
    color: var(--whitebase);
    text-transform: uppercase;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.mainValueWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
}

.mainValue {
    font-size: 37px;
    font-weight: bold;
    color: #000;
}

.mainUnit {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: #000;
}

.title {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    color: #000;
}

.bestWrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4px 8px;
    border-radius: 8px;
    background-color: #e8faef;
}

.bestTitle {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--black-200);
}

.bestValue {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--success-400-base);
}

.table {
    margin-top: 6px;
}

.tableTitle {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--black-200);
}

.list {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-top: 8px;
}

.user {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.userName {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: #000;
}

.userPhoto {
    width: 40px;
    height: 40px;
    border-radius: 24px;
}

.censored {
    text-shadow: 0 0 4px var(--black-100);
    color: transparent;
}

.censoredPhoto {
    filter: blur(3px);
    -webkit-filter: blur(3px);
}

.userRank {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    border-radius: 24px;
    background-color: #f5f5f5;
    min-width: 32px;
}

.userRankHighlight {
    background-color: var(--primary-400-base);
}

.userRankValue {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    color: #000;
}

.userRankValueHighlight {
    color: var(--whitebase);
}

.emptyList {
    font-size: 11px;
    font-style: italic;
    text-align: center;
}

.titleWrap {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
}

.subTitle {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--black-300);
}

.cardCensored {
    filter: blur(2px);
    -webkit-filter: blur(2px);
}
