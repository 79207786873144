.isirName {
    background-color: var(--primary-100);
    min-width: 250px;
    min-height: 70px;
    border-radius: 8px;
    text-align: center;
    font-size: 37px;
    font-weight: bold;
    margin-right: 20px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.header {
    display: flex;
    align-items: center;
    margin-top: 40px;
}

.cards {
    background-color: #e5e5e5;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 8px;
    color: var(--black-200);
    font-size: 15px;
    font-weight: 600;
}

.cardDetail {
    color: var(--black-base);
}

.fileButton {
    border-color: var(--primary-400-base);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 48px;
}

.cardsWrapper {
    margin: 20px 0px;
}

.titleLink {
    font-size: 18px;
    font-weight: bold;
    color: #1e90ff;
    text-decoration: underline;
}

.centerContent {
    text-align: center;
}
