.divider {
    height: 2px;
    background-color: #e6e6e6;
    margin: 40px 0;
}
.radioButton {
    align-items: center;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 50%;
}

.radioButtonGrade {
    border-width: 0px;
    padding-top: 5px;
    padding-left: 2rem;
    width: 50%;
    padding-right: 2rem;
    padding-bottom: 5px;
    border-left-width: 1px;
    border-style: inset;
    border-color: var(--primary-400-base);
    flex-direction: column;
    align-items: center;
}

.groupRadioButton {
    border-width: 1px;
    display: flex;
    max-width: 376px;
    flex-direction: row;
    border-radius: 12px;
    border: solid 2px var(--primary-400-base);
}

.textRadioButton {
    font-family: Poppins, sans-serif;
    font-weight: bold;
    font-size: 18px;
    color: var(--black-300);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-transform: uppercase;
}
.actionWrap {
    margin-top: 111px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.cancelBtn {
    margin-right: 20px;
    text-transform: uppercase;
}
.input input:focus ~ .ellipse {
    box-shadow: none !important;
}

.error {
    border: 1px solid var(--error-400-base) !important;
}
.errorText {
    color: red;
}
.titleWrap {
    display: flex;
    align-items: center;
    padding: 11px 5px;
}
.title {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
