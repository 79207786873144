.divider {
    /*background-color: #62b1ff;*/
}

.uploadTitle {
    margin-bottom: 6px;
    font-size: 16px;
    color: var(--black-300);
    text-transform: uppercase;
}

.uploadIconWrap {
    margin-top: 8px;
}

.uploadIcon {
    cursor: pointer;
}

.linkSectionTitle {
    font-size: 24px;
    font-weight: bold;
    color: var(--black-200);
    margin-bottom: 20px;
}

.linkContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
}

.linkContentPanel {
    flex-direction: column;
    align-items: flex-start;
}

.linkValueContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.linkTypeContainer {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 4px 8px;
    border-radius: 8px;
    border: solid 1px var(--black-100);
    background-color: var(--black-100);
}

.linkTypeContainer.add {
    border: solid 1px var(--black-200);
    background-color: var(--black-200);
}

.linkTypeIcon {
    display: block;
}

.linkTypeText {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    color: var(--black-300);
    text-transform: uppercase;
}

.linkTypeContainer.add .linkTypeText {
    color: var(--whitebase);
}

.linkInput {
    flex: 1;
}

.linkAddContainer {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    width: 100%;
}

.linkAddContainer :global(.klassapp-dropdown) {
    width: 100%;
}
