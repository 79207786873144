.activeUserWrap {
    border-radius: 12px;
    border: solid 1px var(--primary-400-base);
    background-color: var(--whitebase);
    padding: 15px;
}

.avatar {
    border-radius: 12px;
}

.info {
    margin-left: 30px;
    margin-right: 30px;
}

.info__name {
    font-size: 24px;
    font-weight: bold;
    color: var(--black-base);
    margin-bottom: 40px;
}

.id {
    font-size: 21px;
    font-weight: 600;
    color: var(--primary-400-base);
}

.infoItem {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 10px;
}

.infoItem__title {
    font-size: 14px;
    font-weight: bold;
    color: var(--primary-400-base);
    margin-bottom: 0;
}

.infoItem__value {
    font-size: 18px;
    font-weight: bold;
    color: var(--black-base);
}

.otherInfoItem {
    display: flex;
}

.rightInfoWrap {
    padding: 10px 15px;
    border-radius: 10px;
    background-color: var(--primary-100);
}

.rightInfo {
    display: flex;
}

.rightInfoItem {
    display: flex;
    flex: 1;
    margin-bottom: 20px;
}

.rightInfoItem__icon {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
}

.rightInfoItem__value {
    font-size: 16px;
    font-weight: bold;
    color: var(--primary-400-base);
    margin-bottom: 0;
    margin-left: 10px;
    word-break: break-all;
}

.messageBtnWrap {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.profileBtn {
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0);
    font-size: 14px;
    font-weight: bold;
    color: var(--primary-400-base);
    border: 1px solid var(--primary-400-base);
    height: 40px;
    padding: 10px 20px;
    margin-right: 24px;
    cursor: pointer;
    text-transform: uppercase;
}

.messageBtn {
    height: 40px !important;
    text-transform: uppercase;
}

.infoUserID {
    display: flex;
}

.infoUserIDItem {
    flex: 1;
}

.progressWrap {
    margin-bottom: 9px;
}

.progresTittle {
    font-size: 16px;
    font-weight: 500;
    color: var(--black-300);
    margin-bottom: 0;
}

.progressBarWrap {
    position: relative;
    height: 32px;
    border-radius: 8px;
    background-color: var(--primary-200);
}

.progressBarValue {
    position: absolute;
    border-radius: 8px;
    background-color: var(--primary-400-base);
    height: 32px;
    display: flex;
    align-items: center;
}

.progressBarNumber {
    font-size: 18px;
    font-weight: bold;
    color: var(--whitebase);
    margin-left: 16px;
    margin-bottom: 0;
}

.totalPropgressWrap {
    margin-bottom: 9px;
    margin-top: 16px;
}

.totalPropgressTitle {
    font-size: 16px;
    font-weight: 500;
    color: var(--black-300);
    margin-bottom: 0;
}

.totalProgressBarWrap {
    position: relative;
    height: 32px;
    border-radius: 8px;
}

.totalProgressBarValueCompleted,
.progressBarValueAttempted,
.progressBarValueRemaining {
    position: absolute;
    border-radius: 8px;
    height: 32px;
    display: flex;
    align-items: center;
}

.totalProgressBarValueCompleted {
    z-index: 2;
}

.progressBarValueAttempted {
    z-index: 1;
}

.progressBarValueRemaining {
    background-color: var(--warning-300);
}

.hourItemWrap {
    display: flex;
    margin-bottom: 0px;
}

.hourItem {
    display: flex;
    align-items: center;
    flex: 1;
}

.hourItemLeft {
    margin-right: 60px;
}

.hourTitleWrap {
    flex: 1;
    display: flex;
    align-items: center;
}

.hourTitle {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 500;
    color: var(--black-300);
}

.hourDescription {
    width: 50px;
    margin-bottom: 0;
    text-align: right;
    font-size: 21px;
    font-weight: bold;
    color: var(--black-base);
}

.hourStatus {
    width: 13px;
    min-width: 13px;
    height: 13px;
    border-radius: 4px;
    margin-bottom: 0;
    margin-right: 8px;
}

.totalHourAttempted {
    background-color: var(--warning-400-base);
}

.totalHourCompleted {
    background-color: var(--success-400-base);
}

.totalHourRemaining {
    background-color: var(--warning-300);
}

.activitiesCompleted {
    background-color: #5540fb;
}

.activitiesRemaining {
    background-color: #e7e4ff;
}

.activitiesExpectedCompleted {
    background-color: #3b2db0;
}
