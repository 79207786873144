.wrap {
}

.selectWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.nameWrap {
    display: flex;
    align-items: center;
}

.avatar {
    width: 40px;
    height: 40px;
    min-width: 40px;
    border-radius: 50%;
    margin-right: 15px;
}

.name {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 500;
    color: #212121;
}
