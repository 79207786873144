.node {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 16px 0 rgba(48, 49, 51, 0.08);
    border: solid 1px var(--black-100);
    background-color: var(--whitebase);
    position: relative;
    min-width: 250px;
}

.contactStrategyStartNode {
    max-width: 250px;
    padding: 24px;
    border-radius: 12px;
    box-shadow: 0 2px 16px 0 rgba(48, 49, 51, 0.08);
    background-color: var(--primary-100);
    font-size: 14px;
    line-height: 1.33;
}

.workflowStartNode {
    width: 64px;
    height: 64px;
    border-radius: 999px;
    box-shadow: 0 2px 16px 0 rgba(48, 49, 51, 0.08);
    background-color: var(--secondary-400-base);
    font-size: 14px;
    line-height: 1.33;
}

.workflowActionButtonsContainer {
    width: 220px;
}

.endNode {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    box-shadow: 0 2px 16px 0 rgba(48, 49, 51, 0.08);
    background-color: rgb(30, 144, 255);
}

.conditionNode {
    background-color: #fff9ed;
}

.actionNode {
}

.isTarget {
    border-width: 2px;
}

.stepDone {
    border-width: 3px;
    border-color: var(--success-400-base);
}

.stepInProgress {
    border-width: 3px;
    border-color: var(--primary-400-base);
}

.stepInProgress .statusBadge {
    width: 132px;
    padding: 2px;
    border-radius: 4px;
    background-color: var(--primary-400-base);

    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    text-align: center;
    color: var(--whitebase);
}

.stepPending {
    background-color: var(--black-100);
    opacity: 0.5;
}

.sourceHandle {
}

.sourceHandle svg {
    width: 40px;
    height: 40px;
    pointer-events: none;
}

.sourceHandle[data-handleid="TRUE"] svg,
.sourceHandle[data-handleid="FALSE"] svg {
    width: 30px;
    height: 30px;
    margin-top: 5px;
    margin-left: 5px;
}

.sourceHandle[data-handleid="FALSE"] svg path {
    fill: var(--secondary-400-base);
}

.targetHandle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 0;
    border: none;
    transform: none;
}

.nodeType {
    gap: 16px;
}

.nodeTypeIcon {
    width: 40px;
    height: 40px;
    flex-grow: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: solid 1px var(--black-100);
    background-color: white;
    cursor: pointer;
}

.nodeTypeIcon svg {
    width: 24px;
    height: 24px;
}

.nodeTypeText {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--black-300);
}

.nodeContent {
    max-width: 300px;
    margin-top: 16px;
    padding: 8px;
    border-radius: 8px;
    background-color: #f5f5f5;
    max-height: 400px;
    overflow-y: auto;
}

.nodeContentText {
    margin: 0 8px;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--primary-400-base);
}

.nodeContentText p {
    margin-bottom: 6px;
}

.nodeContentBadge {
    padding: 2px 8px;
    border-radius: 8px;
    background-color: var(--black-300);
    font-size: 16px;
    font-weight: 500;
    line-height: 1.43;
    text-align: center;
    color: var(--whitebase);
}

.nodeContentBadge:global(.contacted) {
    background-color: var(--success-400-base);
}

.nodeContentBadge:global(.new) {
    background-color: var(--primary-400-base);
}

.nodeContentDescription {
    margin-top: 4px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: var(--black-300);

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.colorCode {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    display: inline-block;
}

.edgeLabel {
    width: 25px;
    height: 25px;
    padding: 6px 0;
    text-align: center;
    background: var(--black-100);
    cursor: pointer;
    border-radius: 50%;
    font-size: 14px;
    line-height: 1;
    pointer-events: none;
}

.infoIconWrap {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.rteWrapper {
    max-height: 400px;
    overflow-y: auto;
}
