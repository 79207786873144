.container {
    padding: 0 24px;
}

.title {
    font-size: 37px;
    font-weight: bold;
    color: #212121;
    margin-bottom: 16px;
}
