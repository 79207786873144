.arrowDownIcon {
    height: 16px;
}

.statusText {
    padding-right: 3px;
}

.statusArrowDownIcon {
    height: 16px;
    width: 16px;
}

.statusItem {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 4px;
}

.title {
    margin-bottom: 0;
    font-size: 11px;
    font-weight: 600;
    color: #939393;
    flex: 1;
    margin-right: 4px;
}

.statusWrap {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 2px;
}

.statusContainer {
    display: flex;
    flex: 1;
}

.statusItem__cursor {
    cursor: pointer;
}

.menuActionWrap {
    max-height: 300px;
    overflow: auto;
}

.menuActionItem__status {
    text-transform: capitalize;
}

.statusBar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--success-400-base);
    border-radius: 4px;
    padding: 2px;
    font-size: 11px;
    font-weight: 600;
    color: var(--primary-100);
    text-transform: capitalize;
    min-height: 20px;
}

.statusBarSidebar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--success-400-base);
    border-radius: 8px;
    padding: 2px 8px;
    font-size: 12px;
    font-weight: 500;
    color: var(--whitebase);
    text-transform: capitalize;
    min-width: 100px;
    max-width: 150px;
}

.statusArrowDownIconSidebar {
    height: 12px;
    width: 12px;
}

.statusItemSidebar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.titleSidebar {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.33;
    color: var(--black-200);
    text-transform: uppercase;
}

.statusBarSidebar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--success-400-base);
    border-radius: 8px;
    padding: 2px 8px;
    font-size: 12px;
    font-weight: 500;
    color: var(--whitebase);
    text-transform: capitalize;
    min-width: 100px;
    max-width: 150px;
}

.statusTextSidebar {
    padding-right: 5px;
    display: block;
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
