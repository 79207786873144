.verifybtn {
    border-width: 0 !important;
    padding: 0 !important;
    color: black !important;
}
.actionWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
