.wrap {
    margin-top: 40px;
    margin-bottom: 60px;
}

.headerWrap {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.headerTitle {
    font-size: 24px;
    font-weight: 800;
    color: #666666;
    margin-bottom: 0;
}

.headerActionWrap {
    flex: 1;
    justify-content: flex-end;
    display: flex;
}

.headerActionItem {
    margin-right: 40px;
}

.taskWrap {
    display: flex;
    align-items: center;
}

.arrowWrap {
    width: 30px;
    height: 30px;
    min-width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    cursor: pointer;
}

.arrowIcon {
    width: 24px;
    height: 24px;
}

.task__id {
    font-size: 16px;
    font-weight: bold;
    color: #212121;
    margin-bottom: 0;
}

.cellChildTableStyle {
    background: #f5f5f5;
}

.headerChildTitle {
    font-size: 18px;
    font-weight: bold;
    color: #939393;
}

.approveIcon path {
    fill: #18a957;
}

.rejectIcon path {
    fill: #df1642;
}

.filePreview {
    margin-top: 30px;
}

.imgPreview {
    height: 700px;
    overflow-y: scroll;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    background: gray;
}

.enrollmentIdsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
