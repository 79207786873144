.root {
    padding: 20px;
}
.title {
    font-size: 30px;
    font-weight: bold;
    color: var(--black-base);
}

.body {
    margin-top: 10px;
}

.footer {
    margin-top: 40px;
}
.buttons {
    display: flex;
    justify-content: space-between;
}
.button:last-child {
    margin-left: 24px;
}

.description {
    font-size: 20px;
    font-weight: bold;
}

.scheduleRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
}

.listSchedule {
    padding-top: 10px;
    padding-bottom: 30px;
}

.scheduleName {
    font-size: 16px;
    cursor: pointer;
}
.currentSchedule {
    font-size: 16px;
}

.disabled {
    color: rgba(0, 0, 0, 0.5);
}
