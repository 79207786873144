.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.title {
    font-size: 21px;
    font-weight: bold;
    line-height: 1.14;
    color: var(--black-200);
}

.emoji {
    font-size: 80px;
    font-weight: bold;
    line-height: 1;
    color: #2df8a3;
    margin-top: 36px;
}

.description {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    color: #000;
}

.moreDesc {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: #000;
    opacity: 0.5;
    text-align: center;
}

.descHighlight {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
    color: var(--error-400-base);
}

.descWrap {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
}

.moreDescWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.makeupHighlight {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
    color: #000;
}
