.btn {
    border: 2px solid var(--primary-400-base) !important;
    color: var(--primary-400-base) !important;
    background-color: transparent !important;
    border-radius: 4px !important;
    padding: 7px 17px !important;
    height: auto !important;
    display: flex !important;
    align-items: center;
}

.uppercase {
    text-transform: uppercase !important;
}

.bold {
    font-weight: bold !important;
}

@media (max-width: 768px) {
    .btn {
        padding: 4px !important;
        font-size: 8px !important;
    }
}
