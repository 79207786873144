.title {
    font-size: 21px;
    font-weight: 800;
    color: var(--black-200);
    margin: unset;
    text-transform: uppercase;
}

.headerLeftTitleWrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
}
