.title {
    font-family: Poppins;
    font-size: 37px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-200);
}
