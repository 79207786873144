.checklistIconWrap {
    display: flex;
    align-items: center;
}

.checklistIconWrap svg {
    height: 24px;
}

.statusContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.status {
    color: #fff;
    font-size: 12px;
    padding: 5px 8px;
    margin: 0;
    border-radius: 7px;
    text-transform: capitalize;
    text-align: center;
}

.status-done {
    background: #18a957;
}

.status-inprogress {
    background-color: #1e90ff;
}

.status-empty {
    background-color: #939393;
}

.studentTable tr {
    position: relative !important;
}

.studentTable th,
.studentTable td {
    font-size: 13px !important;
    min-height: 36px !important;
    height: 36px !important;
    padding: 0px 8px !important;
    border-right: 1px solid rgba(224, 224, 224, 1) !important;
}

.selectDropdown {
    height: 36px;
    min-height: 36px;
}

.selectDropdown * {
    max-height: 28px !important;
    min-height: unset !important;
}

.actionDetailWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
}

.actionDetailWrapChatPopup {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.chatPopupContainer {
    display: flex;
    flex-direction: column;
    width: 600px;
}

.chatPopup {
    min-height: 180px;
    justify-content: space-between;
    width: 400px;
}

.chatContainer {
    display: none;
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0 4px 16px 0 rgb(0 0 0 / 20%);
    position: absolute;
    background: #fff;
    z-index: 9;
}

.showChatContainer {
    display: block;
}

.chatPopupLeftContainer {
    width: 100%;
    height: 100%;
}

.expandIconContainer {
    margin-left: 8px;
    background-color: var(--primary-100);
    display: flex;
    align-items: center;
    padding: 3px;
    border-radius: 7px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    align-self: flex-end;
}

.statusCollapseIcon {
    margin-left: 4px;
    display: block;
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.isCollapsed path {
    stroke: var(--black-200);
}

.statusSectionContainer {
    margin-top: 32px;
}

.statusSectionDivider {
    height: 2px;
    background-color: var(--primary-400-base);
}

.statusSectionHeader {
    font-size: 24px;
    font-weight: bold;
    color: var(--black-300);
}

.tableContainer {
    margin-top: 8px;
    position: relative;
}

.priorityList {
    max-height: 65vh;
    overflow: auto;
}

@media (min-width: 1921px) {
    .priorityList {
        max-height: 75vh;
    }
}

.priorityListTable th {
    min-height: 36px;
    height: 36px;
}

.priorityListTable th,
.priorityListTable td {
    border-right: 1px solid rgba(224, 224, 224, 1);
}

.categoryTd {
    min-height: 32px;
    height: 32px;
    padding: 5px;
    font-weight: bold;
    background-color: var(--primary-100);
}

.cancelBtn {
    margin-right: 10px;
}

.userId {
    text-decoration: underline;
    color: var(--primary-400-base);
    font-weight: bold;
}

.dued {
    background: #ffeff3 !important;
}

.loadMoreIndicator {
    position: absolute !important;
    top: 16px;
    left: 4px;
    z-index: 999;
}

.columnPickerBtnWrapper {
    text-align: right;
    margin-top: 16px;
}

.columnPickerBtn {
    background: transparent !important;
    border: none !important;
}

.columnPickerBtn svg {
    height: 24px;
}
