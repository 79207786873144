.windowsWrap {
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 100vh;
    position: fixed;
    bottom: 0;
    right: 0;
}

.titleWrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    height: 40px;
    background-color: var(--whitebase);
    border-bottom: solid 1px var(--black-100);
}

.windowsTitle {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.29;
    color: var(--black-200);
}

.windowsCloseWrap {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.closeIcon {
    width: 24px;
    height: 24px;
    fill: var(--black-100);
}

.contentWrap {
    display: flex;
    flex-direction: column;
    position: relative;
    height: calc(100vh - 40px);
    overflow-y: scroll;
    padding: 0 10px;
}
