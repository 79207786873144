.action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.title {
    text-transform: uppercase;
    color: var(--black-200);
    font-size: 20px;
    font-weight: bold;
    margin-top: 40px;
}

.filters_title {
    flex-grow: 0;
    margin: 0 0 8px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-200);
}
.filters {
    flex: 1;
    margin: 16px 40px 0 0;
    padding: 8px 0 8px 16px;
    border-radius: 16px;
    background-color: var(--primary-100);
    min-height: 100px;
    height: fit-content;
}
.options_container {
    display: flex;
    width: 100%;
    height: 48px;
    margin-bottom: 8px;
    user-select: none;
}

.filters_options {
    flex: 1;
    margin: 16px 0px 0 0;
    padding: 8px 0 16px 16px;
    border-radius: 16px;
    background-color: #fbfbfb;
    min-height: 100px;
    height: fit-content;
}
.filter_option {
    height: 48px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    padding: 8px 16px 8px 8px;
    border-radius: 12px;
    background-color: var(--whitebase);
    border: solid 2px var(--black-100);
    text-transform: uppercase;
}

.filter_option_active {
    border: solid 2px var(--primary-400-base);
}

.filters_priority {
    width: 40px;
    height: 40px;
    flex-grow: 0;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin: 4px 10px 4px 0;
    padding: 0 8px;
    border-radius: 12px;
    background-color: var(--black-100);
}

.checkbox label::before {
    width: 24px;
    height: 24px;
    flex-grow: 0;
    object-fit: contain;
}
.buttonWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
}
.cancelBtn {
    margin-right: 20px;
    text-transform: uppercase;
}
