.wrap {
    margin-top: 20px;
    border-radius: 16px;
    background-color: #f5f5f5;
}

.bodyWrap {
    padding: 12px 20px 0;
}

.bodyTitle {
    font-size: 16px;
    color: #666666;
    margin-bottom: 4px;
}

.textarea {
    height: 130px;
    background: #fff;
}

.divider {
    background: #fff !important;
    color: #fff !important;
    height: 3px;
    margin: 20px 0 !important;
}

.footerDesc {
    border-radius: 16px;
    border: solid 1px #1e90ff;
    background-color: #ffffff;
    font-size: 16px;
    padding: 20px;
    margin: 0 20px 24px;
}

.footerDesc__bold {
    font-weight: bold;
}

.footerAction {
    display: flex;
    align-items: center;
    margin: 0 20px 30px;
}

.footerAction__desc {
    font-size: 16px;
    font-weight: 500;
    color: #939393;
    margin-bottom: 0;
}

.footerAction__descBold {
    font-weight: bold;
}

.footerAction__bold_600 {
    font-weight: 600;
}

.footerAction__button {
    display: flex;
    justify-content: flex-end;
    flex: 1;
}

.cancelBtn {
    margin-right: 30px;
}
