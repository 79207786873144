.wrap {
    margin-bottom: 40px;
}

.cardWrap {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    box-shadow: 0 4px 24px 0 rgba(48, 49, 51, 0.16);
    background-color: var(--whitebase);
    padding: 10px 12px;
    height: 100%;
    margin-top: 20px;
}

.attCheckVerified svg {
    width: 34px;
    height: 34px;
}
.titleWrap {
    display: flex;
    align-items: center;
    height: 50px;
    font-weight: bold;
    font-size: 18px;
    color: var(--black-base);
}

.cardValue {
    font-size: 37px;
    font-weight: 800;
    margin: 0;
    text-align: center;
    color: var(--black-200);
}
.blackBtn {
    border-width: 0 !important;
    padding: 0 !important;
    color: black !important;
}
.cardTitle {
    margin-bottom: 0;
    text-transform: uppercase;
}

.cardTitleStandFor {
    color: var(--black-200);
    font-weight: bold;
    text-transform: uppercase;
}

.iconWrapper {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    height: 40px;
    width: 40px;
    min-width: 40px;
}

.lessonColor {
    background-color: var(--primary-100);
}

.attendedColor {
    background-color: #e8faef;
}

.missedColor {
    background-color: var(--error-100);
}

.missedColor svg {
    width: 22px;
}

.actionColor {
    background-color: var(--warning-100);
}

.actionColor svg {
    width: 22px;
    height: 28px;
}

.actionColor svg path {
    fill: var(--warning-400-base);
}

.marginTop {
    margin-top: 60px;
}

.actionWarning {
    display: flex;
}

.actionWarning path {
    fill: var(--warning-400-base);
}

.attCheck svg {
    width: 34px;
    height: 34px;
}

.attCheck path {
    fill: var(--primary-400-base);
}

.attClose path {
    stroke: var(--error-400-base);
}

.title {
    color: var(--black-200);
    font-size: 37px;
    font-weight: bold;
    margin-bottom: 10px;
}

.assignedLabel {
    color: var(--whitebase);
    padding: 8px 16px;
    border-radius: 4px;
    width: 152px;
    text-align: center;
    margin-right: 15px;
}

.assignedWrapper {
    display: flex;
    align-items: center;
}

.missedHoursColor {
    background-color: #f5f5f5;
}

.remainedColor {
    background-color: #f5f5f5;
}
.remainedColor path {
    fill: var(--black-200);
}

.remainedColor svg {
    height: 30px;
}

.makeUpColor {
    background-color: var(--primary-100);
}

.makeUpColor path {
    fill: var(--primary-400-base);
}

.makeUpColor svg {
    height: 30px;
}

.statusWrapper {
    display: flex;
    align-items: center;
    gap: 4px;
}

.statusWrapper path {
    fill: var(--primary-400-base);
}

.noStatusWrapper path {
    fill: #e5e5e5;
}

.statusLabel {
    padding: 6px;
    color: var(--whitebase);
    border-radius: 4px;
    min-width: 110px;
    display: inline-block;
    text-align: center;
}

.enrolledIcon path {
    fill: var(--success-400-base);
}

.cardsMaring {
    margin-bottom: 50px;
}

.rightFilter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
