.container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    transition: all 0.3s ease-in-out;
}

.main {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px;
    border-radius: 12px;
    border: solid 1px var(--black-100);
    background-color: #fbfbfb;
}

.mainCollapsed {
    border-radius: unset;
    border: unset;
    border-bottom: solid 1px var(--black-100);
    background-color: unset;
    padding: 8px 0;
}

.rowHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
}

.row {
    display: flex;
    justify-content: space-between;
    gap: 8px;
}

.additional {
    flex: 1;
}

.search {
    max-width: 400px;
}

.heading {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
}

.headingTilte {
    display: flex;
    gap: 4px;
    align-items: center;
}

.title {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.14;
    color: var(--black-200);
    text-transform: uppercase;
}

.collapseWrap {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.actionIcon {
    width: 24px;
    height: 24px;
}

.icon {
    width: 16px;
    height: 16px;
}

.noFilter {
    font-size: 12px;
    line-height: 1.14;
    color: var(--black-200);
    font-style: italic;
}

.headingItem {
    display: flex;
    gap: 6px;
    align-items: center;
}

.itemLabel {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: var(--black-300);
    text-transform: uppercase;
}

.itemOperator {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: var(--black-300);
}

.itemValueWrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px;
    align-items: center;
}

.itemValue {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    padding: 2px 6px;
    border-radius: 4px;
    background-color: var(--black-100);
}

.itemValueLabel {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: #000;
}

.removeWrap {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.filterWrap {
    border-top: solid 1px var(--black-100);
    margin: 0 -8px -8px -8px;
    padding: 12px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.fieldsWrap {
    min-height: 200px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
}

.actionsWrap {
    border-top: solid 1px var(--black-100);
    padding: 12px 0;
    display: flex;
    justify-content: space-between;
    gap: 12px;
}

.rightActions {
    display: flex;
    gap: 6px;
}

.savedFilters {
    display: flex;
    align-items: center;
    gap: 24px;
}

.orLabel {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: var(--black-200);
    text-transform: uppercase;
}

.savedBox {
    display: flex;
    align-items: center;
    gap: 6px;
}

.selectLabel {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: var(--black-300);
    text-transform: uppercase;
}

.headerPointer {
    cursor: pointer;
}

.manageFilterWrap {
    display: flex;
    align-items: center;
    gap: 12px;
}

.link {
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-400-base);
    text-align: right;
    cursor: pointer;
}
