.itemBodyWrap {
    margin-bottom: 30px;
}

.itemBodyMargin {
    margin-right: 20px;
}

.bodyTitle {
    margin-bottom: 6px;
    font-size: 16px;
    color: var(--black-300);
}

.footerInfo {
    margin-top: 60px;
    font-size: 16px;
    font-weight: 500;
    color: var(--black-200);
}

.footerInfo__bold {
    font-weight: 600;
}

.divider {
    background: var(--primary-300);
    margin: 40px 0 !important;
}

.actionBtnDiv {
    display: flex;
    justify-content: flex-end;
}

.saveBtn {
    margin-left: 20px;
}

.documentSavedCheckContainer {
    display: flex;
    border: 1px solid var(--primary-400-base);
    border-radius: 5px;
    width: 20rem;
    padding: 10px;
}

.documentSavedCheckLabel {
    margin-left: 10px;
}
