.wrap {
    padding: 0 40px;
}

.headerWrap {
    border-bottom: 1px solid #62b1ff;
    padding-bottom: 14px;
    margin-bottom: 40px;
}

.title {
    font-size: 37px;
    font-weight: bold;
    line-height: 1.41;
    color: #666666;
    margin-bottom: 4px;
}

.breakTime {
    display: inline-block;
    padding: 8px 22px;
    border-radius: 12px;
    background-color: var(--primary-400-base);
    font-size: 24px;
    font-weight: bold;
    color: var(--whitebase);
}

.radioGroup {
    display: flex !important;
    border: solid 1px #1e90ff;
    border-radius: 8px;
    width: 60%;
    margin-bottom: 20px;
}

.radioItem {
    border-right: 1px solid #1e90ff;
    padding: 12px !important;
    font-size: 16px !important;
    font-weight: bold;
    line-height: 1.33 !important;
    color: #939393 !important;
    margin: 0 !important;
    flex: 1;
}

.radioItem:last-child {
    border-right: 1px solid transparent;
}

.action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
}
