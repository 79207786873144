.container {
    display: flex;
    flex-direction: row;
    flex: 1;
    gap: 40px;
    margin-top: 21px;
}
.block {
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px;
    border-radius: 12px;
    box-shadow: 1px 2px 16px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px var(--black-100);
    background-color: var(--whitebase);
}
.title {
    font-size: 16px;
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 8px;
}
.info {
    margin-bottom: 0;
    font-size: 32px;
    font-weight: bold;
}
