.actionWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.verifybtn {
    border-width: 0 !important;
    padding: 0 !important;
    color: black !important;
}
.rightIcon {
    width: 32px;
    height: 32px;
}
.rightFilter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
}
.checkOutIcon {
    width: 32px;
    height: 32px;
}
.checkbox {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.tabContainer {
    margin-top: 24px;
}

.dateWrap {
    display: flex;
    align-items: center;
    gap: 4px;
}

.arrowWrap {
    width: 20px;
    height: 20px;
    min-width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.arrowIcon {
    width: 16px;
    height: 16px;
}

.dateValue {
    font-size: 13px;
    font-weight: bold;
    color: #212121;
}
