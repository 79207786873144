.wrap {
    margin-top: 30px;
}

.exportBtnWrap {
    display: flex;
    justify-content: flex-end;
}

.status {
    width: 102px;
    height: 32px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.status__complete {
    background-color: #1e90ff;
}

.status__overdue {
    background-color: #df1642;
}
