.wrap {
    margin-bottom: 24px;
}

.itemWrap {
    display: flex;
    padding: 24px;
    border-radius: 12px;
    box-shadow: 0 4px 24px 0 rgba(48, 49, 51, 0.16);
    background-color: var(--whitebase);
    height: 100%;
}

.iconWrap {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.icon {
    width: 12px;
    height: 12px;
}

.infoWrap {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
}

.infoTitle {
    font-size: 18px;
    font-weight: bold;
    color: var(--black-base);
    margin-bottom: 0;
}

.infoValue {
    font-size: 24px;
    font-weight: 600;
    color: var(--primary-400-base);
    margin-bottom: 0;
}

.exportBtnWrap {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;
}
