.parentDiv {
    margin-top: 20px;
    border-radius: 7px;
}

.container {
    background-color: #f5f5f5;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    height: 100vh;
    border-radius: 7px;
}

.boardContainer {
    width: 300px;
    min-width: 300px;
    padding: 10px;
}

.boardCardsContainer {
    height: 100%;
    overflow-x: scroll;
}

.boardHeaderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    font-weight: bold;
    font-size: 17px;
    border: 1px solid var(--black-100);
    border-radius: 7px;
    color: var(--black-300);
}

.boardHeaderLeftContainer {
    display: flex;
    align-items: center;
}

.boardHeaderIcon {
    width: 14px;
    height: 14px;
    flex-grow: 0;
    background-color: var(--black-200);
    margin-right: 5px;
    border-radius: 50%;
}

.boardHeaderCount {
    color: var(--primary-400-base);
}

.card {
    border: 1px solid;
    border-radius: 7px;
    padding: 7px;
    margin: 15px 0px;
    border: 1px solid var(--black-100);
    background-color: #fff;
}

.cardSelected {
    border: 2px solid var(--primary-400-base);
}

.cardRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 0px;
}

.cardHeader {
    border-bottom: 1px solid var(--black-100);
    padding-bottom: 2px;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
}

.cardCode {
    color: var(--primary-400-base);
}

.studentName {
    color: #000;
    margin-left: 10px;
}

.viewDetailsContainer {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.iconViewDetails {
    width: 100%;
    height: 100%;
}

.iconConfirm {
    width: 104px;
    height: 104px;
}

.dataRow {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    margin: 3px 0px;
}

.dataContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.label {
    color: var(--black-200);
    font-weight: bold;
}

.value {
    color: #000;
}

.statusHTML {
    background-color: var(--black-200);
    border-radius: 7px;
    padding: 1px 3px;
    color: #fff;
    font-weight: bold;
}

.statusPrimary {
    background-color: var(--primary-400-base);
}

.popup {
    min-width: unset !important;
}

.popupContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.popupTitle {
    font-size: 18px;
    font-weight: bold;
}

.popupStudentNameContainer {
    border: 1px solid var(--black-100);
    border-radius: 7px;
    padding: 7px 20px;
    font-weight: 600;
}

.popupStudentCode {
    margin-right: 7px;
    color: var(--primary-400-base);
}

.popupStatusContainer {
    font-weight: 600;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.popupFromStatus {
    margin: 0px 7px;
    color: #fff;
    background-color: var(--black-200);
    padding: 10px 7px;
    border-radius: 7px;
}

.popupToStatus {
    margin-left: 7px;
    width: 220px !important;
}

.popupRow {
    margin: 10px 0px;
}

.actionDetailWrapPopup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    width: 100%;
    margin-top: 30px;
}
