.boardWrap {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 20px;
}

.boardTitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: var(--black-300);
    text-transform: uppercase;
}

.field {
    /* padding-top: 10px; */
    padding-bottom: 10px;
}

.addBtnWrap {
    padding-bottom: 10px;
}

.addBtn {
    background-color: var(--primary-100);
    color: var(--black-300);
    border-color: transparent;
}

.removeBoard {
    position: absolute;
    left: 0px;
    bottom: 5px;
    width: 33px;
    height: 38px;
    cursor: pointer;
    border-radius: 50%;
}

.removeBoard:before,
.removeBoard:after {
    content: "";
    position: absolute;
    top: 21px;
    left: 10px;
    width: 15px;
    height: 3px;
    background: var(--secondary-400-base);
}

.removeBoard:before {
    transform: rotate(45deg);
}

.removeBoard:after {
    transform: rotate(-45deg);
}
