.title {
    font-size: 24px;
    font-weight: 800;
    color: var(--black-300);
}

.courseGradeWrap {
    margin-top: 30px;
}

.inputWrap {
    display: flex;
    align-items: center;
    gap: 12px;
}

.extraInput {
    flex: 1;
    min-width: 60px;
}

.action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
    margin-top: 30px;
}
