.termItemWrap {
    margin-bottom: 24px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.leftHeader {
    display: flex;
    align-items: center;
}

.rightHeader {
    display: flex;
    align-items: center;
    gap: 16px;
}

.termHeader {
    border-radius: 12px;
    border: solid 1px var(--black-100);
    padding: 8px;
    display: flex;
    align-items: center;
    margin-right: 16px;
}

.termNameInput {
    width: 300px;
}

.termNameIconWrap {
    display: flex;
    align-items: center;
    min-width: 18px;
}

.termNameChangesIcon {
    width: 18px;
    height: 18px;
}

.selectableTerm {
    display: flex;
    gap: 6px;
    position: relative;
    width: 240px;
    margin-right: 10px;
}

.termTitle {
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-400-base);
    padding-right: 8px;
}

.termInfo {
    font-size: 18px;
    font-weight: bold;
    color: #000;
}

.deleteIconWrap {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.deleteIcon {
    width: 24px;
    height: 24px;
}

.termBody {
    display: flex;
}

.termBody__timeInfo {
    margin-top: 32px;
}

.termBody__dot {
    height: 32px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: var(--black-200);
}

.coursesWrap {
    display: flex;
    margin-bottom: 8px;
    max-width: calc(100vw - 310px);
    overflow: auto;
}

.coursesBody {
    display: flex;
}

.addCourseBtnWrap {
    border: solid 1px var(--black-100);
    padding: 2px 8px;
    display: flex;
    align-items: center;
    flex: 1;
    cursor: pointer;
    height: 64px;
}

.addCourseBtn {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    padding-left: 10px;
}

.timeWrap {
    display: flex;
    align-items: center;
    height: 32px;
}

.timeTitle {
    font-size: 12px;
    font-weight: 500;
    color: var(--black-200);
    width: 48px;
    min-width: 48px;
}

.timeTitle__large {
    width: 70px;
    min-width: 70px;
}

.dateTitle {
    height: 48px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: var(--black-200);
}

.registrationHoldWrap {
    display: flex;
    align-items: center;
    border-radius: 6px;
    border: solid 1px var(--black-100);
    padding: 8px 16px;
}

.registrationHold__title {
    font-size: 18px;
    font-weight: bold;
    color: var(--black-300);
    margin-right: 24px;
    text-transform: uppercase;
}

.switchItem {
    font-size: 18px;
    font-weight: 500;
    color: var(--black-300);
    margin-left: 11px;
    margin-right: 10px;
    text-transform: uppercase;
}

.switchItem__active {
    color: #000;
    font-weight: bold;
}

.courseDisabled {
    opacity: 0.5;
}

.iconWrap {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(180deg);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    margin-right: 8px;
}

.iconCollapse {
    transform: rotate(0deg);
}

.registeredWrap {
    display: flex;
    align-items: center;
    margin-right: 16px;
}

.registeredIcon {
    height: 24px;
    width: 24px;
}

.registeredTitle {
    padding-left: 8px;
    font-size: 16px;
    font-weight: 600;
    color: var(--success-400-base);
}
