.root {
    height: 100%;
    width: 100%;
    position: absolute;
}

.verticalCenter {
    text-align: center;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
}
.verticalCenter img {
    width: 250px;
    height: auto;
}
