.root {
    padding: 10px 40px;
}

.paginationWrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

.doneBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 40px;
}

.cancelBtn {
    margin-right: 20px;
    padding: 7px 17px !important;
    height: auto !important;
}

.status {
    padding: 5px 10px;
    border-radius: 4px;
    color: #fff;
    font-weight: bold;
    font-size: 13px;
    width: 110px;
    text-align: center;
    text-transform: capitalize;
}

.status-new {
    background-color: var(--success-400-base);
}

.status-verification {
    background-color: var(--warning-400-base);
}

.createTaskWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.iconPlus {
    height: 24px;
}

.labelTerm {
    font-size: 17px;
    margin-left: 10px;
    margin-bottom: 0;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: var(--primary-400-base);
    padding-top: 7px;
}

.divider {
    background: var(--primary-400-base);
    height: 2px;
    margin: 40px 0;
}

.infoBox {
    margin: 0 auto;
    margin-top: 25px;
}

.headerWrap {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
}

.termWrap {
    border-radius: 8px;
    background-color: #f5f5f5;
    padding: 5px 16px;
}

.headerTitle {
    font-size: 14px;
    font-weight: 500;
    color: var(--black-200);
    margin-bottom: 4px;
}

.termInfo__id {
    font-size: 21px;
    font-weight: bold;
    color: var(--black-300);
    margin-right: 12px;
}

.termInfo__name {
    font-size: 21px;
    font-weight: 600;
    color: var(--black-300);
}

.headerValueWrap {
    border-radius: 8px;
    background-color: #e5e5e5;
    padding: 4px 8px;
    font-size: 21px;
    font-weight: bold;
    color: #212121;
    margin-right: 31px;
}

.headerValue__code {
    color: #1e90ff;
    margin-left: 20px;
}

.reportTable {
    width: 100%;
    margin: 20px 0px;
    position: relative;
}

.reportTR {
    display: flex;
    justify-content: space-between;
}

.reportTD {
    width: 30%;
}

.reportTable tr:nth-child(even) {
    background-color: #fbfbfb;
}

.reportCell {
    border: 1px solid #c7c7c7;
    padding: 10px;
    color: var(--black-300);
    display: flex;
}

.reportCellIndex {
    font-weight: bold;
}

.reportCellISIR,
.reportCellSystem {
    color: var(--black-base);
}

.reportColumn {
    width: 33%;
}

.reportColumnFixed {
    border-left: 8px solid var(--black-300);
    border-right: 8px solid var(--black-300);
}

.reportColumnInfo {
    border-left: 8px solid var(--primary-500);
    border-right: 8px solid var(--primary-500);
}

.reportColumnWarning {
    border-left: 8px solid var(--warning-400-base);
    border-right: 8px solid var(--warning-400-base);
}

.reportColumnSuccess {
    border-left: 8px solid var(--success-400-base);
    border-right: 8px solid var(--success-400-base);
}

.reportBoxItem {
    margin: 2px 5px;
}

.reportBox {
    padding: 5px 10px;
    border-radius: 4px;
    color: #fff;
    font-weight: bold;
    font-size: 17px;
    display: inline-flex;
}

.reportBoxSmall {
    font-size: 13px;
}

.bgB2 {
    background-color: var(--black-200);
}

.bgB3 {
    background-color: var(--black-300);
}

.bgP4B {
    background-color: var(--primary-400-base);
}

.bgP5 {
    background-color: var(--primary-500);
}

.bgW1 {
    background-color: var(--warning-100);
}

.bgW4B {
    background-color: var(--warning-400-base);
}

.bgSc4B {
    background-color: var(--secondary-400-base);
}

.bgS1 {
    background-color: var(--success-100);
}

.bgS4B {
    background-color: var(--success-400-base);
}

.bgU {
    background-color: unset !important;
}

.reprotInfoContainer {
    display: flex;
    align-items: center;
}

.infoContainer {
    border: 1px solid;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    min-height: 9rem;
}

.infoContainerNotFound {
    padding: 10px;
    background-color: #fff;
    min-height: 12rem;
}

.bW4B {
    border-color: var(--warning-400-base);
}

.bS4B {
    border-color: var(--success-400-base);
}

.infoTitle {
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
}

.reportSVG {
    border-radius: 50%;
    width: 35px;
    height: auto;
    padding: 7px;
    margin-left: 5px;
}

.iconFull {
    padding: unset;
    border-radius: unset;
}

.noSystemDataContainer {
    float: right;
    width: 33%;
    position: absolute;
    left: 66%;
    top: 9%;
    height: 91%;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    background-color: rgba(94, 94, 94, 0.64);
    padding: 10px;
}

.noSystemDataContainerUpdate {
    top: 12%;
    height: 88%;
}

.reportTRInfo {
    height: 5rem;
}

.fileButton {
    border-color: var(--primary-400-base);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 35px;
}
