.wrap {
    padding: 0 40px;
}

.title {
    font-size: 37px;
    font-weight: bold;
    color: #666666;
    padding-bottom: 14px;
    border-bottom: 2px solid #62b1ff;
    margin-bottom: 60px;
}

.description {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 50px;
}

.actionBtnWrap {
    display: flex;
    justify-content: flex-end;
    margin-top: 60px;
}

.cancelBtn {
    margin-right: 40px;
}

@media (max-width: 768px) {
    .wrap {
        padding: 0;
    }

    .title {
        font-size: 16px;
        padding-bottom: 5px;
        margin-bottom: 10px;
    }

    .description {
        font-size: 12px;
        margin-bottom: 20px;
    }

    .actionBtnWrap {
        margin-top: 10px;
    }

    .cancelBtn {
        margin-right: 10px;
    }
}
