.messageContainer {
    position: relative;
}

.container {
    padding: 12px;
    background-color: var(--whitebase);
    display: flex;
    flex-direction: column;
    gap: 6px;
    position: relative;
}

.heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    width: max-content;
}

.tag {
    padding: 2px 8px;
    border-radius: 8px;
    background-color: var(--black-200);
    font-size: 12px;
    font-weight: 500;
    line-height: 1.29;
    color: var(--whitebase);
}

.avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.fullName {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    color: #000;
}

.dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--black-300);
}

.timestamp {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.29;
    color: var(--black-300);
}

.main {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.title {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.33;
    color: #000;
    word-wrap: break-word;
}

.content {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--black-300);
    word-wrap: break-word;
    white-space: pre-wrap;
}

.replyContainer {
    background-color: #f5f5f5;
    padding: 8px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-left: solid 2px var(--primary-400-base);
}

.container:hover {
    background-color: var(--black-100);
}

.container:hover .replyContainer {
    background-color: var(--black-100);
    cursor: pointer;
}

.navigateWrap {
    cursor: pointer;
}

.expandedInfo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 12px;
}

.expandedAuthor {
    display: flex;
    flex-direction: column;
}

.expandedAvatar {
    width: 40px !important;
    height: 40px !important;
    border-radius: 50% !important;
}

.infoContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1;
}

.scheduledInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.scheduledInfoContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
}

.scheduledTag {
    padding: 2px 8px;
    border-radius: 6px;
    background-color: var(--primary-400-base);
    font-size: 12px;
    font-weight: 500;
    line-height: 1.29;
    color: var(--whitebase);
}

.scheduledValueTag {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    padding: 2px 4px;
    border-radius: 6px;
    background-color: var(--primary-100);
}

.scheduledValueWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
}

.scheduledValue {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.2;
    color: var(--black-300);
}

.scheduledIcon {
    width: 16px;
    height: 16px;
}

.attContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px;
    border-radius: 24px;
    background-color: #f5f5f5;
}

.attIcon {
    width: 14px;
    height: 14px;
}

.rightHeading {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
}

.reactionsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
}

.reactionWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    padding: 2px 5px;
    border-radius: 8px;
    background-color: #f5f5f5;
    cursor: pointer;
}

.reaction {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    color: var(--black-300);
}

.reactionCount {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.2;
    color: var(--black-300);
}

.imgInner {
    width: 50%;
    max-width: 480px;
    height: auto;
    border-radius: 4px;
    overflow: hidden;
    background-color: var(--black-100);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 7px;
    cursor: pointer;
}

.image {
    width: 100%;
    height: auto;
}

.videoInner {
    width: 50%;
    max-width: 480px;
    height: auto;
    border-radius: 4px;
    overflow: hidden;
    background-color: var(--black-100);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 7px;
    position: relative;
    cursor: pointer;
}

.playVideoIconContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100px;
    height: 100px;
    border-radius: 28px;
    transition: all 300ms ease-in-out, box-shadow 10ms ease-out;
    z-index: 10;
    box-shadow: 2px 4px 32px 0 rgba(0, 0, 0, 0.26);
}

.videoInner:hover .playVideoIconContainer > svg > path {
    fill: var(--whitebase);
    stroke: var(--whitebase);
}

.videoOverlay {
    position: absolute;
    background: transparent;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    border-radius: 4px;
}

.fileInner {
    max-width: 250px;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #939393;
    color: var(--black-300);
    display: grid;
    grid-template-columns: 30px 1fr 24px;
    place-items: center left;
    padding: 10px;
    column-gap: 7px;
    margin-bottom: 7px;
}

.fileInner > div > p:first-child {
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 160px;
    margin-bottom: 5px;
}

.fileInner > div > p:last-child {
    text-overflow: ellipsis;
    width: 160px;
}

.downloadWrapper {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.iconWrap {
    display: flex;
    cursor: pointer;
    align-items: center;
}

.icon,
.menuIcon {
    width: 20px;
    height: 20px;
}

.emojiPicker {
    right: 16px !important;
    left: unset !important;
}

.channelWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    max-width: 200px;
    overflow: hidden;
}

.channelName {
    flex: 1;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.29;
    color: var(--black-300);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.channelIcon,
.tagIcon {
    width: 14px;
    height: 14px;
}

.followUpTag {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    padding: 4px 6px;
    border-radius: 24px;
    background-color: #f5f5f5;
    flex: 1;
    overflow: hidden;
}

.followUpTagTitle {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.29;
    color: var(--primary-400-base);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.followUpAction {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 4px;
    border-radius: 24px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
    background-color: var(--whitebase);
    cursor: pointer;
}

.followUpIcon {
    width: 18px;
    height: 18px;
}

.unreadCount {
    min-width: 14px;
    box-sizing: content-box;
    border-radius: 50%;
    background-color: var(--secondary-400-base);
    padding: 2px 3px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.29;
    text-align: center;
    color: var(--whitebase);
}

.separator {
    padding: 0 16px;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.29;
    color: var(--black-200);
}

.separator::before,
.separator::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid var(--black-100);
}

.separator:not(:empty)::before {
    margin-right: 0.5em;
}

.separator:not(:empty)::after {
    margin-left: 0.5em;
}

.myReaction {
    border: 1px solid var(--black-100);
    background-color: var(--whitebase);
}

.systemMessageContainer {
    display: flex;
    justify-content: center;
    gap: 4px;
    padding: 8px 16px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.29;
    color: var(--black-200);
}

.textHighlight {
    font-weight: bold;
}

.highlightWrap {
    background-color: var(--primary-100);
}

.highlightWrap:hover {
    background-color: var(--black-100);
}

.edited {
    width: fit-content;
    font-size: 8px;
    line-height: 1.5;
    color: var(--black-300);
}

.animatePulse {
    animation: pulse 2s 0.45s cubic-bezier(0.4, 0, 0.6, 1) 1;
    animation-delay: 1s;
}
@keyframes pulse {
    0% {
        background: transparent;
    }
    50% {
        background: var(--primary-200);
    }
    100% {
        background: transparent;
    }
}

.systemMessageOutOfOffice {
    background-color: var(--warning-200);
    padding: 20px;
    font-size: 14px;
    border-radius: 10px;
    white-space: pre-line;
    max-width: 60%;
}
