.root {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 10px 0px;
}

.voteButton {
    border-width: 1px !important;
    width: 100%;
    display: flex !important;
    justify-content: center;
}
.votersCount {
    color: var(--black-300);
    font-size: 0.9rem;
    text-align: center;
}
.option {
    display: grid;
    grid-template-columns: 40px auto;
    grid-template-rows: auto auto;
    place-content: center start;
    gap: 5px;
    justify-content: stretch;
}
.resultOption {
    display: grid;
    grid-template-columns: 40px auto;
    grid-template-rows: auto auto;
    place-content: center start;
    column-gap: 5px;
    justify-content: stretch;
}
.textOption {
    align-self: center;
}
.votersCount {
    justify-self: end;
    align-self: center;
}
.radioContainer {
    place-self: center;
}

.iconWrapper {
    justify-self: end;
    align-self: center;
}
.icon {
    display: flex;
}
.icon > svg {
    width: 18px;
    height: 18px;
}
.progressContainer {
    align-self: center;
}
.progress {
    border-radius: 0.125rem;
    transition: width 0.3s;
    transition-delay: 0.09s;
    min-width: 0.3rem;
    background: var(--primary-400-base);
    height: 0.25rem;
}

.titlePoll {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--black-300);
    font-size: 0.9rem;
    height: 26px;
}
.explanationIcon {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    border-radius: 6px;
}
.explanationIcon:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base), 0 3px 5px 0 rgba(48, 49, 51, 0.1), 0 0 1px 0 rgba(48, 49, 51, 0.05);
    background: var(--primary-100);
}
