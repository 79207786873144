.studentItem {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.studentItem__img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 12px;
}

.studentItem__info {
    font-size: 16px;
    font-weight: 500;
    color: var(--black-base);
    flex: 1;
}

.studentItem__iconWrap {
    cursor: pointer;
    padding: 2px;
}

.studentItem__icon {
    width: 32px;
    height: 32px;
}
.unchecked {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: grey;
    position: relative;
}
.checked {
    background: var(--primary-400-base);
}
.attendanceTickIcon {
    width: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
