.container {
    display: flex;
    justify-content: center;
    position: relative;
}

.checkbox {
    text-align: center;
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.checkbox + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
}

.checkbox + label::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 2px solid var(--black-100);
    border-radius: 0.25em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    cursor: pointer;
}

.checkbox:checked + label::before {
    border: 2px solid #1e90ff;
    background-image: url("./check.svg");
    background-size: 40px 30px;
}

.containerDisabled {
    cursor: not-allowed;
}

.containerDisabled + p {
    color: var(--black-100);
}

.containerDisabled .checkbox + label::before {
    border-color: var(--black-100);
    cursor: not-allowed;
}

.containerDisabled .checkbox:checked + label::before {
    background-image: url("./check-disabled.svg");
}

.containerReadOnly {
    cursor: default;
}
