.wrap {
    border-radius: 12px;
    box-shadow: 0 4px 24px 0 rgba(48, 49, 51, 0.16);
    background-color: var(--whitebase);
    padding: 12px 20px;
}

.title {
    font-size: 18px;
    font-weight: 800;
    color: var(--black-200);
    margin-bottom: 0;
}

.body {
    display: flex;
    flex-direction: column;
}

.headerWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.headerTarget {
    font-size: 14px;
    font-weight: bold;
    color: var(--black-300);
    margin-bottom: 0;
    width: 60px;
    text-align: center;
    text-transform: uppercase;
}

.itemWrap {
    position: relative;
}

.comparePercentWrap {
    position: absolute;
    height: 59px;
    width: calc(100% - 400px);
    border: 1px solid var(--black-300);
    top: 24px;
    border-radius: 6px;
    border-left: 0;
    right: 75px;
    z-index: 1;
}

.comparePercentValue {
    position: absolute;
    right: -18px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--black-300);
    font-size: 16px;
    font-weight: bold;
    color: var(--whitebase);
    margin-bottom: 0;
}

.item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
}

.circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--primary-200);
    margin-right: 10px;
}

.item__title {
    font-size: 15px;
    font-weight: bold;
    color: var(--black-300);
    margin-bottom: 0;
    width: 120px;
}

.percent {
    width: 80px;
    min-width: 60px;
    font-size: 17px;
    font-weight: 600;
    color: var(--black-base);
    margin-bottom: 0;
    text-align: right;
    margin-right: 10px;
}

.currentValue {
    font-size: 19px;
    font-weight: bold;
    color: var(--whitebase);
    background: var(--primary-200);
    height: 28px;
    padding: 5px 10px;
    border-radius: 8px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    min-width: 50px;
}

.progressWrap {
    flex: 1;
    position: relative;
    height: 40px;
    border-radius: 8px;
    background-color: #e5e5e5;
    margin-right: 40px;
    margin-left: -30px;
}

.progressValue {
    position: absolute;
    border-radius: 8px;
    height: 40px;
    z-index: 2;
    display: flex;
    align-items: center;
}

.targetValue {
    font-size: 18px;
    font-weight: bold;
    color: var(--black-300);
    background-color: #e5e5e5;
    padding: 6px;
    height: 40px;
    width: 60px;
    border-radius: 6px;
    text-align: center;
}
