.wrap {
    padding: 16px 0 16px 16px;
}

.closeIconWrap {
    width: 32px;
    height: 32px;
    cursor: pointer;
}

.closeIcon {
    width: 24px;
    height: 24px;
}

.header {
    display: flex;
    align-items: center;
    gap: 8px;
}

.headerDetailWrap {
    flex: 1;
}

.headerDetail {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
}

.title {
    font-size: 21px;
    font-weight: bold;
    color: var(--black-200);
    flex: 1;
    text-transform: uppercase;
}

.groupHeaderWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 4px;
}

.groupHeaderInfo {
    display: flex;
    align-items: center;
    gap: 8px;
}

.groupHeaderInfo__title {
    font-size: 16px;
    font-weight: 500;
    color: var(--black-200);
}

.groupHeaderInfo__count {
    font-size: 16px;
    font-weight: 500;
    color: var(--secondary-400-base);
}

.groupHeaderAction {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.groupHeaderAction__text {
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-400-base);
}

.groupHeaderAction__iconWrap {
    display: flex;
    align-items: center;
    transform: rotate(180deg);
}

.loadingWrap {
    position: relative;
    width: 384px;
}

.markAllAsRead {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    background-color: #f5f5f5;
    padding: 4px;
    border-radius: 8px;
    position: relative;
}

.notificationBody {
    height: 60vh;
    overflow: auto;
    padding: 20px 0;
    padding-right: 16px;
}

.notificationItemWrap {
    margin-bottom: 16px;
}

.notificationItemWrap__expand {
    margin-top: 12px;
    margin-bottom: 0;
    cursor: default;
}

.notificationItem {
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;
    width: 384px;
    padding: 8px 24px 8px 12px;
    border-radius: 8px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px var(--black-100);
    background-color: var(--whitebase);
    z-index: 4;
    margin-bottom: 8px;
}

.notificationItem__clickable {
    cursor: pointer;
}

.notificationItem__removeWrap {
    position: absolute;
    top: 2px;
    right: 2px;
    padding: 4px;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    background: rgba(0, 0, 0, 0.08);
    cursor: pointer;
}

.notificationItem__removeWrap:hover {
    background: var(--error-200);
}

.notificationItem__removeIcon {
    width: 16px;
    height: 16px;
}

.notificationItem__header {
    display: flex;
    align-items: center;
    gap: 8px;
}

.notificationItem__code {
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-400-base);
}

.notificationItem_iconWrap {
    width: 20px;
    height: 20px;
}

.notificationItem_icon {
    width: 20px;
    height: 20px;
}

.notificationItem__name {
    font-size: 14px;
    font-weight: 500;
    color: #000;
}

.notificationItem__desc {
    font-size: 14px;
    font-weight: 500;
    color: var(--black-300);
    margin: 0;
    white-space: pre-wrap;
    word-break: break-word;
}

.notificationExpandedWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.notificationExpandedBody {
    display: flex;
    flex: 1;
    gap: 8px;
}

.notificationExpanded__title {
    font-size: 18px;
    font-weight: 500;
    color: var(--black-200);
}

.notificationExpanded__count {
    font-size: 18px;
    font-weight: 500;
    color: var(--secondary-400-base);
}

.notificationExpandedWrap {
    display: flex;
    align-items: center;
}

.notificationItem__changeStatus {
    display: inline-flex;
    flex-wrap: wrap;
    font-size: 14px;
    font-weight: 500;
    color: var(--black-300);
}

.notificationItem__changeStatusHighlight {
    color: #000;
    padding: 0 4px;
}

.allNotificationRead {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    height: 300px;
}

.timeInfo {
    font-size: 14px;
    color: #01122280;
    margin-top: 2px;
}

.downloadReportWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
    position: relative;
}

.downloadReport {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--primary-400-base);
    margin-bottom: 0;
    cursor: pointer;
}
