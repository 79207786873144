.button {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 6px 16px;
    border: 2px solid var(--primary-400-base);
    border-radius: 4px;
    height: 32px;
    min-width: 56px;
    background-color: var(--whitebase);
    color: var(--primary-400-base);
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    cursor: pointer;
    white-space: nowrap;
    outline: none;
}

.calendarButton {
    width: 105px;
    height: 40px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0);
    border-width: 2px;
}

button.button {
    outline: none;
}

.button:active {
    background-color: var(--black-100);
}

.button:disabled {
    background-color: var(--black-100);
    color: var(--primary-200);
    cursor: auto;
}

.button:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base);
}

.button--secondary {
    border-color: var(--secondary-400-base);
    background-color: var(--secondary-400-base);
    color: var(--whitebase);
}

.button--transparent {
    background-color: Transparent;
}

.button--secondary:active {
    background-color: var(--secondary-300);
}

.button--secondary:disabled {
    background-color: var(--secondary-300);
    color: var(--whitebase);
    border-color: var(--secondary-300);
}

.hideDropdownArrow {
    display: none;
}

.dropdownSymbol {
    transform: rotate(180deg);
    font-weight: bold;
    font-size: 1.5em;
    margin-left: 10px;
    padding-top: 5px;
}
