.drawerWrap {
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow-x: hidden;
    /*position: fixed;*/
}

.panelStudentWrap {
    display: flex;
    flex-direction: column;
    max-width: 320px;
    width: 320px;
    height: 100%;
    border: solid 1px var(--black-100);
}

.panelStudentContainer {
    height: 100%;
    max-height: calc(100vh - 42px);
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: #f5f5f5;
}

.panelWrap {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 400px;
}

.statusWrap {
    display: flex;
    flex-direction: column;
    border-bottom: solid 1px var(--black-100);
    padding: 16px;
    gap: 10px;
    background-color: #f5f5f5;
}

.statusItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.33;
    color: var(--black-200);
    text-transform: uppercase;
}

.statusBar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--success-400-base);
    border-radius: 8px;
    padding: 2px 8px;
    font-size: 12px;
    font-weight: 500;
    color: var(--whitebase);
    text-transform: capitalize;
    min-width: 100px;
    max-width: 150px;
}

.statusBar__inactive {
    background-color: var(--error-400-base);
}

.statusBar__admissions {
    background-color: var(--primary-500);
}

.statusBar__finAid,
.statusBar__studentAccount {
    background-color: var(--warning-400-base);
}

.statusText {
    padding-right: 5px;
    display: block;
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.statusItem__cursor {
    cursor: pointer;
}

.statusArrowDownIcon {
    height: 12px;
    width: 12px;
}

.menuActionWrap {
    max-height: 300px;
    overflow: auto;
}

.menuActionItem__status {
    text-transform: capitalize;
}

.profileWrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px;
    gap: 10px;
    min-width: 0;
}

.profileContent {
    display: flex;
    min-width: 0;
    gap: 10px;
    flex: 1;
}

.avatarWrap {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    object-fit: none;
    object-position: center;
    overflow: hidden;
    min-width: 80px;
}

.profileInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    min-width: 0;
    flex: 1;
}

.profileHeader {
    display: flex;
    flex-direction: column;
}

.profileNameContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.profileName {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--black-base);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.profileGender {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--black-200);
}

.profileActionWrap {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
}

.profileActionIconWrap {
    width: 24px;
    height: 24px;
    padding: 4px;
    border-radius: 16px;
    background-color: var(--primary-100);
    cursor: pointer;
}

.profileActionIcon {
    width: 16px;
    height: 16px;
    fill: var(--primary-300);
}

.profileActionIconActiveWrap {
    background-color: var(--primary-400-base);
}

.profileActionIconActive {
    fill: var(--whitebase);
}

.profileLinkIconWrap {
    width: 24px;
    height: 24px;
    padding: 4px;
    border-radius: 16px;
    background-color: var(--secondary-400-base);
    cursor: pointer;
}

.profileLinkIcon {
    width: 16px;
    height: 16px;
    fill: var(--whitebase);
}

.departmentWrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 16px;
}

.headingWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.headingTitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: var(--black-300);
    text-transform: uppercase;
}

.headingIcon {
    width: 24px;
    height: 24px;
}

.collapseButton {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    width: 32px;
    height: 32px;
    transform: rotate(180deg);
    cursor: pointer;
}

.collapsed {
    transform: rotate(0deg);
    transform-origin: center;
}

.studentWrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 16px 20px 16px 16px;
    border-top: solid 1px var(--black-100);
    border-bottom: solid 1px var(--black-100);
}

.contentWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    min-width: 0;
    position: relative;
}

.contentTitle {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--black-200);
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    max-width: 50%;
}

.contentValue {
    flex-shrink: 0;
    /*padding: 4px 8px;*/
    border-radius: 8px;
    background-color: #f5f5f5;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--black-base);
    /*min-height: 26px;*/
    min-width: 32px;
    white-space: nowrap;
    display: inline-block;
    text-overflow: ellipsis;
    text-align: center;
    max-width: 55%;
}

.contentTitle.editable,
.contentValue.editable {
    cursor: pointer;
}

.contentValue .displayValue {
    width: 100%;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
}

.contentValue .contentInput {
    width: 140px !important;
    height: auto !important;
}

.contentValue .contentInput :global(.klassapp-dropdown__control) {
    min-height: 0 !important;
}

.contentActions {
    position: absolute;
    right: 4px;
}

.contentAction {
    margin-top: 0;
    cursor: pointer;
}

.contentAction svg {
    width: 18px;
    height: 18px;
}

.contentAction.edit {
    display: none;
}

.contentWrap:hover .contentAction.edit {
    display: inline-block;
}

.contentLink {
    font-size: 14px;
    font-weight: bold;
    color: var(--primary-400-base);
    cursor: pointer;
}

.contactWrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 16px;
    border-bottom: solid 1px var(--black-100);
}

.moduleWrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 16px;
    background-color: var(--primary-100);
    cursor: pointer;
}

.moduleTitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: var(--black-300);
    text-transform: uppercase;
}

.panelLoading {
    display: flex;
    justify-content: center;
    padding-top: 40px;
}

.topLoading {
    position: absolute;
    right: 12px;
    top: 8px;
}

.workflowNameText {
    display: inline-block;
    flex-wrap: nowrap;
    align-items: center;
    gap: 6px;
    padding: 6px;
    border-radius: 8px;
    background-color: var(--primary-100);
}

.stopIcon {
    display: inline-block;
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    background-color: var(--error-400-base);
    border-radius: 4px;
    margin-left: 4px;
    margin-right: 4px;
}

.workflowNameText.disabled {
    background-color: var(--black-100);
    /*color: var(--black-base);*/
}

.numDaysInput {
    text-align: right !important;
    width: 50px !important;
    height: auto !important;
    padding: 4px 8px !important;
    border-radius: 8px !important;
    border: solid 1px var(--black-100) !important;
    background-color: var(--whitebase) !important;
}

.ferpaActionWrap {
    padding: 4px;
    border-radius: 4px;
    background-color: var(--primary-100);
    cursor: pointer;
    display: flex;
    align-items: center;
}

.ferpaButton {
    font-size: 10px;
    font-weight: 500;
    line-height: 1.43;
    color: var(--primary-400-base);
    text-transform: uppercase;
}

.zoomBtnWrap {
    margin: 0px 16px 16px 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: var(--secondary-400-base);
    cursor: pointer;
}

.zoomTitle {
    font-weight: bold;
    line-height: 1.33;
    text-align: center;
    color: var(--whitebase);
    text-transform: uppercase;
}

.zoomIcon {
    width: 16px;
    height: 16px;
    fill: var(--whitebase);
}

.duplicateCountContainer {
    text-align: center;
    display: flex;
    justify-content: center;
}

.duplicateCount {
    background-color: rgb(226, 147, 0);
    border-radius: 500px;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    font-weight: 600;
    gap: 10px;
    cursor: pointer;
}

.warningIcon {
    color: black !important;
}
