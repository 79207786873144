.actionWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.actionWrap > * {
    margin-left: 16px;
}

.statusWrap {
    display: flex;
    align-items: center;
}

.status {
    display: inline-block;
    padding: 0 8px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    color: var(--whitebase);
    word-break: keep-all;
}

.status:global(.active) {
    background-color: var(--success-400-base);
}

.status:global(.packaged) {
    background-color: var(--primary-400-base);
}

.status:global(.estimated) {
    background-color: var(--black-200);
}

.addButton {
    height: 40px !important;
    justify-content: center;
    font-size: 16px;
}

.btnRequest {
    min-width: 120px;
    height: 32px !important;
    justify-content: center;
    text-transform: none !important;
}

.selectedRow {
    background-color: var(--primary-200) !important;
}

.popupTitle {
    font-size: 37px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-300);
}
