.wrap {
    margin-top: 8px;
    margin-bottom: 40px;
}

.itemWrap {
    display: flex;
    height: 146px;
    flex-direction: column;
    padding: 12px 16px 12px 16px;
    border-radius: 12px;
    box-shadow: 0 4px 24px 0 rgba(48, 49, 51, 0.16);
    background-color: var(--whitebase);
    height: 100%;
}
.degreeConferred {
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 16px;
}
.degreeDate {
    width: 180px !important;
}
.degreeConferredDateTitle {
    display: flex;
    align-items: center;
    gap: 8px;
}
.temporaryDegreeDate {
    width: 100% !important;
    box-shadow: 0 4px 24px 0 rgba(48, 49, 51, 0.1);
}
.label {
    margin-top: 16px;
    margin-bottom: 8px;
}
.itemHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.itemProgress {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.textAlignRight {
    text-align: right !important;
}

.graduatedStatus {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.buttonProgress {
    display: flex;
    margin-bottom: 24px;
    flex-direction: row;
    justify-content: flex-end;
}

.headerProgressReport {
    display: flex;
    justify-content: space-between;
}
.graduatedContainer {
    display: flex;
    margin-bottom: 24px;
    flex: 1;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    justify-content: flex-start;
}
.titleReportProgress {
    flex-grow: 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--primary-400-base);
}
.subTitleReportProgress {
    flex-grow: 0;
    font-family: Poppins;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    margin-bottom: 0;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--primary-400-base);
}

.disabledGraduated {
    background-color: var(--black-100) !important;
    cursor: not-allowed !important;
    color: grey !important;
}
.buttonReportProgress {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 14px;
    border-radius: 8px !important;
    border: solid 2px var(--primary-400-base) !important;
    background-color: var(--whitebase) !important;
    height: auto !important;
}

.textAlignCenter {
    text-align: center !important;
}

.subHeader {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.titleProgress {
    height: 48px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-300);
}

.progressNumber {
    font-family: Poppins;
    font-size: 2.2vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--primary-400-base) !important;
}
