.rightFilter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
}

.cards {
    display: flex;
    align-items: center;
    gap: 40px;
    margin-top: 40px;
}

.card {
    border-radius: 12px;
    box-shadow: 0 4px 24px 0 rgba(48, 49, 51, 0.16);
    padding: 20px;
    display: flex;
    gap: 12px;
}

.cardIconWrap {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #e9f4ff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.actionWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 34px;
}
.addItemButton {
    margin-top: 24px;
}
.cardIcon {
    width: 24px;
    height: 24px;
}
.cancelBtn {
    margin-right: 20px;
    text-transform: uppercase;
}
.cardLabel {
    font-size: 18px;
    font-weight: bold;
    color: #000;
    margin-bottom: 12px;
    text-transform: uppercase;
}

.cardValue {
    font-size: 37px;
    font-weight: 800;
    color: var(--black-200);
    margin: 0;
}

.lessonDetailWrap {
    margin-top: 40px;
    padding-top: 24px;
    border-top: 1px solid var(--primary-400-base);
}

.attendIconWrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

.attendIcon {
    width: 24px;
    height: 24px;
}
