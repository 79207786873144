.wrap {
    background: #ccc;
    position: relative;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
}

.headerContainer {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    background: #ccc;
}

.headerContentWrap {
    border-radius: 12px;
    border: solid 2px #1e90ff;
    background-color: #ffffff;
    padding: 12px 16px;
    margin: 0 40px 40px;
}

.headerWrap {
    display: flex;
    margin-bottom: 11px;
}

.createdInfoWrap {
    margin: 8px 0;
}

.body {
    margin: 0 60px;
}

.pageWrap {
    margin-bottom: 30px;
}

.pageImg {
    margin-bottom: 10px;
    width: 100%;
}

.pageImg:last-child {
    margin-bottom: 0;
}

.lastPage {
    margin-bottom: 0;
}
