.formBody {
    margin: 0;
    padding: 8px 0;
}

.formSection {
    padding-right: 24px;
    padding-left: 24px;
}

.formTitle {
    font-size: 21px;
    font-weight: bold;
    color: var(--black-200);
    text-transform: uppercase;
}

.formDetail {
    margin-top: 20px;
}

.checkboxWrap {
    display: inline-block;
}

.checkbox {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    border-radius: 4px;
    border: solid 1px var(--black-100);
    background-color: var(--whitebase);
    flex: 1;
    cursor: pointer;
}

.checked {
    border: solid 1px var(--primary-400-base);
}

.checkboxTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    color: #000;
    text-transform: uppercase;
}

.line {
    background-color: var(--black-100);
    height: 1px;
    width: 100%;
    margin-bottom: 24px;
    margin-top: 24px;
}

.formAction {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
}

.formTitleWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    margin-bottom: 16px;
}

.collapseButton {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    width: 32px;
    height: 32px;
    transform: rotate(180deg);
    cursor: pointer;
}

.collapsed {
    transform: rotate(0deg);
    transform-origin: center;
}

.formInfoWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
}

.uploadWrap {
    display: flex;
    align-items: center;
    gap: 12px;
}

.uploadTitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    text-align: left;
    color: var(--black-300);
    text-transform: uppercase;
}

.checkboxGroupWrap {
    display: flex;
    margin-top: 12px;
    align-items: center;
    gap: 8px;
}

.offerWrap {
    min-width: 200px;
    max-width: fit-content;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 16px;
    padding: 4px;
    border-radius: 12px;
    border: solid 1px var(--black-100);
    cursor: pointer;
}

.iconWrapper {
    align-items: center;
    width: 32px;
    height: 32px;
}

.icon {
    fill: var(--primary-400-base);
    width: 32px;
    height: 32px;
}

.offerDesc {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: var(--black-300);
    text-transform: uppercase;
}

.removeIconWrapper {
    align-items: center;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.removeIcon {
    fill: var(--primary-400-base);
    width: 24px;
    height: 24px;
}

.row {
    display: flex !important;
    flex-direction: column;
    align-items: flex-end;
}

.salaryUnitWrap {
    width: 110px;
    /* background-color: var(--primary-400-base);
    color: var(--whitebase); */
}

.readOnly > div {
    background-color: #f5f5f5;
}
