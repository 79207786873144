.actionWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.actionWrap > * {
    margin-left: 16px;
}

.statusWrap {
    display: flex;
    align-items: center;
}

.status {
    min-width: 80px;
    display: inline-block;
    padding: 2px 8px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    color: var(--whitebase);
    text-align: center;
    word-break: keep-all;
}

.status:global(.active) {
    background-color: var(--success-400-base);
}

.status:global(.pending) {
    background-color: var(--warning-400-base);
}
.status:global(.requested) {
    background-color: var(--black-200);
}
.status:global(.approved) {
    background-color: var(--success-400-base);
}
.status:global(.rejected) {
    background-color: var(--error-400-base);
}
.status:global(.disbursed) {
    background-color: var(--primary-400-base);
}

.iconDetail {
    width: 20px;
    height: 20px;
}
