.wrap {
    padding: 16px;
    border-radius: 12px;
    box-shadow: 1px 2px 16px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px var(--black-100);
    background-color: var(--whitebase);
    height: 100%;
    gap: 5px;
    flex-direction: row;
    display: flex;
}

.card {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.icon {
    width: 20px;
    height: 20px;
}

.title {
    font-size: 14px;
    font-weight: 500;
    color: var(--black-300);
    margin: 5px 0 8px;
}

.value {
    font-size: 20px;
    line-height: 1.3;
    font-weight: bold;
    color: #000;
    margin: 0;
    word-break: break-all;
}
