.root {
    display: flex;
}

.title {
    font-size: 37px;
    font-weight: bold;
    color: var(--black-base);
    line-height: 50px;
    margin-left: 10px;
}

.titleAlone {
    margin-left: 0;
}

.code {
    font-size: 24px;
    line-height: 32px;
    color: var(--primary-400-base);
    font-weight: 600;
    margin-left: 21px;
    margin-top: 8px;
}

.backButton {
    margin-right: 30px;
    width: 50px;
    height: 50px;
}

.search {
    width: 530px;
    max-width: 530px;
    margin-left: 20px;
}

.headerWrap {
    display: flex;
    margin-bottom: 40px;
    width: 100%;
}

.headerLeftWrap {
    display: flex;
}

.headerRightWrap {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.headerTitle {
    font-size: 24px;
    color: #212121;
    font-weight: bold;
    margin-left: 20px;
    margin-bottom: 0;
}

.headerRightTitle {
    font-weight: 800;
    color: var(--black-base);
    font-size: 24px;
    margin-bottom: 0;
}

.headerRightInfo {
    display: flex;
    margin-top: 10px;
}

.headerRightInfo__title {
    color: var(--black-200);
    font-weight: 600;
    font-size: 18px;
    margin-right: 10px;
    margin-bottom: 0;
}

.headerRightInfo__code {
    color: var(--primary-400-base);
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 0;
}

.flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.notificationWrap {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-left: 12px;
}

.notificationWrap__hasDrawer {
    margin-right: 60px;
}

@media (max-width: 768px) {
    .title {
        font-size: 20px;
        line-height: 14px;
    }

    .code {
        font-size: 16px;
        line-height: 18px;
    }
}
