.root {
    display: flex;
    min-width: 1000px;
}

.eventPreviewHeaderEditIcon {
    width: 25px;
    height: 25px;
}

.rootPanel {
    min-width: unset;
}

.viewOtherUserCalendar {
    margin: 20px 0px;
}
