.root {
    padding: 20px;
}
.title {
    font-size: 30px;
    font-weight: bold;
    color: var(--black-base);
}

.body {
    margin-top: 10px;
}

.footer {
    margin-top: 40px;
}
.buttons {
    display: flex;
    justify-content: space-between;
}
.button:last-child {
    margin-left: 24px;
}
