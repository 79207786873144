.cardWrap {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    box-shadow: 0 4px 24px 0 rgba(48, 49, 51, 0.16);
    background-color: var(--whitebase);
    padding: 10px 12px;
    height: 100%;
}

.titleWrap {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--primary-300);
}

.iconSize {
    width: 30px;
}

.cardValue {
    font-size: 64px;
    font-weight: 600;
    margin: auto;
    text-align: center;
    color: var(--primary-400-base);
}

.cardTitle {
    font-size: 21px;
    font-weight: bold;
    color: var(--black-200);
    margin-bottom: 0;
    padding-left: 10px;
}

.buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
}

.buttonLabel {
    color: var(--black-base);
    font-size: 16px;
    font-weight: 500;
    padding-right: 8px;
}
