.wrapper {
    width: 72px;
    max-width: 72px;
    min-width: 72px;
    background-color: var(--primary-400-base);
    padding: 26px 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.reverse {
    flex-flow: column-reverse;
}

.item {
    margin-top: 13px;
}

.list {
    padding: 0;
    list-style: none;
    margin: 0;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    width: 100%;
    align-items: center;
}

.button {
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: var(--primary-100);
    position: relative;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    outline: none;
}

.icon {
    width: 36px;
    height: 36px;
    fill: var(--primary-400-base);
}

.button:hover {
    background-color: var(--whitebase);
}

.button:focus {
    box-shadow: 0 0 0 2px var(--secondary-400-base);
    background-color: var(--whitebase);
}

.button:disabled {
    cursor: auto;
}

.button:disabled .icon {
    fill: var(--black-100);
}

.buttonAttention {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--secondary-400-base);
    top: -8px;
    right: -8px;
    font-family: "Poppins", sans-serif;
    font-size: 9px;
    font-weight: 600;
    line-height: 10px;
    color: var(--whitebase);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.buttonTransparent {
    background-color: transparent !important;
}

.hidden {
    display: none;
}

.divider {
    width: 100%;
    height: 1px;
    margin: 19px 0px;
    background-color: var(--primary-200);
}

.iconItem {
    width: 24px;
    height: 24px;
}

@media (max-width: 768px) {
    .wrapper {
        width: 40px;
        max-width: 40px;
        min-width: 40px;
    }

    .button {
        width: 24px;
        height: 24px;
        padding: 4px;
    }
}
