.errorContainer {
    display: flex;
    align-items: center;
    color: var(--error-400-base);
}

.errorIcon {
    width: 22px;
    height: 22px;
    margin-right: 8px;
}

.errorCode {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
