.wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 40px;
}

.iconWrap {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e9f4ff;
    border-radius: 50%;
    margin: 0 auto;
}

.icon {
    width: 40px;
    height: 40px;
}

.content {
    font-size: 22px;
    font-weight: bold;
    color: var(--black-base);
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: center;
}

.action {
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
}

.listStudents {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    margin-bottom: 40px;
}

.listStudentHeader {
    margin-top: 20px;
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
}

.totalStudent {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 24px;
    font-weight: bold;
}

.totalStudent__count {
    color: var(--primary-400-base);
}

.totalStudent__title {
    color: var(--black-200);
}

.rightHeader {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 6px;
}

.rightHeader__title {
    font-size: 16px;
    font-weight: 600;
    color: var(--black-200);
}
.studentsBody {
    margin-top: 10px;
    padding-right: 15px;
}

.formItem {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 10px;
    width: 100%;
}

.selectTime {
    display: flex;
    flex: 1;
    width: 100%;
}

.zone {
    display: flex;
    background-color: white;
    border-radius: 6px;
    height: 44px;
    padding: 4px 11px 4px;
    justify-content: center;
    flex-direction: column;
    flex: 1;
}
