.wrap {
    padding: 0 40px 60px;
}

.title {
    font-size: 37px;
    font-weight: bold;
    color: #666666;
    padding-bottom: 14px;
    border-bottom: 2px solid #62b1ff;
    margin-bottom: 60px;
}

.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-radius: 12px;
    box-shadow: 0 4px 24px 0 rgba(48, 49, 51, 0.16);
    padding: 48px 20px;
}

.iconWrap {
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e9f4ff;
    border-radius: 50%;
    cursor: pointer;
}

.text {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.33;
    color: #212121;
    margin-top: 30px;
}

.actionWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
}

@media (max-width: 768px) {
    .wrap {
        padding: 0;
    }

    .title {
        font-size: 16px;
        padding-bottom: 5px;
        margin-bottom: 10px;
    }

    .actionBtnWrap {
        margin-top: 10px;
    }
}
