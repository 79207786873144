.button {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 4px;
    height: 16px;
    min-width: 16px;
    background-color: transparent;
    cursor: pointer;
    outline: none;
}

.icon {
    fill: var(--black-200);
    height: 16px;
    width: 16px;
}

.button:active .icon {
    fill: var(--black-300);
}

.button:focus {
    outline: none;
}

.button:active {
    outline: none;
}

.button:disabled .icon {
    fill: var(--black-100);
    cursor: auto;
}

.button:focus {
    box-shadow: 0 0 0 2px var(--primary-500);
}
