.titleTable {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-300);
}

.row {
    display: flex;
    flex-direction: row;
}
