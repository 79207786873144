.buttonWrap {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.newItemText {
    font-size: 24px;
    font-weight: bold;
    color: #212121;
    margin-bottom: 10px;
}
