.container {
    display: flex !important;
    flex-direction: column;
    background-color: #f5f5f5;
    padding-top: 70px;
    padding-bottom: 70px;
    justify-content: center;
    align-items: center;
}

.wrap {
    padding: 0 40px;
}

.header {
    font-size: 16px;
    color: var(--black-300);
    font-weight: 600;
    margin-bottom: 0;
}
.subHeader {
    font-size: 16px;
    color: var(--black-300);
    font-weight: 600;
    margin-bottom: 20px;
    opacity: 0.5;
}
.rowInput {
    width: 50%;
}

.title {
    font-size: 37px;
    font-weight: 800;
    color: var(--black-300);
    margin-top: 14px;
    margin-bottom: 14px;
    border-bottom: 1px solid var(--primary-300);
}

.subTitle {
    font-size: 37px;
    font-weight: 800;
    color: var(--black-200);
    margin-top: 14px;
    margin-bottom: 14px;
    border-bottom: 1px solid var(--primary-300);
    -webkit-backdrop-filter: blur(24px);
    backdrop-filter: blur(24px);
}
.submitContainer {
    margin-top: 40px;
    width: 50%;
}

.fieldTitle {
    margin-top: 15px;
}
.textError {
    font-size: 13px;
    color: var(--error-400-base);
    margin-bottom: 0px;
}
.errorOptionsList {
    margin-top: 15px;
    padding: 10px;
    width: 50%;
    border-radius: 5px;
    background: var(--error-100);
}
