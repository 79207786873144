.autocompleteWrapper {
    width: 100%;
    z-index: 5;
    position: relative;
    /*
    display: inline-block;*/
}
.autocompleteWrapper.error {
    border: 1px red solid;
}

.autocompleteWrapper > div {
    width: 100%;
}

.autocompleteWrapper input {
    border: 1px solid var(--whitebase);
    padding: 12px 15px;
    font-size: 14px;
    width: 100%;
    border-radius: 6px;
    box-shadow: 0 2px 12px 0 rgba(48, 49, 51, 0.1);
    background-color: var(--whitebase);
}

.autocompleteWrapper input:focus {
    border-color: var(--primary-400-base);
    box-shadow: 0 2px 12px 0 rgba(48, 49, 51, 0.1);
    outline: none;
}

.autocompleteWrapper .dropdown {
    width: 100%;
    padding: 0;
    text-align: left;
    max-height: 280px;
    overflow: hidden;
    overflow-y: auto;
    background-color: var(--whitebase);
    border: 1px solid var(--primary-200);
    border-top: none;
    position: absolute;
}

.autocompleteWrapper .item {
    /*    display: block;
*/
    cursor: pointer;
    font-size: 14px;
    padding: 15px;
}

.autocompleteWrapper .item.selectedItem {
    background-color: var(--primary-400-base);
    color: var(--whitebase);
}

.autocompleteWrapper .item:hover {
    background-color: var(--primary-400-base);
    color: var(--whitebase);
}
