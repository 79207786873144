.wrap {
}

.headerTitle {
    margin-bottom: 0;
    padding: 8px 12px;
    border-radius: 8px;
    background-color: #f5f5f5;
    font-size: 21px;
    font-weight: bold;
    color: var(--black-300);
}

.actionWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.actionWrap > * {
    margin-left: 16px;
}

.status {
    min-width: 80px;
    display: inline-block;
    padding: 2px 8px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    color: var(--whitebase);
    text-align: center;
    word-break: keep-all;
    white-space: nowrap;
}

.status:global(.pending) {
    background-color: var(--black-200);
}
.status:global(.in_progress) {
    background-color: var(--warning-400-base);
}
.status:global(.completed) {
    background-color: var(--success-400-base);
}

.paymentsContainer {
    width: 100%;
    background-color: #f5f5f5;
    padding-left: 64px;
}

.paymentsContainer .paymentTitle {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.43;
    text-align: left;
    color: var(--black-200);
    margin-left: 8px;
}

.paymentsContainer table thead {
    background: none !important;
}

.paymentsContainer table thead th {
    font-weight: 500;
}

.input {
    width: 100px;
    border: solid 2px #e5e5e5;
    outline: none;
    padding: 2px 8px;
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: normal;
    color: var(--black-base);
}

.notes {
    max-width: 350px;
}

.nameWrap {
    display: flex;
    align-items: center;
}

.lockIconWrap {
    margin-right: 10px;
}

.pastDue {
    color: var(--error-400-base) !important;
}

.due {
    color: var(--warning-400-base) !important;
}
