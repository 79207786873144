.iconWrap {
    display: flex;
    cursor: pointer;
    align-items: center;
}

.icon {
    width: 20px;
    height: 20px;
}

.unClickable {
    cursor: default;
}
