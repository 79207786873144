.wrap {
    padding: 0 40px;
}

.title {
    font-size: 37px;
    font-weight: 800;
    color: var(--black-300);
    margin-top: 14px;
    margin-bottom: 14px;
    border-bottom: 1px solid var(--primary-300);
}
.submitContainer {
    display: flex;
    margin-top: 40px;
    align-self: stretch;
    flex-direction: row;
    justify-content: flex-end;
}
