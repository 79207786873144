.title {
    font-size: 16px;
    line-height: 1.75;
    color: #212121;
    margin-bottom: 4px;
}

.checkbox {
    margin-top: 16px !important;
    border-radius: 4px;
    border: solid 1px #1e90ff;
    padding: 8px !important;
}

.checkboxTitle {
    font-size: 16px;
    font-weight: 600;
    color: #212121;
}
