.tableWrap {
    margin-top: 20px;
}

.tableHeaderTitleWrap {
    display: flex;
    align-items: center;
}

.tableHeaderTitle {
    margin-bottom: 0;
    margin-left: 10px;
}

.dateIcon {
    width: 24px;
    height: 24px;
}

.timeIcon {
    width: 20px;
    height: 20px;
}

.gradeTypeWrap {
    display: flex;
    align-items: center;
}

.gradeTypeColor {
    width: 16px;
    height: 16px;
    margin: 4px 12px 4px 0;
    border-radius: 6px;
}

.gradeType {
    margin-bottom: 0;
    text-transform: capitalize;
}
