.termInfo {
    margin-bottom: 20px;
}

.termTitle {
    padding: 8px 20px 8px 17px;
    border-radius: 8px;
    background-color: #e5e5e5;
    font-size: 24px;
    font-weight: bold;
    color: var(--black-base);
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.arrowsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.headerIcon {
    height: 24px;
    width: 24px;
    cursor: pointer;
    padding: 2px;
}

.arrowButton {
    display: flex;
    border: none;
    background-color: transparent;
}

.dateInfo {
    color: var(--black-200);
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 0;
    min-width: 200px;
    text-align: center;
}

.fullCalendarContainer > div {
    max-height: 600px;
    margin-bottom: 20px;
}

.conflictTitle {
    font-size: 37px;
    font-weight: bold;
    line-height: 1.41;
    color: #666666;
    padding-bottom: 14px;
    border-bottom: 1px solid #62b1ff;
    margin-bottom: 30px;
}

.conflictDesc {
    font-size: 16px;
    color: #666666;
}

.scheduleItems {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

.scheduleItem {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    background-color: var(--primary-200);
    border: 2px solid rgb(255 255 255/0%);
    padding: 5px 18px;
    width: fit-content;
    color: var(--whitebase);
    font-size: 16px;
    font-weight: 600;
    border-radius: 4px;
    cursor: pointer;
}

.scheduleItemActive {
    background-color: var(--primary-500);
}

.scheduleItemDisabled {
    background-color: var(--black-100);
    cursor: not-allowed;
}

.conflictWrap {
    padding: 0 40px 60px;
}

.conflictTitle {
    font-size: 37px;
    font-weight: bold;
    color: #666666;
    padding-bottom: 14px;
    border-bottom: 2px solid #62b1ff;
    margin-bottom: 60px;
}
