.createBtnWrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.logsIcon {
    width: 20px;
    height: 20px;
}

.expandIcon {
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    transform: rotate(0deg);
}

.expandIcon svg {
    width: 16px;
    height: 16px;
}

.expandIcon.expanded {
    transform: rotate(180deg);
    transform-origin: center;
}

.rowWrap {
    padding: 6px 8px 8px;
    border-radius: 8px;
    border: solid 1px var(--black-100);
    background-color: var(--whitebase);
    margin: 8px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.rowTitleWrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: #000;
}

.rowFieldWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
}

.rowFieldTitle {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--black-200);
    text-transform: uppercase;
    white-space: nowrap;
}

.rowFieldValue {
    padding: 4px 8px;
    border-radius: 8px;
    background-color: #f5f5f5;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.29;
    color: var(--black-base);
    min-height: 26px;
    min-width: 32px;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
}
