.approveIcon path {
    fill: #18a957;
}

.rejectIcon path {
    fill: #df1642;
}

.printIcon path {
    fill: var(--primary-400-base);
}

.statusWrap {
    display: flex;
    align-items: center;
}

.status {
    width: 32px;
    margin-right: 12px;
}

.status__approved {
    border: solid 1px var(--success-400-base);
    color: var(--success-400-base);
    background-color: #fff;
}

.status__rejected {
    background-color: var(--error-400-base);
    color: var(--whitebase);
}

.statusInfo {
    display: flex;
    flex-direction: column;
}

.statusInfo__text {
    font-size: 14px;
    font-weight: 500;
    color: var(--black-300);
    margin-bottom: 4px;
}

.advisorsWrap {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.link {
    color: var(--primary-400-base);
    font-weight: bold;
}

.previewIcon {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    padding: 2px;
    display: flex;
    justify-items: center;
    cursor: pointer;
}
