.wrap {
    padding: 0 40px;
}

.title {
    font-size: 37px;
    font-weight: bold;
    line-height: 1.41;
    color: #666666;
    padding-bottom: 14px;
    border-bottom: 1px solid #62b1ff;
    margin-bottom: 30px;
}

.body {
}

.body__title {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
    color: #212121;
    margin-bottom: 16px;
}

.btnSave {
    margin-top: 24px;
}
