.fullnameWrap {
    display: flex;
    align-items: center;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    object-fit: contain;
}

.fullname {
    margin: 0 0 0 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tableContainer {
    max-height: 50vh;
}
