.colsPicker {
    position: absolute;
    right: 100px;
    top: 10px;
    background: #fff;
    z-index: 99;
    width: 230px;
    border-radius: 10px;
    box-shadow: 2px 4px 32px 0 rgba(0, 0, 0, 0.16);
    overflow: hidden;
}

.colsPickerTitle {
    font-size: 18px;
    display: flex;
    align-items: center;
    height: 40px;
    font-weight: 800;
    color: var(--black-base);
    background-color: #f5f5f5;
    padding: 0px 20px;
    margin: 0;
    text-transform: uppercase;
}

.colsPickerBody {
    padding: 12px 20px;
    overflow: auto;
    max-height: 400px;
}
