.statsContainer {
    padding: 16px;
    border-radius: 12px;
    box-shadow: 0 4px 24px 0 rgba(48, 49, 51, 0.16);
    background-color: var(--whitebase);
    margin: 40px 0px;
}

.statsContent {
    border-radius: 12px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 16px;
    height: 100%;
}

.canClickable {
    cursor: pointer;
}

.statsContent.statsGroupContainer {
    border: solid 1px var(--black-100);
    background-color: var(--whitebase);
    padding: 5px 3px;
}

.statsGroupContent {
    width: 100%;
}

.statsLabel {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    color: var(--black-200);
}

.statsValue {
    font-size: 35px;
    font-weight: bold;
    text-align: center;
    color: #000;
    line-height: 1.2;
}

.statsSmall .statsLabel {
    font-size: 14px;
}

.statsSmall {
    padding: 8px 0px;
}

.statsSmall .statsValue {
    color: var(--black-300);
    line-height: 35px;
}

.statsGroupContainerLabelGroup {
    margin-top: 2px;
}

.isActivated {
    font-size: 14px;
    color: var(--black-200);
}
