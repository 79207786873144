.studentItem {
    display: flex;
    align-items: center;
    padding: 8px;
    padding-right: 16px;
}

.checkboxCol {
    width: 10%;
    min-width: 10%;
}

.studentInfo {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 60%;
}

.studentItem__img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.studentItem__info {
    font-size: 16px;
    font-weight: 500;
    color: var(--black-base);
    flex: 1;
}

.studentItem__iconWrap {
    cursor: pointer;
    padding: 2px;
}

.studentItem__icon {
    width: 32px;
    height: 32px;
}

.registerWrap {
    width: 30%;
    display: flex;
    align-items: center;
    gap: 8px;
    padding-left: 6px;
}

.checkedIcon {
    width: 20px;
    height: 20px;
}
