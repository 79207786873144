.hourItemWrap {
    display: flex;
    flex-direction: column;
    margin-bottom: 2px;
    margin-top: 9px;
}

.hourItem {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex: 1;
}

.hourItemLeft {
    margin-right: 30px;
}

.hourTitleWrap {
    flex: 0.5;
    display: flex;
    align-items: center;
}

.hourTitle {
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 500;
    margin-right: 10px;
    color: var(--black-300);
}

.hourDescription {
    display: flex;
    margin-bottom: 0;
    text-align: right;
    font-size: 14px;
    font-weight: 500;
    color: var(--black-base);
}
.dateContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
}
