.headerBody {
    display: flex;
    flex: 1;
    border-radius: 4px;
    border: solid 1px #1e90ff;
    padding: 4px 12px;
}

.headerBodyContent {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.headerBodyContent__title {
    font-size: 16px;
    font-weight: 600;
    color: #666666;
    margin-bottom: 8px;
}

.headerActionWrap {
    display: flex;
}

.headerActionItem {
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-color: #a5d3ff;
    margin-right: 20px;
    cursor: pointer;
}

.headerActionItemActive {
    background-color: #1e90ff;
}

.headerActionItem__icon {
    width: 36px;
    height: 36px;
}

.headerRightAction {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.saveBtn {
    width: 100%;
    align-items: center;
    justify-content: center;
}
