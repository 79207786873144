.tableIcon {
    width: 15px;
    height: 15px;
}

.indicator {
    position: absolute;
    right: -2px;
    top: -2px;
    width: 8px;
    height: 8px;
    background: red;
    border: solid 2px white;
    border-radius: 50%;
}
