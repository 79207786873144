.tableWrap {
    position: relative;
}

.horizontalTable {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 1px;
    position: relative;
    width: 100%;
    max-height: 70vh;
}

.horizontalTableHeaderStructure {
    height: 56px;
    min-height: 56px;
    position: relative;
    background: transparent;
}
.loading {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    height: 100%;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.04);
    top: 0px;
    left: 0px;
    padding-top: 24px;
    width: 100%;
}
.headerStructureLeft {
    position: absolute;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: stretch;
    height: 56px;
}

.headerLeft {
    position: relative;
    color: var(--black-300);
    font-size: 18px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    box-shadow: 8px 0 8px 0 rgba(0, 0, 0, 0.12);
    background-color: var(--primary-100);
    color: #939393;
    /* border-left: 1px solid var(--black-200); */
    /* border-right: 1px solid var(--black-200); */
}

.headerStructureRight {
    height: 56px;
    position: absolute;
    right: 0;
}

.headerStructureScrollable {
    scrollbar-width: none;
    overflow-y: hidden;
    min-width: 1px;
    overflow-x: auto;
    transform: translate3d(0, 0, 0);
    width: 100%;
}

.headerStructureScrollContent {
    display: flex;
    align-items: center;
    height: 56px;
}

.horizontalScrollPlaceholder {
    flex: 0 0 auto;
    visibility: hidden;
    height: 1px;
}

.horizontalScrollWrapper {
    position: absolute;
    right: 15px;
    bottom: 0;
    left: 0;
    z-index: 2;
    height: 16px;
}

.horizontalScrollbar__scrollable {
    min-width: 1px;
    overflow-x: auto;
    width: 100%;
    height: 100%;
}

.horizontalScrollbar__Content {
    height: 1px;
}

.headerRight {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    position: relative;
    height: 100%;
    background-color: var(--primary-100);
}

.headerRight__title {
    user-select: none;
    margin-bottom: 0;
    font-size: 16px;
    color: #939393;
    min-width: 40px;
}

.verticalScroll {
    flex: 1;
    overflow-x: hidden;
    padding-bottom: 1px;
    position: relative;
    transform: translate3d(0, 0, 0);
    min-height: 1px;
    overflow-y: auto;
}

.horizontalScroll {
    min-height: 100%;
    min-width: 1px;
    overflow-y: hidden;
    overflow-x: hidden;
}

.rowGroup {
    will-change: contents;
    overflow: unset;
    background: transparent;
}

.rowHeaderGroup {
    will-change: contents;
    overflow: unset;
}

.rowItem {
    contain: style;
    display: flex;
    height: 40px;
}

.stickyCell {
    display: flex;
    position: absolute;
    box-shadow: 8px 0 8px 0 rgba(0, 0, 0, 0.12);
    left: 0;
    z-index: 1;
}

.cell {
    display: flex;
    align-items: center;
    position: relative;
    height: 40px;
    overflow: hidden;
    background: #fff;
    border-bottom: 1px solid var(--black-200);
}

.evenCell {
    background-color: #fbfbfb !important;
}

.statusGroupWrap {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    position: relative;
}

.statusGroupTitle {
    flex: 1;
    font-size: 18px;
}

.itemWrap {
    display: flex;
    width: 100%;
    height: 100%;
}

.item {
    flex: 1;
    padding: 0 5px;
    border-left: solid 1px var(--black-200);
    border-right: 1px solid var(--black-200);
    color: var(--black-200);
    font-size: 16px;
    display: flex;
    align-items: center;
}

.total__text {
    font-size: 16px;
    color: #212121;
}

.resizeDragHandle {
    width: 10px;
    height: 100%;
    cursor: ew-resize;
    position: absolute;
    right: -6px;
    top: 0;
    z-index: 100;
}

.virtualResizeLine {
    width: 2px;
    height: calc(100vh - 110px);
    background: #1e90ff;
}

.colsPickerHeader {
    left: unset;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    height: 32px;
    background: var(--primary-100);
}

.menuActionWrap {
    left: unset;
    right: 0px;
    height: 40px;
}

.plusIconBtn {
    background: transparent !important;
    border: none !important;
}

.iconPlus {
    height: 24px;
}

.colsPicker {
    position: absolute;
    background: #fff;
    z-index: 99;
    width: 230px;
    right: 50px;
    top: 10px;
    border-radius: 10px;
    box-shadow: 2px 4px 32px 0 rgba(0, 0, 0, 0.16);
    overflow: hidden;
}

.closeColsPickerIcon {
    position: absolute !important;
    top: 2px;
    right: 8px;
    border: 0 !important;
    width: 20px !important;
    height: 20px !important;
}

.closeIcon {
    width: 19px;
    height: 19px;
}

.colsPickerTitle {
    font-size: 14px;
    display: flex;
    align-items: center;
    height: 40px;
    font-weight: 800;
    color: var(--black-base);
    background-color: #f5f5f5;
    padding: 0px 20px;
    margin: 0;
    text-transform: uppercase;
}

.colsPickerDesc {
    font-size: 16px;
    color: #212121;
    margin-bottom: 10px;
}

.colsPickerDescAvailable {
    font-size: 16px;
    color: #212121;
    margin-top: 13px;
    margin-bottom: 10px;
}

.colsPickerBody {
    padding: 12px 20px;
    max-height: 400px;
    overflow: auto;
}
