.displayColor {
    width: 20px;
    height: 20px;
    border-radius: 6px;
    background-color: red;
}
.container {
    display: flex;
    padding-top: 14px;
    padding-bottom: 14px;
    flex: 1;
    justify-content: space-between;
    flex-direction: row;
}
.parent {
    position: relative;
    overflow: visible;
}
.icon {
    width: 18px;
    height: 18px;
}
.selectPicker {
    position: absolute;
    top: 52px;
    z-index: 1;
}
.backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    left: 0;
    top: 0;
}
