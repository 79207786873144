.wrap {
    padding: 0 40px;
}

.title {
    font-size: 37px;
    font-weight: bold;
    line-height: 1.41;
    color: #666666;
    padding-bottom: 14px;
    border-bottom: 1px solid #62b1ff;
    margin-bottom: 30px;
}

.body {
    overflow: auto;
    max-height: 70vh;
    padding: 0 10px;
}

.lessonTime {
    display: inline-block;
    border-radius: 12px;
    background-color: var(--primary-400-base);
    font-size: 24px;
    font-weight: bold;
    color: var(--whitebase);
    padding: 8px 16px;
    margin-bottom: 24px;
}

.lessonSettingWrap {
    border-radius: 12px;
    background-color: #f5f5f5;
    padding: 20px 22px;
    margin-bottom: 24px;
}

.lessonSetting__startTime {
    padding-bottom: 20px;
    border-bottom: 2px solid var(--black-200);
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: center;
    align-items: center;
}
.checkInfoWrap {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
    margin-bottom: 20px;
}

.checkInfoItem {
    border-radius: 12px;
    background-color: var(--primary-200);
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 8px 16px;
    cursor: pointer;
}

.checkInfoItem__active {
    background-color: var(--primary-400-base);
}

.checkInfoItem__title {
    flex: 1;
    font-size: 17px;
    font-weight: bold;
    color: var(--primary-400-base);
    padding-right: 5px;
}

.checkInfoItem__icon {
    width: 28px;
    height: 28px;
}

.checkInfoItem__title__active {
    color: var(--whitebase);
}

.listStudents {
    margin-bottom: 40px;
}

.listStudentHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.totalStudent {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 24px;
    font-weight: bold;
}

.totalStudent__count {
    color: var(--primary-400-base);
}

.totalStudent__title {
    color: var(--black-200);
}
.selectTime {
    display: flex;
    flex: 1;
}
.zone {
    display: flex;
    background-color: white;
    border-radius: 6px;
    height: 44px;
    padding: 4px 11px 4px;
    justify-content: center;
    flex-direction: column;
    flex: 1;
}
.rightHeader {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 6px;
}

.rightHeader__title {
    font-size: 16px;
    font-weight: 600;
    color: var(--black-200);
}

.studentsBody {
    margin-top: 10px;
    padding-right: 15px;
}

.action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
