.title {
    font-size: 24px;
    color: #212121;
    font-weight: bold;
    margin-left: 20px;
    margin-bottom: 40px;
}

.screenWrap {
    display: flex;
    gap: 5px;
}

.expandedSidebar {
    width: 320px;
    min-width: 320px;
    background-color: #fbfbfb;
    transition: 0.3s;
    border-left: solid 1px var(--black-100);
}

.hidden {
    display: none;
}
