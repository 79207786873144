.root {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--black-100);
    box-sizing: border-box;
}

.root svg {
    width: 100%;
    height: 100%;
}

.rootSuccess {
    color: var(--primary-400-base);
}

.rootFailure {
    color: var(--black-100);
}

.rootWarning {
    background-color: var(--warning-100);
    padding: 4px;
    width: 26px;
    height: 26px;
}
