.termCourseSectionHeader {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.termCourseSectionHeader__title {
    margin: 0 0 0 10px;
    font-weight: bold;
    font-size: 18px;
    color: var(--black-300);
    max-width: 230px;
    white-space: normal;
    word-break: break-word;
}

.termCourseSectionHeader__action {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.sectionHourInfo {
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: solid 1px var(--primary-500);
    height: 32px;
    padding: 2px 16px;
    margin-right: 12px;
}

.sectionHourInfoTitle {
    font-size: 16px;
    font-weight: 600;
    margin-right: 12px;
    color: var(--primary-500);
}

.sectionHourInfoValue {
    font-size: 18px;
    font-weight: bold;
    color: var(--primary-700);
}

.iconBtn {
    margin-right: 10px;
    border: none !important;
    background: transparent !important;
}

.menuActionWrap {
    padding: 17px;
}

.menuActionIcon {
    width: 24px;
    height: 24px;
}

.menuActionTitle {
    margin-left: 11px;
}
