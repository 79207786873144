.backIcon {
    width: 48px;
    height: 48px;
}

.actionWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.actionWrap > * {
    margin-left: 16px;
}

.statusWrap {
    display: flex;
    align-items: center;
}

.status {
    display: inline-block;
    padding: 0 8px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    color: var(--whitebase);
}

.status:global(.active) {
    background-color: var(--success-400-base);
}

.status:global(.packaged) {
    background-color: var(--primary-400-base);
}

.status:global(.estimated) {
    background-color: var(--black-200);
}

.statusInfo {
    display: flex;
    flex-direction: column;
}

.statusInfo__text {
    font-size: 14px;
    font-weight: 500;
    color: var(--black-300);
    margin-bottom: 4px;
}

.cfpContainer {
    padding: 24px;
    border-radius: 24px;
    border: solid 2px var(--black-100);
    background-color: #fbfbfb;
    margin-top: 40px;
}

.createIcon {
    padding: 20px;
    border-radius: 24px;
    background-color: var(--primary-100);
    margin-bottom: 40px;
}

.createIcon svg {
    display: block;
    width: 40px;
    height: 40px;
}

.progressText {
    margin-bottom: 4px;
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    text-align: right;
    color: var(--black-300);
}

.progressContainer {
    display: flex;
    flex-direction: row;
}

.progressBar {
    flex: 1;
    height: 16px;
    margin: 0 1px;
    border-radius: 6px;
    background-color: var(--black-100);
}

.progressBar.active {
    background-color: var(--primary-400-base);
}

.cfpTextTitle {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
    color: var(--black-300);
}

.cfpTextHeading {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    color: #000;
}

.cfpTextBody {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--black-300);
}

.cfpTextBodyBold {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    color: var(--black-300);
}

.cfpTextDescription {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--black-200);
}

.cfpSectionTitle {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 8px;
    background-color: var(--black-100);
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    color: var(--black-300);
    margin-top: 16px;
}

.agreementIcon {
    padding: 8px;
    border-radius: 12px;
    background-color: var(--primary-100);
}

.agreementIcon svg {
    display: block;
    width: 24px;
    height: 24px;
}

.checkbox {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    border-radius: 4px;
    border: solid 1px #e5e5e5;
    background-color: var(--whitebase);

    font-size: 18px;
    font-weight: 600;
    line-height: 1.56;
    color: var(--black-base);
    text-transform: uppercase;
}

.checkbox > * + * {
    margin-right: 0;
    margin-left: 12px;
}

.checkbox label::before {
    border-color: #e5e5e5;
}

.checkbox.active {
    border: solid 1px var(--primary-400-base);
}

.checkbox.active label::before {
    border-color: var(--primary-400-base);
}

.radioBtnWrap {
    display: flex !important;
    border: solid 2px #1e90ff;
    border-radius: 8px;
    width: 200px;
}

.radioItem {
    border-right: 1px solid #1e90ff;
    padding: 8px 12px !important;
    font-size: 16px !important;
    font-weight: 600;
    line-height: 1.33 !important;
    color: #212121 !important;
    margin: 0 !important;
    flex: 1;
}

.radioItem:last-child {
    border-right: 1px solid transparent;
}

.shortInput {
    width: 350px !important;
}

.sectionDivider {
    margin: 0 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 1.5 !important;
    color: var(--black-300) !important;
}

.sectionDivider::before {
    width: 0 !important;
}

.sectionDivider :global(.ant-divider-inner-text) {
    padding-left: 0 !important;
}
