.listContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.card {
    display: flex;
    flex: 47%;
    max-width: 47%;
    border: 1px solid;
    margin: 10px;
    border-radius: 7px;
    flex-direction: column;
    color: #666;
    font-size: 13px;
    padding: 5px 10px;
    line-height: 20px;
}

.mediaDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #e9f4ff;
    width: 598px;
    height: 300px;
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
}

.mediaDivDetail {
    width: 510px;
    height: 270px;
}

.mediaImage {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: contain;
}

.detailsDiv {
    display: flex;
    flex-direction: column;
    flex: 3;
    padding: 10px;
}

.detailRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.uploadedBy {
    padding-right: 5px;
    font-size: 12px;
    font-weight: 500;
    color: #939393;
    margin-bottom: 0;
}

.title {
    font-size: 16px;
    font-weight: bold;
    color: #212121;
}

.description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0px;
    font-size: 14px;
    font-weight: 500;
    color: #666;
    height: 30px;
}

.headerCard {
    border: 1px solid;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    margin: 0px 10px;
    font-weight: bold;
    cursor: pointer;
}

.statsRow {
    margin-bottom: 20px;
}

.headerCardStudent {
    flex-direction: row;
    align-items: center;
    box-shadow: 0 4px 24px 0 rgb(48 49 51 / 16%);
    border: unset;
    padding: 15px;
}

.headerCardSpanStudent {
    display: flex;
    justify-content: unset;
    align-items: center;
    width: 100%;
    color: #666;
    font-size: 24px;
}

.headerCardValueStudent {
    font-size: 40px;
    color: #0f4880;
    background-color: #e9f4ff;
    border-radius: 5px;
    width: 164px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.paddingBottom {
    padding-bottom: 20px;
}

.actionWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 10px;
    padding-bottom: 20px;
}

.addButton {
    width: 140px;
    height: 40px !important;
    justify-content: center;
    font-size: 16px;
    margin-left: 20px;
}

.formGroup {
    margin-bottom: 32px;
}

.formGroupHalf {
    width: 48%;
}

.formGroup2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footerWrap {
    padding-top: 40px;
    display: flex;
    flex-direction: row;
}

.borderTop {
    border-top: 2px solid;
    border-color: #1e90ff;
    padding-top: 40px;
    margin-top: 40px;
}

.footerInfoWrap {
    margin-right: 20px;
}

.footerInfo {
    border-radius: 4px;
    background-color: #f5f5f5;
    padding: 7px 16px;
    font-size: 14px;
    font-weight: 500;
    color: #939393;
}

.actionDetailWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
}

.cancelBtn {
    margin-right: 40px;
}

.dropzoneWrap {
    flex: 1;
    width: 350px;
}

.dropzoneItem {
    margin-right: 10px;
    padding: 20px;
    outline: none;
    border: 1px dashed #cfcfcf;
    text-align: center;
}

.dropzone__text {
    font-size: 16px;
    color: #212121;
}

.dropzone__btn {
    margin: 0 auto;
}

.icon {
    fill: var(--primary-200);
    width: 22px;
    height: 22px;
    cursor: pointer;
    margin-right: 5px;
}

.iconType {
    fill: var(--primary-200);
    width: 80px;
    height: 80px;
}

.iconUpload {
    width: 32px;
    height: 32px;
}

.formGroup2Div {
    width: 48%;
}

.iconPlus {
    height: 24px;
}

.iconBtn {
    border: none !important;
    background: transparent !important;
}

.menuActionWrap {
    padding: 17px;
}

.menuActionItem {
    display: flex;
    align-items: center;
}

.menuActionIcon {
    width: 24px;
    height: 24px;
}

.menuActionTitle {
    margin-left: 11px;
}

.rowLoading {
    text-align: center;
    margin: 30px;
}

.listingTypeContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.listingIcon {
    margin: 5px;
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
}

.listingIconSelected {
    background-color: #e9f4ff;
}

.radioGroup {
    display: flex;
    border: 1px solid #1e90ff;
    border-radius: 6px;
}

.radioItem {
    padding: 10px !important;
    width: 100%;
    margin: 0px !important;
    font-weight: bold;
}

.radioItemBorderRight {
    border-right: 1px solid #1e90ff;
}

.videoImageThumbnail {
    display: none;
}

.emptyData {
    text-align: center;
}

.mediaPopupContainer {
    width: 95%;
    max-height: 85vh;
    text-align: -webkit-center;
}

.mediaPopupContainer > img {
    width: 100%;
    height: 100%;
    max-height: 76vh;
    object-fit: contain;
}

.mediaPopupContainer > div > iframe {
    max-height: 76vh;
}

.mediaDiv > span {
    height: 300px;
    display: flex;
    align-items: center;
}

.mediaPreviewContainer {
    width: 75vh;
    height: 50vh;
    display: flex;
    background-color: #e9f4ff;
    justify-content: center;
    align-items: center;
    position: relative;
}

.mediaPreviewContainer > div > iframe {
    max-height: 50vh;
}

.mediaPreviewClose {
    position: absolute;
    top: -28px;
    right: -10px;
    width: 30px;
    height: 30px;
}

.actionBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
}

.concernMeta {
    display: flex;
    gap: 6px;
    background: #f5f5f5;
    padding: 5px 16px;
    border-radius: 4px;
    color: #636363;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
}

.concernMeta .createdAt {
    color: #929292;
}

.concernActions {
    display: flex;
}

.isAnonymous {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid dodgerblue;
    padding: 10px 16px;
    border-radius: 4px;
}

.isReviewConfirm {
    border: 1px solid var(--black-100);
}

.marginBottom3 {
    margin-bottom: 1rem !important;
}

.comment:not(:last-child) {
    margin-bottom: 20px;
}

.commentMeta {
    text-transform: uppercase;
    margin-bottom: 8px;
    font-size: 13px;
    font-weight: 500;
}

.commentContent {
    padding: 10px;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
}

.borderY {
    border-top: 2px solid;
    border-bottom: 2px solid;
    border-color: #1e90ff;
    padding: 40px 0;
    margin: 40px 0;
}

.heading {
    font-size: 18px;
    font-weight: 800;
    color: var(--black-200);
    margin-bottom: 16px;
}

.status {
    background: #666;
    color: #fff;
    padding: 10px 12px;
    border-radius: 4px;
    line-height: 1.2;
    text-align: center;
    font-size: 14px;
}

.statusNew {
    background: #18a957;
}

.statusInProgress {
    background: #ff8a38;
}

.statCount {
    display: block;
    color: #939393;
    background: #e6e6e6;
    border-radius: 4px;
    line-height: 1.2;
    font-size: 20px;
    padding: 7px 15px;
}

.listStatus {
    border-radius: 5px;
    padding: 6px 20px;
    color: #fff;
    font-weight: bold;
}

.success400Base {
    background: var(--success-400-base);
}

.primary400Base {
    background: var(--primary-400-base);
}

.warning400Base {
    background: var(--warning-400-base);
}

.black300 {
    background: var(--black-300);
}

.rightActionBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.primary400Base {
    background: var(--primary-400-base);
}

.success500 {
    background: var(--success-500);
}

.reviewConfirm {
    color: #636363;
    font-weight: 600;
    margin-right: 20px;
}

.reviewContainer {
    display: flex;
    align-items: center;
}

.reviewDetails {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    font-size: 13px;
}

.anynomousUserDetails {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: var(--warning-100);
    color: var(--black-300);
    font-size: 14px;
    font-weight: 600;
    border-radius: 7px;
}

.anynomousUserDetailsIcon {
    background-color: var(--warning-400-base);
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 50%;
    padding: 3px;
}
