.termCode {
    color: #3e3636;
    font-weight: 600;
    margin-left: 6px;
}

.fundType {
    color: #aaaaaa;
    font-size: 13px;
    font-weight: 600;
    margin-left: 6px;
}
