.buttonWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
}

.addItemText {
    position: relative;
    cursor: pointer;
}

.addCircleBtn {
    height: 32px !important;
    width: 32px !important;
    margin-left: 10px;
}

.addCircleBtn svg {
    height: 20px !important;
}

.addItemText.disabled {
    pointer-events: none;
    color: #ccc !important;
}
