.wrap {
    position: relative;
}

.studentViewHeader {
    display: flex;
    margin-bottom: 30px;
}

.studentViewHeaderItem {
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    padding: 3px 16px;
    margin-right: 2px;
}

.studentViewHeaderItem__title {
    font-size: 14px;
    font-weight: 500;
    color: #939393;
    line-height: 1;
    margin-bottom: 2px;
}

.studentViewHeaderItem__value {
    font-size: 21px;
    font-weight: bold;
    line-height: 1.33;
    color: #666666;
    margin-bottom: 0;
}

.createdInfo {
    margin-top: 30px !important;
    margin-bottom: 0 !important;
}

@media (max-width: 768px) {
    .studentViewHeader {
        margin-bottom: 10px;
    }

    .studentViewHeaderItem__title {
        font-size: 8px;
        line-height: 10px;
    }

    .studentViewHeaderItem__value {
        font-size: 10px;
        line-height: 12px;
    }
}
