.mentions--singleLine .mentions__input:focus,
.mentions--singleLine .mentions__input {
    min-height: 14px;
    max-height: 180px !important;
    outline: none;
    border: none;
    margin: 10px 0 10px 10px !important;
}

.mentions--multiLine .mentions__control {
    scrollbar-color: #cdcdcd87 transparent; /* only for Firefox` */
    font-family: Poppins, sans-serif;
    font-size: 16px;
    color: var(--black-bace);
    max-height: 200px;
    display: grid;
}
.mentions--multiLine .mentions__highlighter {
    max-height: 200px;
    padding: 11px 0 11px 11px;
}
.mentions--multiLine .mentions__input {
    min-height: 14px;
    max-height: 180px !important;
    /* margin: 10px 0 10px 10px !important; */
    outline: none;
    border: none;
    overflow-y: scroll !important;
    width: 99% !important;
}

.mentions--multiLine div:nth-child(2) {
    position: absolute;
    left: 0% !important;
    top: unset !important;
    margin-bottom: 20px !important;
    bottom: 100% !important;
    overflow: visible;
    border-radius: 10px;
}

.mentions__suggestions__list {
    background-color: var(--whitebase);
    padding: 12px 0 !important;
    border-radius: 10px;
    box-shadow: 2px 4px 32px 0 rgba(0, 0, 0, 0.16);
    background-color: var(--whitebase);
    overflow: hidden;
    max-height: 500px;
    overflow-y: auto;
}
.mentions__suggestions__list::-webkit-scrollbar,
.mentions__input::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.mentions__suggestions__list::-webkit-scrollbar-thumb,
.mentions__input::-webkit-scrollbar-thumb {
    background: #cdcdcd87;
    border-radius: 10px;
}

.mentions__suggestions__item {
    padding: 5px 20px !important;
    display: flex;
}

.mentions__suggestions__item--focused {
    background-color: var(--primary-200);
}
.mentions__highlighter strong {
    background-color: var(--primary-100) !important;
    border-radius: 5px;
}

.mentions__mention {
    position: relative;
    z-index: 1;
    color: blue;
    text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white;
    text-decoration: underline;
    pointer-events: none;
}
