.container {
    display: flex;
    flex-direction: column;
    gap: 36px;
}

.section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
}

.sectionHeading {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

.title {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.33;
    color: var(--black-200);
}

.infoContainer {
    display: flex;
    flex-direction: row;
    gap: 12px;
    flex-wrap: wrap;
}

.info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    padding: 8px 16px;
    border-radius: 12px;
    border: solid 1px var(--black-100);
    background-color: #f5f5f5;
}

.valueWrap {
    display: flex;
    flex-direction: column;
    /* min-width: 100px; */
}

.valueTitle {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--black-200);
}

.value {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: #000;
}

.highlightContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
}

.highlightItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 16px 24px;
    border-radius: 12px;
    box-shadow: 1px 2px 16px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px var(--secondary-400-base);
    background-color: var(--whitebase);
    flex: 1;
}

.highlightIcon {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
}

.highlightContent {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.33;
    color: #000;
}

.chartContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    padding: 16px;
    border-radius: 12px;
    box-shadow: 1px 2px 16px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px var(--black-100);
    background-color: var(--whitebase);
    height: 100%;
}

.chartTitle {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    color: #000;
}

.divider {
    height: 1px;
    width: 100%;
    background-color: var(--black-100);
}

.chartArea {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
}

.itemTitle {
    font-size: 21px;
    font-weight: bold;
    line-height: 1.14;
    color: var(--black-200);
    /* min-height: 24px; */
}

.cardIcon {
    width: 40px;
    height: 40px;
    font-size: 40px;
    display: flex;
    align-items: center;
}

.paymentCard {
    padding: 16px;
    border-radius: 16px;
    overflow: hidden;
    border: solid 1px var(--black-100);
    background-color: #f5f5f5;
}

.paymentCard.due {
    border: solid 1px var(--secondary-400-base);
    background-color: var(--secondary-100);
}

.paymentLabel {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    color: var(--black-300);
    text-transform: uppercase;
}

.paymentValue {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    text-align: right;
    color: #000;
}

.contentWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}

.contentTitle {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--black-200);
    text-transform: uppercase;
    white-space: normal;
}

.contentValue {
    flex-shrink: 0;
    font-size: 14px;
    font-weight: 500;
    color: var(--black-base);
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
}

.attendanceIcon {
    width: 24px;
    height: 24px;
    min-width: 24px;
}

.cardWrap {
    border-radius: 12px;
    box-shadow: 1px 2px 16px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px var(--black-100);
    margin-top: unset;
    overflow: hidden;
    padding: 8px;
    flex: 1;
}

.fixedHeight {
    height: 300px;
    max-height: 300px;
}

.sapWrap {
    padding: 2px 6px;
    border-radius: 6px;
    background-color: var(--error-400-base);
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    text-align: center;
    color: var(--whitebase);
}

.sapGood {
    background-color: var(--primary-400-base);
}

.sapWarning {
    background-color: var(--warning-400-base);
}

.cardContainer {
    height: 100%;
    border-radius: 12px;
    box-shadow: 1px 2px 16px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px var(--black-100);
    background-color: var(--whitebase);
    position: relative;
}

.card {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 10px;
}

.comingSoon {
    position: absolute;
    width: 100%;
    top: 40%;
    left: 0;
    height: 64px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12), inset 0 -1px 3px 0 rgba(0, 0, 0, 0.12),
        inset 0 1px 2px 0 rgba(255, 255, 255, 0.25);
    border-style: solid;
    border-width: 1px;
    border-image-source: linear-gradient(to right, #ff7cbf, #c2337c);
    border-image-slice: 1;
    background-image: linear-gradient(to right, var(--warning-400-base), #e46e42 63%, #991f5d),
        linear-gradient(to right, #ff7cbf, #c2337c);
    transform: rotate(-5deg);
}

.comingSoonLabel {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.33;
    color: var(--whitebase);
    text-transform: uppercase;
}

.cardCensored {
    filter: blur(2px);
    -webkit-filter: blur(2px);
}

.blocked {
    position: absolute;
    width: 100%;
    top: 40%;
    left: 0;
    height: 124px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 12px;
    background-color: #df1642;
}

.blockedLabel {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    color: var(--whitebase);
    text-transform: uppercase;
}

.noticeWrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
    padding: 8px;
    border-radius: 12px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px var(--warning-400-base);
    background-color: var(--whitebase);
    min-height: 48px;
}

.noticeTextWrap {
    display: flex;
    flex-direction: column;
}

.noticeHeading {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--black-base);
}

.noticeDescription {
    font-size: 10px;
    font-weight: 500;
    line-height: 1.6;
    color: var(--black-300);
}
