.wrap {
}

.title {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
    color: #939393;
    margin-bottom: 8px;
}

.description {
    font-size: 16px;
    line-height: 1.75;
    color: #666666;
    margin-bottom: 8px;
}

.uploadIconWrap {
    display: inline-block;
    cursor: pointer;
}

.copyDigitalFormat {
    border-radius: 8px;
    background-color: #f5f5f5;
    padding: 8px 16px 23px;
    margin-top: 23px;
}

.copyDigitalFormatTitle {
    font-size: 16px;
    color: #666;
    margin-bottom: 8px;
}
