.wrap {
    padding: 0 40px;
}

.title {
    font-size: 37px;
    font-weight: bold;
    color: var(--black-300);
    padding-bottom: 18px;
    margin-bottom: 24px;
    border-bottom: 1px solid var(--primary-300);
}

.courseWrap {
    border-radius: 12px;
    background-color: #f5f5f5;
    padding: 8px 16px;
    margin-bottom: 24px;
}

.courseInfo {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.courseName {
    font-size: 37px;
    font-weight: bold;
    color: var(--black-base);
    margin-right: 14px;
}

.courseCode {
    font-size: 24px;
    font-weight: bold;
    color: var(--primary-400-base);
}

.otherInfo {
    display: flex;
    margin-bottom: 4px;
}

.otherInfoTitle {
    font-size: 16px;
    font-weight: 600;
    color: var(--black-200);
    text-transform: uppercase;
    width: 200px;
}

.otherInfoValue {
    font-size: 18px;
    font-weight: bold;
    color: var(--black-base);
    text-transform: uppercase;
    flex: 1;
}

.timeInfo {
    border-radius: 12px;
    background-color: var(--black-200);
    display: inline-block;
    padding: 12px 18px;
    font-size: 21px;
    font-weight: bold;
    color: var(--whitebase);
    margin-bottom: 24px;
}

.selectionWrap {
    margin-bottom: 40px;
}

.radioBtnWrap {
    display: flex !important;
    border: solid 1px #1e90ff;
    border-radius: 8px;
    width: 100%;
}

.radioBtnItem {
    border-right: 1px solid #1e90ff;
    padding: 12px !important;
    font-size: 16px !important;
    font-weight: bold;
    line-height: 1.33 !important;
    color: #939393 !important;
    margin: 0 !important;
    flex: 1;
}

.radioBtnItem:last-child {
    border-right: 1px solid transparent;
}

.radioBtn__active {
    color: #212121 !important;
}

.actionWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 40px;
}

.studentInfoWrap {
    border-radius: 12px;
    background-color: #f5f5f5;
    padding: 8px;
    display: flex;
    margin-bottom: 32px;
}

.studentInfoImageWrap {
    margin-right: 24px;
}

.studentInfoImage {
    border-radius: 12px;
    width: 120px;
    height: 120px;
}

.studentInfoBody {
}

.studentName {
    font-size: 24px;
    font-weight: bold;
    color: var(--black-base);
    margin-bottom: 12px;
}

.studentOtherInfo {
}

.studentOtherInfoItem {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
}

.studentOtherInfoTitle {
    font-size: 16px;
    font-weight: 600;
    color: var(--black-200);
    width: 150px;
}

.studentOtherInfoValue {
    font-size: 18px;
    font-weight: bold;
    color: var(--black-base);
    flex: 1;
    text-align: right;
}

.studentId {
    color: var(--primary-400-base);
}
