.createBtnWrap {
    display: flex;
    justify-content: flex-end;
}

.plusIcon {
    height: 24px;
    width: 24px;
    margin-right: 6px;
}
.assignedTo {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.enrollmentIdItem {
    border-radius: 2px;
    background: var(--primary-600);
    color: var(--whitebase);
    padding: 2px 4px;
}
.applicantIdItem {
    border-radius: 2px;
    background: var(--secondary-400-base);
    color: var(--whitebase);
    padding: 2px 4px;
}
