/* table */

/* filters */

.filterItem {
    margin-bottom: 32px;
}

.filterDivider {
    background: var(--whitebase);
    height: 3px;
    margin: 6px 0 24px 0 !important;
}

/* add button */

.createBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.iconPlus {
    height: 24px;
}

/* bottom actions */

.actions {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 80px;
}

.actions > * + * {
    margin-right: 0;
    margin-left: 40px;
}

.actions__text {
    padding: 7px 16px;
    border-radius: 4px;
    background-color: #f5f5f5;
    font-size: 16px;
    line-height: 1.13;
    color: var(--black-200);
}

.actions__cancel {
    padding: 7px 17px !important;
    height: auto !important;
}

/* form */

.error {
    border: 1px solid var(--error-400-base) !important;
}

.errorSelect {
    border: 1px solid var(--error-400-base) !important;
    border-radius: 6px !important;
}

.errorSelect > div {
    box-shadow: none !important;
}

.menuActionWrap {
    padding: 17px;
}

.menuActionItem {
    display: flex;
    align-items: center;
    padding: 8px 30px !important;
}

.menuActionItem:hover {
    background-color: var(--primary-100) !important;
}

.menuActionIcon {
    height: 24px;
    width: 24px;
}

.moreIcon {
    width: 18px;
    height: 18px;
    /*margin-left: 6px;*/
    margin-top: 4px;
}

.menuActionTitle {
    margin-left: 16px;
}

.radioGroup {
    display: inline-flex !important;
    border: solid 1px var(--primary-400-base);
    border-radius: 8px;
}

.radioGroup__item {
    border-right: 1px solid var(--primary-400-base);
    padding: 12px !important;
    font-size: 16px !important;
    font-weight: bold;
    line-height: 1.33 !important;
    color: var(--black-200) !important;
    margin: 0 !important;
    text-transform: uppercase;
}

.radioGroup__item:last-child {
    border-right: 1px solid transparent;
}

.radioGroup__item.active {
    color: var(--black-base) !important;
}

.checkbox {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    border-radius: 4px;
    border: solid 1px #e5e5e5;
    background-color: var(--whitebase);

    font-size: 18px;
    font-weight: 600;
    line-height: 1.56;
    color: var(--black-base);
}

.checkbox > * + * {
    margin-right: 0;
    margin-left: 12px;
}

.checkbox label::before {
    border-color: #e5e5e5;
}

.checkbox.active {
    border: solid 1px var(--primary-400-base);
}

.checkbox.active label::before {
    border-color: var(--primary-400-base);
}

.checkboxGroup {
    border-radius: 6px;
    border: solid 1px var(--primary-200);
}

.checkboxGroup :global(.ant-checkbox-group-item) {
    padding: 10px 40px;
    margin-right: 0 !important;
    border-right: solid 1px var(--primary-200);
}

.checkboxGroup :global(.ant-checkbox-group-item):last-child {
    border-right: none;
}

.checkboxButtonGroup {
    margin: -12px -20px !important;
}

.checkboxButtonGroup :global(.ant-radio-button-wrapper) {
    margin: 12px 20px;
    width: calc(50% - 40px);
    height: auto !important;
    padding: 10px 24px;
    border-radius: 8px !important;
    border: solid 1px #e5e5e5 !important;
    background-color: var(--whitebase) !important;

    font-size: 24px;
    font-weight: bold;
    line-height: 1.17;
    color: var(--black-300);
}

.checkboxButtonGroup :global(.ant-radio-button-wrapper):hover {
    color: var(--black-300) !important;
}

.checkboxButtonGroup :global(.ant-radio-button-wrapper)::before {
    display: none !important;
}

.checkboxButtonGroup :global(.ant-radio-button-wrapper.ant-radio-button-wrapper-checked) {
    background-color: var(--primary-100) !important;
    border-color: var(--primary-100) !important;
    box-shadow: none !important;
}

.numberInput {
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    height: 44px;
    font-size: 16px;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
}

.numberInput:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
}

.numberInput:focus {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.divider {
    margin: 30px 0 !important;
    background-color: #1e90ff;
}

.rteWrappter {
    height: auto;
    max-height: 400px;
    overflow-y: auto;
}

/* workflow form */

.workflowForm {
}

.workflowForm p {
    margin-bottom: 0;
}

/* process workflow step */

.workflowStepList {
}

.workflowStep__arrow {
    position: relative;
    width: 0;
    height: 32px;
    margin: 0 auto;
    border-left: 2px solid var(--black-200); /* line width */
}

.workflowStep__arrow::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-top: 12px solid var(--black-200);
    right: -9px;
    bottom: 0;
}

.workflowStep__arrow__add {
    position: absolute !important;
    top: 32px;
    left: 50%;
    margin-left: -16px;
    border: solid 2px var(--black-200) !important;
    border-radius: 8px !important;
    padding: 2px !important;
}

.workflowStep__add {
    height: auto !important;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    padding: 12px 24px 12px 8px !important;

    border-radius: 8px !important;
    border: solid 1px var(--primary-400-base) !important;
    background-color: var(--primary-100) !important;

    font-size: 18px !important;
    font-weight: bold !important;
    line-height: 1.33 !important;
    color: var(--black-base) !important;
    text-transform: uppercase !important;
}

.workflowStep__add__icon {
    width: 28px;
    height: 28px;
    margin-right: 16px;
}

.workflowStep {
    padding: 16px 24px 24px 24px;
    border-radius: 12px;
    background-color: #f5f5f5;
    border: solid 1px transparent;
}

.workflowStep > * + * {
    margin-top: 24px;
    margin-bottom: 0;
}

.workflowStep__header {
    padding: 10px 16px;
    border-radius: 8px;
    border: solid 2px var(--primary-400-base);
    background-color: var(--whitebase);

    display: flex;
    flex-direction: row;
    align-items: center;
}

.workflowStep__header > * + * {
    margin-right: 0;
    margin-left: 16px;
}

.workflowStep__header__number {
    width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    background-color: var(--primary-400-base);
    border-radius: 999px;

    font-size: 18px;
    font-weight: bold;
    color: var(--whitebase);
}

.workflowStep__header__title {
    font-size: 21px;
    font-weight: bold;
    line-height: 1.14;
    color: var(--black-300);
    text-transform: uppercase;
}

.workflowStep__body {
}

.workflowStep__body > * + * {
    margin-top: 24px;
    margin-bottom: 0;
}

.workflowStep__peopleRow {
    display: flex;
    flex-direction: row;
    align-items: stretch;
}

.workflowStep__peopleRow > * + * {
    margin-right: 0;
    margin-left: 24px;
}

.workflowStep__personCard {
    flex: 1 1 0%;
    min-width: 0;
    padding: 4px;
    border-radius: 12px;
    border: solid 2px var(--black-200);
    background-color: var(--whitebase);
}

.workflowStep__personCard__header {
    padding: 8px 12px;
    border-radius: 8px;
    background-color: #e5e5e5;
    display: flex;
    flex-direction: row;
}

.workflowStep__personCard__header > * + * {
    margin-right: 0;
    margin-left: 12px;
}

.workflowStep__personCard__header__icon {
    width: 28px;
    height: 28px;
}

.workflowStep__personCard__header__title {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    color: var(--black-base);
    text-transform: uppercase;
}

.workflowStep__personCard__header__subtitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: var(--black-300);

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.workflowStep__personCard__body {
    padding: 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: var(--black-300);
}

.workflowStep__personCard__body > * + * {
    margin-top: 8px;
    margin-bottom: 0;
}

.workflowStep__personCard__activity {
    overflow-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 8px;
}

.workflowStep__personCard__mandatoryNote {
    display: inline-block;
    padding: 2px 8px;
    border-radius: 8px;
    background-color: var(--primary-400-base);

    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    color: var(--whitebase);
    text-transform: uppercase;
}

.workflowStep__personCardAdd {
    width: calc(33.33% - 24px);
    padding: 20px 0;
    border-radius: 12px;
    background-color: var(--whitebase);
    text-align: center;
}

.workflowStep__footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.workflowStep__footer > * + * {
    margin-right: 0;
    margin-left: 24px;
}

/* region process workflow add person form */

.workflowStep__form {
}

.workflowStep__form__section {
}

.workflowStep__form__section__title {
    padding: 8px 0;
    /*background-color: #f5f5f5;*/

    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    color: var(--black-300);
    text-transform: uppercase;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.workflowStep__form__section__title > * + * {
    margin-right: 0;
    margin-left: 8px;
}

.workflowStep__form__section__content {
    padding: 24px 0;
    border-bottom: solid 1px var(--black-300);
}

.workflowStep__form_footer {
    padding: 12px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.workflowStep__form_footer > * + * {
    margin-right: 0;
    margin-left: 24px;
}

.workflowStep__form_footer__label {
    font-size: 14px;
}

.errorAlert {
    position: relative !important;
    justify-content: flex-start !important;
    margin-top: 0 !important;
    left: 0 !important;
}

/* endregion process workflow add person form */

/* region automated workflow */

.autoWorkflow {
    padding: 32px 32px 40px 32px;
    border-radius: 12px;
    background-color: #f5f5f5;
}

.autoWorkflow__section {
    border-radius: 8px;
    border: solid 1px var(--black-200);
    background-color: var(--whitebase);
}

.autoWorkflow__section.shadow {
    border: none;
    box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.12);
}

.autoWorkflow__section__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 22px 40px;
}

.autoWorkflow__section__header__title {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.17;
    color: var(--black-200);
    text-transform: uppercase;
}

.autoWorkflow__section__header__subtitle {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.56;
    color: var(--black-200);
    margin-left: 12px;
}

.autoWorkflow__section__body {
}

.autoWorkflow__section__body__title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.17;
    color: var(--primary-300);
}

.autoWorkflow__section__footer {
    padding: 16px 40px 24px 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.autoWorkflow__trigger__valueContainer {
    margin-top: 20px;
}

.autoWorkflow__trigger__operators {
    margin-top: 20px;
    padding: 8px 24px;
    border-radius: 6px !important;
    background-color: #f5f5f5;
    min-height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.autoWorkflow__trigger__operators :global(.ant-radio-wrapper) {
    margin-right: 0;
    margin-left: 50px;
}

.autoWorkflow__trigger__operators :global(.ant-radio-wrapper):first-child {
    margin-left: 0;
}

.autoWorkflow__trigger__valueInput {
    width: 80px !important;
    margin-right: 10px !important;
}

.autoWorkflow__trigger__valueInput__suffix {
    font-size: 16px;
    line-height: 1.5;
    color: var(--black-base);
    margin-left: 8px;
}

.autoWorkflow__trigger__valueInput__unit {
    font-size: 16px;
    margin-left: 8px;
    min-width: 85px;
}

.autoWorkflow__trigger__valueType {
    width: 110px;
}
.autoWorkflow__actionType {
    display: flex !important;
    flex-direction: row;
    align-items: center;
}

.autoWorkflow__actionType > * + * {
    margin-right: 0;
    margin-left: 24px;
}

.autoWorkflow__actionType__icon {
    width: 44px;
    height: 44px;
    border-radius: 999px;
    background-color: var(--primary-100);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.autoWorkflow__actionType.selected .autoWorkflow__actionType__icon {
    background-color: white !important;
}

.autoWorkflow__actionType__icon__svg {
    width: 24px;
    height: 24px;
}

.autoWorkflow__action__messageType__group {
    border-radius: 6px !important;
    background-color: #f5f5f5;
    margin-bottom: 32px !important;
}

.autoWorkflow__action__messageType__group :global(.ant-radio-wrapper) {
    padding: 10px 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--black-300);
    min-width: 180px;
}

.autoWorkflow__action__messageType__group :global(.ant-radio-wrapper):not(:last-child) {
    border-right: solid 2px white;
}

.autoWorkflow__action__messageType__group :global(.ant-radio-wrapper).active {
    color: var(--black-base);
    font-weight: bold;
    line-height: 1.75;
}

.autoWorkflow__section__triggerView,
.autoWorkflow__section__actionView {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 40px 32px 40px;
}

.autoWorkflow__section__triggerView__icon,
.autoWorkflow__section__actionView__icon {
    flex-shrink: 0;
    width: 64px;
    height: 64px;
    border-radius: 999px;
    background-color: var(--primary-100);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.autoWorkflow__section__triggerView__icon__svg,
.autoWorkflow__section__actionView__icon__svg {
    width: 32px;
    height: 32px;
}

.autoWorkflow__section__triggerView__title,
.autoWorkflow__section__actionView__title {
    margin-left: 30px;
    margin-right: 48px;

    font-size: 24px;
    font-weight: bold;
    line-height: 1.17;
    color: var(--black-300);
}

.autoWorkflow__section__triggerView__content {
    padding: 16px 24px;
    border-radius: 8px !important;
    border: solid 1px var(--black-200);
    background-color: var(--whitebase);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 12px;
}

.autoWorkflow__section__triggerView__content > * + * {
    margin-right: 0;
    margin-left: 20px;
}

.autoWorkflow__section__triggerView__operator {
    padding: 2px 12px;
    border-radius: 6px !important;
    background-color: var(--primary-400-base);

    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: var(--whitebase);
}

.autoWorkflow__section__triggerView__value {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.17;
    color: var(--primary-400-base);
}

.autoWorkflow__section__actionView__content {
    flex-grow: 1;
    min-height: 124px;
    max-height: 400px;
    overflow-y: auto;
    padding: 16px 24px;
    border-radius: 8px !important;
    border: solid 1px var(--black-200);
    background-color: var(--whitebase);
}

.autoWorkflow__section__actionView__content__heading {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.25;
    color: var(--black-300);
}

.autoWorkflow__section__actionView__content__body {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
    color: var(--black-300);
}

.autoWorkflow__section__actionView__content__highlighted {
    font-size: 16px;
    line-height: 1.25;
    color: var(--primary-400-base);
}

/* endregion automated workflow */

/* ProcessWorkflowModal */

.workflowModal {
    margin: 32px 0;
}

.workflowModal :global(.ant-modal-body) {
    padding: 0;
}

.workflowModalContent {
    display: flex;
    flex-direction: row;
}

.workflowModalContent .leftSidebar {
    width: 64px;
    background-color: var(--primary-400-base);
    text-align: center;
    padding-top: 40px;
}

.workflowModalContent .leftSidebar .workflowIcon {
    width: 36px;
    height: 36px;
}

.workflowModalContent .mainContent {
    flex: 1;
    padding: 34px 64px;
}

.workflowModal .header {
    margin-bottom: 16px;
}

.workflowModal .headerTitle {
    font-size: 37px;
    font-weight: 800;
    color: var(--black-base);
    margin-right: 20px;
    line-height: 1.29;
}

.workflowModal .headerCode {
    font-size: 24px;
    color: var(--primary-400-base);
    font-weight: 600;
    line-height: 1.29;
}

.workflowStep .workflowStep__header {
    border-color: var(--black-200);
}

.workflowStep.inProgress {
    border-color: var(--primary-400-base);
}

.workflowStep.done {
    border-color: var(--success-400-base);
}

.workflowStep.inProgress .workflowStep__header {
    border-color: var(--primary-400-base);
}

.workflowStep.done .workflowStep__header {
    border-color: var(--success-400-base);
}

.workflowStep.inProgress .workflowStep__header__number {
    background-color: var(--primary-400-base);
}

.workflowStep.done .workflowStep__header__number {
    background-color: var(--success-400-base);
}

.workflowStep .workflowStep__personCard.inProgress {
    border-color: var(--primary-400-base);
}

.workflowStep .workflowStep__personCard.done {
    border-color: var(--success-400-base);
}

.workflowStep.done .checkmarkIcon {
    width: 40px;
    height: 40px;
    margin-top: -4px;
}

.workflowStep.inProgress .statusBadge {
    width: 132px;
    padding: 2px 4px;
    border-radius: 4px;
    background-color: var(--primary-400-base);

    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    text-align: center;
    color: var(--whitebase);
}

.workflowStep .workflowStep__personCard.done .personBadge {
    width: 180px;
    flex-grow: 0;
    padding: 4px 6px;
    border-radius: 8px;
    border: solid 1px var(--success-400-base);
    background-color: var(--whitebase);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.workflowStep .workflowStep__personCard.done .infoIcon {
    width: 24px;
    height: 24px;
    margin-top: 10px;
}

.workflowStep .workflowStep__personCard.done .personBadge .checkmarkIcon {
    width: 28px;
    height: 28px;
    margin-right: 8px;
}

.workflowStep .workflowStep__personCard.done .personBadge .personName {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
    text-align: left;
    color: var(--black-300);
}

.personNotes {
    width: 341px;
}

.personNotes .infoIconContainer {
    width: 36px;
    height: 36px;
    padding: 6px;
    border-radius: 999px;
    background-color: var(--primary-100);
}

.personNotes .infoIconContainer .infoIcon {
    width: 24px;
    height: 24px;
}

.personNote {
}

.personNote .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.personNote .header .badge {
    padding: 2px 4px;
    border-radius: 4px;
    background-color: var(--primary-400-base);

    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: var(--whitebase);
}

.personNote .header .name {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: var(--black-base);
}

.personNote .content {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: var(--black-300);
}

.activityDetail {
    margin-bottom: 32px;
}

.activityDetail .section {
    margin-bottom: 24px;
}

/* action task */

.createdInfo {
    border-radius: 4px;
    background-color: #f5f5f5;
    padding: 7px 16px;
    margin-top: 30px;
    margin-bottom: 0;
}

.createdInfo__text {
    font-size: 16px;
    font-weight: 500;
    color: #939393;
}

.createdInfo__text b {
    font-weight: 600;
}

.libraryTaskFileViewer {
    margin-top: 30px;
}

.statusBadge {
    display: inline-block;
    padding: 2px 4px;
    border-radius: 4px;
    background-color: var(--primary-400-base);

    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    text-align: center;
    color: var(--whitebase);
}

/* Process Workflow */

.flowChartContainer {
    height: 70vh;
    position: relative;
}

.flowChartContent {
    width: 100%;
    height: 100%;
}

.flowChartContent :global(.react-flow__panel.react-flow__attribution) {
    visibility: hidden;
}

.processWorkflowStepForm {
}
