.lessonInfoWrap {
    border-radius: 12px;
    background-color: #f5f5f5;
    padding: 8px 20px;
    margin-bottom: 16px;
}

.lessonHeader {
    display: flex;
    align-items: center;
    gap: 16px;
}

.lessonName {
    font-size: 24px;
    font-weight: bold;
    color: var(--black-base);
}

.lessonCode {
    font-size: 21px;
    font-weight: bold;
    color: var(--primary-400-base);
}

.lessonInfo__title {
    font-size: 16px;
    font-weight: 600;
    color: var(--black-200);
}

.lessonInfo__value {
    font-size: 18px;
    font-weight: bold;
    color: var(--black-300);
}

.capitalize {
    text-transform: capitalize;
}
