.requirementWrap {
    margin-top: 8px;
    position: relative;
}

.requirementTitle {
    font-size: 28px;
    font-weight: bold;
    color: var(--black-200);
}

.studentLeftInfo {
    border-radius: 10px;
    background-color: #f5f5f5;
    padding: 8px 10px;
    flex: 1;
    height: fit-content;
}

.studentRightInfo {
    flex: 1;
}

.studentRightInfo__margin {
    margin-bottom: 8px;
}

.majorVersionInfoWrap {
    margin-top: 12px;
    margin-bottom: 12px;
}

.majorVersionData {
    font-size: 24px;
    font-weight: 800;
    color: var(--black-base);
}
