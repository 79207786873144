.wrap {
    margin-top: 40px;
    padding: 10px;
    border-radius: 12px;
    box-shadow: 0 4px 24px 0 rgba(48, 49, 51, 0.16);
    background-color: var(--whitebase);
}

.headerWrap {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.headerLeft {
    flex: 2;
}

.headerLeftTitleWrap {
    display: flex;
    justify-content: space-between;
}

.headerLeft__title {
    font-size: 20px;
    font-weight: 800;
    color: var(--black-200);
    margin-bottom: 0px;
}

.cardItem {
    border-radius: 12px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 0px;
}

.cardItem__number {
    font-size: 40px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 0;
    line-height: 1;
}

.cardItem__title {
    font-size: 15px;
    font-weight: bold;
    color: var(--black-200);
    margin-bottom: 0;
}

.cardItem__pending {
    border: solid 1px var(--black-100);
    background-color: #ffffff;
    padding: 5px;
}

.pendingWrap {
    display: flex;
    align-items: center;
    width: 100%;
}

.pendingCard {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--black-100);
    flex: 1;
    margin: 0 4px;
    border-radius: 12px;
    padding: 5px 0;
}

.pendingCard__number {
    font-size: 30px;
    font-weight: bold;
    line-height: 1;
    color: var(--black-300);
    margin-bottom: 0;
}

.pendingCard__title {
    font-size: 14px;
    font-weight: bold;
    color: var(--black-200);
    margin-bottom: 0;
}

.pending__title {
    font-size: 15px;
    font-weight: bold;
    color: var(--black-200);
    margin-bottom: 0;
    margin-top: 5px;
}

.filterContainer {
    margin-bottom: 20px;
}

.actionWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
}

.dataContainer {
    position: relative;
}
