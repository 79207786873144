.iconTableWrap {
    width: 22px;
    height: 22px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-100);
    border-radius: 50%;
    border: solid 1px transparent;
}

.iconTableWrap svg {
    width: 18px;
    height: 18px;
}

.iconTableWrap.red {
    background-color: var(--error-200);
}

.iconTableWrap.green {
    background-color: var(--success-400-base);
}

.iconTableWrap.white {
    border: solid 1px var(--primary-400-base);
    background-color: var(--whitebase);
}

.completedDateWrap {
    display: flex;
    align-items: center;
}

.completedDateInfo {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
}

.completedDateInfo__item {
    font-size: 13px;
    font-weight: 500;
    color: var(--black-300);
    margin-bottom: 3px;
}

.createTaskWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.activitiesTable {
    /*table-layout: fixed;*/
}

.workflowActivitiesTableContainer {
    width: 100%;
    background-color: var(--primary-100);
}

.workflowActivitiesTable {
    /*table-layout: fixed;*/
}

.workflowActivitiesTable tbody tr {
    background-color: unset !important;
}

.activityIcon svg {
    width: 22px;
    height: 22px;
}

.textNoWrap {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.activitiesTable th,
.activitiesTable td {
    font-size: 13px;
    min-height: 36px;
    height: 36px;
    padding: 0px 8px;
    /*border-right: 1px solid rgba(224, 224, 224, 1) !important;*/
}

.activityExpandIcon {
    width: 17px;
    height: 17px;
    margin-right: 3px;
}

.activityNameContainer {
    display: flex;
    align-items: center;
}

.expandedRow th,
.expandedRow td {
    min-height: unset !important;
    height: unset !important;
}

.expandedRow > td {
    padding: 0 !important;
}

.tableActionMenu {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
}

.rowLoading {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}

.rowWrap {
    padding: 6px 8px 8px;
    border-radius: 8px;
    border: solid 1px var(--black-100);
    background-color: var(--whitebase);
    margin: 8px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.rowTitleWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: #000;
}

.rowEnrollment {
    color: var(--primary-400-base);
}

.rowDescription {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: var(--black-200);
}

.rowFieldWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}

.rowFieldTitle {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--black-200);
    text-transform: uppercase;
    white-space: nowrap;
}

.rowFieldValue {
    padding: 4px 8px;
    border-radius: 8px;
    background-color: #f5f5f5;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.29;
    color: var(--black-base);
    min-height: 26px;
    min-width: 32px;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
}

.rowStatusIcon {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.rowStatusValue {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.rowActivityIcon {
    width: 20px;
    height: 20px;
}

.rowCompletedValue {
    background-color: var(--success-100);
}
