.wrapper {
    margin: 20px 40px;
}

.title {
    font-size: 24px;
    color: #212121;
    font-weight: bold;
    margin-left: 20px;
    margin-bottom: 40px;
}

.wrap {
    border-radius: 12px;
    background-color: var(--primary-100);
    margin-top: 40px;
    margin-bottom: 30px;
    padding: 20px;
}

.tableWrap {
    padding: 12px;
    background: #fff;
    border-radius: 8px;
}

.activity__title {
    font-size: 32px;
    font-weight: 800;
    color: var(--black-200);
    margin-bottom: 0;
}

.headerActivities {
    display: flex;
    justify-content: space-between;
}
.screenTitle {
    font-size: 25px;
}

.backBtnWrap {
    cursor: pointer;
}

.backIcon {
    width: 48px;
    height: 48px;
}
