.actionWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.emptyData {
    text-align: center;
    margin: 30px;
}
