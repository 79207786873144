.wrap {
    margin-top: 20px;
}

.body {
    padding: 8px 16px 16px 8px;
    border-radius: 12px;
    background-color: #f5f5f5;
    margin-bottom: 24px;
}

.headerBody {
    border-bottom: 2px solid #fff;
    padding-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.headerFilterIconWrap {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: solid 2px var(--primary-400-base);
    background-color: var(--primary-400-base);
    cursor: pointer;
}

.closeIconWrap {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.closeIcon {
    width: 30px;
    height: 30px;
}

.bodyContent {
    padding: 16px 8px;
    border-bottom: 2px solid #fff;
}

.footerBody {
    padding-top: 16px;
    display: flex;
    justify-content: flex-end;
}

.footerBodyAction {
    display: flex;
    align-items: center;
    gap: 24px;
}

.filterWrap {
    display: flex;
    align-items: center;
    gap: 24px;
}

.leftFilterWrap {
    display: flex;
    align-items: center;
    gap: 24px;
    /* flex: 1; */
}

.filterIconWrap {
    width: 40px;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: solid 2px var(--primary-400-base);
    background-color: var(--primary-100);
    cursor: pointer;
}

.hasFilterBody {
    background-color: var(--primary-400-base) !important;
}

.rightFilterWrap {
    flex: 1;
}
