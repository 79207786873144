.titleWrap {
    font-size: 24px;
    font-weight: 800;
    color: var(--black-300);
    margin-bottom: 20px;
    display: flex;
    gap: 24px;
    text-transform: uppercase;
    align-items: center;
}

.spanId {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    color: var(--primary-400-base);
}
