.studentItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 8px;
    flex: 1;
}

.studentItem:hover {
    background-color: var(--black-100) !important;
}

.studentItem:hover .buttonNext {
    opacity: 1;
}

.studentItem__img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 12px;
}

.studentItem__info {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    color: var(--black-base);
    flex: 1;
}

.buttonNext {
    opacity: 0;
    position: relative;
    cursor: pointer;
    height: 32px;
    width: 32px;
    margin-right: 10px;
}

.attendanceTickIcon {
    width: 32px;
}
