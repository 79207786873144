.body {
    overflow: auto;
    max-height: 65vh;
    padding: 0 10px;
}

.title {
    font-size: 37px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-300);
}

.divider {
    background-color: #62b1ff;
    height: 2px;
    margin: 10px 0 20px 0;
}

.actionBtnWrap {
    display: flex;
    justify-content: flex-end;
    padding: 30px 0;
    gap: 24px;
}

.startDateLabel {
    font-size: 21px;
    font-weight: bold;
    line-height: 1.14;
    color: var(--black-300);
}

.startDateValue {
    font-size: 21px;
    font-weight: bold;
    line-height: 1.14;
    color: var(--primary-400-base);
}

.infoBlock {
    padding: 12px 16px;
    border-radius: 6px;
    border: solid 1px var(--primary-400-base);
    background-color: var(--whitebase);
    margin-bottom: 8px;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.blockTitle {
    font-size: 15px;
    font-weight: bold;
    line-height: 1.33;
    color: #000;
    text-transform: uppercase;
}

.blockDescription {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: var(--black-300);
}

.radioGroup :global(.ant-radio-wrapper) {
    font-size: 15px;
    font-weight: bold;
    line-height: 1.33;
    color: var(--black-200);
}

.radioGroup :global(.ant-radio-wrapper .ant-radio-inner) {
    border-width: 2px;
    width: 20px;
    height: 20px;
}

.radioGroup :global(.ant-radio-wrapper .ant-radio-inner::after) {
    border-radius: 999px;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
}

/* TABLE */

.table {
    width: 100%;
    table-layout: auto;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
}

.tableHead {
    background: var(--primary-100);
}

.tableRowHeader {
    color: inherit;
    display: table-row;
    outline: 0;
    vertical-align: middle;
}

.tableCell {
    display: table-cell;
    padding: 3px 8px;
    font-size: 13px;
    text-align: left;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    vertical-align: inherit;
    color: #212121;
    min-height: 40px;
    height: 40px;
    word-break: normal;
}

.tableCellHeader {
    font-size: 13px;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 1.5rem;
    position: relative;
    color: var(--black-200);
}

.tableCellHeader.small {
    font-size: 11px;
}

.tableCellBody {
    font-size: 13px;
    font-weight: 600;
}

.bodyTable {
    max-height: 500px;
    overflow: auto;
    display: table-row-group;
}

.tableRow {
    color: inherit;
    display: table-row;
    outline: 0;
    vertical-align: middle;
    background: white;
}

.tableRow:nth-child(even) {
    background: #fbfbfb;
}

.tableRow:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

/* USER INFO */

.userWrap {
    position: relative;
    border-radius: 12px;
    border: solid 1px var(--primary-400-base);
    background-color: var(--whitebase);
    padding: 12px;
    overflow: hidden;
    padding-top: 2px;
    margin-bottom: 24px;
}

.header .content {
    display: flex;
    align-items: normal;
    gap: 24px;
}

.header .content .contentItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    gap: 12px;
    border-bottom: 1px solid var(--primary-400-base);
    padding: 6px 0;
}

.body .content {
    display: flex;
    align-items: normal;
    gap: 24px;
}

.body .content .contentItem {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    border-bottom: 1px solid var(--black-100);
    padding: 2px 0;
}

.body .content .content__title {
    font-size: 12px;
    font-weight: 500;
    color: var(--primary-400-base);
    text-transform: uppercase;
}

.body .content .content__value {
    font-size: 13px;
    font-weight: bold;
    color: var(--black-base);
}

.avatar {
    border-radius: 12px;
    width: 32px;
    height: 32px;
    object-fit: cover;
}

.userName {
    font-size: 24px;
    font-weight: bold;
    color: var(--black-base);
}

.userId {
    padding: 2px 8px 3px;
    border-radius: 4px;
    background-color: var(--primary-100);
    font-size: 14px;
    font-weight: 500;
    color: var(--black-300);
}

.creditEarned {
    align-self: flex-end;
    padding: 8px;
    border-radius: 4px;
    background-color: var(--black-100);
    font-size: 14px;
    font-weight: 500;
    color: var(--black-300);
}

.creditEarnedValue {
    font-size: 18px;
    font-weight: bold;
    color: var(--primary-400-base);
}
