.wrap {
    border-radius: 12px;
    box-shadow: 0 4px 24px 0 rgba(48, 49, 51, 0.16);
    padding: 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.headerWrap {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
}

.title {
    font-size: 21px;
    font-weight: 800;
    color: #939393;
    margin-bottom: 0;
    margin-left: 11px;
}

.divider {
    background-color: #e95c7b;
    height: 1px;
    width: 50%;
    margin-bottom: 16px;
}

.chartWrap {
    flex: 1;
    display: flex;
    align-items: flex-end;
}
