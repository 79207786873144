.addSectionWrap {
    display: flex;
    align-items: center;
    margin-top: 30px;
}

.addSection {
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0);
    color: #1e90ff;
    border: 1px solid #1e90ff;
    padding: 8px 15px;
    cursor: pointer;
}

.addSectionText {
    margin: 0 10px 0 0;
    font-size: 16px;
    font-weight: bold;
}

.plusIcon {
    width: 16px;
    height: 16px;
}

.termBodyWrap {
    overflow: auto;
    white-space: nowrap;
    margin-top: 40px;
    position: relative;
}

.termBodyWrap > div {
    display: inline-block;
    margin-right: 30px;
}

.termItem {
    background: #fff;
    width: 420px;
    height: 60px;
    border-radius: 8px;
    display: inline-block;
    margin-right: 20px;
}

.termBody {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 16px;
    cursor: pointer;
    border: 1px dashed #ccc;
}

.doneBtn {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
}

.saveBtnWrap {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.loadingWrap {
    margin-top: 40px;
}

.registerAllCourses {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 12px;
}

.registerAllCoursesError {
    color: var(--error-400-base);
}

.allCoursesAction {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
