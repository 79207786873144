.cardWrap {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    box-shadow: 0 4px 24px 0 rgba(48, 49, 51, 0.16);
    background-color: var(--whitebase);
    padding: 10px 12px;
    height: 100%;
}

.cardTitle {
    font-size: 21px;
    font-weight: bold;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-200);
    margin: 5px;
}

.line {
    margin-top: 3px;
    margin-left: 6px;
    left: 0;
    border: none;
    width: calc(100% + 5px);
    border: none;
    height: 0.5px;
    background-color: var(--primary-300);
}

.number {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 64px;
    font-weight: bold;
    margin: 0;
}

.completion {
    color: var(--primary-600);
}

.pending {
    color: var(--secondary-500);
}

.title {
    font-size: 32px;
    font-weight: 800;
    color: var(--black-200);
    margin: 0;
}
