.wrap {
}

.title {
    font-size: 16px;
    font-weight: 600;
    color: var(--black-300);
    margin-bottom: 16px;
    text-transform: uppercase;
}

.item {
    margin-bottom: 24px;
}
