.wrapper {
    margin: 20px 0px;
}

.buttonWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
}

.addCircleBtn {
    height: 32px !important;
    width: 32px !important;
    margin-left: 10px;
}

.addCircleBtn svg {
    height: 20px !important;
}

.cancelBtn {
    margin-right: 20px;
    text-transform: uppercase;
}

.totalInfo {
    padding: 10px;
    border-radius: 8px;
    background-color: #f5f5f5;
    color: var(--black-300);
    display: flex;
    align-items: center;
}

.actionWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 20px;
    height: 90%;
}

.mb30 {
    margin-bottom: 30px !important;
}

.leadPoolTitleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.leadPoolTitle {
    display: flex;
    align-items: center;
}

.leadPoolTitleSpan {
    border: 1px solid var(--black-100);
    color: var(--black-300);
    padding: 7px;
    border-radius: 10px;
    margin-right: 10px;
    font-weight: bold;
}

.leadPoolTitleSeparator {
    border-bottom: 1px solid var(--black-100) !important;
    flex-grow: 1;
}

.deletePoolIcon {
    width: 22px;
    height: 22px;
    cursor: pointer;
}

.footer {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid var(--black-100);
}

.alertIcon {
    margin-right: 10px;
    width: 24px;
    height: 24px;
}

.leadDistributionPoolContainer {
    box-shadow: 0 4px 24px 0 rgba(48, 49, 51, 0.16);
    padding: 15px;
    border-radius: 7px;
    margin-bottom: 50px;
}

.statusButtonContainer {
    margin-top: unset !important;
}

.saveBtn {
    margin-top: 1rem;
}

.radioBtnWrap {
    display: flex !important;
    border: solid 1px #1e90ff;
    border-radius: 8px;
    margin-bottom: 20px;
}

.radioBtnItem {
    border-right: 1px solid #1e90ff;
    padding: 12px !important;
    font-size: 16px !important;
    font-weight: bold;
    line-height: 1.33 !important;
    color: #939393 !important;
    margin: 0 !important;
    flex: 1;
}

.radioBtnItem:last-child {
    border-right: 1px solid transparent;
}

.saveBtnContainer {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;
}

.itemBodyWrap {
    margin-bottom: 30px;
}

.groupRadioButton {
    border-width: 1px;
    display: flex;
    max-width: 450px;
    flex-direction: row;
    border-radius: 12px;
    border: solid 2px var(--primary-400-base);
}

.radioButton {
    align-items: center;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 50%;
}

.radioButtonGrade {
    border-width: 0px;
    padding-top: 5px;
    padding-left: 2rem;
    width: 50%;
    padding-right: 2rem;
    padding-bottom: 5px;
    border-left-width: 1px;
    border-style: inset;
    border-color: var(--primary-400-base);
    flex-direction: column;
    align-items: center;
}

.textRadioButton {
    font-family: Poppins, sans-serif;
    font-weight: bold;
    font-size: 18px;
    color: var(--black-300);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-transform: uppercase;
}

.dropdownHeadline {
    width: 36rem;
}

.notAllowed {
    cursor: not-allowed;
}
