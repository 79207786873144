.actionWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.emptyData {
    text-align: center;
    margin: 30px;
}

.videoContainer {
    display: flex;
    gap: 10px;
    align-items: center;
}

.videoLink {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: var(--primary-400-base);
}

.addContainer {
    display: flex;
    gap: 8px;
    align-items: center;
    cursor: pointer;
    justify-content: flex-end;
    margin-top: 16px;
}

.addText {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--black-base);
}

.visibleCheckboxWrap {
    display: flex;
    justify-content: flex-start;
}
