.checkListWrap {
    display: flex;
    flex-direction: column;
}

.title {
    width: fit-content;
    padding: 4px 8px;
    border-radius: 8px;
    background-color: #f5f5f5;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    color: var(--black-300);
    text-transform: uppercase;
}
