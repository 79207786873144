.deleteIconWrap {
    width: 104px;
    height: 104px;
    background-color: var(--primary-100);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 40px;
}

.deleteIcon {
    width: 40px;
    height: 40px;
}

.content {
    font-size: 24px;
    font-weight: bold;
    color: #000;
    text-transform: uppercase;
    margin-bottom: 40px;
    text-align: center;
}

.action {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
