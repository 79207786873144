.root {
    padding: 20px;
}
.title {
    font-size: 30px;
    font-weight: bold;
    color: var(--black-base);
}

.body {
    margin-top: 10px;
}

.footer {
    margin-top: 40px;
}
.buttons {
    display: flex;
}
.expandedButtons {
    justify-content: space-between;
}
.leftButtons {
    justify-content: flex-start;
}
.centerButtons {
    justify-content: center;
}
.rightButtons {
    justify-content: flex-end;
}

.button:last-child {
    margin-left: 24px;
}
