.drawerWrap {
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow-x: hidden;
}

.panelJobWrap {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    width: 400px;
    height: 100%;
    border-right: solid 1px var(--black-100);
}

.panelJobContainer {
    height: 100%;
    padding: 16px;
    overflow-y: scroll;
}

.panelWrap {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.profileLinkIconWrap {
    width: 24px;
    height: 24px;
    padding: 4px;
    border-radius: 16px;
    background-color: var(--secondary-400-base);
    cursor: pointer;
}

.profileLinkIcon {
    width: 16px;
    height: 16px;
    fill: var(--whitebase);
}

.headingTitle {
    display: flex;
    align-items: center;
    flex: 1;
    margin-bottom: 8px;
}

.titleWrap {
    display: flex;
    align-items: center;
    flex: 1;
}

.jobLogo {
    width: 40px;
    height: 40px;
    margin-right: 12px;
}

.jobTitle {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    text-align: left;
    color: var(--black);
}

.jobInfo {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-bottom: 16px;
}

.contentWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}

.contentTitle {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--black-200);
    text-transform: uppercase;
    white-space: nowrap;
}

.contentValue {
    padding: 4px 8px;
    border-radius: 8px;
    background-color: #f5f5f5;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: var(--black-base);
    min-height: 26px;
    min-width: 32px;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
}

.categoriesWrap {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 12px;
    flex-wrap: wrap;
}

.categoryItem {
    padding: 2px 8px;
    border-radius: 8px;
    background-color: var(--black-200);
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    text-align: center;
    color: var(--whitebase);
}

.metadataWrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    padding: 7px 16px;
    border-radius: 4px;
    background-color: #f5f5f5;
    margin-top: 16px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--black-200);
    text-transform: uppercase;
}
