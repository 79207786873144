.root {
    width: 100%;
    height: 100%;
    min-height: 531px;
}

.map {
    width: 100%;
    height: 100%;
}
