.bodyWrap {
    display: flex;
}

.item {
    flex: 1;
    margin-bottom: 20px;
}

.item__left {
    margin-right: 15px;
}

.item__right {
    margin-left: 15px;
}
