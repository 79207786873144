.attCheckVerified svg {
    width: 34px;
    height: 34px;
}

.rightFilter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
}
