.wrapper {
    position: relative;
    overflow: visible;
}

.dropdown {
    width: 160px;
    border-radius: 10px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
    background-color: var(--whitebase);
    padding: 15px 0;
    top: 0;
    z-index: 2;
}

.dropdownList {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.dropdownItem {
    cursor: pointer;
    padding: 10px 0 10px 20px;
    font-size: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: background-color 0.15s ease-in-out;
}

.dropdownItem:hover {
    background-color: var(--primary-100);
}

.dropdownItem p {
    margin: 8px;
}

.dropdowntem:hover {
    background-color: #e9f4ff;
    font-weight: bold;
}
