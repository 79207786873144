.wrap {
    padding: 0 40px;
}

.title {
    font-size: 37px;
    font-weight: bold;
    color: #666666;
    line-height: 1.41;
    margin-bottom: 14px;
    border-bottom: 2px solid #62b1ff;
    padding-bottom: 6px;
}
