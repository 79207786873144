.wrap {
    border-radius: 8px;
    border: solid 1px #e5e5e5;
    background-color: #fff;
    display: flex;
    flex-direction: column-reverse;
}

.editorWrap {
    padding: 0 20px;
    min-height: 150px;
    max-height: 300px;
    resize: vertical;
    font-size: 14px; /* default font size */
}

.toolbar {
    border-radius: 8px !important;
    background-color: #f5f5f5 !important;
    margin: 6px !important;
}

.wrap :global(.rdw-list-dropdown),
.wrap :global(.rdw-text-align-dropdown) {
    z-index: 1 !important;
}

.error {
    border: 1px solid red;
}
