.headingTitle {
    font-size: 37px;
    font-weight: bold;
    line-height: 1.41;
    color: var(--black-300);
    line-height: 1.14;
    padding-bottom: 12px;
    border-bottom: solid 1px var(--black-100);
    margin-bottom: 16px;
}

.saveBtn {
    margin-left: auto;
    min-width: 90px;
    justify-content: center;
}

.doneBtn {
    display: flex;
    margin-top: 30px;
}

.noteInput {
    margin: 8px 0px;
    border-radius: 8px;
    border: solid 1px #c7c7c7;
    background-color: var(--whitebase);
    padding: 8px;
}

.descTitle {
    font-size: 15px;
    color: var(--black-300);
    padding-bottom: 8px;
}
