.label {
    font-size: 16px;
    color: #666666;
    margin-bottom: 6px;
}

.star {
    /* font-size: 16px; -- use parent font-size */
    font-weight: bold;
    color: var(--error-400-base);
    padding-left: 4px;
    padding-right: 4px;
}

@media (max-width: 768px) {
    .label {
        font-size: 8px;
        line-height: 10px;
        margin-bottom: 2px;
    }
}
