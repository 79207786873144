.modal {
    border-radius: 24px;
    overflow: hidden;
}

.container {
    margin: 24px 0;
}

.title {
    margin-top: 24px;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
    color: #000;
    text-align: center;
}

.desc {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--black-300);
    text-align: center;
}

.btn {
    margin: 24px;
    display: inline-block !important;
}
