.inputWrap {
    margin: 0 0 20px;
}

.action {
    display: flex;
    align-items: center;
}

.btnWrap {
    display: flex;
    justify-content: flex-end;
    flex: 1;
}

.clearBtn {
    margin-right: 20px;
}
