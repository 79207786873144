.itemBodyWrap {
    margin-bottom: 30px;
}

.itemBodyMargin {
    margin-right: 20px;
}

.bodyTitle {
    margin-bottom: 6px;
    font-size: 16px;
    color: var(--black-300);
}

.footerInfo {
    margin-top: 60px;
    font-size: 16px;
    font-weight: 500;
    color: var(--black-200);
    background-color: #f5f5f5;
    padding: 7px 15px;
}

.footerInfo__bold {
    font-weight: 600;
}

.buttonWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
}

.cancelBtn {
    margin-right: 20px;
}

.divider {
    background-color: var(--primary-400-base);
}

.uploadIconWrap {
    margin-top: 8px;
}

.uploadIcon {
    cursor: pointer;
}

.checkboxContainer {
    display: flex;
}

.checkboxWrap {
    display: flex !important;
}

.checkboxItem {
    display: flex;
    align-items: center;
    border: solid 1px #e5e5e5;
    background-color: var(--whitebase);
    border-radius: 4px;
    margin-right: 16px;
    padding: 7px 12px;
}

.checkboxItem__active {
    border: solid 1px var(--primary-400-base);
}

.infoWrap {
    display: flex;
    flex-direction: column;
    width: 400px;
}

.info__text {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 500;
    color: var(--black-300);
}

.fileNameWrap {
    border: 0;
}

.checkboxMargin {
    margin-right: 10px;
}
