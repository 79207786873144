.stepItem {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.title {
    font-size: 16px;
    font-weight: 500;
    color: var(--black-300);
    margin-bottom: 6px;
}

.statusWrap {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
}

.line {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
    height: 4px;
    background-color: #e5e5e5;
}

.line.active {
    background-color: var(--primary-400-base);
}

.point {
    min-width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    padding: 4px;
    border-radius: 24px;
    background-color: var(--black-100);
    z-index: 2;
    cursor: pointer;
}

.pointIcon {
    width: 16px;
}

.pointText {
    font-size: 12px;
    font-weight: 500;
    color: var(--whitebase);
    margin-left: 4px;
}

.currentWrap {
    position: absolute;
    left: 50%;
    display: flex;
    align-items: center;
    z-index: 1;
    width: 50%;
}

.currentLine {
    background-color: var(--primary-400-base);
    z-index: 1;
    width: 100%;
    height: 4px;
}

.currentText {
    font-size: 12px;
    font-weight: bold;
    background-color: var(--primary-400-base);
    color: var(--whitebase);
    border-radius: 24px;
    height: 20px;
    padding: 4px 8px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    z-index: 2;
}
