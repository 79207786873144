.wrap {
    padding: 8px;
    border-radius: 12px;
    background-color: #ffe5eb;
    margin-top: 24px;
}

.header {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: #df1642;
}

.header__icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
}

.title {
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    flex: 1;
    margin-bottom: 0;
}

.collapse {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.body {
    overflow: auto;
    transition: all 0.3s ease-in-out;
    height: fit-content;
    max-height: 0px;
}

.body__show {
    max-height: 150px;
    margin-top: 8px;
}

.item {
    padding: 4px 8px 8px;
    border-radius: 4px;
    background-color: #ffffff;
    margin-bottom: 4px;
}

.item__title {
    font-size: 16px;
    font-weight: 600;
    color: #212121;
    line-height: 1.5;
    margin-bottom: 2px;
}

.item__titleHighlight {
    color: #1e90ff;
}

.description {
    font-size: 14px;
    font-weight: 500;
    color: #666666;
    line-height: 1.71;
    margin-bottom: 0;
}
