.passFail {
    padding: 4px 24px 4px 25px;
    border-radius: 4px;
    width: 88px;
}
.passFailText {
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--whitebase);
}

.titleWrap {
    display: flex;
    align-items: center;
    padding: 11px 5px;
}
.title {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
.columnLowHigh {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
}
.colorColumn {
    min-width: 50px;
    width: 50px;
    max-width: 50px;
}
