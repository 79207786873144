.root {
    display: flex;
    justify-content: space-between;
}

.text {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black-base);
}

.track {
    box-sizing: border-box;
    width: 36px;
    height: 20px;
    position: relative;
    border-radius: 10px;
    background-color: var(--black-200);
    cursor: pointer;
}

.thumb {
    position: absolute;
    left: 3px;
    top: calc(50% - 6px);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: var(--whitebase);
    box-shadow: 0px 1px 4px rgba(137, 137, 137, 0.5);
}

.input {
    display: none;
}

.input:checked ~ .track {
    background-color: var(--primary-400-base);
}

.input:checked ~ .track .thumb {
    left: calc(100% - 16px);
}

.input:disabled ~ .track {
    cursor: auto;
}
