.wrapper {
    margin-bottom: 40px;
}
.searchBar {
    width: 300px;
}
.backBtnWrap {
    cursor: pointer;
    width: 48px;
    height: 48px;
}
.sectionView {
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}
.programName {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
    color: var(--black-base);
    margin-left: 10px;
}
.addSectionBtn {
    margin-top: 30px;
}
.addSectionBtn {
    margin-top: 30px;
}

.saveActionWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
}

.actionWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.addCourses {
    margin-top: 0;
}

.deleteIcon {
    width: 16px;
    height: 16px;
}
.line {
    height: 2px;
    width: 100%;
    margin-top: 20px;
    background-color: rgba(0, 0, 0, 0.4);
}
.trashButton {
    cursor: pointer;
}
