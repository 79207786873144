.breakLine {
    border-color: var(--primary-400-base);
    margin: 30px 0px;
}

.isirName {
    background-color: var(--primary-100);
    min-width: 250px;
    min-height: 70px;
    border-radius: 8px;
    text-align: center;
    font-size: 37px;
    font-weight: bold;
    align-items: center;
    display: flex;
    justify-content: center;
}

.header {
    display: flex;
    align-items: center;
    margin-top: 40px;
    gap: 24px;
}

.cards {
    background-color: #e5e5e5;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 8px;
    color: var(--black-200);
    font-size: 15px;
    font-weight: 600;
    text-align: center;
}

.cardDetail {
    color: var(--black-base);
}

.fileButton {
    border-color: var(--primary-400-base);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 48px;
}

.cardsWrapper {
    margin: 20px 0px;
}

.titleLink {
    font-size: 18px;
    font-weight: bold;
    color: #1e90ff;
    text-decoration: underline;
}

.centerContent {
    text-align: center;
}

.divider {
    height: 1px;
    background-color: #e5e5e5;
    margin: 30px 0 40px;
}

.action {
    display: flex;
    justify-content: space-between;
}

.actionBtn {
    height: 48px !important;
    border-radius: 8px !important;
    font-size: 18px !important;
    font-weight: bold !important;
    display: flex !important;
    padding: 10px 20px !important;
    align-items: center;
}

.rejectBtn {
    border: solid 1px #df1642 !important;
    color: #df1642 !important;
}

.action__icon {
    width: 32px;
    height: 32px;
    margin-left: 15px;
}

.approveBtn {
    color: #18a957 !important;
    border: solid 1px #18a957 !important;
}

.title {
    font-size: 37px;
    font-weight: 800;
    line-height: 1.41;
    color: #666666;
    padding-bottom: 14px;
    margin-bottom: 32px;
    border-bottom: 1px solid #62b1ff;
}

.wrap {
    padding: 0 30px 20px;
}

.taskPopupSubtitle {
    font-weight: bold;
    color: #666666;
    font-size: 20px;
    line-height: 0.3;
}

.taskPopupCCode {
    font-weight: bold;
    font-size: 25px;
    background-color: #eeeeee;
    padding: 10px;
    border-radius: 7px;
}

.taskPopupCode {
    color: #1e90ff;
}

@media (max-width: 768px) {
    .wrap {
        padding: 0;
    }
    .title {
        font-size: 16px;
        padding-bottom: 5px;
        margin-bottom: 10px;
    }
}

.review {
    font-size: 18px;
    font-weight: bold;
    color: #1e90ff;
    text-decoration: underline;
}

.statusWrap {
    display: flex;
    align-items: center;
}

.signatureWrap {
    padding: 7px 5px;
    display: flex;
    align-items: center;
    margin-right: 8px;
}

.signature__statusIcon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

.signature__approval {
    background-color: #e8faef;
}

.signature__reject {
    background-color: #ffe5eb;
}

.signature__icon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

.signature__img {
    width: 56px;
    height: 20px;
}

.dateInfo {
    display: flex;
    flex-direction: column;
}

.dateInfo__text {
    font-size: 14px;
    font-weight: 600;
    color: #666666;
    margin-bottom: 3px;
}

.upload {
    font-size: 18px;
    font-weight: bold;
    color: #1e90ff;
    text-decoration: underline;
}

.fileName {
    display: inline-block;
    padding: 4px 10px;
    border-radius: 4px;
    background-color: #939393;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
}

.buttonWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
}

.tableScrollHidden {
    overflow: visible !important;
}

.disabledHtml {
    color: #666666;
    text-decoration: none;
}

.verificationCard {
    border-bottom: 2px groove;
    padding-bottom: 40px;
    margin-bottom: 40px;
}

.saveBtn {
    margin-left: 20px;
}

.isRequiredCheckbox {
    display: flex;
    align-items: center;
    justify-content: center;
}

.rowHighlighted {
    background-color: #cbcbcb !important;
}
