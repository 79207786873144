.root {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.input {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
}

.ellipse {
    display: block;
    width: 26px;
    height: 26px;
    box-sizing: border-box;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
}

.ellipse::before {
    position: absolute;
    box-sizing: border-box;
    content: "";
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: solid 2px var(--black-100);
    top: calc(50% - 12px);
    left: calc(50% - 12px);
}

.input:checked ~ .ellipse::before {
    border-color: var(--primary-400-base);
}

.input:checked ~ .ellipse::after {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    top: calc(50% - 6px);
    left: calc(50% - 6px);
    background-color: var(--primary-400-base);
}

.input:disabled ~ .ellipse {
    cursor: auto;
}

.input:disabled ~ .ellipse::after {
    background-color: var(--black-100);
}

.input:disabled ~ .ellipse::before {
    border-color: var(--black-100);
}

.input:focus ~ .ellipse {
    box-shadow: 0 0 0 2px var(--primary-500);
}

.text {
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    padding: 0;
    margin: 0;
    margin-left: 8px;
    color: var(--black-base);
}
