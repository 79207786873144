.eventContent {
    font-size: 12px;
    display: block;
    margin-left: 8px;
    width: 100%;
    height: 100%;
    line-height: 1;
    padding-right: 3px;
    cursor: pointer;
    overflow: auto;
}

.eventTitle {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 9px;
}

.eventTime {
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 9px;
    margin-bottom: 4px;
}

.eventMonthView {
    display: flex;
    align-items: center;
    color: var(--black-300);
    padding: 0px 3px;
    width: 100%;
}

.eventTitleMonthView {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.eventTitleMonthView > strong {
    margin-right: 5px;
}

.dot {
    width: 7px;
    height: 7px;
    border-radius: 4px;
    margin-right: 3px;
}

.bodyWrap {
    display: flex;
}

.rightSide {
    position: relative;
    min-width: 600px;
    flex: 1;
}

.viewOptionsWrap {
    margin-bottom: 30px;
}

.calendarIndicator {
    margin-bottom: 10px;
}

.highlightEventPreviewWrap {
    z-index: 9999 !important;
}

.highlightEventPreview {
    box-shadow: 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%), 0px 3px 5px -1px rgb(0 0 0 / 20%) !important;
}

.eventPreviewWrap {
    max-width: 300px;
    width: 300px;
}

.eventPreviewHeader {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 4px;
}

.eventPreviewHeaderBtn {
    border: none !important;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.eventPreviewHeaderEditIcon {
    width: 25px;
    height: 25px;
}

.eventPreviewHeaderIcon {
    width: 18px;
    height: 18px;
}

.eventPreviewContent {
    margin-bottom: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 500px;
}

.eventPreviewContentRow {
    display: flex;
    margin-bottom: 12px;
}

.eventPreviewContentRow:last-child {
    margin-bottom: 0;
}

.eventPreviewContentLeft {
    width: 30px;
    height: 30px;
    min-width: 30px;
}

.eventPreviewType {
    width: 14px;
    height: 14px;
    border-radius: 4px;
    margin-top: 5px;
    background: #ccc;
}

.eventPreviewInfo {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
}

.eventPreviewTitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #3c4043;
    margin-bottom: 2px;
}

.submoduleNamePreview {
    font-size: 14px;
    color: #3c4043;
    margin-bottom: 3px;
}

.eventPreviewDate {
    font-size: 13px;
    font-weight: 400;
    color: #3c4043;
}

.eventPreviewDate__dot {
    margin: 0 6px;
}

.tooltipCard {
    padding-top: 0;
    font-size: 11px;
}

.infoIcon {
    width: 20px;
    height: 20px;
    margin-top: 2px;
}

.warningIconWrap {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.warningIcon {
    width: 60%;
}
