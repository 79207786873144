.filterWrap {
    margin-bottom: 30px;
}

.createBtnWrap {
    display: flex;
    justify-content: flex-end;
}

.plusIcon {
    height: 24px;
    width: 24px;
}
