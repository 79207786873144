.container {
    display: flex;
    flex-direction: column;
}

.downloadButton {
    align-self: flex-end;
    margin-right: 48px;
}

.pdfPage {
    padding: 32px;
    width: 100%;
}

.titleSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    font-family: Poppins, sans-serif;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--black-300);
}

.logo {
    height: 48px;
}

.pageTitle {
    font-family: Poppins, sans-serif;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-base);
    margin-bottom: 0;
}

.studentSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 40px;
}

.studentSection .studentRow {
    font-family: Poppins, sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    margin-bottom: 6px;
}

.studentSection .studentRow .studentLabel {
    min-width: 120px;
    text-align: right;
    font-weight: bold;
    color: var(--black-base);
}

.studentSection .studentRow .studentValue {
    color: var(--black-300);
    line-height: 1.2;
}

.tableContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 30px;
}

.table {
}

.tableColumn {
    padding: 4px 6px;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-base);
    vertical-align: top;
}

.tableHeaderText {
    border-bottom: 1px solid var(--black-100);
    padding: 6px;
    margin-bottom: 6px;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-200);
    vertical-align: top;
}

.money {
    text-align: right;
}

.receiptText {
    max-width: 120px;
    overflow: hidden;
    /*white-space: nowrap;*/
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.totalRow {
    border-top: 1px solid var(--black-100);
}

.descText {
    font-size: 12px;
    color: #939393;
}
