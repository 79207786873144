.title {
    display: flex;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--black-100);
    font-size: 37px;
    font-weight: bold;
    line-height: 1.41;
    color: var(--black-300);
}

.main {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 12px 0;
}

.loadingData {
    margin-top: 12px;
}

.emptyList {
    font-size: 12px;
    font-style: italic;
    /* text-align: center; */
}

.pinned {
    padding: 6px 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.pinned:hover {
    background-color: #f5f5f5;
}

.pinnedContent {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--black-300);
    word-wrap: break-word;
    white-space: pre-wrap;
}

.attachContent {
    font-size: 12px;
    line-height: 1.5;
    color: var(--black-300);
    font-style: italic;
}

.messageContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.infoContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.fullName {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    color: #000;
}

.pinned:hover .removeWrap {
    display: flex;
}

.removeWrap:focus {
    display: flex;
    box-shadow: 0 0 0 2px var(--secondary-400-base), 0 3px 5px 0 rgba(48, 49, 51, 0.1), 0 0 1px 0 rgba(48, 49, 51, 0.05);
    background: var(--primary-100);
}

.actionWrap {
    display: flex;
    justify-content: flex-end;
    padding-top: 8px;
    border-top: 1px solid var(--black-100);
}

.dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--black-300);
}

.timestamp {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.29;
    color: var(--black-300);
}
