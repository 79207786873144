.wrap {
    position: relative;
}

.fillOut {
    z-index: 2;
}

.fieldAction {
    position: relative;
    border: solid 1px;
    min-width: 12px;
    min-height: 12px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.fieldActionSet {
    opacity: 0.5;
}

.resizableBox {
    position: absolute;
    cursor: move;
    z-index: 999;
}

.customHandleResizable {
    position: absolute;
    bottom: -4px;
    right: -4px;
    width: 8px;
    height: 8px;
    background-color: #1153aa;
    opacity: 0.75;
    border-radius: 4px;
    cursor: se-resize;
}

.icon {
    width: 60%;
    height: 60%;
}

.indicatorWrap {
    position: absolute;
    top: 4px;
    left: 4px;
    display: flex;
    align-items: center;
}

.indicator {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 3px;
    background: #000;
}

.errorField {
    border: 2px solid var(--error-400-base);
}
