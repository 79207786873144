.wrap {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    box-shadow: 0 4px 24px 0 rgba(48, 49, 51, 0.16);
    padding: 8px;
    height: 100%;
    cursor: pointer;
}

.headerWrap {
    display: flex;
    height: 50px;
}

.status {
    width: 20px;
    height: 20px;
    min-width: 20px;
    margin-right: 10px;
    border-radius: 6px;
}

.status__started {
    background-color: #ff8a38;
}

.status__done {
    background-color: #18a957;
}

.status__overdue {
    background-color: #df1642;
}

.status__todo {
    background-color: #125699;
}

.status__rejected {
    background-color: #df1642;
}

.status__awaiting {
    background-color: #b3246d;
}

.status__draft {
    background-color: #777476;
}

.titleWrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: calc(100px - 32px);
}

.title {
    font-size: 16px;
    font-weight: bold;
    color: #666666;
    margin-bottom: 0;
    line-height: 1.14;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 2px;
}

.count {
    font-size: 32px;
    line-height: 36px;
    font-weight: bold;
    color: #212121;
    margin-bottom: 0;
    padding: 20px 24px 60px;
    text-align: center;
}
