.studentHeaderAction {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.studentHeaderActionInfo {
    display: flex;
    align-items: center;
    border-radius: 12px;
    background-color: #ffebf5;
    padding: 12px 16px;
}

.studentHeaderAction__title {
    font-size: 16px;
    font-weight: 500;
    color: #212121;
    margin-bottom: 0;
    margin-left: 16px;
}

.studentHeaderActionWrap {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
}

.rejectBtn {
    margin-right: 40px;
    text-transform: uppercase;
}

.openTaskBtn {
    text-transform: uppercase;
}

@media (max-width: 768px) {
    .studentHeaderAction {
        margin-bottom: 12px;
    }

    .studentHeaderActionInfo {
        margin-right: 10px;
    }

    .studentHeaderAction__title {
        font-size: 12px;
        line-height: 13px;
    }

    .rejectBtn {
        margin-right: 10px;
    }
}
