.wrap {
    border-radius: 12px;
    background-color: var(--primary-100);
    margin-top: 24px;
    margin-bottom: 24px;
    padding: 20px;
}

.tableWrap {
    padding: 12px;
    background: #fff;
    border-radius: 8px;
}

.activity__title {
    font-size: 32px;
    font-weight: 800;
    color: var(--black-200);
    margin-bottom: 0;
}

.headerActivities {
    display: flex;
    justify-content: space-between;
}
