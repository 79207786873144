.root {
    position: absolute;
    top: 0px;
    right: 2px;
    z-index: 20;
    width: auto;
    height: 34px;
    padding: 6px;
    border-radius: 8px;
    box-shadow: 2px 4px 24px 0 rgba(0, 0, 0, 0.16);
    background-color: var(--whitebase);
    display: flex;
    align-content: flex-start;
    justify-content: space-between;
    gap: 10px;
}

.item {
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.item:hover .icon > * {
    fill: var(--primary-400-base);
}

.icon {
    width: 20px;
    height: 20px;
}

.iconRotate {
    transform: rotateY(180deg);
}

.divider {
    width: 1px;
    height: 20px;
    background-color: var(--black-100);
}

.iconWrap {
    display: flex;
    cursor: pointer;
    align-items: center;
}

.menuIcon {
    width: 20px;
    height: 20px;
}

.menuIcon > * {
    fill: var(--primary-400-base);
}
