.title {
    font-family: Poppins;
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-300);
    text-transform: uppercase;
}

.box {
    width: 520px;
    height: 480px;

    flex-grow: 0;
    margin: 40px 0 170px 40px;
    padding: 28px 24px 24px 22px;
    border-radius: 12px;
    border: solid 1px var(--black-200);
    background-color: #f5f5f5;
    position: relative;
}

.box label {
    flex-grow: 0;
    margin-bottom: 8px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-300);
}

.input {
    height: 44px;
}

.inputRow {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
}

.inputColumn {
    flex: 1;
}

.payButton {
    height: 48px !important;
    font-size: 21px;
    max-height: 48px !important;
    width: calc(100% - 48px);
    text-transform: uppercase;
    text-align: center;
    position: absolute;
    bottom: 24px;
    position: absolute !important;
}
.payButton span {
    margin: 0 auto;
}

.dollarSignIcon {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background-color: var(--primary-400-base);
}

.notice {
    display: flex;
    align-items: center;

    height: 36px;
    padding: 8px 19px 8px 8px;
    border-radius: 8px;
    background-color: var(--secondary-100);
    max-width: fit-content;

    margin-top: 18px;
    margin-bottom: 24px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-300);
}
.notice svg {
    margin-right: 16px;
    width: 20px;
}
