.title {
    font-size: 24px;
    font-weight: 800;
    color: var(--black-300);
}

.courseGradeWrap {
    margin-top: 30px;
}

.inputWrap {
    display: flex;
    align-items: center;
    gap: 12px;
}

.extraInput {
    flex: 1;
    min-width: 60px;
}

.action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
    margin-top: 30px;
}

.passFailWrap {
    height: 100%;
    display: flex;
    align-items: center;
}

.dropDownPassFail {
    border: 1px solid gray;
    border-radius: 5px;
    color: red;
    width: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 5px;
}

.dropDownPass {
    color: green !important;
}

.dropDownFail {
    color: red !important;
}
