.subtitle {
    flex-grow: 0;
    margin: 16px 0;
    font-family: Poppins;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-300);
}
.notice {
    display: flex;
    align-items: center;
    padding: 12px 16px 12px 16px;
    border-radius: 8px;
    background-color: var(--secondary-100);
    max-width: 694px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-300);
    height: 48px;
}
.notice svg {
    margin-right: 16px;
    width: 24px;
}
.line {
    height: 1px;
    flex-grow: 0;
    margin: 20px 0;
    background-color: var(--black-100);
}

.balanceBox {
    width: 213px;
    height: 76px;
    flex-grow: 0;
    padding: 8px 46px 12px 16px;
    border-radius: 8px;
    background-color: #f5f5f5;
}

.balanceBoxTitle {
    flex-grow: 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-200);
}
.balanceBoxIcon {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background-color: var(--primary-400-base);
}

.balanceBoxBody {
    font-family: Poppins;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-300);
    margin-left: 16px;
}

.button {
    height: 48px !important;
    font-size: 21px;
    max-height: 48px !important;
}
.app .table {
    overflow: initial !important;
}
.table tbody tr {
    border: solid 2px #c7c7c7;
    background-color: var(--whitebase);
}
.table tbody th {
    border: solid 2px #c7c7c7;
    background-color: var(--whitebase);

    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}

.dropdown,
.dropdown div:first-of-type,
.table :host.klassapp-dropdown,
.table :host.klassapp-dropdown div:first-of-type {
    border: none !important;
    box-shadow: none;
    max-width: 100%;
    border-radius: 0px;
}

.disabled {
    background-color: var(--black-100);
    width: 100%;
    height: 100%;
}

.actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 16px 0;
}

.actionBtns {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.global {
    display: flex;
    justify-content: center;
    align-items: center;
}
