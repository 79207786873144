.wrap {
    border-radius: 12px;
    box-shadow: 0 4px 24px 0 rgba(48, 49, 51, 0.16);
    margin-top: 40px;
    padding: 12px 20px;
}

.headerWrap {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.headerLeft {
    flex: 2;
}

.headerLeftTitleWrap {
    display: flex;
    margin-bottom: 10px;
}

.headerLeft__title {
    font-size: 20px;
    font-weight: 800;
    color: var(--black-200);
    padding-bottom: 6px;
    margin-right: 30px;
    margin-bottom: 10px;
}

.headerLeft__desc {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    font-weight: bold;
    color: var(--primary-400-base);
    padding: 5px 20px;
    border: 1px solid var(--primary-400-base);
    margin-bottom: 0;
    border-radius: 4px;
    cursor: pointer;
}

.headerLeftInfoWrap {
    display: flex;
    align-items: center;
}

.headerLeftInfo {
    display: flex;
    align-items: center;
    margin-right: 25px;
}

.headerLeftInfo__color {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    background-color: var(--success-400-base);
}

.headerLeftInfo__colorTarget {
    background-color: var(--secondary-500);
}

.headerLeftInfo__title {
    font-size: 14px;
    font-weight: 500;
    color: var(--black-base);
    margin-bottom: 0;
    margin-left: 8px;
}

.headerRightWrap {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.headerRightItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    border: solid 1px var(--success-400-base);
    background-color: var(--whitebase);
    margin-left: 10px;
    padding: 0px 40px;
}

.headerRightItemTarget {
    border: solid 1px var(--secondary-500);
}

.headerRightItem__title {
    font-size: 14px;
    font-weight: 600;
    color: var(--success-300);
    margin-bottom: 0;
}

.headerRightItem__titleTarget {
    color: var(--secondary-500);
}

.headerRightItem__value {
    font-size: 36px;
    font-weight: bold;
    color: var(--success-400-base);
    margin-bottom: 0;
}

.headerRightItem__valueTarget {
    color: var(--secondary-500);
}

.startDateWrap {
    display: flex;
    height: 30px;
    min-height: 30px;
}

.startDateBodyWrap {
    position: relative;
}

.startDateItem {
    position: absolute;
    display: flex;
}

.startDateItem__icon {
    width: 20px;
    height: 20px;
}

.startDateItem__value {
    margin-left: 4px;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0;
    color: #212121;
}

.yearDesc {
    font-size: 25px;
    font-weight: bold;
    color: #666666;
    margin-bottom: 0;
}
