.infoChoicesWrap {
    display: flex;
    border-radius: 12px;
    border: solid 1px var(--primary-400-base);
    padding: 16px 19px;
    flex-direction: column;
    font-weight: 500;
    width: 600px;
}
.infoTitleChoicesWrap {
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight: bold;
}
.radioGroup {
    display: flex !important;
    width: 340px;
}
.radioGroup > .ant-radio-wrapper > span {
    font-size: 16px !important;
}

.doneBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 40px;
}

.cancelBtn {
    margin-right: 20px;
    padding: 7px 17px !important;
    height: auto !important;
    margin-left: auto;
}
