.balanceBox {
    width: 213px;
    height: 76px;
    flex-grow: 0;
    padding: 8px 46px 12px 16px;
    border-radius: 8px;
    background-color: #f5f5f5;
}

.balanceBoxTitle {
    flex-grow: 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-200);
}
.balanceBoxIcon {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background-color: var(--primary-400-base);
}

.balanceBoxBody {
    font-family: Poppins;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-300);
    margin-left: 16px;
}
