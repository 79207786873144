.header {
    background-color: var(--primary-100);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 238px;
    min-width: 238px;
    transition: 0.3s;
}

.wrapper {
    height: 100vh;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.collapsed {
    width: 30px;
    min-width: unset;
}

.logo {
    overflow: hidden;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 0 26px 5px;
    margin-top: 31px;
}

.logoLink {
    padding: 5px;
    margin: 0;
    display: block;
    /*    height: 40px;*/
    border-radius: 5px;
}

.logoImg {
    height: 100px;
    object-fit: contain;
}

.logoLink:focus {
    box-shadow: 0 0 0 2px var(--secondary-400-base);
}

.logoIcon {
    width: 135px;
    height: 35px;
}

.sideBarWrapper {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
}

.hidden {
    display: none;
}

@media (max-width: 768px) {
    .header {
        width: 160px;
        min-width: 160px;
    }

    .collapsed {
        min-width: 6px;
        width: 6px;
    }

    .logo {
        padding: 0 12px;
        margin-top: 12px;
    }

    .sideBarWrapper {
        margin: 10px 0;
    }

    .logoImg {
        height: 60px;
    }
}
