.tabHeader {
    display: flex;
    align-items: center;
}

.tabIcon {
    width: 20px;
    height: 20px;
    margin-right: 4px;
}

.tabTitle {
    font-size: 14px;
    color: var(--black-300);
}

:global(.ant-tabs .ant-tabs-tab.ant-tabs-tab-active) .tabTitle {
    color: #1890ff;
}
