.root {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 6px;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
    background-color: var(--whitebase);
    overflow: hidden;
}

.actionWrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.optionWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    flex-wrap: wrap;
}

.option {
    cursor: pointer;
}

.icon {
    width: 24px;
    height: 24px;
}

.divider {
    height: 24px;
    width: 1px;
    background-color: var(--black-100);
}

.checkWrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    padding: 4px 8px;
    border-radius: 8px;
    background-color: #f5f5f5;
    cursor: pointer;
}

.checkedWrap {
    background-color: var(--primary-100);
}

.checkTitle {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    color: #000;
}

.headingIcon {
    width: 20px;
    height: 20px;
}

.headingTitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: var(--primary-400-base);
    text-transform: uppercase;
}

.titleInputWrap {
    margin: 12px 0;
    width: 100%;
}

.titleInput {
    width: 100%;
    border: none;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.29;
}

.titleInput::placeholder {
    color: var(--black-100);
}

.titleInput:focus {
    outline: none;
}

.channelInputWrap {
    max-width: 360px;
}

.replyWrap {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    background-color: #f5f5f5;
    margin: 0 -16px;
    padding-left: 16px;
}

.replyContainer {
    background-color: #f5f5f5;
    padding: 8px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-left: solid 2px var(--primary-400-base);
}

.cancelReply {
    margin-right: 16px;
}

.cancelReplyIcon {
    width: 20px;
    height: 20px;
    color: var(--error-400-base);
}

.option > input {
    display: none;
}

.attachmentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 8px;
    max-width: 200px;
    border-radius: 12px;
    border: solid 1px var(--black-100);
    position: relative;
}

.removeIcon {
    cursor: pointer;
    flex: 1;
    min-width: 24px;
}

.fileIcon {
    flex: 1;
    min-width: 24px;
}

.attachmentFileName {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: var(--black-300);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.previewMedia {
    width: auto;
    height: 70px;
}

.removePreview {
    cursor: pointer;
    flex: 1;
    min-width: 24px;
    position: absolute;
    border-radius: 50%;
    background-color: #f5f5f5;
    top: 4px;
    right: 4px;
}

.emojiPicker {
    bottom: 100% !important;
    margin-bottom: 10px !important;
    right: unset !important;
    left: 16px !important;
    top: unset !important;
    transform: unset !important;
    max-height: unset !important;
}

.actionRightWrap {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
}

.scheduledSettingWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    width: fit-content;
    height: 40px;
    cursor: pointer;
}

.scheduledText {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: #000;
    white-space: nowrap;
}

.messageRichTextEditor {
    max-height: 250px !important;
}

.mediaLibraryIcon {
    width: 24px;
    height: 24px;
    min-width: 24px;
}
