.statusContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.status {
    color: #fff;
    font-size: 12px;
    padding: 5px 8px;
    margin: 0;
    border-radius: 7px;
    text-transform: capitalize;
    text-align: center;
}
