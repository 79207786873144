.actionWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.cancelBtn {
    margin-right: 40px;
}

.mt3 {
    margin-top: 30px;
}

.actionBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
    flex-direction: row-reverse;
}

.emailTemplateMeta {
    display: flex;
    gap: 6px;
    background: #f5f5f5;
    padding: 5px 16px;
    border-radius: 4px;
    color: #636363;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
}

.emailTemplateMeta .createdAt {
    color: #929292;
}

.emailTemplateActions {
    display: flex;
}

.divider {
    background: #62b1ff;
    margin: 40px 0 !important;
}

.footerInfo {
    padding: 6px 16px;
    border-radius: 4px;
    background-color: #f5f5f5;
    font-size: 14px;
    font-weight: 500;
    color: var(--black-200);
}

.composeEmail {
    font-weight: bold;
    margin-bottom: 10px;
}

.templateTextEditor {
    min-height: 220px;
}
