.addAction {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
}

.selectCourseWrap {
    padding: 16px 16px 18px;
    border-radius: 8px;
    background: #f5f5f5;
}

.selectCourseTitle {
    font-size: 18px;
    font-weight: bold;
    color: var(--black-300);
    text-transform: uppercase;
}

.selectCourseTable {
    background: #fff;
}

.classAssistance {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}
.classAssistanceInfo {
    margin-top: 5px;
}
