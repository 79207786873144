.root {
    border-radius: 50%;
    margin-right: 10px;
    width: 40px;
    height: 40px;
    overflow: hidden;
    background-color: var(--black-100);
}

.image {
    width: 100%;
    height: 100%;
    object-position: center center;
    object-fit: cover;
}
