.popupContent {
    padding-right: 0 !important;
    padding-bottom: 0 !important;
}

.popupWrapper {
    display: flex;
    flex-direction: column;
}

.modalTitle {
    color: var(--black-300);
    font-size: 37px;
    font-weight: bold;
    margin-bottom: 0px;
}

.modalContent {
    width: 60vw !important;
}

.modalContent.spanFull {
    width: 80vw !important;
}

.modalLeftSide {
    padding: 24px 24px 24px 0;
}

.modalRightSide {
    padding: 40px;
    background-color: #fbfbfb;
    border: solid 1px var(--black-100);
}

.sectionTitle {
    font-size: 21px;
    font-weight: bold;
    color: var(--black-200);
    text-transform: uppercase;
}

.radioGroup :global(.ant-radio-button-wrapper) {
    border-radius: 24px !important;
    margin-inline-end: 8px !important;
    font-weight: bold !important;
    border-left-width: 1px !important;
}

.radioGroup :global(.ant-radio-button-wrapper:not(:first-child)::before) {
    display: none !important;
}

.highlightBlock {
    margin: 0 !important;
    padding: 16px 24px;
    border-radius: 12px;
    background-color: #fbfbfb;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    font-size: 18px;
    font-weight: 500;
    color: #000;
}

.blockTitle {
    font-size: 18px;
    font-weight: bold;
    color: var(--black-200);
}

.textGray {
    color: var(--black-200);
}

.textGraySmall {
    font-size: 12px;
    line-height: 1.33;
    color: var(--black-200);
}

.input {
    width: 100%;
    height: 40px;
    border: solid 2px #e5e5e5;
    outline: none;
    padding: 8px 14px;
    font-size: 16px;
    text-align: right;
    color: var(--black-base);
    margin: 0;
}

.checkbox {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    border-radius: 12px;
    border: solid 1px #e5e5e5;
    background-color: var(--whitebase);

    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: var(--black-base);
    text-transform: uppercase;
}

.checkbox > * + * {
    margin-right: 0;
    margin-left: 12px;
}

.checkbox label::before {
    border-color: #e5e5e5;
}

.checkbox.active {
    border: solid 1px var(--primary-400-base);
}

.checkbox.active label::before {
    border-color: var(--primary-400-base);
}

.chargeId {
    color: #3e3636;
    font-weight: bold;
    margin-right: 6px;
}

.chargeDate {
    margin-left: 8px;
    color: #666666;
    font-size: 11px;
    text-transform: capitalize;
}

.contentInput {
    width: 120px;
    border: solid 2px #e5e5e5;
    padding: 8px 14px;
    text-align: right;
}

.paymentError {
    text-align: right;
    font-size: 12px;
    color: var(--error-400-base);
}
