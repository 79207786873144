.fc-timegrid-slot {
    height: 45px !important;
}

.fc-col-header-cell-cushion {
    color: var(--black-base) !important;
    font-weight: 400 !important;
}

.fc-col-header > tbody > tr > th {
    height: 50px !important;
}

.fc-theme-standard .fc-scrollgrid,
.fc-col-header > tbody > tr > th:first-child {
    border: none !important;
}

.fc-col-header > tbody > tr > th {
    border-right: none !important;
    border-left: none !important;
}

.fc-timegrid-slot-label {
    vertical-align: top !important;
    border-top: none !important;
}

.fc-col-header-cell {
    padding: 8px !important;
}

.fc-day {
    vertical-align: middle !important;
}

.fc-day > div > a {
    padding: 4px 4px !important;
    width: 60px;
    height: 60px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    word-wrap: break-word;
    white-space: pre-line;
    text-decoration: none !important;
}

.fc-day > div > a:hover {
    background-color: #f1f3f4;
    border-radius: 50%;
}

.fc-day-today > div > a {
    background-color: var(--primary-400-base) !important;
    color: var(--whitebase) !important;
    border-radius: 100%;
}

.conflictEvent {
    width: 100% !important;
    background: rgba(223, 22, 66, 0.6) !important;
    left: 0 !important;
    z-index: 100 !important;
    border: solid 2px var(--error-400-base) !important;
}

.fc-timegrid-now-indicator-line {
    border-width: 2px 0 0 !important;
    border-color: var(--error-400-base) !important;
}

.fc-timegrid-now-indicator-line::before {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: var(--error-400-base) !important;
    margin-top: -7px;
}

.fc-popover {
    background: var(--whitebase) !important;
    border-radius: 8px !important;
    border: unset !important;
    box-shadow: 0 4px 24px 0 rgb(48 49 51 / 16%) !important;
}

.fc-popover-header {
    border-radius: 8px 8px 0 0 !important;
    background: #e5e5e5 !important;
}

.fc-popover-body {
    background: var(--whitebase) !important;
    max-height: 500px;
    overflow: auto;
}

.fc-daygrid-day-number {
    color: var(--black-base) !important;
}

.fc-daygrid-day-top {
    justify-content: center !important;
}

.fc-daygrid-day.fc-day.fc-day-wed.fc-day-today .fc-daygrid-day-number {
    background: var(--primary-400-base) !important;
    color: var(--whitebase) !important;
    border-radius: 100%;
    width: 24px !important;
    height: 24px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.fc-col-header-cell-cushion {
    font-size: 17px;
}

.fc-toolbar {
    padding-left: 5px !important;
    align-items: center !important;
    justify-content: flex-start !important;
    margin-top: 3px;
}

.fc-today-button,
.fc-todayBtn-button {
    background-color: var(--whitebase) !important;
    color: var(--primary-400-base) !important;
    border-radius: 4px !important;
    border: 2px solid var(--primary-400-base) !important;
    padding: 8px 25px !important;
    cursor: pointer !important;
    background: var(--whitebase) !important;
    text-transform: uppercase !important;
    color: var(--primary-400-base) !important;
    font-weight: 600 !important;
}

.fc-next-button,
.fc-prev-button,
.fc-prevIcon-button,
.fc-nextIcon-button {
    background-color: var(--whitebase) !important;
    border: none !important;
    border-radius: 4px !important;
    margin-right: 5px !important;
    color: var(--black-base) !important;
}

.fc-button-primary:focus {
    box-shadow: unset !important;
}

.fc .fc-daygrid-day-number {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
    margin-top: 3px;
    height: 24px;
    width: 24px;
    line-height: 24px;
    text-decoration: none !important;
}

.fc a[data-navlink]:hover {
    background-color: #f1f3f4;
    border-radius: 50%;
}

.fc .fc-more-popover {
    z-index: 0;
    opacity: 0;
}

.fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 10px !important;
    font-size: 0.9em;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end > span,
.fc-direction-rtl .fc-daygrid-event.fc-event-start > span {
    width: 100%;
}

.fc-media-screen .fc-timegrid-event {
    margin-right: 10px;
}

@keyframes ghost-chip-fade-in-out {
    0% {
        opacity: 0.1;
    }
    50% {
        opacity: 0.3;
    }
    to {
        opacity: 0.1;
    }
}

.time-event-animation {
    animation: 3s ease-in-out 0s infinite ghost-chip-fade-in-out;
    border: 2px #202124 solid !important;
    border-radius: 4px;
}

.home-calendar .fc-timegrid-slot {
    height: 40px !important;
}

.not-work-day {
    background-color: var(--warning-100) !important;
}

.holiday-day {
    background-color: #ffeff3 !important;
}

.course-schedule-calendar .fc-col-header-cell {
    padding: 0 !important;
}

.course-schedule-calendar .fc-day > div > a {
    margin: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70px;
}

.course-schedule-calendar .fc-day > div > a:hover {
    background: none;
    border-radius: 0;
}

.course-schedule-calendar .fc-day-today > div > a {
    background-color: #fff !important;
    border-radius: 0;
    color: inherit !important;
}

@media (max-width: 768px) {
    .fc-day > div > a {
        width: 40px;
        height: 40px;
        font-size: 11px;
        line-height: 13px;
    }
    .fc-col-header-cell {
        padding: 4px !important;
    }
}
