.modalRoot {
    padding: 20px;
    width: "100%";
}
.modalTitle {
    font-size: 30px;
    font-weight: bold;
    color: var(--black-base);
    padding: 0px 10px;
    margin-bottom: 20px;
    display: inline;
}

.qrModalBody {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.qrModalQRCode {
    width: 390px;
    height: 390px;
}

.qrModalLabel {
    font-size: 18px;
    color: var(--black-300);
}

.qrModalText {
    font-size: 18px;
    margin-top: 10px;
}

.mt3 {
    margin-top: 30px;
}

.modalHeading {
    font-size: 24px;
    text-align: center;
    margin-top: 20px;
}

.logo {
    width: 125px;
    margin: 0 auto;
    display: block;
}

.centerQR {
    text-align: center;
    padding: 0 auto;
}

.disflex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mt2 {
    margin-top: 20px;
}

.defaultLogo {
    width: 80px;
    display: inline;
    margin-left: 10px;
}

.baseAddCircleBtn {
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-color: #ff349b !important;
    height: 50px !important;
    width: 50px !important;
    border-radius: 50%;
    font-weight: 700;
}

.downloadIcon {
    font-size: 20px;
    color: #ffffff;
}

.footer {
    font-size: 14px;
}
