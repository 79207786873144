.drawerWrap {
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow-x: hidden;
}

.panelApplicationWrap {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    width: 400px;
    height: 100%;
    border-right: solid 1px var(--black-100);
    background-color: var(--whitebase);
    padding-bottom: 16px;
}

.panelApplicationContainer {
    height: 100%;
    overflow-y: scroll;
}

.panelWrap {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.jobWrap {
    padding: 16px 16px 0px 16px;
    background-color: #f5f5f5;
}

.sectionTitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: var(--black-200);
    text-transform: uppercase;
    margin-bottom: 12px;
}

.applicationWrap {
    padding: 16px;
}

.applicationTitleWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profileLinkIconWrap {
    width: 24px;
    height: 24px;
    padding: 4px;
    border-radius: 16px;
    background-color: var(--secondary-400-base);
    cursor: pointer;
}

.profileLinkIcon {
    width: 16px;
    height: 16px;
    fill: var(--whitebase);
}

.statusTitle {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
    color: var(--black-300);
}

.statusList {
    display: flex;
    gap: 10px;
    align-items: center;
    padding-top: 8px;
    flex-wrap: wrap;
}

.profileAction {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding-bottom: 12px;
}

.profileActionWrap {
    display: flex;
    align-items: center;
    gap: 10px;
}

.profileActionIconWrap {
    width: 36px;
    height: 36px;
    padding: 8px;
    border-radius: 24px;
    background-color: var(--primary-100);
    cursor: pointer;
}

.profileActionIcon {
    width: 20px;
    height: 20px;
    fill: var(--primary-300);
}

.profileActionIconActiveWrap {
    background-color: var(--primary-400-base);
}

.profileActionIconActive {
    fill: var(--whitebase);
}

.reactionActionWrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.profile {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 5px;
    padding-bottom: 12px;
}

.avatar {
    width: 64px;
    height: 64px;
    background-color: var(--black-100);
    border-radius: 12px;
}

.nameContainer {
    margin: 0 0 10px 10px;
}

.name {
    font-size: 18px;
    font-weight: 500;
    color: var(--black-base);
    line-height: 1.33;
}

.gender {
    font-size: 12px;
    font-weight: 500;
    color: var(--black-200);
    margin-top: 5px;
    line-height: 1.33;
}

.actionContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 12px;
    border-top: solid 1px var(--black-100);
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: solid 1px var(--black-100);
    flex-wrap: wrap;
}

.addButton {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.14;
    text-align: center;
}

.contentWrap {
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.appliedAt {
    display: inline-block;
    padding: 4px 8px;
    border-width: 0;
    border-radius: 8px;
    color: var(--black-200);
    background-color: #f5f5f5;
}

.subjectTitle {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    text-transform: uppercase;
    color: var(--black-base);
}

.subjectContent {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    padding-top: 8px;
    min-height: 200px;
    max-height: 300px;
    overflow-y: scroll;
}

.subjectEmpty {
    min-height: 300px;
    height: 200px;
    padding-top: 16px;
    text-align: center;
    margin: 30px;
}

.attachmentsWrap {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    margin-top: 10px;
    flex-wrap: wrap;
}

.documentWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 8px;
    max-width: 200px;
    border-radius: 12px;
    border: solid 1px var(--black-100);
    cursor: pointer;
}

.documentFileName {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: var(--black-300);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fileIcon {
    flex: 1;
    min-width: 24px;
}

.applicationViaWebsite {
    display: inline-block;
    padding: 8px 12px;
    border-radius: 12px;
    background-color: var(--primary-100);
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    color: #000;
    margin-bottom: 24px;
}

.rejectedInfoWrap {
    width: 100%;
    text-align: center;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: #ffeff3;
}

.rejectedLabel {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    color: var(--error-400-base);
    text-transform: uppercase;
}

.offerInfoWrap {
    width: 100%;
    text-align: center;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: var(--secondary-100);
}

.offerLabel {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    color: var(--secondary-400-base);
    text-transform: uppercase;
}

.descTitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: var(--black-200);
    text-transform: uppercase;
}

.descValue {
    margin: 16px 0 0;
    max-height: 300px;
    overflow-y: scroll;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    color: #000;
}

.expandedDescValue {
    max-height: unset;
    overflow: hidden;
    flex: 1;
}

.metadataWrap {
    display: flex;
    border-top: solid 1px var(--black-100);
    padding-top: 16px;
}

.metadata {
    display: flex;
    flex: 1;
    gap: 10px;
    padding: 7px 16px;
    border-radius: 4px;
    background-color: #f5f5f5;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    color: var(--black-200);
    text-transform: uppercase;
}

.studentActionWrap {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 8px;
}

.studentAction {
    justify-content: center;
}

.actionBorder {
    width: 1px;
    height: 36px;
    flex-grow: 0;
    background-color: var(--black-100);
}

.reactionContainer {
    display: flex;
    gap: 2px;
    cursor: pointer;
}

.count {
    font-size: 12px;
}

.applicationStatusWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 8px 0;
    border-radius: 8px;
    background-color: #e8faef;
    flex: 1;
}

.applicationStatus {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    text-align: center;
    color: var(--success-400-base);
}

.applicationAppliedIcon {
    width: 20px;
    height: 20px;
}

.noteWrap {
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
}

.noteContainer {
    border-top: solid 1px var(--black-100);
}
