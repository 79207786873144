.root {
    width: 100%;
    position: relative;
}

.input {
    width: 100%;
    height: 44px;
    background-color: transparent;
    outline: none;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: var(--black-bace);
    padding-left: 8px;
    padding-right: 35px;
    border-radius: 5px;
    border: solid 1px var(--black-100);
    border-radius: 6px;
}

.inputClear {
    padding-right: 60px !important;
}

.iconWrapper {
    width: 48px;
    height: 100%;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
}

.icon {
    fill: var(--primary-400-base);
    width: 24px;
    height: 24px;
}

.input:disabled {
    background-color: var(--grey-100);
}

.clearButton {
    position: absolute;
    top: calc(50% - 8px);
    right: 45px;
}

.input:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base), 0 3px 5px 0 rgba(48, 49, 51, 0.1), 0 0 1px 0 rgba(48, 49, 51, 0.05);
}

.input::placeholder {
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--black-200);
    padding-left: 0;
}
