.checklistIconWrap {
    display: flex;
    align-items: center;
}

.checklistIconWrap svg {
    height: 24px;
}

.statusContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.status {
    color: #fff;
    font-size: 12px;
    padding: 5px 8px;
    margin: 0;
    border-radius: 7px;
    text-transform: capitalize;
    text-align: center;
}

.status-done {
    background: #18a957;
}

.status-inprogress {
    background-color: #1e90ff;
}

.status-empty {
    background-color: #939393;
}

.studentTable tr {
    position: relative !important;
}

.studentTable th,
.studentTable td {
    font-size: 13px !important;
    min-height: 36px !important;
    height: 36px !important;
    padding: 5px 8px !important;
    border-right: 1px solid rgba(224, 224, 224, 1) !important;
}

.selectDropdown {
    height: 36px;
    min-height: 36px;
}

.selectDropdown * {
    max-height: 28px !important;
    min-height: unset !important;
}

.actionDetailWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
}

.actionDetailWrapChatPopup {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.chatPopupContainer {
    display: flex;
    flex-direction: column;
    width: 600px;
}

.chatPopup {
    min-height: 180px;
    justify-content: space-between;
    width: 400px;
}

.chatContainer {
    display: none;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 4px 16px 0 rgb(0 0 0 / 20%);
    position: absolute;
    background: #fff;
    z-index: 9;
}

.showChatContainer {
    display: block;
}

.chatPopupLeftContainer {
    width: 100%;
    height: 100%;
}

.expandIconContainer {
    margin-left: 8px;
    background-color: var(--primary-100);
    display: flex;
    align-items: center;
    padding: 3px;
    border-radius: 7px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    align-self: flex-end;
}
