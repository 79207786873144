.headingTitle {
    font-size: 37px;
    font-weight: bold;
    line-height: 1.41;
    color: var(--black-300);
    padding-bottom: 12px;
    border-bottom: solid 1px var(--black-100);
    margin-bottom: 16px;
}

.actionButtons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 20px;
}

.addOption {
    border-width: 1px !important;
    height: 40px !important;
    display: flex !important;
    justify-content: center;
    gap: 10px;
}
