.wrap {
    margin-top: 24px;
    padding: 12px 12px 16px;
    border-radius: 8px;
    background-color: #f5f5f5;
    margin-bottom: 40px;
    display: flex;
}

.stepItem {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.title {
    font-size: 16px;
    font-weight: 500;
    color: var(--black-300);
    margin-bottom: 6px;
}

.statusWrap {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.line {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
    height: 4px;
    background-color: #e5e5e5;
}

.completedLine {
    background-color: var(--success-400-base);
}

.inProgressLine {
    background-color: var(--primary-400-base);
    z-index: 1;
    width: 100px;
}

.statusCompletedWrap {
    height: 24px;
    display: flex;
    align-items: center;
    padding: 4px;
    border-radius: 24px;
    background-color: var(--success-400-base);
    z-index: 2;
    gap: 4px;
}

.status,
.statusCompletedIconWrap {
    background-color: #e5e5e5;
    border-radius: 50%;
    margin: 7px 0;
    position: relative;
}

.statusCompletedIconWrap {
    background-color: #fff;
    border: 7px solid var(--success-400-base);
}

.inProgressWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.statusCompletedText {
    font-size: 12px;
    font-weight: 500;
    color: var(--whitebase);
}

.statusEmpty {
    width: 16px;
    height: 16px;
    background-color: var(--black-100);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.statusCompletedIcon {
    width: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.currentWrap {
    position: absolute;
    left: 50%;
    display: flex;
    align-items: center;
    z-index: 1;
    background-color: var(--primary-400-base);
    height: 4px;
    width: 50%;
}

.currentDate {
    font-size: 12px;
    font-weight: bold;
    background-color: var(--primary-400-base);
    color: var(--whitebase);
    border-radius: 24px;
    height: 20px;
    padding: 4px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    z-index: 2;
}
