.title {
    flex-grow: 0;
    margin: 0 459px 8px 0;
    font-family: Poppins;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
.box {
    width: 639px;
    height: 480px;
    position: relative;
    flex-grow: 0;
    margin: 8px 0 0;
    padding: 4px 24px 24px;
    border-radius: 12px;
    background-color: #f5f5f5;
}

.box label {
    flex-grow: 0;
    margin-bottom: 8px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-300);
}

.input {
    height: 48px;
    flex-grow: 0;
    margin: 8px 0 0;
    padding: 8px 16px 8px 20px;
    border-radius: 6px;
    box-shadow: 0 2px 12px 0 rgba(48, 49, 51, 0.1);
}
.inputRow {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
}
.inputColumn {
    flex: 1;
}
.inputColumnSmall {
    flex: 0.45;
}
.icon {
    height: 34px;
    width: 34px;
}
.dataEncrypted {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 24px;
}
.dataEncryptedLabel {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-200);
    margin-left: 9px;
    margin-bottom: 0px;
}

.cardElement {
    margin-top: 48px;
    border-radius: 4px 4px 0 0;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    width: 100%;
    background: white;
    box-sizing: border-box;
}
.creditCardButtonWrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
    margin-right: -12px;
}

.cancelButton {
    margin-right: 18px;
}
