.containerWrap {
    display: flex;
    flex-direction: row;
    flex: 1;
    height: 100%;
}

.container {
    min-height: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
    flex: 1;
    overflow: hidden;
}

.heading {
    flex-shrink: 0;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 51px;
    max-height: 51px;
    border-bottom: solid 1px var(--black-100);
    overflow: hidden;
    gap: 6px;
}

.avatarWrap {
    overflow: hidden;
    border-radius: 50%;
    background-color: #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    text-transform: uppercase;
    color: var(--black-200);
    width: 40px;
    height: 40px;
    min-width: 40px;
    object-fit: cover;
}

.hasStudentAvatarWrap {
    color: var(--primary-400-base);
}

.title {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.33;
    color: #000;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: var(--black-100);
    min-height: 1px;
}

.titleWrap {
    display: flex;
    align-items: center;
    column-gap: 8px;
    overflow: hidden;
}

.roomActionWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.iconWrap {
    display: flex;
    cursor: pointer;
    align-items: center;
}

.icon {
    width: 20px;
    height: 20px;
}

.mainContent {
    flex-grow: 1;
    margin: 0;
    padding: 12px 16px 16px;
    background: #f5f5f5;
    min-height: 0;
    max-height: 100%;
}

.chatContent {
    overflow-y: auto;
    height: 100%;
    /* padding: 16px; */
    border-radius: 8px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
    background-color: var(--whitebase);
}

.messageBox {
    background: #f5f5f5;
    padding: 0px 16px 16px;
    position: relative;
}

.loading {
    display: flex;
    justify-content: center;
    padding: 10px;
}

.loadMoreText {
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--black-300);
}

.avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.fullName {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    color: #000;
}

.typingContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    background: #f5f5f5;
    padding: 4px 16px;
}

.roomInfoWrap {
    display: flex;
    flex-direction: column;
}

.onlineStatus {
    font-size: 10px;
    line-height: 1.33;
}

.onlineWrap {
    position: relative;
}

.onlineDot {
    position: absolute;
    bottom: 0;
    right: -5px;
    width: 16px;
    height: 16px;
    background-color: var(--success-400-base);
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid var(--whitebase);
}

.offlineDot {
    background-color: var(--black-200);
}
