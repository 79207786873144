.editorWrapper {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: solid 1px #c7c7c7;
    background-color: var(--whitebase);
    padding: 8px 14px 16px 14px;
    min-height: 80px;

    resize: vertical;
    overflow-y: auto;
    /*max-height: fit-content;*/
}

.spacePadding {
    flex: 1;
}

.placeholder {
    position: relative;
    height: 0;
    pointer-events: none;
    color: var(--black-base);
    opacity: 70%;
}

.toolbar {
    align-self: flex-start;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
    border-radius: 8px;
    background-color: #f5f5f5;
}

.toolbarDivider {
    height: 24px;
    width: 1px;
    margin: 0 4px;
    background-color: var(--black-100);
}

.toolbarButton {
    margin: 0 4px;
    padding: 4px;
    cursor: pointer;
    color: var(--black-base);
}

.toolbarButtonHighlight {
    background-color: #e6e7e9;
}

.editor {
}

.editor div[data-slate-node="element"] {
    margin-bottom: 8px;
}

.editor:global(.html) div[data-slate-node="element"] {
    margin-bottom: 0 !important;
}

.imageContent {
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.image {
    display: block;
    max-width: 100%;
    max-height: 300px;
    object-fit: contain;
}

.imageHighlight {
    border: solid 2px var(--primary-400-base);
}

.linkInput {
    margin-left: -20px;
}

.linkInputContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 320px;
}

.linkInputInput {
    width: 100%;
    margin-right: 16px;
}

.linkInputButton {
}

.linkInputIcon {
    margin: 0 4px;
    padding: 4px;
    cursor: pointer;
    color: var(--black-base);
}

.linkInputIconHighlight {
    background-color: #e6e7e9;
}

.dropdownMenu {
    max-height: 300px;
    overflow-y: auto;
}

.dropdownMenu :global(.ant-dropdown-menu-item-group-title) {
    background-color: var(--primary-100);
    font-size: 18px;
    font-weight: 700;
    line-height: 1.11;
    color: var(--black-200);
    padding-top: 4px;
    padding-bottom: 4px;
}

.dropdownMenu :global(.ant-dropdown-menu-item-group-list) {
    margin: 0;
}

.dropdownMenu :global(.ant-dropdown-menu-item-active) {
    background-color: var(--primary-200);
}

.dropdownTitle {
    padding: 4px 12px;
    background-color: var(--black-100);
    font-size: 14px;
    font-weight: 600;
    color: var(--black-200);
    text-transform: uppercase;
    user-select: none;
}

.fieldLabelContent {
    padding: 2px;
    font-size: 16px;
    font-weight: 600;
    color: var(--primary-300);
    cursor: default;
}

.fieldLabelContentHighlighted {
    color: var(--primary-200);
}

.fieldLabelContent.fieldReadOnly {
    color: var(--black-base);
}

.fieldInputContent {
    margin: 0 2px;
    display: inline-block;
    border: solid 1px transparent;
}

.fieldInputContentHighlighted {
    border-color: var(--primary-200);
}

.fieldInputText {
    width: auto;
}

.fieldInputDate {
    width: auto;
    background-color: white !important;
    border-color: #d9d9d9 !important;
}

.finAidTableContent {
}

.finAidTable {
    border: solid 2px #c7c7c7;
}

.finAidTable thead {
    border: none;
}

.finAidTable td {
    border: none;
}

.finAidTableContent.highlighted .finAidTable {
    border: solid 3px var(--primary-200);
}
