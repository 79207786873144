.wrap {
}

.filterWrap {
    margin: 12px 0 32px;
}

.actionBtnWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
}

.cancelBtn {
    margin-right: 20px;
}

.studentOption {
    display: flex;
    align-items: center;
    gap: 10px;
}
.studentOptionEnrollmentsContainer {
    display: flex;
    gap: 5px;
}
.studentOptionEnrollmentItem {
    border-radius: 2px;
    background: var(--primary-600);
    color: var(--whitebase);
    padding: 2px 4px;
}

.selectStudentWrap {
    display: flex;
    align-items: center;
    gap: 8px;
}

.selectAll {
    margin-bottom: 6px;
}

.newStudentWrap {
    margin-top: 12px;
}
