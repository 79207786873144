.root {
    width: 100%;
    position: relative;
}

.input {
    width: 100%;
    height: 44px;
    border: none;
    background-color: var(--whitebase);
    outline: none;
    padding: 8px 16px;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    letter-spacing: normal;
    color: var(--black-base);
    margin: 0;
    padding-right: 80px;
    border-radius: 6px;
    box-shadow: 0 3px 5px 0 rgba(48, 49, 51, 0.1), 0 0 1px 0 rgba(48, 49, 51, 0.05);
}

.iconWrapper {
    width: 44px;
    height: 44px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
}

.icon {
    fill: var(--primary-400-base);
    width: 24px;
    height: 24px;
}

.input:disabled {
    background-color: var(--grey-100);
}

.clearButton {
    position: absolute;
    top: calc(50% - 8px);
    right: 58px;
}

.input:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base), 0 3px 5px 0 rgba(48, 49, 51, 0.1), 0 0 1px 0 rgba(48, 49, 51, 0.05);
}

.input::placeholder {
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--black-200);
    padding-left: 0;
}
