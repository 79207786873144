.jobItem {
    flex-grow: 0;
    margin: 8px 0;
    padding: 16px 16px 14px;
    border-radius: 12px;
    border: solid 1px var(--black-100);
    background-color: var(--whitebase);
    min-height: 140px;
    display: flex;
}

.jobItemHover {
    background-color: var(--primary-100);
    cursor: pointer;
}

.jobLogo {
    width: 40px;
    height: 40px;
    margin: 0 8px 0 0;
}

.jobDescription {
    flex: 1;
}

.jobInfo {
    width: 230px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.jobTitle {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    text-align: left;
    color: var(--black);
}

.jobCompany {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    text-align: left;
    color: var(--black-300);
    margin-top: 2px;
    margin-bottom: 5px;
}

.jobLocation {
    margin-top: 14px;
    display: flex;
    align-items: center;
}

.locationIcon {
    width: 20px;
    height: 20px;
    background-image: url("./assets/location.svg");
}

.location {
    margin-left: 5px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    text-align: left;
    color: var(--black-300);
}

.jobCreated {
    margin-right: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    text-align: left;
    color: var(--black-300);
}

.tagWrap {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
}

.tagItem {
    padding: 2px 8px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    text-align: center;
}

.categoryItem {
    background-color: var(--black-200);
    color: var(--whitebase);
}

.jobTag {
    color: var(--black-300);
    background-color: var(--black-100);
}

.sourceAndReactionWrap {
    display: flex;
    align-items: center;
    gap: 10px;
}

.reactionContainer {
    display: flex;
    gap: 2px;
    cursor: pointer;
}

.count {
    font-size: 12px;
}
