.userWrap {
    position: relative;
    border-radius: 12px;
    border: solid 1px var(--primary-400-base);
    background-color: var(--whitebase);
    padding: 12px;
    overflow: hidden;
    padding-top: 2px;
}

.headerEditIconWrap {
    position: absolute !important;
    top: -6px;
    right: -4px;
}

.headerEditIconWrap svg {
    margin-top: 4px;
    width: 18px;
    height: 18px;
}

.avatar {
    border-radius: 12px;
    width: 32px;
    height: 32px;
    object-fit: cover;
}

.staffAvatar {
    border-radius: 12px;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.loadingWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.header {
    display: flex;
    gap: 24px;
}

.leftHeader {
    display: flex;
    align-items: center;
    flex: 1;
}

.studentName {
    font-size: 24px;
    line-height: 36px;
    font-weight: bold;
    color: var(--black-base);
    margin: 0px;
    margin-left: 8px;
    display: flex;
}

.studentGenderPronoun {
    color: var(--black-300);
    font-weight: 500;
    margin-left: 10px;
    font-size: 15px;
}

.studentInfoHeader {
    border-radius: 4px;
    background-color: var(--primary-100);
    padding: 2px 8px 3px;
}

.studentIdWrap {
    display: flex;
    justify-content: space-between;
    margin: 0;
}

.studentId__title {
    font-size: 14px;
    font-weight: 500;
    color: var(--black-300);
    min-width: 120px;
}

.studentId__value {
    font-size: 16px;
    font-weight: bold;
    color: var(--primary-400-base);
}

.enrollmentId {
    margin-left: 2px;
}

.rightHeader {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
}

.rightHeaderIconWrap {
    margin-left: 12px;
    cursor: pointer;
}

.rightHeaderIcon {
    width: 28px;
    height: 28px;
}

.content {
    display: flex;
    align-items: normal;
    gap: 24px;
    justify-content: flex-end;
}

.contentItem {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    border-bottom: 1px solid var(--black-100);
    padding: 2px 0;
}

.contentItemNoBorder {
    border: unset;
}

.content__title {
    font-size: 12px;
    font-weight: 500;
    color: var(--primary-400-base);
    text-transform: uppercase;
}

.content__value {
    font-size: 13px;
    font-weight: bold;
    color: var(--black-base);
}

.hideDetailWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: auto;
}

.hideStaffDetailWrap {
    margin-top: 6px;
}

.hideDetailBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 4px;
    background-color: var(--whitebase);
    padding: 4px 0;
    height: 32px;
    cursor: pointer;
    text-align: end;
    min-width: 160px;
}

.hideDetail__text {
    font-size: 13px;
    font-weight: 600;
    color: var(--primary-400-base);
    padding-right: 16px;
    text-transform: uppercase;
}

.hideDetailIconWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    background-color: var(--primary-100);
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
}

.hideDetailIcon {
    width: 12px;
}

.hideDetailIcon__collapse {
    transform: rotate(180deg);
}

.detailBody {
    margin-top: 16px;
    display: flex;
    gap: 24px;
}

.detailBodyLeft,
.detailBodyRight {
    border-radius: 10px;
    background-color: #f5f5f5;
    padding: 5px;
    flex: 1;
    height: fit-content;
}

.detailBodyRight__empty {
    background: transparent;
}

.studentPhoneEmail {
    display: flex;
    align-items: center;
    gap: 12px;
}

.studentPhoneWrap {
    display: flex;
    flex: 4;
    align-items: center;
}

.studentEmailWrap {
    display: flex;
    flex: 5;
    align-items: center;
}

.studentPhoneInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.studentPhoneIconWrap {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}

.studentPhoneIcon {
    width: 24px;
    height: 24px;
}

.studentPhone__value {
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-400-base);
    word-break: break-all;
    margin-bottom: 0;
}

.studentAddress {
    display: flex;
    align-items: center;
}

.selectAdvisor {
    display: flex;
    cursor: pointer;
}

.selectAdvisor__text {
    font-size: 13px;
    margin: 0;
    color: var(--primary-400-base);
    border: 1px solid var(--primary-400-base);
    padding: 4px 12px;
    border-radius: 4px;
}

.advisorWrap {
    display: flex;
    align-items: center;
    padding-right: 4px;
}

.advisorName {
    padding-right: 4px;
}

.defaultIcon {
    width: 16px;
    height: 16px;
}

.actionNeededWrap {
    min-height: 32px;
    border-radius: 4px;
    background-color: var(--error-400-base);
    display: inline-flex;
    align-items: center;
    padding: 4px 12px;
    margin-bottom: 8px;
    margin-right: 16px;
}

.actionNeededIcon {
    margin-left: 4px;
}

.actionNeededIcon path {
    fill: #fff;
}

.actionNeededText {
    font-size: 14px;
    font-weight: bold;
    color: var(--whitebase);
    margin-bottom: 0;
    margin-left: 6px;
}

.statusBar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--success-400-base);
    border-radius: 4px;
    padding: 2px;
    font-size: 11px;
    font-weight: 600;
    color: var(--primary-100);
    text-transform: capitalize;
    min-height: 20px;
}

.statusBarNotDefined {
    background-color: #939393;
}

.statusBar__inactive {
    background-color: var(--error-400-base);
}

.statusBar__admissions {
    background-color: var(--primary-500);
}

.statusBar__finAid,
.statusBar__studentAccount {
    background-color: var(--warning-400-base);
}

.advisorsWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.campusWrap {
    display: flex;
    flex-direction: column;
}

.enrollmentWrap {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.arrowDownIcon {
    height: 16px;
}

.statusText {
    padding-right: 3px;
}

.statusArrowDownIcon {
    height: 16px;
    width: 16px;
}

.statusItem {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 4px;
}

.title {
    margin-bottom: 0;
    font-size: 11px;
    font-weight: 600;
    color: #939393;
    flex: 1;
    margin-right: 4px;
}

.statusWrap {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 2px;
}

.statusContainer {
    display: flex;
    flex: 1;
}

.statusItem__cursor {
    cursor: pointer;
}

.menuActionWrap {
    max-height: 300px;
    overflow: auto;
}

.menuActionItem__status {
    text-transform: capitalize;
}

.campusName {
    text-align: right;
}

.ferpaButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-400-base);
    border-radius: 4px;
    padding: 2px;
    font-weight: 800;
    color: #fafafa;
    font-size: 11px;
}

.paddingBottom {
    padding-bottom: 5px;
}

.imgPadding {
    padding: 5px 0;
}

.messageBtnWrap {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.profileBtn {
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0);
    font-size: 14px;
    font-weight: bold;
    color: var(--primary-400-base);
    border: 1px solid var(--primary-400-base);
    height: 40px;
    padding: 10px 20px;
    margin-right: 24px;
    cursor: pointer;
    text-transform: uppercase;
}
