.root {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    min-height: 0;
    background: rgba(0, 0, 0, 0.9);
    color: #fff;
    z-index: 100;
    padding: 40px;
    display: none;
    grid-template-columns: auto;
    grid-template-rows: 64px 1fr;
    justify-items: stretch;
    align-items: center;
    transition-duration: 400ms;
    opacity: 1;
    transition: opacity 0.15s ease;
    overflow: auto;
}
.rootIsOpen {
    display: grid;
}
.actionsContainer {
    display: flex;
    justify-self: end;
    align-items: center;
}
.iconContainer {
    width: 48px;
    height: 48px;
    cursor: pointer;
    background-color: transparent;
    border: none;
}

.mediaContainer {
    width: fit-content;
    max-width: 100%;
    height: 100%;
    min-height: 0;
    display: grid;
    place-items: center;
    margin-left: auto;
    margin-right: auto;
}
.image {
    max-width: 100%;
    max-height: 100%;
    min-height: 0;
}

.video {
    max-width: 100%;
    min-width: 200px;
    max-height: 100%;
    min-height: 200px;
}
