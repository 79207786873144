.wrap {
    display: flex;
    align-items: center;
    background-color: var(--primary-100);
    margin-bottom: 2px;
    margin-top: 20px;
}

.actionWrap {
    display: flex;
    align-items: center;
}

.actionItem {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    margin-right: 3px;
    cursor: pointer;
}

.actionItemIcon {
    width: 24px;
    height: 24px;
}

.actionItemTitle {
    font-size: 14px;
    font-weight: 600;
    color: var(--black-base);
    margin-bottom: 0;
    margin-left: 4px;
}

.paginationWrap {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.paginationPanelWrap {
    width: 100%;
}

.panelWrap {
    background-color: unset;
}

.columnPicker {
    padding-right: 6px;
}

.plusIconBtn {
    background: transparent !important;
    border: none !important;
}

.iconPlus {
    height: 24px;
}
