.statusWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 102px;
    height: 30px;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
}

.statusText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.status__started {
    background-color: #ff8a38;
}

.status__done {
    background-color: #18a957;
}

.status__overdue {
    background-color: #df1642;
}

.status__todo {
    background-color: #125699;
}

.status__rejected {
    background-color: #df1642;
}

.status__done_with_rejected {
    background-color: #df1642;
}

.status__awaiting {
    background-color: #b3246d;
}

.status__draft {
    background-color: #777476;
}

@media (max-width: 768px) {
    .statusWrap {
        width: 56px;
        height: 18px;
        font-size: 8px;
    }
}
