.wrap {
    margin-bottom: 30px;
}

.statusWrap {
    display: flex;
    align-items: center;
}

.status {
    width: 32px;
    margin-right: 12px;
}

.status__approved {
    border: solid 1px var(--success-400-base);
    color: var(--success-400-base);
    background-color: #fff;
}

.status__rejected {
    background-color: var(--error-400-base);
    color: var(--whitebase);
}

.statusInfo {
    display: flex;
    flex-direction: column;
}

.statusInfo__text {
    font-size: 14px;
    font-weight: 500;
    color: var(--black-300);
    margin-bottom: 4px;
}

.createBtnWrap {
    display: flex;
    justify-content: flex-end;
}

.plusIcon {
    height: 24px;
    width: 24px;
}

.approveIcon path {
    fill: #18a957;
}

.rejectIcon path {
    fill: #df1642;
}

.printIcon path {
    fill: var(--primary-400-base);
}

.listingTypeContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.listingIcon {
    margin: 5px;
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
}

.listingIconSelected {
    background-color: #e9f4ff;
}

.popupTitle {
    font-size: 36px;
    font-weight: bold;
    line-height: 1.41;
    color: var(--black-300);
}

.popupHeading {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.14;
    color: var(--black-300);
}

.popupBody {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--black-200);
    margin-top: 4px;
}
