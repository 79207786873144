.popupTitle {
    font-size: 30px;
    font-weight: bold;
    color: var(--black-base);
}
.actionDetailWrapPopup {
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: flex-end;
    flex: 1;
}
.popupTitle {
    font-size: 25px;
    font-weight: bold;
    color: var(--black-base);
}
.buttonPopupConfirm {
    margin-left: 10px;
}
.smallTitle {
    font-size: 20px;
    color: var(--black-base);
}
