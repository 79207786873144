.wrap {
    padding: 0 40px;
}

.title {
    font-size: 37px;
    font-weight: bold;
    line-height: 1.41;
    color: #666666;
    padding-bottom: 14px;
    border-bottom: 1px solid #62b1ff;
    margin-bottom: 30px;
}

.body {
    overflow: auto;
    max-height: 70vh;
    padding: 0 10px;
}

.listStudent {
    max-height: 30vh;
    overflow: auto;
}

.lessonTime {
    display: inline-block;
    border-radius: 12px;
    background-color: var(--primary-400-base);
    font-size: 24px;
    font-weight: bold;
    color: var(--whitebase);
    padding: 8px 16px;
    margin-bottom: 24px;
}

.listStudents {
    margin: 24px 0 40px 0;
    position: relative;
}

.listStudentHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.totalStudent {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 24px;
    font-weight: bold;
}

.totalStudent__count {
    color: var(--primary-400-base);
}

.totalStudent__title {
    color: var(--black-200);
}

.rightHeader {
    display: flex;
    align-items: center;
    gap: 6px;
}

.rightHeader__title {
    font-size: 16px;
    font-weight: 600;
    color: var(--black-200);
}

.studentsBody {
    margin-top: 10px;
}

.action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.courseScheduleInfo {
    display: flex;
    align-items: center;
    height: 64px;
    gap: 16px;
    padding: 16px 24px;
    border-radius: 20px;
    border: solid 1px var(--black-100);
    font-size: 24px;
    font-weight: 500;
    color: var(--black-base);
    margin-bottom: 24px;
}

.courseScheduleInfo__schedule {
    color: var(--primary-400-base);
}

.scheduleDropdown {
    margin-bottom: 16px;
}

.studentHeader {
    display: flex;
    align-items: center;
    padding: 8px;
}

.checkboxCol {
    width: 10%;
    min-width: 10%;
}

.studentNameCol {
    font-size: 14px;
    font-weight: 500;
    color: var(--black-200);
    width: 60%;
}

.registerCol {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 30%;
    font-size: 14px;
    font-weight: 500;
    color: var(--black-200);
}

.checkedIcon {
    width: 24px;
    height: 24px;
}
