.title {
    font-size: 24px;
    font-weight: 800;
    color: var(--black-300);
}

.sectionInfo {
    display: flex;
    align-items: center;
}

.sectionTitle {
    font-size: 24px;
    font-weight: 800;
    color: var(--black-base);
    margin-right: 24px;
}

.tableWrap {
    max-height: 500px;
    overflow: auto;
}

.action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
}
