.container {
    margin-bottom: 48px;
}

.horizontalTable {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 1px;
    position: relative;
    width: 100%;
    max-height: 80vh;
    /*border: 1px solid var(--black-200);*/
}

.horizontalTableHeaderStructure {
    height: 64px;
    min-height: 64px;
    position: relative;
    background: transparent;
    overflow-y: hidden;
}

.headerStructureLeft {
    position: absolute;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: stretch;
    height: 64px;
}

.headerLeft {
    position: relative;
    color: var(--black-300);
    font-weight: bold;
    font-size: 18px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    background-color: #e9f4ff;
    color: #939393;
    /*border-left: 1px solid var(--black-100);*/
    border-right: 1px solid var(--black-100);
}

.headerLeft__title {
    user-select: none;
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 600;
    color: #939393;
    line-height: 1;
}

.headerStructureRight {
    height: 64px;
    position: absolute;
    right: 0;
    overflow: hidden;
}

.headerStructureRight .headerStructureScrollContent {
    padding-right: 17px;
}

.headerStructureScrollable {
    scrollbar-width: none;
    overflow-y: hidden;
    min-width: 1px;
    overflow-x: auto;
    transform: translate3d(0, 0, 0);
    width: 100%;
}

.headerStructureScrollContent {
    display: inline-flex;
    align-items: center;
    height: 64px;
}

.horizontalScrollPlaceholder {
    flex: 0 0 auto;
    visibility: hidden;
    height: 1px;
}

.horizontalScrollWrapper {
    position: absolute;
    right: 15px;
    bottom: 0;
    left: 0;
    z-index: 2;
    height: 16px;
}

.horizontalScrollbar__scrollable {
    min-width: 1px;
    overflow-x: auto;
    width: 100%;
    height: 100%;
}

.horizontalScrollbar__Content {
    height: 1px;
}

.headerRight {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 12px;
    position: relative;
    height: 100%;
    border-left: solid 1px var(--black-100);
    /*border-right: solid 1px var(--black-100);*/
    background-color: #f5f5f5;
}

.headerRight__title {
    user-select: none;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
    color: #939393;
    line-height: 1;
    max-height: 100%;
}

.verticalScroll {
    flex: 1;
    overflow-x: hidden;
    padding-bottom: 1px;
    position: relative;
    transform: translate3d(0, 0, 0);
    min-height: 1px;
    overflow-y: scroll;
}

.horizontalScroll {
    min-height: 100%;
    min-width: 1px;
    overflow-y: hidden;
    overflow-x: auto;
}

.horizontalScrollTotal {
    min-width: 1px;
    overflow-y: hidden;
    overflow-x: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.horizontalScroll::-webkit-scrollbar {
    display: none;
    height: 0;
}

/* Hide scrollbar for IE, Edge and Firefox */
.horizontalScroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.rowGroup {
    will-change: contents;
    overflow: unset;
    background: transparent;
    cursor: pointer;
}

.rowGroup:hover .cell {
    background-color: var(--primary-100) !important;
}

.rowHeaderGroup {
    will-change: contents;
    overflow: unset;
}

.rowItem {
    contain: style;
    display: flex;
}

.stickyCell {
    display: flex;
    position: absolute;
    left: 0;
    z-index: 1;
}

.cell {
    display: flex;
    align-items: center;
    position: relative;
    /*overflow: hidden;*/
    background-color: #fff;
    border-bottom: 1px solid var(--black-100);
    height: 40px;
    line-height: 1;
}

.cell__total {
    background-color: #f5f5f5;
}

.statusGroupWrap {
    display: flex;
    align-items: center;
    position: relative;
    /*border-right: 1px solid var(--black-100);*/
}

.statusGroupWrap:last-child {
    border-right: 1px solid var(--black-100);
}

.statusGroupTitle {
    flex: 1;
    font-size: 18px;
}

.itemWrap {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 4px;
}

.total__text {
    width: 100%;
    text-align: right;
    font-size: 16px;
    font-weight: 800;
    color: #212121;
    padding-right: 8px;
}

.resizeDragHandle {
    width: 10px;
    height: 100%;
    cursor: ew-resize;
    position: absolute;
    right: -6px;
    top: 0;
    z-index: 100;
}

.virtualResizeLine {
    width: 2px;
    height: calc(100vh - 110px);
    background: #1e90ff;
}

.loading {
    width: 100%;
    text-align: center;
    margin: 24px 0;
}

.itemWrap :global(.klassapp-dropdown__menu) {
    margin-top: 2px !important;
}
