.headingTitle {
    font-size: 37px;
    font-weight: bold;
    line-height: 1.41;
    color: var(--black-300);
    line-height: 1.14;
    padding-bottom: 12px;
    border-bottom: solid 1px var(--black-100);
    margin-bottom: 16px;
}

.saveBtn {
    margin-left: auto;
    min-width: 90px;
    justify-content: center;
}

.doneBtn {
    display: flex;
    margin-top: 30px;
}

.noteInput {
    margin: 8px 0px;
    border-radius: 8px;
    border: solid 1px #c7c7c7;
    background-color: var(--whitebase);
    padding: 8px;
}

.descTitle {
    font-size: 15px;
    color: var(--black-300);
    padding-bottom: 8px;
}

.field {
    padding-top: 16px;
}

.fieldLabel {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    color: var(--black-300);
    text-transform: uppercase;
}

.fieldDescription {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--black-300);
    padding-bottom: 12px;
}

.attachmentsWrap {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    margin-top: 10px;
}

.documentWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 8px;
    max-width: 200px;
    border-radius: 12px;
    border: solid 1px var(--black-100);
}

.documentFileName {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: var(--black-300);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.removeIcon {
    cursor: pointer;
    flex: 1;
    min-width: 24px;
}

.fileIcon {
    flex: 1;
    min-width: 24px;
}
