.container {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.addLessonButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-color: var(--black-100);
    margin-left: 10px;
}
.review {
    display: flex;
    flex-direction: row;
    padding: 4px 6px 4px 6px;
    align-items: center;
    gap: 4px;
    border-radius: 6px;
    width: 67px;
    background-color: var(--secondary-100);
}
.attendanceQrCode {
    padding: 11px;
}
.addLessonContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.signText {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-300);
}
.dateInfo {
    display: flex;
    flex-direction: column;
}

.dateInfo__text {
    font-size: 10px;
    font-weight: 600;
    color: #666666;
    margin-bottom: 3px;
}
.signatureName {
    font-size: 8px;
    font-weight: 600;
    color: #666666;
    margin-bottom: 3px;
}
.disabledHtml {
    color: #666666;
}
.statusWrap {
    display: flex;
    align-items: center;
}

.signatureWrap {
    padding: 7px 5px;
    display: flex;
    align-items: center;
    margin-right: 8px;
}

.signature__statusIcon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

.signature__approval {
    background-color: #e8faef;
}

.signature__reject {
    background-color: #ffe5eb;
}

.signature__icon {
    height: 16px;
    margin-right: 10px;
}

.signature__img {
    width: 50px;
    height: 20px;
}
.stats {
    display: flex;
    gap: 16px;
    margin-top: 20px;
    margin-bottom: 44px;
    justify-content: space-between;
    flex-direction: row;
}

.statsBox {
    display: flex;
    flex: 1;
    flex-direction: column;
    border-radius: 12px;
    box-shadow: 1px 2px 16px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px var(--black-100);
    background-color: var(--whitebase);
    padding: 10px 20px 10px 20px;
}

.filterStatsBox {
    display: flex;
    flex: 1;
    flex-direction: column;
    border-radius: 12px;
    cursor: pointer;
    box-shadow: 1px 2px 16px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px var(--black-100);
    background-color: var(--whitebase);
    padding: 10px 20px 10px 20px;
}

.statsBoxSelected {
    border: 2px solid var(--primary-300);
}

.statsTitle {
    margin-top: 6px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-300);
}

.statsValue {
    margin-top: 8px;
    font-family: Poppins;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}

.statsIcon {
    width: 15px;
    height: 15px;
}
.breakItem__icon {
    width: 26px;
    height: 26px;
}
.breakWrap {
    display: flex;
    gap: 12px;
    flex: 1;
}
.checkInWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.checkInContainer {
    display: flex;
    align-items: center;
    position: relative;
    gap: 64px;
}
.checkInIconWrap {
    width: 28px;
    height: 28px;
    display: flex;
    background-color: white;
    z-index: 2;
    border-radius: 14px;
    justify-content: center;
    align-items: center;
}

.checkInIcon {
    width: 24px;
    height: 24px;
}

.attendanceContainer {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: center;
    align-items: center;
    position: relative;
}
.breakItem {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.progressLine {
    height: 1px;
    background-color: var(--black-200);
    position: absolute;
    left: 0px;
    right: 0px;
    top: 13px;
    z-index: 1;
}
.checkInIconWrap {
    width: 28px;
    height: 28px;
    display: flex;
    background-color: white;
    z-index: 2;
    border-radius: 14px;
    justify-content: center;
    align-items: center;
}
.checkInTime {
    font-size: 12px;
    font-weight: 600;
    color: var(--black-300);
    margin-top: 3px;
    text-align: center;
    min-width: 60px;
}
.addStudentContainer {
    display: flex;
    width: 100%;
    margin-top: 20px;
    padding: 12px 8px 12px 8px;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f5f5;
}

.addStudentText {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: var(--black-200);
}

.addStudentRightButton {
    display: flex;
    gap: 16px;
}

.titleSignContainer {
    display: flex;
    padding-top: 20px;
    flex-direction: column;
    align-items: center;
}
.titleSign {
    margin-top: 20px;
    font-family: Poppins;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-200);
}
.subTitleSign {
    margin-bottom: 20px;
    margin-top: 8px;
    font-family: Poppins;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #000;
}
.datePicker {
    width: 500px;
    border-radius: 6px !important;
    box-shadow: 0 2px 12px 0 rgba(48, 49, 51, 0.1);
    margin-top: 16px !important;
    background-color: var(--whitebase) !important;
    border-color: transparent !important;
    padding-right: 0px !important;
}
.actionWrap {
    display: flex;
    justify-content: flex-end;
}
.attachmentContainer {
    padding: 16px;
    border-radius: 12px;
    background-color: #f5f5f5;
    width: 100%;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
