.courseItem {
    border-radius: 8px;
    box-shadow: 0 1px 12px 0 rgba(48, 49, 51, 0.16);
    background: #fff;
    padding: 4px;
    margin-bottom: 8px;
}

.pendingReview {
    display: flex;
    align-items: center;
    border-radius: 6px;
    border: solid 1px var(--warning-400-base);
    background-color: var(--warning-400-base);
    padding: 2px 12px;
}

.pendingReviewIcon {
    width: 20px;
    height: 20px;
}
.scheduleDropped {
    opacity: 0.4;
    pointer-events: none;
}

.pendingReview__left {
    display: flex;
    align-items: center;
    flex: 1;
    font-size: 14px;
    font-weight: bold;
    color: var(--whitebase);
    text-transform: uppercase;
}

.pendingReviewTitle {
    padding-left: 8px;
}

.bodyWrap {
    padding: 0 16px;
}

.courseItemDetail p {
    margin-bottom: 0;
}

.courseItemDetail__action {
    width: 32px;
    min-width: 32px;
}

.courseItemDetail__left {
    display: flex;
    align-items: center;
    width: calc(100% - 32px);
}

.courseItemDetail__left__courseCode {
    font-size: 16px;
    font-weight: 600;
    color: var(--primary-400-base);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    margin-right: 10px;
}

.courseItemDetail__left__courseName {
    font-size: 16px;
    font-weight: 500;
    color: var(--black-base);
    white-space: nowrap;
    overflow: hidden;
    flex: 2;
    text-overflow: ellipsis;
    display: inline-block;
}

.courseItemDetail {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #62b1ff;
    padding: 4px 0;
}

.courseItemBodyItem {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.courseItemBodyItem__title {
    font-size: 14px;
    font-weight: 500;
}

.courseItemBodyItem__creditWrap {
    display: inline-flex;
}

.courseItemBodyItem__credit {
    border-radius: 4px;
    background-color: #c7c7c7;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 16px;
    font-size: 16px;
    font-weight: bold;
    color: var(--black-base);
}

.courseItemBodyItem__value {
    font-size: 16px;
    white-space: pre-wrap;
    line-height: 18px;
    font-weight: bold;
    color: var(--black-base);
    min-height: 20px;
}

.prerequisiteRequireWrap {
    display: inline-block;
    margin-top: 8px;
}

.prerequisiteRequireBody {
    border: solid 1px var(--warning-400-base);
    background-color: var(--warning-100);
    border-radius: 8px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
}

.prerequisiteRequireText {
    font-size: 16px;
    font-weight: 600;
    color: var(--black-base);
    margin-left: 8px;
}

.menuActionItem {
    display: flex;
    align-items: center;
}

.iconBtn {
    border: none !important;
    background: transparent !important;
}

.iconBtn {
    border: none !important;
    background: transparent !important;
}

.menuActionWrap {
    padding: 17px;
}

.menuActionIcon {
    width: 24px;
    height: 24px;
}

.menuActionTitle {
    margin-left: 11px;
}

.threeDotsIcon {
    height: 16px;
}

.registerCourseBtnWrap {
    display: flex;
    align-items: center;
    gap: 12px;
}

.registerCourseBtn {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
    justify-content: center;
    height: 28px !important;
}

.courseStatus {
    display: flex;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 8px;
    word-break: break-word;
    white-space: pre-wrap;
}

.courseStatus__icon {
    height: 24px;
    width: 24px;
}

.courseStatus__pending {
    padding-left: 8px;
    font-size: 16px;
    font-weight: 600;
    color: var(--warning-400-base);
}

.courseStatus__registered {
    padding-left: 8px;
    font-size: 16px;
    font-weight: 600;
    color: var(--success-400-base);
}

.courseStatus__removed {
    padding-left: 8px;
    font-size: 16px;
    font-weight: 600;
    color: var(--error-400-base);
}

.courseStatus_loa {
    padding-left: 8px;
    font-size: 16px;
    font-weight: 600;
    color: var(--primary-300);
}

.selectScheduleWrap {
    position: relative;
}

.selectScheduleTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    line-height: 1.25;
    font-weight: bold;
    color: var(--black-300);
    margin-bottom: 6px;
    text-transform: uppercase;
}

.listSchedule {
    display: inline-flex;
    flex-wrap: wrap;
}

.scheduleItem {
    padding: 2px 10px;
    border-radius: 4px;
    box-shadow: 0 1px 12px 0 rgba(48, 49, 51, 0.16);
    background-color: var(--whitebase);
    margin-bottom: 10px;
    margin-right: 15px;
    font-size: 16px;
    font-weight: bold;
    color: var(--primary-400-base);
    border-radius: 4px;
    background: #fff;
    cursor: pointer;
}

.scheduleItem__selected {
    background-color: var(--primary-400-base);
    color: var(--whitebase);
}

.dropCourse {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.dropDateWrap {
    margin-top: 20px;
}
.classAssistance {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}
.classAssistanceInfo {
    margin-top: 5px;
}
.classAssistanceLabel {
    font-size: 16px;
    font-weight: 600;
    color: var(--primary-400-base);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
