.wrapper {
    margin-bottom: 40px;
}

.buttonWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
}

.cancelBtn {
    margin-right: 20px;
    text-transform: uppercase;
}

.titleWrap {
    display: flex;
    align-items: center;
}
.select {
    width: 250px;
}
